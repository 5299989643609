import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, locale: string = 'en-US'): any {
    let format = 'MM/dd/yyyy, h:mm a';
    if (locale === 'en-GB' || locale.startsWith('fr')) {
      format = 'dd/MM/yyyy, HH:mm';
    }
    return this.datePipe.transform(value, format, undefined, locale);
  }
}