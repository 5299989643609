import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { SharedService } from '../shared.service';
import { BaseService } from "../legacy/base/base.service"
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WebsocketService} from '@websocket' 
import { Location } from '@angular/common';
import { AudioService } from '../services/audio.service';
import { ChangeDetectorRef } from '@angular/core';



import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

declare var TelnyxWebRTC: any;

@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.css']
})

export class CallsComponent implements OnInit {


  sipDomain = environment.sipDomain;

selectedNumber: string = '17869834280';
  users = [ ];


  @Input() bPositionRight: boolean = false;

  callerIdText: string;

  settings = {
      autoCool1Week: false,
      addNewContacts: false,
      clearNumberOnHangup: true,
      autoDialNextContact: false,
      displayErrorMessages: true,
      playSoundsOnCall: true,
      doNotDisturbWhileOnCall: true,
      displayIncomingCalls: true,
      outboundRingtone: '',
      inboundRingtone: '',
      forwardNumber: '',
      callDirectly: false,
      callOption: ''
  };


  bSettingsLoaded = false;
  loadSettings() {
    this.settings = this.userInfo?.settings ?? this.settings;
    this.bSettingsLoaded = true;
  }

  async saveSettings() {
    localStorage.setItem('settings', JSON.stringify(this.settings));
    var api_url = `api/tmpdatasource/patch/datasource/users/_id/${this.userInfo._id}`
    let postBody = {
      "key": "settings",
      "value": this.settings
    }

    var result = await this.http.patch(api_url, postBody).toPromise();

    if(this.settings.callOption !== "normalCalls"){
      this.http.get('api/calls/setInboundConnectionProfile').toPromise();
    } else {
      this.http.get('api/calls/setDefaultConnectionProfile').toPromise();
  
    }
  
    console.log(86, this.settings.callDirectly)

  }



  buttonState = 'call';

  selectedCountry = "+1";

  lastCallDirection = "";
  private audioPlayer: HTMLAudioElement;
  private incomingCallAudioPlayer: HTMLAudioElement;
  private client: any;
  private currentCall: any;
  private incomingCall: any;

  private disconnectMessage: HTMLAudioElement;

  callState: any;
  inputPhoneNumber:any = "";
  leadInfo: any;
  lastCallUpdate: any;
  bIsRecording = false;
  bIsMuted = false;
  bIsOutbound = false;
  bCallState = false;
  status = 'Waiting For Call';
  audioInDevices = [];
  audioOutDevices = [];
  audioInputDeviceId = ""
  number = "";


  audioDevices = [];

  /*  Emulating call barging through call conferences
    
  */

  // This flag indicates an admin has initiated call barging mode.
  callBargingMode = false;
  /*
      If callBargingMode == true, when an incoming call is a call to itself, it's automatically answered.
      Then, a conference call is created.
  */

  // This flag indicates a client is the target of call barging.  This will cause calls to the automatically
  // joined into conferences
  callbargingTarget = false;
  callBargingConferenceId = ""; // If callbargingTarget is true, this is expected to be the conference id they will join

 draggingDisabled = false;

 call_barge_control_id = "";

 call_barge_target = "";
 async startAdminCallBarging(user_id =""){

console.log(599, "startAdminCallBarging");


  this.call_barge_target = user_id;
  this.callBargingMode = true;

  var api_url = "api/calls/dialMe"

  try {
     var result = await this.http.post(api_url, { "admin_id": this.userInfo._id, "agent_id": user_id } ).toPromise();

     this.call_barge_control_id = result["call_info"]["call_control_id"];

     
   } catch(err){
     console.log(182, err);
   }

   this.muteCall();

 }

 secondaryNumbers = [];

async call(acctInfo =null){

  // Format number

  // The user could be rage clicking -- let's just do nothing
  if(this.callLoading === true){
    if(this.callCircle === true){
      return;
    }
  }

  this.callLoading = true;
  this.callCircle = true;

  let countryCode = this.selectedCountry;
  if(this.inputPhoneNumber[0] == "+"){
    countryCode = "";
  }
  let numberToDial = this.dataService.formatPhoneNumber(countryCode + this.inputPhoneNumber);

  

  let authorized = await this.checkRestrictions(this.inputPhoneNumber);
  if(authorized === false){
    //alert("You are not authorized to call this number");
    this.dataService.setPopupMessage({"title": "Not Authorized", "message":"You are not authorized to call this number.  It is on the 'Do Not Call' list"})
    this.callLoading = false;
    this.callCircle = false;
    //bb console.log(193, "unauthorized");
    return;
  }

  this.playRinging(this.inputPhoneNumber);

  //bb console.log(172, "checkRestrictions returned");

  this.dataService.setDialedNumber(numberToDial);

  let callerID = this.dataService.formatPhoneNumber(this.selectedCountry + this.number);

  this.inputPhoneNumber = numberToDial;
  // We will generate a new uuid for every outbound call.

  const uuid = uuidv4();
  this.call_uuid = uuid;
  //bb console.log(208, 'setting call_uuid', uuid);
  this.recordEndpoint = this.recordEndpoint + uuid;

  if(typeof this.leadInfo !== "undefined"){
   // if(typeof this.leadInfo["call_uuid"] !== "undefined"){
    this.leadInfo["call_uuid"] = uuid;
   //}
  }

  var destinationNumber = this.inputPhoneNumber.replace(/\D/g, ''); // "18004377950";//   

  if(typeof this.client === 'undefined'){
    //alert("There was an internet connection hiccup.  Please wait a few seconds and try again.");
    this.dataService.setPopupMessage({"title": "Internet Problem", "message":"There was an internet connection hiccup.  Please wait a few seconds and try again."})


    this.initializeCallAgent(this.userInfo);

    //bb console.log(199, " returned");
      this.callLoading = false;
    this.callCircle = false;
    return;
  }

  //bb console.log(200);

  //destinationNumber = '18004633339'

  let sipDestination = `sip:+${destinationNumber}@scrapercrmtest.sip.telnyx.com`

  let callData = {
    destinationNumber: sipDestination,
    callerNumber: this.number
  }

  // let callData = {
  //   destinationNumber: destinationNumber, //"", // 
  //   callerNumber: this.number
  // }

  let clientState = { }
  if(this.bAgentCallBarging === true){
    clientState["conference_id"] = this.agentCallBargeConferenceId;

  } else {
    callData['destinationNumber'] = destinationNumber;
  }

  if(acctInfo !== null){
    callData["clientState"] = btoa(JSON.stringify( { ... clientState, "contact_id": this.leadInfo["_id"], call_uuid: uuid, "role":"agent", agent_id: this.userInfo._id } ) )
  } else {
    callData["clientState"] = btoa(JSON.stringify( { ... clientState, call_uuid: uuid, "role": "agent" } ) )
  }

  if(callData.callerNumber[0] !== "+"){
    callData.callerNumber = `+${callData.callerNumber}`
  }

  if(callData.destinationNumber[0] !== "+"){
    callData.destinationNumber = `+${callData.destinationNumber}`
  }

  //sipDestination = `sip:+${destinationNumber}@${this.sipDomain}`
  sipDestination = `sip:+${destinationNumber}@callwhiserping.saas-product.com.sip.telnyx.com`
  callData['destinationNumber'] = sipDestination;

  if(sipDestination == `sip:+@${this.sipDomain}`){
      this.callLoading = false;
      this.callCircle = false;
    return;
  }

  if(sipDestination == `sip:+@${this.sipDomain}`){
      this.callLoading = false;
      this.callCircle = false;
    return;
  }

  if(this.settings.callDirectly === false){
      //sipDestination = `sip:+${destinationNumber}@${this.sipDomain}`
        sipDestination = `sip:+${destinationNumber}@callwhiserping.saas-product.com.sip.telnyx.com`
      callData['destinationNumber'] = sipDestination;//`+${callData['destinationNumber']}`;
      //console.log(298, "Calling Using Server", sipDestination)
  } else {
      callData['destinationNumber'] = destinationNumber;
      //console.log(301, "Calling Directly", destinationNumber)
  }

  //const savedState = localStorage.getItem('openDispositionOnHangup');
  localStorage.setItem('lastDialedNumber', destinationNumber);

  // async call()
  this.bIsOutbound = true;
  this.currentCall = await this.client.newCall(callData);

  console.log(303, 'Call Data', callData);

  this.callLoading = false;
  this.callCircle = false;

  //if(acctInfo !== null)
  //  this.addInitialCallRecord(this.leadInfo["_id"], uuid);
}

  callLoading = false;
  callCircle = false;

  

async manualCall(){


  console.log(324, this.inputPhoneNumber);

  if(this.inputPhoneNumber.length == 0){

    this.inputPhoneNumber = localStorage.getItem('lastDialedNumber');
  }
  
  this.callLoading = true;
  this.callCircle = true;

 // return;


  try {
    const uuid = uuidv4();
    this.call_uuid = uuid;
      //bb console.log(299, 'setting call_uuid', uuid);
    this.recordEndpoint = this.recordEndpoint + uuid;
    this.inputPhoneNumber = this.dataService.formatPhoneNumber(this.inputPhoneNumber);

    if(this.inputPhoneNumber === 'Invalid phone number'){
       this.callLoading = false;
       this.callCircle = false;
       return; 
    }

    this.dataService.setDialedNumber(this.inputPhoneNumber);
    let authorized = await this.checkRestrictions(this.inputPhoneNumber);
    if(authorized === false){
      this.dataService.setPopupMessage({"title": "Not Authorized", "message":"You are not authorized to call this number"})
      this.callLoading = false;
      this.callCircle = false;
      //bb console.log(193, "unauthorized");
      return;
    }

    this.playRinging(this.inputPhoneNumber);

    const destinationNumber = this.inputPhoneNumber.replace(/\D/g, '');
    let sipDestination = destinationNumber;

    localStorage.setItem('lastDialedNumber', destinationNumber);
    console.log(367, destinationNumber);
    localStorage.setItem('lastDialedNumber', destinationNumber);
    
    let callData = {
      destinationNumber: destinationNumber,
      callerNumber: this.number
    };
    let clientState = { };
    if(this.bAgentCallBarging === true){
      clientState["conference_id"] = this.agentCallBargeConferenceId;
    } else {
      callData['destinationNumber'] = destinationNumber;
    }

    if(this.settings.callDirectly === false){
      //sipDestination = `sip:+${destinationNumber}@${this.sipDomain}` callwhiserping.saas-product.com
      sipDestination = `sip:+${destinationNumber}@callwhiserping.saas-product.com.sip.telnyx.com`
      callData['destinationNumber'] = sipDestination;
      console.log(340, "Calling Using Server",  sipDestination)
    } else {
      callData['destinationNumber'] = `+${callData['destinationNumber']}`;
      console.log(343, this.settings.callDirectly, "Calling Directly", `${callData['destinationNumber']}`)
    }

    callData["clientState"] = btoa(JSON.stringify( { ... clientState, call_uuid: uuid, "role":"agent", agent_id: this.userInfo._id } ) );
    
    callData['callerNumber'] = `+${callData['callerNumber']}`;

    this.bIsOutbound = true;

    console.log(943, "manualCall", callData);

    if(sipDestination == `sip:+@${this.sipDomain}`){
      this.callLoading = false;
      this.callCircle = false;      
      return;
    }

    if(sipDestination == `sip:+@${this.sipDomain}`){
      this.callLoading = false;
      this.callCircle = false;
      return;
    }

    if(destinationNumber.length < 5){
      this.callLoading = false;
      this.callCircle = false;
      return;
    }

    console.log(396, callData);

    
    this.currentCall = await this.client.newCall(callData);

    //const { telnyxCallControlId, telnyxSessionId, telnyxLegId } = this.currentCall.telnyxIDs;
    console.log(394, 'Call Control ID:', this.currentCall);

  } catch (error) {
    console.error('telnyx.js manualCall:', error);
  }

    this.callLoading = false;
    this.callCircle = false;
}

 endAdminCallBarging(){
  this.callBargingMode = false;
  this.call_barge_target = "";

  clearInterval(this.notifyCallBargingInterval);

  if(this.bIsCallActive){
    this.disconnect();
  }

  this.unmuteCall();

 }

  toggleRecording(){
    

    if(!this.bIsRecording){
      this.startRecording()
    } else {
      this.stopRecording()
    }
  }

  toggleMuted(){


    this.bIsMuted = !this.bIsMuted

    if(this.bIsMuted){
      this.muteCall()
    } else {
      this.unmuteCall()
    }
  }

  async muteCall(){
    this.bIsMuted = true;
    this.currentCall.muteAudio();
    this.sharedService.disableMuteButton();
  }

  async unmuteCall(){
    this.bIsMuted = false;
    this.currentCall.unmuteAudio();
    this.sharedService.enableMuteButton();
  }

  stream: MediaStream | null = null;
  isMuted: boolean = false;

  async getMicrophone() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    } catch (error) {
      console.error('Error accessing the microphone:', error);
        alert("Unable to initialize calling -- try browser refresh");
        this.dataService.setPopupMessage({"title":"Internet Problem", "message":"Unable to initialize calling -- try browser refresh"})
    }
  }

  // toggleMute() {
  //   this.isMuted = !this.isMuted;
  //   this.stream.getAudioTracks().forEach(track => {
  //     track.enabled = !this.isMuted;
  //   });
  // }

recordEndpoint = `api/calls/startRecording/call_uuid/`

circle: boolean = false;
idle: boolean = false;
  // Request Initiated
  loading: boolean = false;

  // Success
  success: boolean = false;

  // Failure
  failure: boolean = false;
  material_icon = ""

async startRecording(){
  //bb console.log(336, 'startRecording');
  try {
      this.circle = true;
      this.loading = true;
      var api_url = `api/calls/startRecording/call_uuid/${this.call_uuid}`;
      let result = await this.http.get(api_url).toPromise();
      this.sharedService.enableRecordButton();
      this.bIsRecording = !this.bIsRecording;
      this.circle = false;
      this.loading = false;
    } catch (error) {
      console.error('telnyx.js startRecording:', error);
      this.circle = false;
      this.loading = false;
    }
}

async stopRecording(){
  try {
    this.bIsRecording = !this.bIsRecording;
    this.sharedService.disableRecordButton();
    var api_url = `api/calls/stopRecording/call_uuid/${this.call_uuid}`;
    let result = await this.http.get(api_url).toPromise();
  } catch (error) {
    console.error('telnyx.js stopRecording:', error);
  }
}

async resumeRecording(){
  try {
    var api_url = `api/calls/resumeRecording/call_uuid/${this.call_uuid}`;
    let result = await this.http.get(api_url).toPromise();
  } catch (error) {
    console.error('telnyx.js resumeRecording:', error);
  }
}

  @ViewChild('dialPad') private dialPad: ElementRef;

  refreshPage(): void {
    //setTimeout(() => window.location.reload(), 6000);
  }

  bAgentCallBarging = false;
  agentCallBargeConferenceId = ""

  constructor(
    private audioService: AudioService,
    private sharedService: SharedService, 
    private location: Location,
    private translate: TranslateService,
    private websocketService: WebsocketService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService, private http: HttpClient, public service: BaseService, private router: Router ) {

    // this.dataService.getUserInfo().subscribe(loginInfo => {
    //   this.userInfo = loginInfo;
    // });    

    this.sharedService.getData().subscribe(data => {

      // Use data here
      if(this.bCallState === false) {
        this.inputPhoneNumber = data.row.phone;
        this.leadInfo = data.row;
        this.call(data);
      } else {
      
      if(this.callDirection == "incoming"){
          //alert("You have an incoming call.  Please answer or reject it before trying to make another call");
          this.dataService.setPopupMessage({"title":"Error", "message":"You have an incoming call.  Please answer or reject it before trying to make another call"})
          return;  
      }
        //alert("You are already on a call.");
        console.log(572, "You are already on a call");
        //this.dataService.setPopupMessage({"title":"Error", "message":"You are already on a call."})
      }
    });

    this.sharedService.getCmd().subscribe(cmd => {

      switch(cmd["cmd"]){

      case "join_conference_call":

        this.bAgentCallBarging = true;
        this.agentCallBargeConferenceId = cmd["conference_id"];
        this.audioPlayer.src = 'assets/audio/waiting-ringtone.wav';
        this.audioPlayer.load();
        // Now, do something to bridge the two calls...
        break;

      case "end_call_barging":

        this.bAgentCallBarging = false;
        //this.audioPlayer.src = 'assets/audio/phone-ringing-2.m4a';
        //this.audioPlayer.load();
        //this.agentCallBargeConferenceId = "";
        // Now, do something to bridge the two calls...
        break;

      case "startAdminCallBarge":

      
        this.startAdminCallBarging(cmd["user"]["_id"]);
        break;

      case "endAdminCallBarge":
        console.log(596, 'endAdminCallBarging')
        this.endAdminCallBarging();
        break;

        case "disconnect":

          this.disconnect();
          this.unmuteCall();
          break;
        case "record":

          this.bIsRecording = true;
          this.startRecording()
          break;

        case "pause":

          this.bIsRecording = false;
          this.stopRecording();
          break;

        case "mute":
          this.muteCall();
          break;
        
      case "unmute":
        this.unmuteCall();
        break;
        }
    })
  }

  async onDeviceChange(device: any) {
      // Function logic here

      //this.audioInputDeviceId = deviceId;
      // Other actions based on deviceId

      try {
        var audio = await this.client.setAudioSettings({
         micId: device.deviceId,
         micLabel: device.label,
         speakerId: device.deviceId,
         speakerLabel: device.label,
         echoCancellation: false
        })
        
      } catch(err){
        
      }
  }

  playRinging(to: any): void {

    if(!this.settings.callDirectly){
      this.audioService.play("single_ring", to);
    } else {
      this.audioService.play("outbound_call", to);
    }
    //
  }

  playIncomingCallRinging(): void {
    this.audioService.play("inbound_call");
  }

  pauseRinging(): void {
    this.audioService.pause();
  }

  bIsCallActive = false;
  currentCallId = "";
  incomingCaller = "";

  incomingCallAccepted = null;
  bAnsweredIncomingCall = null;
  callDirection = "";

  userInfo = null;

  playDisconnectMessage(msg: string){
    switch(msg){
    case 'you_disconnected':
      this.audioService.play("disconnected");
      break;
    
    case 'the_call_dropped':
      this.audioService.play("dropped");
      break;
    
    case 'the_other_party_hungup':
      //bb console.log(475);
      this.audioService.play("hungup");
      break;
    }
  }

  waiting_for_call = "Waiting For Call";
  enter_phone_number = "Enter phone number";

  private langChangeSubscription: Subscription;

  async updateLanguage(){

  }

  bTelephonyInitDataReceived = false;

  officeUsers = [
    { number: '1234567890', name: 'John Doe' },
    { number: '0987654321', name: 'Jane Smith' }
  ];

async ngOnInit() {



  const savedState = localStorage.getItem('openDispositionOnHangup');
  if (savedState !== null) {
    this.openDispositionOnHangup = JSON.parse(savedState);
  }
  this.selectedCountry = localStorage.getItem('countryCode');
  if(this.selectedCountry === null){
    this.dataService.setCountryCode("+1");
    this.selectedCountry = "+1";
  }
  this.translate.get('waiting_for_call').subscribe((res: string) => {
    this.waiting_for_call = res;
    this.status = this.waiting_for_call;
  });
  this.translate.get('enter_phone_number').subscribe((res: string) => {
    this.enter_phone_number = res;
  });
  this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('waiting_for_call').subscribe((res: string) => {
      this.waiting_for_call = res;
      this.status = this.waiting_for_call;
    });
    this.translate.get('enter_phone_number').subscribe((res: string) => {
      this.enter_phone_number = res;
    });
  });
  this.callState = "disconnected"
  this.dataService.getDialerNumber().subscribe(data => {
    this.inputPhoneNumber = data;
  });
  this.dataService.getUserInfo().subscribe(async data => {

    if(data === null){
      return;
    }

    if(this.bSettingsLoaded === true){
      return;
    }

    try {
      this.userInfo = data;

      this.loadSettings();

       this.updateTalkTime();

    this.callerIdText = this.number === this.userInfo.telephony.number ? 'Set American Caller ID' : 'Set German Caller ID';

      if(Array.isArray(this.userInfo.additionalNumbers)){

        this.secondaryNumbers = this.userInfo.additionalNumbers;  
        this.secondaryNumbers.unshift(this.userInfo.telephony);

      }
      
      if((typeof data !== 'undefined')&&(data !== null)){
        if(typeof data['telephony'] !== 'undefined'){
          if(this.bTelephonyInitDataReceived == false){
            this.bTelephonyInitDataReceived = true;
            await this.initializeCallAgent(data);  
          }
        }
      }




this.sharedService.getOfficeUsers(this.userInfo['office_id']).subscribe(officeUsers => {
  if (!officeUsers) {
    return;
  }

  // Update users without causing duplicates
  this.users = [...officeUsers];

  // Find the matching user and set selectedNumber
  const matchingUser = this.users.find(user => user.number === this.settings.forwardNumber);

  if (matchingUser) {
    this.selectedNumber = matchingUser.number;
  } else {
    this.selectedNumber = this.users[0]?.number || ''; // Default to the first user's number
  }

});


    } catch (error) {
      console.error('telnyx.js ngOnInit:', error);
    }
  });


}

trackByUserNumber(index: number, user: any): string {
  return user.number;
}

onNumberChange(event: Event): void {
    this.draggingDisabled = true;
    const selectedNumber = (event.target as HTMLSelectElement).value;

    for(var obj of this.secondaryNumbers){
      if(selectedNumber == obj.number){
        this.initializeCallAgent( { "telephony": obj } )
      }
    }
    // Add your logic here to handle the change event
  }

  onForwardToUserChange($event): void {
    this.draggingDisabled = true;
    const selectedNumber = (event.target as HTMLSelectElement).value;
    //bb console.log('Selected number:', selectedNumber);
    this.settings['forwardNumber'] = selectedNumber;
    this.selectedNumber = selectedNumber;
    this.saveSettings();
    // for(var obj of this.officeUsers){
    //   if(selectedNumber == obj.number){
    //     //this.initializeCallAgent( { "telephony": obj } )
    //   }
    // }
  }

ngOnDestroy(): void {
    this.bReadyToCall = false;
    this.bTelephonyInitDataReceived = false;
    this.client.disconnect();
}

async resetDialPad() {

  var api_url = `api/calls/resetAgentSettings`;

  try {
     let result = await this.http.get(api_url).toPromise();

   } catch(err){
     //bb console.log(182, err);
   }

  this.dataService.resetDialPad(true);
}

async startCallRecording(callControlId: String){

}

reject(){

  //bb console.log(555, "reject() called");

  this.thisUserDisconnected = true;
  console.log(877, ".hangup()");
  this.incomingCall.hangup();
  this.audioService.pause();


  //bb console.log(556, this.thisUserDisconnected)

  if(this.bIsCallActive == true){
    this.buttonState = "disconnect"
  } else {
    //bb console.log(545, "Calling resetDialer(null)")
    this.resetDialer(null)
  }
}

thisUserDisconnected = false;
disconnect(){

  console.log(917, "See if we're supposed to be in a conference");

  this.sharedService.sendCmd({ "cmd":"disconnectBtnClicked"});

  
  this.thisUserDisconnected = true;
  //bb console.log(567, "disconnect called", this.thisUserDisconnected);

  this.currentCall.hangup();
  //this.client.disconnect();
  this.bCallState = false;
  //bb console.log(812, "setting bCallState false");
  this.audioService.pause();

  this.resetDialer(null);
  
}

dialnum(digit: any =''){

  this.inputPhoneNumber = this.inputPhoneNumber + digit;
  this.audioService.play(digit);

  if (this.currentCall && digit) {
    this.currentCall.dtmf(digit);
  } else {

  }
}


  // public addToArray(id, key, value, pluralize = false) {
  //   var api_url = this.baseUrl + '/datasource/' + this.key + '/array' + '/id/' + id;

  //   if (pluralize == true)
  //     api_url += "/pluralize";

  //   this.last_post_body = {
  //     "key": key,
  //     "value": value
  //   }
  //   return this.http.post(api_url, {
  //     "key": key,
  //     "value": value
  //   }, this.httpOptions).pipe(
  //     map(response => {

  //       return response;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }

call_uuid = "";
async addInitialCallRecord(id ="", uuid =""){
  
  return;

  var api_url = `api/datasource/coldleads/array/id/${id}`;
  var postBody: any = {
    "key": "callRecords",
    "value": {
      name: `${this.userInfo.first_name} ${this.userInfo.last_name}`,
      call_uuid: uuid,
      webRtcSessionId: '',
      callControlSessionId: ''
    }
  }


  if(typeof this.unAttachedIncomingCall['custom_headers'] !== 'undefined'){
    postBody = { 
      "key" : "callRecords",
      "value": { "call_uuid": uuid, name: `${this.userInfo.first_name} ${this.userInfo.last_name}`, ... this.unAttachedIncomingCall, callControlSessionId: this.unAttachedIncomingCall['call_control_id'] }
    }
  }

  try {
     let result = await this.http.post(api_url, postBody).toPromise();

   } catch(err){
     //bb console.log(182, err);
   }

   this.call_uuid = uuid;
     //bb console.log(884, 'setting call_uuid', uuid);
   this.recordEndpoint = this.recordEndpoint + uuid;
}

async addCallLog(id ="", callLog ={}){
 
  return;
  
  var api_url = `api/datasource/coldleads/array/id/${id}`;
  let postBody = {
    "key": "callLogs",
    "value": {
      direction: callLog['direction'],
      gotAnswer: callLog['gotAnswer'],
      gotEarly: callLog['gotEarly'],
      id: callLog['id'],
      options: callLog['options'],
      prevState: callLog['prevState'],
      role: callLog['role'],
      sipCallId: callLog['sipCallId'],
      sipCode: callLog['sipCode'],
      sipReason: callLog['sipReason'],
      sessionid: callLog["session"]["sessionid"],
      session_uuid: callLog["session"]["uuid"]
  }
  
  }

  try {
     let result = await this.http.post(api_url, postBody).toPromise();

   } catch(err){
     //bb console.log(182, err);
   }  

   //this.associateCallRecording(id)

}

/* At this stage of development, I don't, yet, have a reliable mechanism to associate specific calls with specific recordings.  I expect
to be able to resolve this with continued development, but for the time being the only information I have is 1) The timestamp of when a call
began.
*/

async associateCallRecording(_id: any){
  try {
    let notificationTimestamp = dayjs().utc().format();
    var api_url = `api/calls/linkCallRecordingWithAccount`;
    var postBody = {
      _id: _id,
      timestamp: notificationTimestamp
    }
    await this.http.post(api_url, postBody).toPromise();
  } catch (error) {
    console.error('telnyx.js associateCallRecording:', error);
  }
}

inboundCallerBestMatches: any = [];

async updateCallRecord(call_uuid: string, sessionid: string){
  try {
    var api_url = `api/calls/updateCallRecord/`;
    let updateCallRecordResult = await this.http.post(api_url, { "call_uuid": this.call_uuid, "sessionid":sessionid }).toPromise();
  } catch (error) {
    console.error('telnyx.js updateCallRecord:', error);
  }
}

async joinIfMonitored() {
  var api_url = `api/calls/joinIfMonitored`;
  
  try {
    console.log(1074, { user_id: this.userInfo._id });
    //setTimeout( async () => {
      await this.http.post(api_url, { user_id: this.userInfo._id }).toPromise();
    //}, 5000)
    
  } catch (error) {
    console.error('Error in joinIfMonitored:', error);
  }
}



async findBestMatches(remoteCallerNumber: string){

  if(this.settings.doNotDisturbWhileOnCall == true){
    if(this.bIsCallActive){
       return;  
    }
  }
  
  if(!this.settings.displayIncomingCalls){
    return;
  }

  try {
    if(this.callBargingMode){
      return;
    }
    let countryCode = this.selectedCountry;
    var api_url = `api/calls/findBestMatches/remoteCallerNumber/${remoteCallerNumber}`;
    let bestMatches = await this.http.get(api_url).toPromise();
    if(bestMatches["incoming_call"]["exact_match"] == true){
      //bb console.log(744, bestMatches);
      this.dataService.setIncomingCall(bestMatches);
    } else {
      this.dataService.openUnmatchedIncomingCallModal(`${countryCode}${remoteCallerNumber}`);
    }
  } catch (error) {
    console.error('telnyx.js findBestMatches:', error);
  }
}

async answer(){

  // const uuid = uuidv4();
  // this.call_uuid = uuid;
  // this.leadInfo["call_uuid"] = uuid;

  this.buttonState = 'disconnect';

  try {
    await this.currentCall.answer();
  } catch(err){
    console.log(1128)
  }

  this.inputPhoneNumber = this.incomingCaller;



}

async selectCorrectLead(leadInfo){
  try {
    this.leadInfo = leadInfo;
    var title = this.leadInfo["scrape_result"]["title"];
    var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.title" + '/' + this.leadInfo["scrape_result"]["title"];
    this.router.navigate([`main/search`]);
    let result = await this.http.get(api_url, this.httpOptions).toPromise();
    this.sharedService.updateSearchResults(result, title);
    this.inboundCallerBestMatches = [];
    this.addInitialCallRecord(this.leadInfo["_id"], this.currentCall.id);
  } catch (error) {
    console.error('telnyx.js selectCorrectLead:', error);
  }
}

  httpOptions = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    "withCredentials": true
  };

async checkRestrictions(inputPhoneNumber){

  
  var api_url = `api/calls/checkRestrictions`

  try {
     var result = await this.http.post(api_url, {phoneNumber: `${inputPhoneNumber.startsWith('+') ? inputPhoneNumber : '+' + inputPhoneNumber}`}).toPromise();
   } catch(err){
     //bb console.log(182, err);
   }

   ////bb console.log(825, result);

   if(result['restrictions'] === 'none'){
    return true;
   }

   return false;

}



unAttachedIncomingCall = {}

async getUnattachedCallRecord(){
  //setTimeout( async () => {
    // var api_url = `api/calls/getUnAttachedIncomingCallRecord/call_uuid/${this.call_uuid}`;
    // let unAttachedRecord = await this.http.get(api_url).toPromise();  
    // this.unAttachedIncomingCall = unAttachedRecord['unAttachedIncomingCall'];
}

bReadyToCall = false;

    toggleCallerId($event) {
      $event.preventDefault();
    this.number = this.number === this.userInfo.telephony.number ? '17869834080' : this.userInfo.telephony.number;

    this.callerIdText = this.number === this.userInfo.telephony.number ? 'Set American Caller ID' : 'Set German Caller ID';
    this.changeCallerId();
  }

  changeCallerId() {
    // Implement the logic to handle the change in caller ID here
    //bb console.log('Caller ID changed to:', this.number);
  }

async initializeCallAgent(data){


      this.number = data.telephony.number;
      
      try {
        this.client = new TelnyxWebRTC.TelnyxRTC({
          login: data.telephony.login,
          password: data.telephony.password
        });
      
      } catch(err){
        //bb console.log(197, "Error Making WebRTC Client", err)
        //alert("Error Making WebRTC Client")
        this.dataService.setPopupMessage({"title":"Internet Problem", "message":"Error Making WebRTC Client"})
      }

    this.client
      .on('telnyx.error', (err) => {
        //console.log(892, err.message)
        this.resetDialer(null);
        //alert("There was an error connecting.  Try browser refresh")
        //this.pauseRinging();
      })
      .on('telnyx.ready', () => {
        this.bReadyToCall = true;
      })

      .on('telnyx.socket.open', () => {
        //this.pauseRinging();
        console.log(1216)
      })

      .on('telnyx.socket.close', () => {
        //console.log(122)

      })

      .on('telnyx.socket.error', (error) => {
        //console.log(913, "socket error", error);
        //this.pauseRinging();
      })

      .on('telnyx.socket.message', (message) => {
        //console.log(918, "socket message", message);
      })

      // .on('telnyx.callUpdate', (call) => {
      //   console.log(1204, call.state)
      //   if (call.state === 'active') {
      //     console.log('Call is active');

      //     // Listen for DTMF events
      //     call.on('dtmf', (event) => {
      //       console.log('DTMF received:', event.digit);
      //       // Handle the DTMF digit as needed
      //     });
      //   }
      // })

      .on('dtmf', async (dtmf) => {
        //console.log(1217, "dtmf received");
      })

      .on('telnyx.notification', async (notification) => {

        //console.log(923, 'telnyx.notification', notification);

        const call = notification.call;

        if(typeof call.direction !== 'undefined'){
          this.lastCallDirection = call.direction;
        }

        let notificationTimestamp = dayjs().utc().format();

        if(notification.type == "callUpdate"){
          this.callState = call.state;
          this.lastCallUpdate = call;

          console.log(1274, call.state, call.options.remoteCallerName);

          if(call.state == "ringing"){
              if(call.options.remoteCallerName == "ConferenceReconnect"){
                this.callState = "reconnecting";
              // Answer the call
              console.log(1289, "Answering the call")
              await call.answer(); 
              console.log(1291, "Call Answered")
              this.currentCallId = call.id;
              
              this.currentCall = call;
              console.log(1323)

              try {
                await this.joinIfMonitored();  
              } catch(err){
                console.log(1328, err);
              }
              

              console.log(1327)
              // Issue a command to rejoin the conference

              return;
            }
          }

          switch(call.state){

          case "new":
            this.sharedService.setCallStatus(call.state);

            if(this.settings.doNotDisturbWhileOnCall == true){
              //bb console.log(927, this.client);
            }

            if(this.bIsOutbound == true){

              this.bCallState =  true;
              //bb console.log(1145, "setting bCallState true");

              this.websocketService.setCallStatus(this.bCallState);
              try{
                ////bb console.log(870, "new");
                if(!this.callBargingMode) { 
                  //this.playRinging(); 
                }
              } catch(err){

              }
              this.buttonState = "disconnect";
              this.status = "Ringing"

              this.sharedService.enableCallButtons(true);
              this.bIsOutbound = false;
            }
            break;

          // case "answered":

          //   console.log(1308, "Call is answered, join it back into the conference", call.options.remoteCallerName);

          //   if(call.options.remoteCallerName === "ConferenceReconnect"){
          //     this.joinIfMonitored();
          //   }
          //   break;

          case "ringing":

            this.sharedService.setCallStatus(call.state);
            if(this.bIsCallActive === true){
              if(this.currentCallId !== call.id){
                if(this.settings.doNotDisturbWhileOnCall == true){
                  if(this.bIsCallActive){
                              console.log(1305, "call.hangup");
                              call.hangup(call);
                              this.thisUserDisconnected = true;
                              this.findBestMatches(this.dataService.formatPhoneNumber(call.options.remoteCallerNumber));
                              setTimeout( () => {
                                this.thisUserDisconnected = false;
                              }, 4000)
                              
                              return;
                           }
                          }
                  }
                }
            this.currentCallId = call.id;
            this.callState = "ringing";
            this.currentCall = call;
            this.buttonState = "answer";
            this.callDirection = "incoming"
            this.incomingCall = call;

            //bb console.log(1191, this.callDirection);

            this.http.get('/api/calls/getIncomingCallUuid').subscribe(result => {
            
              // Handle the result here

              if(this.call_uuid === result['call_uuid']){
                console.log(1198, "The call ids are the same, trying again in 3 seconds");
                setTimeout( async () => {
                  let result = await this.http.get('/api/calls/getIncomingCallUuid').toPromise();
                  this.call_uuid = result['call_uuid'];
                }, 3000)
              }

              this.call_uuid = result['call_uuid'];
              
            });

            // if(this.callBargingMode === false){
            //   this.call_uuid = call.id;
            // }
            

            //this.getUnattachedCallRecord();

            this.maximizeDialPad();


            if(!this.bIsCallActive){
              if(!this.callBargingMode) 
                { 
                  this.playIncomingCallRinging(); 
                } else {
                  this.answer(); 
              }
              
              this.incomingCallAccepted = false;

              // Let's give the user 120 seconds to answer, before auto-rejecting the call
              setTimeout( () => {

                // They answered the incoming call.  No further action is needed
                // Will need to handle hangup logic.
                if(this.bAnsweredIncomingCall == true){
                  this.bAnsweredIncomingCall = null;
                  return;""
                }

                // A call is not active
                if(!this.bIsCallActive){
                  //if(this)
                }
              }, 60 * 1000 * 2)
            } else {
              // Handle the logic of what to do when you're on an existing call but another
              // call comes in.  I actually don't think I'll get notified under this structure

            }

            try {

              this.findBestMatches(this.dataService.formatPhoneNumber(call.options.remoteCallerNumber));
              //this.incomingCaller = this.dataService.formatPhoneNumber(call.options.remoteCallerNumber);              
            } catch(err){

            }

            // Finally, set a 30 second timeout.  If the call isn't answered by then, reject it.
            setTimeout( () =>{
              if(this.bIsCallActive !== true){
                // Click the reject button
                this.reject();
              }
            }, 1000 * 30);

            break;

            case "requesting":
              //this.updateCallRecord(this.call_uuid, call.session.sessionid);
              break;
            case "trying":
              break;
            case "early":
              break;
            case "active":

            //   console.log(1429)
              
            // console.log(1308, "Call is answered, join it back into the conference", call.options.remoteCallerName);

            // if(call.options.remoteCallerName === "ConferenceReconnect"){
            //   this.joinIfMonitored();
            // }

              this.buttonState = "disconnect";
              this.audioService.pause();

              

              this.currentCallTalkTime = "00:00:00";
              
              this.timer = setInterval(() => {
                  this.seconds++;
                  let hrs = Math.floor(this.seconds / 3600);
                  let mins = Math.floor((this.seconds % 3600) / 60);
                  let secs = this.seconds % 60;

                  this.currentCallTalkTime = 
                      (hrs < 10 ? "0" : "") + hrs + ":" + 
                      (mins < 10 ? "0" : "") + mins + ":" + 
                      (secs < 10 ? "0" : "") + secs;
              }, 1000);

              if(this.callDirection == "incoming"){
                this.bAnsweredIncomingCall = true;
                
              } else {
                if(this.callBargingMode){
                  this.muteCall();

                  // We give the backend a small breather, since a user won't notice 1.5 seconds
                  setTimeout( () => {
                    this.createConference();
                  }, 1500)
                  
                }
              }
                


        //              this.bAgentCallBarging = true;
        //this.agentCallBargeConferenceId = cmd["conference_id"];

              if(this.bAgentCallBarging == true){

                // setTimeout( () => {
                //   this.joinConference(this.call_uuid, this.agentCallBargeConferenceId);  
                // }, 5000);
                
              }


              this.bIsCallActive = true;
              this.currentCallId = call.id;

              this.callState = "connected";
              try {
                const currentTimeInTimezone = dayjs().tz(this.leadInfo["timezone"]).format('h:mm A');
                this.status = `It's ${currentTimeInTimezone} In the Contacts Timezone`  
              } catch(err){
                 this.status = `Have a nice day` 
              }
              
              break;

            case "hangup":

              this.handleSipCause(call);
              
              if(call.causeCode == 17){
                if(this.settings.playSoundsOnCall)
                  this.audioService.playDelayed("unavailable");
                break;
              }

              if(call.causeCode == 18){
                if(this.settings.playSoundsOnCall)
                  this.audioService.playDelayed("cause_code_18");
                break;
              }

              if(call.causeCode == 19){
                //this.audioService.play("cause_code_18");
                break;
              }

              if(call.causeCode == 21){
                if(this.settings.playSoundsOnCall)
                  this.audioService.playDelayed("rejected");
                break;
              }

              if(call.causeCode == 20){
                if(this.settings.playSoundsOnCall)
                  this.audioService.playDelayed("unavailable");
                break;
              }

              if(call.cause != 'NORMAL_CLEARING'){
               // this.audioService.play("dropped");
                break;
              }

              if(call.direction == "outbound"){

                this.resetDialer(null);
              }

              this.handleHangup();

              break;
            case "destroy":

              if(this.settings.doNotDisturbWhileOnCall == true){
                
              }

              clearInterval(this.timer);
              
              this.seconds = 0;

              if(call.id === this.currentCallId){
                this.sharedService.setCallStatus(call.state);
              }
              this.resetDialer(call);
              
              break;
            default:
              break;
          }
        }

      });

    this.client.enableMicrophone();
    this.client.remoteElement = 'remoteMedia';

    this.client.connect();

}

 bIsDialpadHidden = false;
 minimizeDialPad() {
  this.dialPad.nativeElement.style.height = '50px'; // Minimized height
}

async handleSipCause(call){
  if(call.sipCode === null){
    if(!this.settings.playSoundsOnCall){

      if(call.id != ""){
        if(this.currentCallId == call.id){
          
        }
        
      }
      
      return;
    }    
    return;
  }

  if(call.sipCode === 200){
    return;
  }

  if(call.sipCode === 480){
    this.thisUserDisconnected = true;
    if(this.settings.playSoundsOnCall){
    
    this.audioService.playDelayed("unavailable", 2000);
  }}
  

  if(!this.settings.displayErrorMessages){
    return;
  }

  this.dataService.setPopupMessage({"title": `Error Message`, "message":call.sipReason, 'code': call.sipCode })
    
}

resetRequests = 0;

resetDialer(call){

    this.resetRequests++;
    //this.dataService.refreshTable()
    if(call !== null){

      // I'm not happy with this workaround.  It's sloppy.
      //if(this.resetRequests < 2){
        if(this.currentCallId != call.id){
            if(this.currentCallId !== ""){
            //bb console.log(1143, "Returning Early");
            return;
          }
        }      
      //} else {
      //}
    } else {

    }

    this.resetRequests = 0;
    setTimeout( () => {
      this.thisUserDisconnected = false;  
      //bb console.log(1179, this.thisUserDisconnected)
    }, 1500)
    
    this.sharedService.setCallStatus("destroy");

    if(this.settings.clearNumberOnHangup){
     this.inputPhoneNumber = "" 
    }

    ////bb console.log(1183, this.thisUserDisconnected)
    this.currentCallId = "";
    this.bIsOutbound = false;
    this.callDirection = "";
              this.unAttachedIncomingCall = {};
              this.incomingCaller = ""
              this.bIsCallActive = false;
              this.inboundCallerBestMatches = [];
              this.bIsRecording = false;
              this.buttonState = "call";
              this.unmuteCall();
              this.audioService.pause();

              this.pauseRinging()
              this.callDirection = "";
              this.unAttachedIncomingCall = {};
              this.incomingCaller = ""
              this.bIsCallActive = false;
              this.bIsRecording = false;
              this.status = this.waiting_for_call;
              this.inboundCallerBestMatches = [];
              this.buttonState = "call";
              this.bConferenceCreated = false;
              this.unmuteCall();
              this.audioService.pause();

              if(typeof this.leadInfo !== 'undefined') {
              try {
                this.addCallLog(this.leadInfo["_id"], this.lastCallUpdate)
              } catch(err) { }
                this.sharedService.enableCallButtons(false);
              }

              this.sharedService.enableCallButtons(false);
              this.callState = "call"
              this.unmuteCall();
              this.callDirection = "";
              this.audioService.pause();
              this.inboundCallerBestMatches = []
              this.bIsRecording = false;
              this.bIsCallActive = false;
              this.bCallState =  false;
              //bb console.log(1489, "setting bCallState false");
              this.websocketService.setCallStatus(this.bCallState);

              this.updateTalkTime();

}

talkTime: any = {}
currentCallTalkTime = "00:00:00";
timer;
seconds = 0;

async updateTalkTime(){
  let talkTime: any = await this.http.post(`api/administration/reports/getAgentTalkTime`, { agent_id: this.userInfo._id, period: 'day' }).toPromise();

  this.talkTime = talkTime.talk_time;

}

maximizeDialPad() {

  this.bIsDialpadHidden = false;
  this.dialPad.nativeElement.style.height = '644px';

}

saveCountryCode(){
  this.dataService.setCountryCode(this.selectedCountry);
}

openDispositionOnHangup: boolean = false;

updateLocalStorage() {
    localStorage.setItem('openDispositionOnHangup', JSON.stringify(this.openDispositionOnHangup));
}

displayDisposition = false;
@ViewChild('calldisposition') calldisposition: ElementRef;
@ViewChild('closePopup') closePopup: ElementRef;

  contact = {
    first_name: "",
    last_name: "",
    apemail: "",
    extension: "",
    email: "",
    phone: "",
    title: "",
    group: "",
    additionalPhones: [],
  };

  result: any = { }
  current_id = "";

  async handleHangup(){

    //bb console.log(1263, this.thisUserDisconnected);
    //this.sharedService.setCallStatus("destroy");

    if(this.thisUserDisconnected === false){
      //bb console.log(1279, "playing hungup");
      if(this.settings.playSoundsOnCall){
        this.audioService.playDelayed("hungup", 2000);  
      }
    }
        
    if(this.openDispositionOnHangup){

        this.calldisposition.nativeElement.click(); 

        try {
          let result = await this.http
            .get(`api/datasource/coldleads/id/${this.leadInfo["_id"]}`)
            .toPromise();

          let coldleads = result["coldleads"];

          this.contact.first_name = coldleads["contactFirstName"];
          this.contact.last_name = coldleads["contactLastName"];
          this.contact.extension = coldleads["contactExtension"];
          this.contact.email = coldleads["contactEmail"];
          this.contact.apemail = coldleads["contactAPEmail"];
          this.contact.phone = coldleads["scrape_result"]["phone"];
          this.contact.title = coldleads["scrape_result"]["title"];
          this.contact.group = coldleads["scrape_result"]["group"] || "";
          this.contact.additionalPhones = coldleads["additionalPhones"] || [];
          this.result = result["coldleads"];

          this.current_id = this.result["_id"];

        } catch(err){
          
        }

         
    }
  }

  selectedCoolingPeriod ="";

  note = "";
  first_name ="";
  last_name = "";
  email = "";

  async onfNameChange() {
    try {
      let patchData = { contactFirstName: this.contact.first_name };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onlNameChange() {
    try {
      let patchData = { contactLastName: this.contact.last_name };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();

      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onExtensionChange() {
    try {
      let patchData = { contactExtension: this.contact.extension };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();

      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onEmailChange() {
    try {
      let patchData = { contactEmail: this.contact.email };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();

      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }
  
  appointmentId = null;
  appointmentTime = "";
  appointmentDay = "";
  record: any = null;

async setAppointmentTime() {
  try {
    const timeZone = this.result["scrape_result"]["timezone"];
    const appointmentTimeInTimeZone = dayjs.tz(
      `${dayjs().format("YYYY-MM-DD")}T${this.appointmentTime}`,
      timeZone
    );
    const appointmentTimeInUTC = appointmentTimeInTimeZone.utc();

    let appointment = {
      appointmentTime: appointmentTimeInUTC.format("YYYY-MM-DDTHH:mm:ss.sssZ"),
      contact_id: this.contact["_id"],
      contact_title: this.contact.title,
      contact_phone: this.contact.phone,
      contact_name: this.contact.first_name,
      contacts_timezone: timeZone,
    };

    var record;
    if (this.appointmentId == null) {
      record = await this.http.post(`api/datasource/appointments`, appointment).toPromise();
      this.appointmentId = record["appointments"]["inserted_id"];
    } else {
      record = await this.http.patch(`api/datasource/appointments/id/${this.appointmentId}`, {
        key: "appointmentTime",
        value: appointmentTimeInUTC.format("YYYY-MM-DDTHH:mm:ss.sssZ"),
      }).toPromise();
    }

    this.record = record;

    let patchData = { cooldown: appointmentTimeInUTC.format("YYYY-MM-DDTHH:mm:ss.sssZ") };

    let result = await this.http.put(`api/datasource/coldleads/id/${this.current_id}`, patchData).toPromise();
  } catch (error) {
    console.error('telnyx.js setAppointmentTime:', error);
  }
}

async setAppointmentDay() {
  try {
    const timeZone = this.result["scrape_result"]["timezone"];
    let appointment = {
      appointmentDay: this.appointmentDay,
      contact_id: this.result["_id"],
      contact_title: this.contact.title,
      contact_phone: this.contact.phone,
      contact_name: this.contact.first_name,
      contacts_timezone: timeZone,
    };

    if (this.appointmentId == null) {
      let record = await this.http.post(`api/datasource/appointments`, appointment).toPromise();
      this.appointmentId = record["appointments"]["inserted_id"];
    } else {
      let record = await this.http.patch(`api/datasource/appointments/id/${this.appointmentId}`, {
        key: "appointmentDay",
        value: this.appointmentDay,
      }).toPromise();
    }

    let patchData = { cooldown: this.appointmentDay };

    let result = await this.http.put(`api/datasource/coldleads/id/${this.current_id}`, patchData).toPromise();
  } catch (error) {
    console.error('telnyx.js setAppointmentDay:', error);
  }
}


  async onDropdownChange() {
    let cooldownUntil = "";
    let now = dayjs();

    switch (this.selectedCoolingPeriod) {
      case "1 week":
        cooldownUntil = now.add(1, "week").format();
        break;
      case "1 month":
        cooldownUntil = now.add(1, "month").format();
        break;
      case "6 weeks":
        cooldownUntil = now.add(6, "week").format();
        break;
      case "3 months":
        cooldownUntil = now.add(3, "month").format();
        break;
      case "6 months":
        cooldownUntil = now.add(6, "month").format();
        break;
      case "12 months":
        cooldownUntil = now.add(12, "month").format();
        break;
      case "Forever":
        //cooldownUntil = now.add(100, 'year').format();
        break;
    }

    let patchData = { cooldown: cooldownUntil };
    if (this.selectedCoolingPeriod == "Forever") {
      patchData["archived"] = now.add(100, "year");
      delete patchData["cooldown"];
    }

    try {
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();

      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    this.dataService.refreshTable();

  }

saveDisposition(){

  this.addNote();

  this.closePopup.nativeElement.click();
  
  this.dataService.refreshTable();

  if(this.settings.autoDialNextContact){
    this.dataService.autoDialNextNumber();
  }
}

  async addNote() {
    var fullName = this.userInfo.firstName + " " + this.userInfo.lastName;

    // /datasource/user/array/id/604fae76fe82bb0a6438164f
    let now = dayjs();

    let submission = await this.http
      .post(`api/datasource/coldleads/array/id/${this.current_id}`, {
        key: "notes",
        value: {
          content: this.note,
          date: `${fullName} - ${now.format("MM-DD-YYYY")}`,
        },
      })
      .toPromise();

  }

  bConferenceCreated = false;
  notifyCallBargingInterval = null;
  async createConference(){

    console.log(1897, 'createConference called');

    if(this.bConferenceCreated){
      return;
    }

    this.bConferenceCreated = true;

    let conference = await this.http
      .get(`api/calls/createConference/call_control_id/${this.call_barge_control_id}`)
      .toPromise();

    let conference_id = conference["conference_id"];

    this.websocketService.sendCmd({ "cmd": "notify_call_barging", filter: { "conference_id": conference_id, user_id: this.call_barge_target } } );
    //this.notifyCallBargingInterval = setInterval( () => {
    //  this.websocketService.sendCmd({ "cmd": "notify_call_barging", filter: { "conference_id": conference_id, user_id: this.call_barge_target } } );   
    //}, 15000)

  }

  async joinConference(call_uuid, conference_id){

    // let conference = await this.http
    //   .get(`api/calls/joinConference/call_uuid/${call_uuid}/conference_id/${conference_id}`)
    //   .toPromise();

  }

  isMini = false;

  toggleSize() {
    this.isMini = !this.isMini;
  }

  async waitForRecordResponse($event){
    //bb console.log(1718, $event);
  }

  onEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.manualCall();
    }
  }

}
