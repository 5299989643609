import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HttpClient } from '@angular/common/http';
import { FlextableComponent } from "../../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../../shared.service';
import { DataService } from '@services/data.service'

import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

@Component({
  selector: 'app-flagged',
  templateUrl: './flagged.component.html',
  styleUrls: ['./flagged.component.css']
})

export class FlaggedComponent implements OnInit, FlexTableInterface {

  panelId = "";
  startRecordingOnOrder: boolean = false;
  displayCallScript: boolean = false;
  startAutoDialerOnFirstCall = false;
  bIsAutoDialing = false;
  currentRowCalling = null;
  @ViewChild('flextable') flextable: FlextableComponent;
  @ViewChild('openscript') openscript: ElementRef;
  @ViewChild('closeOrderPopup') closeOrderPopup: ElementRef;

  bOnActiveCall = false;
  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService) {
    this.sharedService.getCallStatus().subscribe(callStatus => {


      if(callStatus == "destroy"){
        this.bOnActiveCall = false;
        if(this.startAutoDialerOnFirstCall == true){

          let rowData = this.flextable.getRowByIndex(this.currentRowCalling + 1);

          setTimeout( () => {
            console.log(38, rowData)
            let callInfo = { "_id": rowData["_id"], buttonName: "Call", row: rowData, rowIndex: this.currentRowCalling + 1 }
            this.sharedService.sendData( callInfo );
          }, 2500)
          this.parentEvent.emit( { id: rowData['_id'] } );
          this.flextable.selectRow( this.currentRowCalling + 1 );
          this.currentRowCalling = this.currentRowCalling + 1;         
        }
      }

      if(callStatus == "new"){
        this.bOnActiveCall = true;
      }
      
    });
  }

  scripts = [];
  products = [];
  intro: string = "";
  provocativeQuestion: string = "";
  pitch: string = "";

  refreshedFilterOptions: any;

  userInfo: any = {}

  ngOnInit(): void {

      this.dataService.notifyOfRefreshRequest().subscribe( bRefreshRequest => {

        this.flextable.refreshTableStayOnPage();
      })

      this.dataService.notifyAutoDialRequest().subscribe(bAutoDial => {

          console.log(32, this.currentRowCalling)
          // let rowData = this.flextable.getRowByIndex(this.currentRowCalling + 1);
          // console.log(36, rowData);
          // setTimeout( () => {
          //   console.log(38, rowData)
          //   let callInfo = { "_id": rowData["_id"], buttonName: "Call", row: rowData, rowIndex: this.currentRowCalling + 1 }
          //   this.sharedService.sendData( callInfo );
          // }, 2500)
          // this.parentEvent.emit( { id: rowData['_id'] } );
          // this.flextable.selectRow( this.currentRowCalling + 1 );
          // this.currentRowCalling = this.currentRowCalling + 1; 

      })

      this.dataService.getUserInfo().subscribe(data => {

        this.userInfo = data;

      })

    // const dropdownCategory = localStorage.getItem('dropdownCategory');
    // if (dropdownCategory) {
    //   console.log(66, dropdownCategory)
    // }

    // const dropdownCity = localStorage.getItem('dropdownCity');
    // if (dropdownCity) {

    // }

    // const dropdownState = localStorage.getItem('dropdownState');
    // if (dropdownState) {
    //   console.log(76, dropdownState)
    // }

    let combinedFilter = JSON.parse(localStorage.getItem('contactPageAggregate') || '{}');

    this.refreshedFilterOptions = combinedFilter;

      this.http.get('/api/datasource/call_scripts/max_records/1000').subscribe(
      response => {
        this.scripts = response["call_scripts"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );

       this.sharedService.getProducts().subscribe(data => this.products = data);

      
        
        let savedFilters = JSON.parse(localStorage.getItem('contactsSavedFilters') || '[]');
        savedFilters = savedFilters.slice(-5);
        for(let savedFilter of savedFilters){


        }
        this.savedFilters = savedFilters;

      this.loadCustomNotes();
  }

  notes = [];

  async loadCustomNotes(){
    let results = await this.http.get(`api/datasource/customnotes`).toPromise();
    for(let customnote of results['customnotes']){
      this.notes.push({ id: customnote['_id'], name: customnote['custom_note'] } );
    }

    for(var actionButton of this.flextable.options.actionButtons){
      if(actionButton['buttonName'] == "Notes"){
        actionButton['actions'] = actionButton['actions'].concat(this.notes);
      }
    }
  }

  savedFilters = [];

  getProducts(){
 
  }

  @ViewChild('drawer') drawer: MatDrawer;
  @Output() public parentEvent = new EventEmitter<any>();

  sendEventToChild(_id: String) {
    const eventData = 'Custom event data from parent';
    this.parentEvent.emit(_id);
  }

  loadFilter($event, value){
    $event.preventDefault();
    this.flextable.updateFilterByValue("Category", value);
  }

  activePanel(componentInfo: any){
    console.log(28)
    this.panelId = componentInfo._id;
    // if(this.drawer.opened){
    //   setTimeout( () => {
        
    //   }, 1500)      
    // }

    // this.drawer.toggle()
  }

  handleEvent(eventData: string) {
    console.log('Received event data in parent:', eventData);
  }

  closePanel(newVar: string) {
     //this.drawer.toggle();
  }
  
  headerButtonClicked(event: any){

    if(event == 'Dials'){

    }
    
    if(event == 'Convos'){

    }

    if(event == "Conversions"){

    }

  }

  async tableButtonClicked(requestResult: any) {

    let action = "tel";
    
    if(requestResult.buttonName == "Text"){
      action = "sms:"
    }
    
    if(requestResult.buttonName == "Email"){

    }

    if(requestResult.buttonName == "Archive"){
      console.log(235, "here");
      this.flextable.selectRow(requestResult["rowIndex"]);
     
    }

    if(requestResult.buttonName == "Notes"){
      //console.log(240, "Notes", requestResult.content)
      await this.addNote(requestResult['row']['_id'], requestResult.content)
    }

    if(requestResult.buttonName == "Actions"){
      this.flextable.selectRow(requestResult["rowIndex"]);

      if(requestResult.content == "Archive"){
        await this.archive(requestResult['row']['_id'], requestResult["rowIndex"]);
        this.flextable.refreshTableStayOnPage();
        return;
      }
      
      //console.log(254, this.drawer);

      await this.onSetCooldownPeriod(requestResult['row']['_id'], requestResult.content);

      // if(this.drawer.opened === false){
      //   this.drawer.toggle();  
      // }

    }

    if(requestResult.buttonName == "Call"){
      let phoneNumber = requestResult.row.phone;
      navigator.clipboard.writeText(phoneNumber);
      this.sharedService.sendData( requestResult );

      // if(this.drawer.opened === false){
      //   this.drawer.toggle();  
      // }
      
      this.parentEvent.emit( { id: requestResult['row']['_id'] } );
      this.flextable.selectRow(requestResult["rowIndex"]);
      this.currentRowCalling = requestResult["rowIndex"];
    }

    //setTimeout( () => {
      this.sharedService.setLoadingFalse();  
    //}, 2500)
    
  }

  async onSetCooldownPeriod(contact_id, selectedCoolingPeriod) {
    let cooldownUntil = "";
    let now = dayjs();

    switch (selectedCoolingPeriod) {
      case "1 Hour":
        cooldownUntil = now.add(1, "minute").format();
        break;
      case "Tomorrow Morning":
        cooldownUntil = now.add(10, "hour").format();
        break;
      case "1 Day":
        cooldownUntil = now.add(1, "day").format();
        break;
      case "1 Week":
        cooldownUntil = now.add(1, "week").format();
        break;
      case "1 Month":
        cooldownUntil = now.add(1, "month").format();
        break;
      case "6 Weeks":
        cooldownUntil = now.add(6, "week").format();
        break;
      case "3 Months":
        cooldownUntil = now.add(3, "month").format();
        break;
      case "6 Months":
        cooldownUntil = now.add(6, "month").format();
        break;
      case "12 Months":
        cooldownUntil = now.add(12, "month").format();
        break;
      case "Forever":
        //cooldownUntil = now.add(100, 'year').format();
        break;
      default:
        // Intentionally return the function if we don't have an exact match
        return;
    }

    // For testing

    let patchData = { cooldown: cooldownUntil, appointment: 'appointment' };
    if (selectedCoolingPeriod == "Forever") {
      patchData["archived"] = now.add(100, "year");
      delete patchData["cooldown"];
    }

    try {
      let result = await this.http
        .put(`api/datasource/coldleads/id/${contact_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    console.log(312, contact_id);

    this.dataService.addToArray("coldleads", contact_id, "cooldownHistory", { appointmentTime: cooldownUntil } )
    this.dataService.addToArray("coldleads", contact_id, "appointments", { appointmentTime: cooldownUntil } )

    this.flextable.refreshTableStayOnPage();

    // console.log(340, this.drawer);

    // if(this.drawer.opened === false){
    //     this.drawer.toggle();  
    //   }

  }

  async addNote(current_id, note) {
    let now = dayjs();
    let fullName = this.dataService.getFullName();
    console.log(271, fullName);
    let submission = await this.http
      .post(`api/datasource/coldleads/array/id/${current_id}`, {
        key: "notes",
        value: {
          content: note,
          date: `${fullName} - ${now.toISOString()}`,
        },
      })
      .toPromise();

    await this.http.put(`api/datasource/coldleads/id/${current_id}`, { "lastDisposition": note } ).toPromise();

  }

  selectedRow = null;
  rowLinkClicked($event){
    this.dataService.openPanel($event.rowData._id);
  }

  async archive(contact_id, rowNum) {

    this.flextable.selectRow( rowNum );

    let now = dayjs();

    let patchData = { archived: now.add(100, "year") };

    try {
      var result = await this.http
        .put(`api/datasource/coldleads/id/${contact_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    console.log(376, patchData, result);
    this.flextable.refreshTableStayOnPage();

  }

  handleNotification($event){
    if($event.event == "placeOrder"){
      this.customer = $event.contact;
      console.log(115, this.customer);
    }
    console.log(238, "refresh table received")
    this.flextable.refreshTable()
  }

  rowDeletedEvent(rowIndex){
    let deletedRow = rowIndex;
    console.log(99, deletedRow, this.selectedRow);
    if(deletedRow == this.selectedRow){
      // if(this.drawer.opened === true){
      //   this.drawer.toggle();  
      // }    
    }
  }

  cartItems = [];
  onAddToCart($event){
    var product_id = $event.target.value;
    console.log(167, product_id);
    for(var product of this.products){
      if(product._id == product_id){
        product["qty"] = 25;
        this.cartItems.push(product);
      }
    }
  }

  customer: any = { }
  
deleteItem(index: number): void {
    this.cartItems.splice(index, 1);
  }

edit = false;
orderPopupClosed(): void {
    console.log('Modal closed!');
    // Your logic here
    this.cartItems = [];
      this.edit = false;
  }

saveContact(){
  this.edit = false;
}

editContact(){
  this.edit = true;
}

async placeOrder(){

    if(this.cartItems.length == 0){
      alert("You cart cannot be empty to place an order");
      return;
    }

    //this.dataService.placeOrder(this.cartItems, this.customer, this.userInfo)

    this.closeOrderPopup.nativeElement.click();
    
}

async headerDropdownChanged($event){
  console.log(275, $event);

  let combinedFilter = JSON.parse(localStorage.getItem('combinedFilter') || '{}');
  
  let contactPageAggregate = $event["aggregate"];
  localStorage.setItem('contactPageAggregate', JSON.stringify(contactPageAggregate));

  let savedFilters = JSON.parse(localStorage.getItem('contactsSavedFilters') || '[]');


  savedFilters.push($event['dropdownData']);

  savedFilters = savedFilters.slice(-5);

  console.log(289, savedFilters);

  let savedAr = savedFilters;
  const uniqueByLabel = savedAr.reduce((acc, current) => {
    if (!acc.tempMap[current.label]) {
      acc.unique.push(current);
      acc.tempMap[current.label] = true;
    }
    return acc;
  }, { unique: [], tempMap: {} }).unique;

  console.log(305, $event['combinedFilter']['scrape_result.category']);//.category);

  if(savedFilters.includes($event.combinedFilter['scrape_result.category'])){
    console.log(316, 'true');
    return;
  }

  localStorage.setItem('contactsSavedFilters', JSON.stringify(savedAr));

  this.savedFilters = savedAr;
  //let dropdowns = JSON.parse(localStorage.getItem('dropdowns') || '[]');

  // if($event["dropdownData"] && $event["dropdownData"]["label"] == "Category"){
  //   dropdowns = dropdowns.filter(d => d.label !== "Category");
  //   dropdowns.push({ label: "Category", value: $event["value"] });
  // }
  // if($event["dropdownData"] && $event["dropdownData"]["label"] == "City"){
  //   dropdowns = dropdowns.filter(d => d.label !== "City");
  //   dropdowns.push({ label: "City", value: $event["value"] });
  // }
  // if($event["label"] == "State"){
  //   dropdowns = dropdowns.filter(d => d.label !== "State");
  //   dropdowns.push({ label: "State", value: $event["value"] });
  // }

  //localStorage.setItem('dropdowns', JSON.stringify(dropdowns));

  // if($event["dropdownData"]["label"] == "Category"){
  //   localStorage.setItem('dropdownCategory', $event["value"]);
  // }  
  // if($event["dropdownData"]["label"] == "City"){
  //   localStorage.setItem('dropdownCity', $event["value"]);
  // }  
  // if($event["label"] == "State"){
  //   localStorage.setItem('dropdownState', $event["value"]);
  // }  

  // 
}

}
