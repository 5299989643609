import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '@network-request-button/network-request-button.component'
import { FlextableComponent } from "@flextable/flextable.component"
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.css'
})
export class CompaniesComponent implements OnInit {

  constructor(private renderer: Renderer2, public http: HttpClient, private sharedService: SharedService) {}

  endpoint = "/api/datasource/companies";
  verb = "POST";

  payload: any = {
    _id: '',
    companyCountry: '',
    companyAddress: '',
    companyLanguage: '',
    companyCity: '',
    companyName: ''
  }

  buttonAction = "Add Company"


  countries = [];
  languages = [];

  async ngOnInit() {
    this.sharedService.getCountries().subscribe(data => this.countries = data);
    this.sharedService.getLanguages().subscribe(data => this.languages = data);

    console.log(32, this.languages)
  }

  rowLinkClicked($event){

    this.buttonAction = "Save"
    this.payload = { ... $event.rowData }    

    this.verb = "PUT"

    this.endpoint = "/api/datasource/companies/id/" + this.payload["_id"];
    this.openModal.nativeElement.click();
  }

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('submitForm') submitForm: NetworkRequestButtonComponent;
  @ViewChild('flextable') flextable: FlextableComponent;

  async waitForResponse(response: any){

    if(response == false){
      console.error("Request Failure");
      return;
    }

    this.dismissModal();

    this.flextable.refreshTable();
  }

  headerButtonClicked(event: any){
    this.buttonAction = "Add Company"
    this.verb = "POST"
    this.payload = {
      companyCountry: '',
      companyAddress: '',
      companyLanguage: '',
      companyCity: '',
      companyName: ''      
    }
    this.endpoint = "/api/datasource/companies";
    this.openModal.nativeElement.click();
  }

  tableButtonClicked(requestResult: any){
    alert( JSON.stringify(requestResult ) );
  }

  dismissModal() {
    const modalElement: HTMLElement = this.myModal.nativeElement;
    this.renderer.selectRootElement(modalElement, true).dispatchEvent(new Event('click'));
  }

  async linkQuickbooks($event, company_id){
    $event.preventDefault();
    var linkQuickbooksURI = `/api/administration/linkQuickbooksByCompanyId/company_id/${company_id}`
    let result = await this.http.get(linkQuickbooksURI).toPromise();
    console.log(94, result['redirect_uri']);
  }
}
