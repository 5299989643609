
  <div class="image-container" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1500" (scrolled)="loadMoreImages()">
    <div *ngFor="let lead of leads">
      <a href="{{ lead['google_maps_url'] }}" target="_blank" class="card-text">Google Maps Link</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="{{ lead['website'] }}" target="_blank" class="card-text">{{ lead['website'] }}</a>
    <div class="row" [style.background-color]="lead.disabled ? 'lightgrey' : 'none'">
      <h3>{{lead['title']}}</h3><p>{{lead['phone']}}<p>{{lead['address']}}
      <div class="col-md-2" *ngFor="let image of lead['images']; index as i">
        <div class="image-wrapper">
          <img class="img-fluid" loading="lazy" [src]="image" referrerPolicy="no-referrer" alt="Image {{i}}">
          <!-- <button class="btn-close" (click)="removeImage(i)"></button> -->
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-primary m-2 p-2" (click)="addToShortlist(lead)">Add To Shortlist</button>
    <button type="button" class="btn btn-success m-2 p-2" (click)="worthTrying(lead)">Worth Trying</button>
    <button type="button" class="btn btn-danger m-2 p-2" (click)="doNotBother(lead)">Do Not Bother</button>

    <hr class="my-4">
  </div>
</div>

<!-- <app-flextable
  [options]="
    {

      bAllowDeletion: false,

        datasource: 'coldleads',

        noIdAggregate: true,

        columns: [
          '_id',
          'images'
        ],

        iconColumns: [
          {
            column: 'icons',
            icon: 'edit',
          }
        ],

        aggregate: [ 
          { $unwind: '$scrape_result.images' }, 

          { $project: { _id: 1, imageLinks: 1 } }
        ]

    }"
></app-flextable> -->