import { Component } from '@angular/core';

@Component({
  selector: 'app-table-badges',
  templateUrl: './table-badges.component.html',
  styleUrls: ['./table-badges.component.css']
})
export class TableBadgesComponent {

}
