import { Component, OnInit, ViewChildren, ViewChild, Input, EventEmitter, Output, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DataService } from '@services/data.service';
import { MatDrawer } from '@angular/material/sidenav';
// import { SharedService } from '../../../../../../shared.service';

@Component({
  selector: 'app-agent-calls',
  templateUrl: './agent-calls.component.html',
  styleUrl: './agent-calls.component.css'
})

export class AgentCallsComponent implements OnInit {

  @ViewChildren('audioPlayers') audioPlayers: QueryList<ElementRef>;
  @ViewChildren('playIcons') playIcons: QueryList<ElementRef>;

currentAudioFile = "";
loadAudioFile = false;
tracks = Array.from({ length: 200 }, () => ({ url: '' }));

  constructor(private route: ActivatedRoute, private dataService: DataService, public http: HttpClient) { }

  all_calls = [];
  agent_id = "";
  ngOnInit(){
    const id = this.route.snapshot.params['id'];
    this.agent_id = id;
    this.getAllCalls(id);
  }

calculateDuration(start: string, end: string): string {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Check if either date is invalid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return '00:00:00'; // Return empty string if dates are invalid
  }

  const duration = endDate.getTime() - startDate.getTime(); // Duration in milliseconds

  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor(duration / (1000 * 60 * 60));

  const hoursPad = (hours < 10) ? "0" + hours : hours;
  const minutesPad = (minutes < 10) ? "0" + minutes : minutes;
  const secondsPad = (seconds < 10) ? "0" + seconds : seconds;

  return `${hoursPad}:${minutesPad}:${secondsPad}`;
}


  async getAllCalls(id){
    var api_url = `api/calls/getAgentCalls/agent_id/${id}`;

    let all_calls = await this.http.get(api_url).toPromise();  
    for(var call of all_calls['all_calls']){
      call['from'] = this.dataService.formatPhoneNumber(call['from']);
      call['to'] = this.dataService.formatPhoneNumber(call['to']);
      call['duration'] = this.calculateDuration(call['start_time'], call['end_time'])


    }


    this.all_calls = all_calls["all_calls"]

    this.nextPageId = this.all_calls[this.all_calls.length - 1]["_id"];
    this.prevPageId = this.all_calls[0]["_id"];

    //!call?.coldleadDetails?.[0]?.scrape_result?.['title']

    //this.findSecondaryNumbers();

  }

  @ViewChildren('contactText') callLinks: QueryList<ElementRef>;

  async findSecondaryNumbers(){

    return;

    let unknownContacts = [];
    for(var call of this.all_calls){
      if (!call.coldleadDetails || call.coldleadDetails.length === 0 || !call.coldleadDetails[0].scrape_result || !call.coldleadDetails[0].scrape_result['title']) {
        // Attempt to find a secondary number title

        unknownContacts.push(call["formattedNumber"]);
        // Here we attempt to find a secondary number.
        // This is a stopgap because doing this in an aggregate was unacceptably slow.
        // Can possibly revisit making a more effecient backend function in the future
      }
    }


      var api_url = `api/calls/getTitlesFromAlternatePhones`;
    
    let unknown_calls = await this.http.post(api_url, { alternatePhonesAr: unknownContacts }).toPromise();  

    for(var unknown of unknown_calls["additionalPhones"]){

    this.callLinks.forEach((link: ElementRef) => {

      //if(link.nativeElement.id == unknown["_id"]){

      //}
      // You can modify the text content like this:
      // link.nativeElement.textContent = 'New Text Here';
    });

    }

  }

  // formatPhoneNumber(phoneNumber: string): string {
  //   phoneNumber = phoneNumber.substring(2)
  //   // Remove non-digit characters from the input
  //   const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  //   // Assuming cleanedPhoneNumber is now a 10-digit string
  //   const areaCode = cleanedPhoneNumber.substring(0, 3);
  //   const firstPart = cleanedPhoneNumber.substring(3, 6);
  //   const secondPart = cleanedPhoneNumber.substring(6);

  //   // Format: +1 XXX-XXX-XXXX
  //   return `+1 ${areaCode}-${firstPart}-${secondPart}`;
  // }

  async searchNumber(phoneNumber){
    

  //   var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.phone" + '/' + phoneNumber;

  //   //this.router.navigate([`main/search`]);
  //   let result = await this.http.get(api_url).toPromise();
  //   this.sharedService.updateSearchResults(result, phoneNumber);
  }

  async playAudio(call_session_id, index){

    var api_url = `api/calls/getCallRecordingLink/call_record_id/${call_session_id}`;

    let audio_link = await this.http.get(api_url).toPromise();  

    try {
      let download_urls = audio_link["download_urls"];
      let keys = Object.keys(download_urls);

      var audioPlayerRef = this.audioPlayers.toArray()[index];
      var url = download_urls[keys[0]];

      this.tracks[index].url = url;
    } catch(err) {
        console.log(76, err);
        return;
    }

    if (audioPlayerRef && audioPlayerRef.nativeElement) {
      // Check if source is already set, if not, set it here and load

      if (!audioPlayerRef.nativeElement.src || audioPlayerRef.nativeElement.src !== this.tracks[index].url) {
        audioPlayerRef.nativeElement.src = this.tracks[index].url;
        audioPlayerRef.nativeElement.load();
      }
      // Attempt to play

      audioPlayerRef.nativeElement.play().then(() => {
        console.log('Playback started successfully');
      }).catch(e => {
        console.error('Playback failed:', e);
        // Handle or log the error, possibly retry or provide user feedback
      });
    }

  }

  onPause(index){
    var audioPlayerRef = this.audioPlayers.toArray()[index];
  }

  onEnded(index){
    var audioPlayerRef = this.audioPlayers.toArray()[index];
  }

  onPlay(index){
    var audioPlayerRef = this.audioPlayers.toArray()[index];
  }

  nextPageId ="";
  prevPageId ="";

  async loadNextPage($event){
    $event.preventDefault();
    var api_url = `api/calls/getAgentCalls/agent_id/${this.agent_id}/nextPage/${this.nextPageId}`;
    
    let all_calls = await this.http.get(api_url).toPromise();  
    for(var call of all_calls['all_calls']){
      call['from'] = this.dataService.formatPhoneNumber(call['from']);
      call['to'] = this.dataService.formatPhoneNumber(call['to']);
    }
    this.all_calls = all_calls["all_calls"]

    this.nextPageId = this.all_calls[this.all_calls.length - 1]["_id"];
    this.prevPageId = this.all_calls[0]["_id"];

    this.findSecondaryNumbers()

  }

  async loadPrevPage($event){
    $event.preventDefault();
    var api_url = `api/calls/getAgentCalls/agent_id/${this.agent_id}/prevPage/${this.prevPageId}`;

    let all_calls = await this.http.get(api_url).toPromise();  
    for(var call of all_calls['all_calls']){
      call['from'] = this.dataService.formatPhoneNumber(call['from']);
      call['to'] = this.dataService.formatPhoneNumber(call['to']);
    }
    this.all_calls = all_calls["all_calls"]

    this.nextPageId = this.all_calls[this.all_calls.length - 1]["_id"];
    this.prevPageId = this.all_calls[0]["_id"];

  }

  @ViewChild('drawer') drawer: MatDrawer;


  

  @Output() public parentEvent = new EventEmitter<any>();

  sendEventToChild(_id: String) {
    const eventData = 'Custom event data from parent';
    this.parentEvent.emit(_id);
  }
panelId = "";
customer: any = { }

  openPanel(_id: any){


    // this.panelId = _id;

    // if(this.drawer.opened === false){
    //   this.drawer.toggle();  
    // }
    
    // this.parentEvent.emit({ id: _id, rowData: { _id: _id } } );

    //this.drawer.toggle()
    this.dataService.openPanel(_id);
  }

  activePanel(componentInfo: any){

    this.panelId = componentInfo._id;
    if(this.drawer.opened){
      setTimeout( () => {
        
      }, 1500)      
    }

    this.drawer.toggle()
  }

  handleEvent(eventData: string) {
    console.log('Received event data in parent:', eventData);
  }

  closePanel(newVar: string) {
     this.drawer.toggle();
  }
  

  handleNotification($event){
    if($event.event == "placeOrder"){
      this.customer = $event.contact;
    }
    

  }

  
}

