import { Component } from '@angular/core';

@Component({
  selector: 'app-snail-mail',
  templateUrl: './snail-mail.component.html',
  styleUrls: ['./snail-mail.component.css']
})
export class SnailMailComponent {

}
