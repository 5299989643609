import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string) {
    console.log(9, 'https://earth.google.com/web/@' + url);
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://earth.google.com/web/@' + url);
  }
}
