import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@services/data.service';
@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrl: './language-select.component.css'
})

export class LanguageSelectComponent implements OnInit {

  constructor( private translate: TranslateService, public dataService: DataService ) { }
  
  ngOnInit() {
    this.loadLanguagePreference();
    
  }

  currentLanguageLabel = "English"

  changeLanguage(event: Event, language: string): void {
    event.preventDefault();
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    this.currentLanguageLabel = languageLabel[language];
    localStorage.setItem('selectedLanguage', language);

    this.translate.use(language);

    this.dataService.triggerRequest();
    // Add any additional logic here for applying the language changes in your application
  }

  private loadLanguagePreference(): void {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    if (selectedLanguage && languageLabel[selectedLanguage]) {
      this.currentLanguageLabel = languageLabel[selectedLanguage];
    }

    this.translate.setDefaultLang(selectedLanguage);


    // Add any additional initialization logic here for applying the stored language preference
  }
}
