<div class="d-flex flex-row">
    <div *ngFor="let filter of savedFilters; let i = index" class="d-flex align-items-center">
        <div><a href="#" (click)="loadFilter($event, filter.value)">{{filter.value}}</a></div>
        <div *ngIf="i < savedFilters.length - 1" class="mx-2">•</div>
    </div>
</div>

<!--    <div class="row">
      <div class="col">
        <input type="checkbox" [(ngModel)]="startRecordingOnOrder"> Start Recording On Order
      </div>
      <div class="col">
        <input type="checkbox" [(ngModel)]="startAutoDialerOnFirstCall"> Start Auto Dialer on First Call
      </div>
      <div class="col">
        <input type="checkbox" [(ngModel)]="displayCallScript"> Display Call Script
      </div>

    </div> -->
    <app-flextable
        #flextable
        (headerButtonClicked)="headerButtonClicked($event)"
        (rowTableBtnClicked)="tableButtonClicked($event)"
        (rowLinkClicked)="rowLinkClicked($event)"
        (rowDeletedEvent)="rowDeletedEvent($event)"
        (headerDropdownChanged)="headerDropdownChanged($event)"
        [refreshedFilterOptions]="refreshedFilterOptions"
        [options]="
   {
   datasource: 'coldleads',

   'all':'all',

   rowButtons: [
      {
      'column': 'name',
      'buttonName': 'Call',
      'api': '',
      'action': 'call'
      }
   ],

    actionButtons: [
        {
            'column': 'name',
            'buttonName': 'Actions',
            actions: [
                { id: 10, name: '1 Hour'},
                { id: 11, name: 'Tomorrow Morning'},
                { id: 2, name: '1 Day' },
                { id: 3, name: '1 Week' },
                { id: 4, name: '1 Month' },
                { id: 5, name: '6 Weeks' },
                { id: 6, name: '3 Months' },
                { id: 7, name: '6 Months' },
                { id: 8, name: '12 Months' },
                { id: 9, name: 'Forever' },
                { id: 1, name: 'Archive' },
            ]
        },
        {
            'column': 'name',
            'buttonName': 'Notes',
            actions: [
              { id: 'spokeWithDM', name: 'Spoke With DM' },
              { id: 'dmNotAvailable', name: 'DM Not Available' },
              { id: 'leftVM', name: 'Left VM' },
              { id: 'dmAskedForInfo', name: 'DM Asked for Info' },
              { id: 'notInterested', name: 'Not interested' },
              { id: 'dnc', name: 'DNC' },
              { id: 'notQualified', name: 'Not Qualified' },
              { id: 'calledNoAnswerTryAgain', name: 'Called, no answer.  Will try again in a week' }
            ]
        },

    ],

    columnBadges: {
        'appointment': {
            column: 'appointment',
            color: 'red',
            states: {
                'complete': { color: 'white', icon_color: '#93C572', material_icon: 'thumb_up', display_text: false, tooltip: 'This transaction is complete' },
                'appointment': { color: 'red', material_icon: 'hourglass_empty', tooltip: 'We are waiting on the seller to complete an action'  },
                '': { color: 'white', material_icon: '', tooltip: 'We are waiting on the buyer to complete an action'  },
                'escrowed': { icon_color: '#FF7F50', color: 'teal', material_icon: 'lock', tooltip: 'This exchange is in escrpw'   },
                'quality_check': { color: 'teal', material_icon: 'lock', tooltip: 'A human reviewer from scraperCRM needs to review this to ensure quality'  },
                'rejected': { color: 'teal', material_icon: 'lock', tooltip: 'A human reviewer from scraperCRM needs to review this to ensure quality'  }
            },
            index: 0
        }
                              
    },

   bAllowDeletion: false,
   filter: {
         visible: {
         $ne: false 
      }, 
      archived: { $ne: true }
   }, 
   columns: [
      'name',
      'phone',
      'appointment',
      'city',
      'state',
      'category',
      'revenue',
      'images'
   ],
   linkColumnPreventDefault: true,
   linkColumns: [ {
      column: 'Name',
      path: '/main/sysadmin/planmanagement/plan/'
      }
      ],
      iconColumns: [ {
      column: 'images',
      icon: 'edit',
      }
      ],

    hiddenColumns: {
        'Images': { header: false }
    },

    scope: 'account',
    
    aggregate: [
      {
        $match: 
        { 
            $and: 
            	[
        			{ bFlagAccountForReview: true },
        			{ archived: { $exists: false } },
      			]
    }
   },
   {
   $project: {
      _id: 1,
      borough: '$scrape_result.complete_address.borough',
      phone: '$scrape_result.phone',
      street: '$scrape_result.complete_address.street',
      city: '$scrape_result.complete_address.city',
      postal_code: '$scrape_result.complete_address.postal_code',
      state: '$scrape_result.complete_address.state',
      country: '$scrape_result.complete_address.country',
      name: '$scrape_result.title',
      category: '$scrape_result.category',
      estimatedRevenue: '$estimatedRevenue',
      cooldown: '$cooldown',
      'appointment': {
      '$cond': {
          'if': { '$or': [{ '$eq': ['$appointments', []] }, { '$eq': [{ '$type': '$appointments' }, 'missing'] }] },
          'then': '',
          'else': 'appointment'
        }
      },
      'revenue': {
        '$cond': {
          'if': { '$or': [{ '$eq': [{ '$type': '$estimatedRevenue' }, 'missing'] }, { '$eq': ['$estimatedRevenue', ''] }] },
          'then': ' - ',
          'else': '$estimatedRevenue'
        }
      },
      images: {
      $map: {
      input: '$scrape_result.images',
      as: 'imageObj',
      in: '$$imageObj.image'
      }
      }
      }
        }
   ],
   filters: [
      {
      'filter': { 'scrape_result.category': '' },
      'label': 'Category',
      'valueKeys': [],
      'valueIds': [],
      'datasource': 'coldleads',
      'key': 'scrape_result.category',
      'distinct': 'scrape_result.category',
      'all':false
      },
      {
      'filter': { 'search_term': '' },
      'label': 'Search Term',
      'valueKeys': [],
      'valueIds': [],
      'datasource': 'coldleads',
      'key': 'search_term',
      'distinct': 'search_term',
      'all':false
      },
      {
      'filter': { 'scrape_result.complete_address.city': '' },
      'label': 'City',
      'valueKeys': [],
      'valueIds': [],
      'datasource': 'coldleads',
      'key': 'scrape_result.complete_address.city',
      'distinct': 'scrape_result.complete_address.city',
      'all':false
      },
      {
      'filter': { 'scrape_result.timezone': '' },
      'label': 'Timezone',
      'valueKeys': [],
      'valueIds': [],
      'datasource': 'coldleads',
      'key': 'scrape_result.timezone',
      'distinct': 'scrape_result.timezone',
      'all':false
      },
      {
      'filter': { 'scrape_result.complete_address.state': '' },
      'label': 'State',
      'valueKeys': [],
      'valueIds': [],
      'datasource': 'coldleads',
      'key': 'scrape_result.complete_address.state',
      'distinct': 'scrape_result.complete_address.state',
      'all':false
      }
      ]  
   }"
    ></app-flextable>



<!-- <div class="card-body">

    <button type="button" #openscript class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#verticalycentered" style="display: none;"></button>
    <div class="modal fade" appMoveModalToBody  id="verticalycentered" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Call Script</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label for="inputText" class="col-sm-2 col-form-label">Subject</label>
                        <div class="col-sm-10">
                            <select name="mySelect" class="form-select" aria-label="Default select example">
                                <option value="default">Choose Category</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="scripts && scripts.length > 0">
                        <div *ngFor="let section of scripts[0]['sections']" class="form-group mb-2">
                            
                            <textarea class="form-control" rows="5" [(ngModel)]="section.section_text" placeholder="Section Text">{{section.section_text}}</textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" (click)="placeOrder()">Place Order</button>
                </div>
            </div>
        </div>
    </div>
</div> -->