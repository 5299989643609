<main>
  <div class="container">
    <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div class="d-flex justify-content-center py-4">
              <a href="index.html" class="logo d-flex align-items-center w-auto">
                <img src="assets/img/logo.png" alt="">
                  <span class="d-none d-lg-block">{{dataService.getProductName()}}</span>
                </a>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">{{ "create_an_account" | translate }} </h5>
                    <p class="text-center small">{{ "Enter your personal details to create account" | translate }} </p>

<form [formGroup]="registrationForm" class="row g-3 needs-validation" (ngSubmit)="submitForm()">

  <div class="col-12">
    <label class="form-label" for="firstName" >{{"First Name" | translate }}</label>
    <input class="form-control" type="text" id="firstName" formControlName="firstName" required>
    <div *ngIf="registrationForm.get('firstName').invalid && registrationForm.get('firstName').touched">
      <div class="invalid-feedback" *ngIf="registrationForm.get('firstName').errors['required']">{{"First name is required." | translate }}</div>
    </div>
  </div>

  <div>
    <label class="form-label"  for="lastName">{{"Last Name" | translate }}</label>
    <input class="form-control"  type="text" id="lastName" formControlName="lastName" required>
    <div *ngIf="registrationForm.get('lastName').invalid && registrationForm.get('lastName').touched">
      <div *ngIf="registrationForm.get('lastName').errors['required']">{{"Last name is required." | translate }}</div>
    </div>
  </div>
  <div>
    <label class="form-label"  for="userId">{{"Email" | translate }}</label>
    <input class="form-control" autocapitalize="none" autocorrect="off" spellcheck="false" autocomplete="off" type="email" id="userId" formControlName="userId" required>
    <div *ngIf="registrationForm.get('userId').invalid && registrationForm.get('userId').touched">
      <div *ngIf="registrationForm.get('userId').errors['required']">{{"Email is required." | translate}}</div>
      <div *ngIf="registrationForm.get('userId').errors['userId']">{{"Invalid email format." | translate}}</div>
    </div>
  </div>
  <div>
    <label class="form-label"  for="password">{{"Password" | translate }}</label>
    <input class="form-control" type="password" id="pwd" formControlName="pwd" required>
    <div *ngIf="registrationForm.get('pwd').invalid && registrationForm.get('pwd').touched">
      <div *ngIf="registrationForm.get('pwd').errors['required']">{{"Password is required." | translate }}</div>
    </div>
  </div>

                      <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="termsAndConditions" formControlName="termsAndConditions" required>
                        <label class="form-check-label" for="acceptTerms">{{"I agree and accept the" | translate}} <a href="#" routerLink="/terms">{{"terms and conditions" | translate}}</a></label>

                        <div class="invalid-feedback" *ngIf="registrationForm.get('termsAndConditions').invalid && registrationForm.get('termsAndConditions').touched">
      <div *ngIf="registrationForm.get('termsAndConditions').errors['required']">{{"You must accept the terms and conditions." | translate}}</div>
    </div>

                       
                      </div>
                    </div><div class="col-12">
                      <network-request-button #createAccount [disabled]="registrationForm.invalid" (result)="waitForRegistrationResponse($event)" [request]="registerEndpoint" [payload]="registrationForm.value" verb="POST">{{"Create Account" | translate}}</network-request-button>
                    </div>

                    <div class="col-12">
                      <p class="small mb-0">{{"Already have an account?" | translate }} <a href="" routerLink="/login">{{"Log in" | translate}}</a></p>
                    </div>

</form>
  <!-- Language Dropdown -->
<div class="dropdown">
  <a class="nav-link dropdown-toggle" href="#" role="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
    <span id="currentLanguage">{{currentLanguageLabel}}</span>
  </a>
  <ul class="dropdown-menu" aria-labelledby="languageDropdown">
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'en')">🇺🇸 English</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'de')">🇩🇪 Deutsch</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'tr')">🇹🇷 Türkçe</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'fr')">🇫🇷 Français</a></li>
  </ul>
</div>























                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </main>