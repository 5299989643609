<div class="dropdown">
  <a class="nav-link dropdown-toggle" href="#" role="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
    <span appTwemoji id="currentLanguage">{{currentLanguageLabel}}</span>
  </a>
  <ul class="dropdown-menu" aria-labelledby="languageDropdown">
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'en')">🇺🇸 English</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'de')">🇩🇪 Deutsch</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'tr')">🇹🇷 Türkçe</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'fr')">🇫🇷 Français</a></li>
  </ul>
</div>