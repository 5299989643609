import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ViewChildren, OnInit, OnChanges, QueryList, ElementRef, SimpleChanges, HostListener } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "@app/shared.service";
import { DataService } from "@services/data.service";

@Component({
  selector: 'app-disposition-dropdown',
  templateUrl: './disposition-dropdown.component.html',
  styleUrl: './disposition-dropdown.component.css'
})

export class DispositionDropdownComponent {

  callDispositions: string[] = [
    'Completed',
    'Left Voicemail',
    'No Answer',
    'Busy',
    'Wrong Number',
    'Callback Requested',
    'Interested',
    'Not Interested',
    'Discontinued',
    'Escalated'
  ];

  constructor( private http: HttpClient, private sharedService: SharedService, private dataService: DataService) {}

}
