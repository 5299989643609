<button #openModal type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#products">
 {{"Add Product" | translate }}
</button>

<app-flextable #flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
    (rowLinkClicked)='rowLinkClicked($event)'
	[options]="
		{
			datasource: 'products',

			bAllowDeletion: true,

            linkColumnPreventDefault: true,
            
            linkColumns: [ {
                     column: 'Product Name',
                     path: '/main/sysadmin/planmanagement/plan/'
                }
            ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

			scope: 'account',

			columns: [
				'product_name',
				'product_unit_price',
				'product_package_price',
				'product_currency'
			]
		}"
></app-flextable>

<div class="modal fade" appMoveModalToBody  #verticalycentered id="products" tabindex="-1" appMoveModalToBody>
    <form name="addCountry">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{"Add Product" | translate }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    

                    <div class="mb-4">
                        <label for="product_name" class="form-label">{{"Product Name" | translate }}</label>
                        <input id="product_name" name="product_name" [(ngModel)]="payload.product_name" type="text" class="form-control">
                    </div>

                  <div class="mb-4">
                        <label for="product__unit_price" class="form-label">{{"Product Unit Price" | translate }}</label>
                        <input id="product_unit_price" name="product_unit_price" [(ngModel)]="payload.product_unit_price" type="number" class="form-control">
                  </div>

                  <div class="mb-4">
                        <label for="product_package_price" class="form-label">{{"Product Package Price" | translate }}</label>
                        <input id="product_package_price" name="product_package_price" [(ngModel)]="payload.product_package_price" type="number" class="form-control">
                  </div>
                  <div class="mb-4">
                        <label for="product_currency" class="form-label">{{"Product Category" | translate }}</label>
                        <input id="product_currency" name="product_category" [(ngModel)]="payload.productCategory" type="text" class="form-control">
                  </div>
                  <div class="mb-4">
                        <label for="product_currency" class="form-label">{{"Product Currency" | translate }}</label>
                        <input id="product_currency" name="product_currency" [(ngModel)]="payload.product_currency" type="text" class="form-control">
                  </div>

                <div class="mb-4">
                      <label for="warehouseDropdown" class="form-label">{{"Warehouse" | translate }}</label>
                      <select id="warehouseDropdown" name="warehouse" class="form-control" [(ngModel)]="payload.warehouse_id" (change)="onWarehouseChange($event)">
                        <option *ngFor="let warehouse of warehouses" [ngValue]="warehouse.warehouse_id">{{ warehouse.warehouseName }}</option>
                      </select>
                </div>

                    <div class="mb-4">
                        <label for="primary_language" class="form-label">{{"Primary Language" | translate }}</label>
                       <select id="languageDropdown" name="language" class="form-control" [(ngModel)]="payload.product_language">
                     <option *ngFor="let language of languages" [ngValue]="language.languageShortCode">{{ language.languageName }}</option>
                  </select>

                  </div>
                  
                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{"Close" | translate }}</button>
                    <!-- <button type="submit" class="btn btn-primary">Add Language</button> -->
                    <network-request-button #submitForm (result)="waitForResponse($event)" [request]="endpoint" [payload]="payload" [verb]="verb">{{buttonAction | translate}}</network-request-button>
                </div>
            </div>
        </div>
    </form>
</div>

