import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HttpClient } from '@angular/common/http';
import { FlextableComponent } from "@flextable/flextable.component"
import { SharedService } from '../../../../shared.service';
import { DataService } from '@services/data.service'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})

export class MyTeamsOrdersComponent implements OnInit, FlexTableInterface {

  panelId = "";

  @ViewChild('flextable') flextable: FlextableComponent;
  @ViewChild('openscript') openscript: ElementRef;
  @ViewChild('closeOrderPopup') closeOrderPopup: ElementRef;
  @ViewChild('placeOrderBtn') placeOrderBtn: ElementRef;

  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService) { }

  scripts = [];
  products = [];
  intro: string = "";
  provocativeQuestion: string = "";
  pitch: string = "";
  startRecordingOnOrder: boolean = false;
  displayCallScript: boolean = false;
  userInfo = null;

  actionButtonClicked($event){
    console.log(34, "Action triggered in parent");
  }

  ngOnInit(): void {

      this.http.get('/api/datasource/call_scripts/max_records/1000').subscribe(
      response => {
        this.scripts = response["call_scripts"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );

       this.sharedService.getProducts().subscribe(data => this.products = data);
  }

  getProducts(){

  }

  @ViewChild('drawer') drawer: MatDrawer;
  @Output() public parentEvent = new EventEmitter<any>();

  sendEventToChild(_id: String) {
    const eventData = 'Custom event data from parent';
    this.parentEvent.emit(_id);
  }

  activePanel(componentInfo: any){
    console.log(28)
    this.panelId = componentInfo._id;
    if(this.drawer.opened){
      setTimeout( () => {
        
      }, 1500)      
    }

    this.drawer.toggle()
  }

  handleEvent(eventData: string) {
    console.log('Received event data in parent:', eventData);
  }

  closePanel(newVar: string) {
     this.drawer.toggle();
  }
  
  headerButtonClicked(event: any){

    if(event == 'Dials'){

    }
    
    if(event == 'Convos'){

    }

    if(event == "Conversions"){

    }

  }

  labelsAr: string[] = [];

  closePopup() {
    this.labelsAr = [];
  }

openLink(event: MouseEvent, url: string) {
    event.preventDefault();
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if (newTab) {
      newTab.blur();
      window.focus();
    }
  }

  async tableButtonClicked(requestResult: any) {

    // Assuming requestResult contains a property 'phoneNumber'

    let action = "tel";
    
    try {
    if(requestResult.content == "Reject"){
        let approveOrderResult = await this.http.post(`api/administration/rejectOrder`, 
        { 
          "order_id": requestResult['row']['_id']
        } ).toPromise();   
         this.flextable.refreshTableStayOnPage();
    }

    if(requestResult.content == "Pre-Approve"){
       let approveOrderResult = await this.http.post(`api/administration/preapproveOrder`, 
        { 
          "order_id": requestResult['row']['_id']
        } ).toPromise();
        this.flextable.refreshTableStayOnPage();
    }

    if(requestResult.content == "View Order"){
       console.log(147, "View Order", requestResult['row']['_id']);

       this.dataService.editOrder(requestResult['row']['_id']);
       this.placeOrderBtn.nativeElement.click();
    }

    //       this.dataService.editOrder($event['_id']);
     

    if(requestResult.content == "Approve"){
       let approveOrderResult = await this.http.post(`api/administration/approveOrder`, 
        { 
          "order_id": requestResult['row']['_id']
        } ).toPromise();
        this.flextable.refreshTableStayOnPage();
    }

    if (requestResult.content == "Send To Customer") {
      // Show a confirmation dialog
      const confirmation = confirm("Are you sure you want to send this invoice to the customer?");
      
      // Proceed only if the user confirms
      if (confirmation) {
        try {
          let approveOrderResult = await this.http.post(`api/administration/emailInvoiceToCustomer`, 
            { 
              "order_id": requestResult['row']['_id']
            }).toPromise();

          alert("Invoice has been emailed to the customer");
        } catch (error) {
          // Handle errors (optional)
          console.error("Error sending invoice:", error);
          alert("An error occurred while sending the invoice.");
        }
      } else {
        // User canceled the action
      }
    }

    if (requestResult.content == "Mark as paid") {
      // Show a confirmation dialog
      const confirmation = true;//confirm("Are you sure you want to send this invoice to the customer?");
      
      // Proceed only if the user confirms
      if (confirmation) {
        try {
          let approveOrderResult = await this.http.post(`api/administration/markOrderAsPaid`, 
            { 
              "order_id": requestResult['row']['_id']
            }).toPromise();
           this.flextable.refreshTableStayOnPage();
        } catch (error) {
          // Handle errors (optional)
          console.error("Error sending invoice:", error);
          
        }
      } else {
        // User canceled the action
      }
    }

    if(requestResult.content == "Create Fedex Shipping Label"){

       let result = await this.http.get(`api/administration/createFedexShippingLabel/order_id/${requestResult['_id']}`).toPromise();

       //console.log(157, result);
       //let label = result['output']['transactionShipments'][0]['pieceResponses'][0]['packageDocuments'][0]['url'];

        this.labelsAr = result['labelUrls']
        for(var labelUrl of result['labelUrls']){
          //window.open(labelUrl, "_blank", "noopener,noreferrer");
        }
       
       //alert("Invoice has been emailed to customer");

       this.flextable.refreshTableStayOnPage();
      //return;
    }

    if(requestResult.content == "Create Fedex Return"){

      const confirmation = confirm("Are you sure you want to create a Fedex Return Label?");

      if (confirmation) {
        try{
          let result = await this.http.get(`api/administration/createFedexReturnShippingLabel/order_id/${requestResult['_id']}`).toPromise();
        } catch(err){
           
        }
      }
       

       // let label = result['output']['transactionShipments'][0]['pieceResponses'][0]['packageDocuments'][0]['url'];

       // for(var piece of result['output']['transactionShipments'][0]['pieceResponses']){
       //  var labelUrl = piece['packageDocuments'][0]['url'];
       //  window.open(labelUrl, "_blank");
       // }
       
       alert("Return Label Has Been Generated And Emailed");

      //return;
    }

  } catch(err){

  }

    this.sharedService.setLoadingFalse();

  }

  selectedRow = null;
  rowLinkClicked($event){

    this.dataService.openPanel($event.rowData.customer_id);
  }

  handleNotification($event){
    if($event.event == "placeOrder"){
      this.customer = $event.contact;
      console.log(115, this.customer);
    }
    this.flextable.refreshTable()

  }

  rowDeletedEvent(rowIndex){
    let deletedRow = rowIndex;
    console.log(99, deletedRow, this.selectedRow);
    if(deletedRow == this.selectedRow){
      if(this.drawer.opened === true){
        this.drawer.toggle();  
      }    
    }
  }

  cartItems = [];
  onAddToCart($event){
    var product_id = $event.target.value;
    console.log(167, product_id);
    for(var product of this.products){
      if(product._id == product_id){
        product["qty"] = 25;
        this.cartItems.push(product);
      }
    }
  }

  customer: any = { }
  
deleteItem(index: number): void {
    this.cartItems.splice(index, 1);
  }

edit = false;
orderPopupClosed(): void {
    console.log('Modal closed!');
    // Your logic here
    this.cartItems = [];
      this.edit = false;
  }

saveContact(){
  this.edit = false;
}

editContact(){
  this.edit = true;
}

async placeOrder(){

    if(this.cartItems.length == 0){
      alert("You cart cannot be empty to place an order");
      return;
    }

    //this.dataService.placeOrder(this.cartItems, this.customer, this.userInfo)
    
    // let call_recording_info = await this.http.post(`api/datasource/orders`, 
    // { 
    //   "customer_id": this.customer._id, 
    //   "order": this.cartItems, 
    //   title: this.customer.scrape_result.title, 
    //   address: this.customer.scrape_result.address, 
    //   contactFirstName: this.customer.contactFirstName,
    //   contactLastName: this.customer.contactLastName,
    //   contactEmail: this.customer.contactEmail, 
    //   contactPhone: this.customer.scrape_result.phone, 
    //   "approved":"pending"
    // } ).toPromise();

    this.closeOrderPopup.nativeElement.click();
}

}
