<div class="modal fade" appMoveModalToBody id="editUsers" tabindex="-1">
   <form name="editUser" (ngSubmit)="updateUser()">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title">{{"Edit User" | translate}}</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <div class="row mb-4">
                  <label for="inputText" class="col-sm-2 col-form-label">{{"Email" | translate}}</label>
                  <div class="col-sm-10">
                     <input id="email" name="email" [(ngModel)]="selectedUserInfo.email" type="text" class="form-control" />
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="inputText" class="col-sm-2 col-form-label">{{"First Name" | translate}}</label>
                  <div class="col-sm-10">
                     <input id="firstName" name="firstName" [(ngModel)]="selectedUserInfo.first_name" type="text" class="form-control" />
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="inputText" class="col-sm-2 col-form-label">{{"Last Name" | translate}}</label>
                  <div class="col-sm-10">
                     <input id="lastName" name="lastName" [(ngModel)]="selectedUserInfo.last_name" type="text" class="form-control" />
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="inputText" class="col-sm-2 col-form-label">{{"Alias" | translate}}</label>
                  <div class="col-sm-10">
                     <input id="alias" name="alias" [(ngModel)]="selectedUserInfo.alias" type="text" class="form-control" />
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="inputText" class="col-sm-2 col-form-label">{{"Phone Number" | translate}}</label>
                  <div class="col-sm-10">
                     <input id="phoneNum" name="phoneNum" [(ngModel)]="selectedUserInfo.selectedPhoneNumber" type="text" class="form-control" />
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="inputPassword" class="col-sm-2 col-form-label">{{"Password" | translate}}</label>
                  <div class="col-sm-5">
                     <input id="sUIPassword" name="sUIPassword" [(ngModel)]="selectedUserInfo.pwd" type="password" class="form-control" />
                  </div>
                  <div class="col-sm-3">
                     <button class="btn btn-primary w-100" (click)="changePassword()">{{"Change Password" | translate}}</button>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="roleDropdown" class="col-sm-2 col-form-label">{{"Role" | translate}}</label>
                  <div class="col-sm-10 mb-2">
                     <select id="sUIroleDropdown" name="sUIrole" class="form-control" [(ngModel)]="selectedUserInfo.account_type">
                        <option *ngFor="let role of availableRoles" [ngValue]="role">{{ role | translate }}</option>
                     </select>
                  </div>
                  <label for="roleSuperior" class="col-sm-2 col-form-label mt-2">{{"Superior" | translate}}</label>
                  <div class="col-sm-10 mt-2">
                     <select id="roleSuperior" name="roleSuperior" class="form-control" [(ngModel)]="selectedUserInfo.role_superior" (change)="onSupervisorChange($event)">
                        <option *ngFor="let role of availableRoles" [ngValue]="role">{{ role | translate }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-3">
                  <label class="col-sm-2 col-form-label">&nbsp;</label>
               </div>
               <div class="row mb-4">
                  <label for="teamDropdown" class="col-sm-2 col-form-label">{{"Team" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="teamDropdown" name="team" class="form-control" [(ngModel)]="selectedUserInfo.team_id">
                        <option *ngFor="let team of teams" [ngValue]="team.team_id">{{ team.team_name }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="officeDropdown" class="col-sm-2 col-form-label">{{"Office" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="officeDropdown" name="office" class="form-control" [(ngModel)]="selectedUserInfo.office_id">
                        <option *ngFor="let office of offices" [ngValue]="office.office_id">{{ office.office_name }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="supervisorDropdown" class="col-sm-2 col-form-label">{{"Supervisor" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="supervisorDropdown" name="supervisor" class="form-control" [(ngModel)]="selectedUserInfo.superior_id">
                        <option *ngFor="let availableSuperior of availableSuperiors" [ngValue]="availableSuperior._id">{{ availableSuperior.first_name }} {{ availableSuperior.last_name }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="supervisorDropdown" class="col-sm-2 col-form-label">{{"Dashboard Access" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="dashboardAccessDropdown" name="dashboardAccess" class="form-control" [(ngModel)]="selectedUserInfo.homeRoute">
                        <option default value="main/contacts">Contacts</option>
                        <option value="main/child1">Dashboard</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="countryDropdown" class="col-sm-2 col-form-label">{{"Country" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="countryDropdown" name="country" class="form-control" [(ngModel)]="selectedCountry">
                        <option value="USA">{{"USA" | translate }}</option>
                        <option value="Canada">{{"Canada" | translate }}</option>
                        <option value="Germany">{{"Germany" | translate }}</option>
                        <option value="France">{{"France" | translate }}</option>
                     </select>
                  </div>
               </div>

               <div class="row mb-4">
                  <div class="col-sm-12">
                     <button type="button" class="btn btn-secondary w-100" (click)="getOwnedNumbers()">{{"Add Phone Number" | translate}}</button>
               </div>
               </div>

               <div class="list-group mt-2 border p-2" *ngIf="ownedPhoneNumbers.length > 0">
               <h5>{{"Phone Numbers You Already Own" | translate }}</h5>
               <div class="row">
                  <div class="col-3" *ngFor="let phoneNumber of ownedPhoneNumbers">
                     <div class="form-check">
                        <input type="radio" class="form-check-input" name="selectedPhoneNumber" [(ngModel)]="selectedPhoneNumber" [value]="phoneNumber.phone_number" id="{{phoneNumber.phone_number}}" (change)="onPhoneNumberChange(phoneNumber.phone_number)">
                        <label class="form-check-label" for="{{phoneNumber.phone_number}}">
                           {{ phoneNumber.phone_number | formatPhone }}
                        </label>
                     </div>
                  </div>
                  <div class="col-sm-12 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" (click)="assignPhoneNumber()">{{"Assign Phone Number" | translate}}</button>
                    <button type="button" class="btn btn-secondary" (click)="addSecondaryPhoneNumber()">{{"Add Secondary Phone Number" | translate}}</button>
                  </div>
               </div>

            </div>

            </div>
            <div class="modal-footer">
               <button #closeEditUser type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{"Close" | translate }}</button>
               <button type="submit" class="btn btn-primary">{{"Save Changes" | translate }}</button>
            </div>
         </div>
      </div>
   </form>
</div>