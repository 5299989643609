import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DataService } from '@services/data.service'
import { FlextableComponent } from "@flextable/flextable.component"

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})

export class MyOrdersComponent implements OnInit {

   @ViewChild('placeOrderBtn') placeOrderBtn: ElementRef;
   @ViewChild('flextable') flextable: FlextableComponent;

   constructor(private dataService: DataService) { }

   ngOnInit(){
      this.dataService.updateMyOrders().subscribe( data => {
         console.log(18, "Refresh My Orders Page");
         this.flextable.refreshTableStayOnPage();
      });
   }

   rowLinkClicked($event){
      console.log(26, $event.rowData.customer_id);


      this.dataService.openPanel($event.rowData.customer_id);
   }

   async tableButtonClicked($event){
      console.log(16, $event);
      this.dataService.editOrder($event['_id']);
      this.placeOrderBtn.nativeElement.click();
   }
}
