<div [style.visibility]="isVisible ? 'visible' : 'hidden'">
  <div class="backdrop"></div>
  <div class="map" [cdkDragDisabled]="draggingDisabled" cdkDrag (cdkDragMoved)="onDragMoved($event)">
    <div cdkDragHandle class="map-header">
      <button class="btn" (click)="toggleVisibility()">Hide</button>
    </div>
    <div class="col mapcol">
      <div id="map" style="height: 800px; width: 100%;">
        <div style="max-height:420px; overflow-y: auto;">
        <div class="card-body bg-light">
          <h5 class="card-title text-success">Exact Match Found: {{caller.phone | formatPhone }}</h5>
          <h2 class="card-subtitle mb-3 text-muted"><a href="#" (click)="openPanel($event, caller.contact_id)">{{ caller.title }}</a></h2>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Contact:</strong>
            </div>
            <div class="col-sm-8">{{ caller.name }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Address:</strong>
            </div>
            <div class="col-sm-8">{{ caller.address }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Phone:</strong>
            </div>
            <div class="col-sm-8">{{ caller.phone }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Additional Numbers:</strong>
            </div>
            <div class="col-sm-8">
              <div *ngFor="let phone of caller.additionalPhones" class="col-sm-8">{{phone.number}}</div>
              <div class="row mb-2">
                <div class="col-sm-8">
                  <a href="#" (click)="addPhoneNumber($event)" class="text-decoration-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>&nbsp;Add Phone</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Website:</strong>
            </div>
            <div class="col-sm-8">
              <a href="{{ caller.website }}" target="_blank" class="text-decoration-none">{{ caller.website }}</a>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Google Maps:</strong>
            </div>
            <div class="col-sm-8">
              <a href="{{ caller.mapsLink }}" target="_blank" class="text-decoration-none">View on Maps</a>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-4 text-sm-end">
              <strong>Email:</strong>
            </div>
            <div class="col-sm-8">
              <span>{{ caller.email }}</span>
              <div class="row mb-2">
                <div class="col-sm-8">
                  <a href="#" (click)="addPhoneNumber($event)" class="text-decoration-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>&nbsp;Add AP Email
                  </a>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <a href="#" (click)="addPhoneNumber($event)" class="text-decoration-none">Generate Return Label</a> | <a href="#" (click)="addPhoneNumber($event)" class="text-decoration-none">Transfer Call</a> | <a href="#" (click)="addPhoneNumber($event)" class="text-decoration-none">Attempt To Save</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div  style="max-height: 380px; overflow-y: auto;">
          <div class="card-body">
            <h5 class="card-title">Order History</h5>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Invoice</th>
                    <th scope="col">Status</th>
                    <th scope="col">Sales Agent</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let order of caller.ordersAr; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ order.createdAt | date: 'dd/MM/yyyy'}}</td>
                    <td>{{ order.invoice }}</td>
                    <td>{{ order.approved }}</td>
                    <td>{{ order.salesAgent }}</td>
                    <td>{{ order.totalValue }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body">
            <h5 class="card-title">Call History</h5>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Direction</th>
                    <th scope="col">Number</th>
                    <th scope="col">Agent Spoken To</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let call of caller.callHistory; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ call.occurred_at  | date: 'dd/MM/yyyy'}}</td>
                    <td>{{ call.direction }}</td>
                    <td>{{ call.formattedNumber }}</td>
                    <td>{{ call.first_name }} {{ call.last_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>