import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/*
	The WebsocketService is designed to add a layer between the underlying socket.io layer and components that need to react
	to events.

	The use case is this:
		- A component has a need to receive notifications of events, or get realtime data
		- It calls getData
			Example:
			  this.websocketService.getData().subscribe(data => {
		      	// Use data here
				switch(data.event){
					case exampleEvent:
						this.handleExampleEvent(data);
						break;
					... other code here
				}
		       });
*/

export class WebsocketService {

	private connectionStatus = new BehaviorSubject<boolean>(false); // Assuming false as initial disconnected state

	private dataSubject = new Subject<any>();
	private cmds = new Subject<any>();

	private connectedSubject = new Subject<any>();

	private callStatus = new BehaviorSubject<boolean>(false);

  public user_id = "";

  constructor(){

  }

  setUserId(){
    const encodedStr = this.getCookie('Authorization');
    const base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
    const decodedStr = atob(base64Str);
    const authStr = decodedStr.split(':')[0];
    this.user_id = authStr.split('@%40@')[1];    
  }

  getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  sendData(data: any) {
  	this.dataSubject.next(data);
  }

  sendCmd(data: any){
  	this.cmds.next(data);
  }

  getCmds(){
  	return this.cmds.asObservable();
  }

  getData() {
		return this.dataSubject.asObservable();
  }

  notifyConnected(){
    this.setUserId();

  	this.connectionStatus.next(true);

  }

  notifyDisconnected(){
  	 this.connectionStatus.next(false);
  }

  getConnectionStatus() {
    return this.connectionStatus.asObservable();
  }

  setCallStatus(bOnCallStatus: boolean){
  	this.callStatus.next(bOnCallStatus);
  }

  getCallStatus(){
  	return this.callStatus.asObservable();
  }

  // For sending the list of people to watch for call status updates
	private callStatusUpdates = new BehaviorSubject<any[]>([]);

  private lastCallTime: number = 0;
  private isThrottled: boolean = false;
  private throttleDelay: number = 1000; // 15 seconds

  requestCallStatusUpdates(user_idsAr = []) {

    if(user_idsAr.length == 0){
      return;
    }

    const now = Date.now();
    
    if (this.isThrottled) {

      return;
    }

    if (now - this.lastCallTime >= this.throttleDelay) {
      this.callStatusUpdates.next(user_idsAr);
      this.lastCallTime = now;
      this.isThrottled = true;

      setTimeout(() => {
        this.isThrottled = false;
      }, this.throttleDelay);
    } else {

    }
  }

  // requestCallStatusUpdates(user_idsAr =[]){
  //     console.log(105, "websocket.service.ts", 'requestCallStatusUpdates')
  // 	this.callStatusUpdates.next(user_idsAr);
  // }

  getCallStatusUpdates(){
  	return this.callStatusUpdates.asObservable(); // Return as Observable
  }

  private userStatusUpdate = new BehaviorSubject<any>({});
  updateUserOnCallStatus(user: any){
  	this.userStatusUpdate.next(user);
  }

  getUserOnCallStatus(){
  	return this.userStatusUpdate.asObservable();
  }


}