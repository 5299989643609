import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../legacy/base/base.service';
import { SharedService } from '../../../shared.service'

interface City {
  name: string;
}

interface StateOrCounty {
  name: string;
  cities: City[];
}

interface Country {
  name: string;
  statesOrCounties: StateOrCounty[];
}

@Component({
  selector: 'app-scrape-data',
  templateUrl: './scrape-data.component.html',
  styleUrls: ['./scrape-data.component.css']
})

export class ScrapeDataComponent implements OnInit {

  constructor(private http: HttpClient, public service: BaseService, public sharedService: SharedService) { }

  keyword: string = '';

  required_category_keywords: string = '';
  jobName: string = '';
  agent: any = {} ;
  agents = [ ] ;

  states = [];
  cities = [];
  zips = [];
  countries = [];

  regionLabel = "State";

  selectedCountry = 'USA';
  fullName = '';
  postCodeLabel = "Zip"

  previousJobs = [];

  teams = [];
  selectedTeam = "";

  languages = [];
  selectedLanguage = "";

  
  toggleExactMatch($event){
   

    console.log(60, this.requiredCategoryKeywords, this.categoryValues)
    for(var requiredCategory of this.requiredCategoryKeywords){
      this.categoryValues[requiredCategory] = requiredCategory;
    }


  }

  async ngOnInit(){

      let profile = await this.http.get(`/api/main/user/profile/getProfile`).toPromise();

      if(profile["profile"]){
        this.fullName = profile["profile"]["fullName"];
      }

      this.agents = await this.getAgents();
      this.teams = await this.getTeams();
      this.languages = await this.getLanguages();
      console.log(60, this.teams);
      // this.service.getArray("jobs", { }, true, 9999).subscribe((data: any) => {
      //   this.previousJobs = data;
      //   console.log(47, this.previousJobs)
      // })

      this.sharedService.getCountries().subscribe(data => this.countries = data);

  }

  async getAgents() {
    let url = "/api/administration/getAgents"
    let results = await this.http.get(url).toPromise();
    return results["agents"];
  }

  async getTeams() {
    let url = "/api/administration/getTeams"
    let results = await this.http.get(url).toPromise();
    return results["teams"];    
  }

  async getLanguages(){
    let url = "/api/administration/getLanguages"
    let results = await this.http.get(url).toPromise();
    return results["languages"];    
  }

  requiredCategoryKeywords: string[] = [];
  // , 'roofing', 'plumbing', 'electrical', 'flooring', 'painting', 
  //   'insulation', 'siding', 'carpentry', 'concrete', 'masonry', 'landscaping', 
  //   'excavation', 'hvac', 'foundation', 'tiling', 'cabinetry', 'demolition', 
  //   'ventilation', 'waterproofing', 'fencing'
  // ];
  // framing, drywall, roofing, plumbing, electrical, flooring, painting, insulation, siding, carpentry, concrete, masonry, landscaping, excavation, hvac, foundation, tiling, cabinetry, demolition, ventilation, waterproofing, fencing 
  categoryValues: { [key: string]: string } = {};
  async setRequiredCategories(){
   
    let keywords = this.keyword.split(",").map(keyword => keyword.trim());
    this.requiredCategoryKeywords = keywords;

    let url = "/api/administration/discoverCategories"
    let results = await this.http.post(url, { "keywords": keywords } ).toPromise();
    
    this.categoryValues = results['categories'];

  }

  testRequiredCategories(){
    console.log(111, this.categoryValues);

  }

  async performSampleScrape(){
    if(this.allKeywordsAr.length == 0){
      this.allKeywordsAr = this.keywords;
      
    }

     //let required_category_keywordsAr = this.required_category_keywords.split(",").map(keyword => keyword.trim());

    console.log(95, this.selectedTeam, this.selectedLanguage, this.allKeywordsAr.length);



    // Break keywords into chunks of 1000
    const chunkSize = 1000;
    for (let i = 0; i < this.allKeywordsAr.length; i += chunkSize) {
      const keywordsChunk: any = this.allKeywordsAr.slice(i, i + chunkSize);
      
      console.log(131, keywordsChunk)
      //let requiredCategoryKeywords = this.categoryValues[keywordsChunk].split(",").map(keyword => keyword.trim());

      const payload = {
        keywords: keywordsChunk, 
        requiredCategoriesAr: this.categoryValues,
        jobName: this.jobName,
        team_id: this.selectedTeam, 
        language: this.selectedLanguage,
        targetColl: "leadsSampleScrape"
      };
      
      // Conditionally add agents to the payload
      // if (typeof this.agent["_id"] === 'undefined') {
      //   payload.agents = this.agents;
      // } else {
      //   payload.agents = [this.agent];
      // }

      console.log(74, payload);

      //await new Promise(resolve => setTimeout(resolve, 0)); // Non-blocking delay

      

      try {
        await this.http.post('/api/administration/addKeywordsToScrape', payload).toPromise()  
      } catch(err){
        console.log(122, err);
      }
      
    }

      try {
        var primaryKeywords = this.keyword.split(",").map(keyword => keyword.trim());
      const payload = {
        keywords: primaryKeywords, 
        requiredCategoriesAr: this.categoryValues,
        jobName: this.jobName,
        team_id: this.selectedTeam, 
        language: this.selectedLanguage,
        targetColl: "leadsSampleScrape",
      };
        await this.http.post('/api/administration/createScrapeJob', payload).toPromise()  
      } catch(err){
        console.log(122, err);
      }

    alert("Scraping has started. This process can take several hours to complete");

  }

  async startScraping() {

    if(this.allKeywordsAr.length == 0){
      this.allKeywordsAr = this.keywords;
    }

     //let required_category_keywordsAr = this.required_category_keywords.split(",").map(keyword => keyword.trim());

    console.log(95, this.selectedTeam, this.selectedLanguage, this.allKeywordsAr.length);

    // Break keywords into chunks of 1000
    const chunkSize = 1000;
    for (let i = 0; i < this.allKeywordsAr.length; i += chunkSize) {
      const keywordsChunk: any = this.allKeywordsAr.slice(i, i + chunkSize);
      
      console.log(131, keywordsChunk)
      //let requiredCategoryKeywords = this.categoryValues[keywordsChunk].split(",").map(keyword => keyword.trim());

      const payload = {
        keywords: keywordsChunk, 
        requiredCategoriesAr: this.categoryValues,
        jobName: this.jobName,
        team_id: this.selectedTeam, 
        language: this.selectedLanguage,
        targetColl: "coldleads"
      };
      
      // Conditionally add agents to the payload
      // if (typeof this.agent["_id"] === 'undefined') {
      //   payload.agents = this.agents;
      // } else {
      //   payload.agents = [this.agent];
      // }

      console.log(74, payload);

      //await new Promise(resolve => setTimeout(resolve, 0)); // Non-blocking delay

      try {
        await this.http.post('/api/administration/addKeywordsToScrape', payload).toPromise()  
      } catch(err){
        console.log(122, err);
      }
      
    }

    alert("Scraping has started. This process can take several hours to complete");
  }

  // startScraping() {

  //   console.log(95, this.selectedTeam, this.selectedLanguage);

  //   const payload = {
  //     keywords: this.keywords, search_term: this.keyword, team_id: this.selectedTeam, language: this.selectedLanguage
  //   }

  //   console.log(74, this.agent);
  //   // // this.agent["agent_id"] = this.agent._id;
  //   // if(typeof this.agent["_id"] === 'undefined'){
  //   //   payload.agents = this.agents;
  //   // } else {
  //   //   payload.agents = [ this.agent ]
  //   // }

  //   this.http.post('/api/administration/addKeywordsToScrape', payload).subscribe(
  //     response => {
  //       console.log('Scraping started', response);
  //     },
  //     error => {
  //       console.error('Error starting scraping', error);
  //     }
  //   );

  //   alert("Scraping has started.  This process can take several hours to complete");
  // }

  onTargetKeywordChange(event: any) {
    // Logic for target keyword change
  }

  onCountryChange(event: any) {
    this.selectedCountry = event.target.value;
    // Reset selected state or county when country changes
    if(this.selectedCountry != 'United States'){
      this.regionLabel = "Region"
      this.postCodeLabel = "Post Code"
      this.states = [];
    } else {
    this.regionLabel = "State"
    this.postCodeLabel = "Zip"
    this.http.get('/api/datasource/uszips/distinct/state/all').subscribe(
      response => {
      console.log('Scraping started', response['uszips']);
          this.states = response['uszips'];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );
  }

    if(this.selectedCountry == "Germany"){
      this.http.get('/api/datasource/germanzips3/distinct/state/all').subscribe(
        response => {
        console.log('Scraping started', response['uszips']);
            this.states = response['germanzips3'];
          },
          error => {
            console.error('Error starting scraping', error);
          }
        );
    }

    if(this.selectedCountry == "France"){

    }

  }

  selectedState = '';

  @ViewChild('zipSelect') zipSelect: ElementRef;
  @ViewChild('cityList') cityList: ElementRef;
  @ViewChild('stateList') stateList: ElementRef;

  onStateChange(event: any) {
    // if (this.selectedCountry) {
    //   this.selectedStateOrCounty = this.selectedCountry.statesOrCounties.find(state => state.name === event.target.value);
    // }

    this.selectedState = event.target.value;

    if(this.selectedCountry == "Germany"){
      this.service.getArray("germanzips3", {"state": event.target.value }, true, 1000).subscribe((data: any) => {
        let cities = [];

         console.log(1649, data);

         for(let city of data){
              cities.push(city["city"])
          }
          cities = [ ... new Set(cities) ]
          this.cities = cities;
      })
      return;
    }

    console.log(1644, event);

    this.cities = [];
    

    this.service.getArray("uszips", {"state": event.target.value }, true, 1000).subscribe((data: any) => {
      let cities = [];

       console.log(1649, data);

       for(let city of data){
            cities.push(city["city"])
        }
        cities = [ ... new Set(cities) ]
        this.cities = cities;
    })

      this.cities = [];
      this.zips = [];
      this.selectedCityName = "";
      this.selectedZip = "";
      this.keywords = [];
      this.clearKeywords(null);

  }

  keywords: string[] = [];

  onCityChange(event: any) {

    this.selectedCityName = event.target.value;

    if(this.selectedCountry == "Germany"){
    this.service.getArray("germanzips3", {"city": event.target.value, "state": this.selectedState }, true, 1000).subscribe((data: any) => {
        console.log(1640, data);

        for(let zip of data){
          this.zips.push(`${zip['zip']}`)
        }
      })
      return;

    }
    
    this.service.getArray("uszips", {"city": event.target.value, "state": this.selectedState }, true, 1000).subscribe((data: any) => {
        console.log(1640, data);

        for(let zip of data){
          this.zips.push(`${zip['zip']}`)
        }
      })
    this.zips = [];
    //}
  }

  selectedZip = '';
  selectedCityName = '';

  onZipChange(event: any) {
    if(this.keyword.length == 0){
      alert("You must type in a keyword first");
      return;
    }
    this.selectedZip = event.target.value;

      console.log(1669, this.keyword, this.selectedCityName, event)
      let keywordsAr = this.keyword.split(',');
      keywordsAr.reverse()

      const selectedOptions = this.zipSelect.nativeElement.selectedOptions;

    // Extract the values of selected options and store them in the 'zips' array
    let allZips = Array.from(selectedOptions).map((option: any) => option.value);
    for(let zip of allZips){
      for(var keyword of keywordsAr){
        const searchKeyword = `${keyword} in ${this.selectedCityName}, ${this.selectedState}. ${zip}`;
        this.keywords.unshift(searchKeyword);   
      }  
    }
    console.log(1704, allZips);

  }

  onKeywordChange(event: any) {
    // Logic for keyword selection change
  }

  allKeywordsAr = [];
  async selectAllStates($event){
    $event.preventDefault();
    console.log(1717, "selectAllStates");

    let collection = "uszips";
    if(this.selectedCountry == "Germany"){
      collection = "germanzips3"
    }    

    let url = `/api/administration/getAllZips/collection/${collection}`

    alert("This process takes A LONG time.")

    let zips = await this.http.get(url).toPromise();
    let keywordsAr = [];

    for(var zip of zips['zips']){
      var keywords = this.keyword.split(',');
       for(var keyword of keywords){
        var kw = `${keyword} in ${zip.city}, ${zip.state}. ${zip.zip}`;
        kw = kw.trim();
        keywordsAr.push(kw);
      }
    }
    this.allKeywordsAr = keywordsAr;

    this.keywords = keywordsAr.slice(0, 6000);
    this.keywords.unshift("");
    this.keywords.unshift("List represents a sample of all keywords");
    this.keywords.unshift("");
    this.keywords.unshift(`${keywordsAr.length} keywords were generated`);
    this.keywords.unshift("");    

}

  selectAllCities($event){
    $event.preventDefault();
    console.log(1717, "selectAllCities");

    let collection = "uszips";
    if(this.selectedCountry == "Germany"){
      collection = "germanzips3"
    }

    //console.log(393, "zip count", this.zips);
    //console.log(394, "city count", this.cities)

    this.service.getArray(collection, {"state": this.selectedState }, true, 5000).subscribe((data: any) => {
      let keywords = [];

       let cities = [];
       let zips = []

       let keywordsAr = this.keyword.split(",").map(keyword => keyword.trim());

       for(let city of data){
            //cities.push(city["city"])
          for(let kw of keywordsAr){
          //console.log(1649, this.keyword);
          const keyword = `${kw} in ${city["city"]}, ${this.selectedState}. ${city["zip"]}, ${this.selectedCountry}`;
          //console.log(1739, keyword);
          keywords.unshift(keyword);     
          zips.push(city["zip"]);
          cities.push(city["city"])
          }
        }

        keywords = [ ... new Set(keywords), ... this.keywords ]
        this.keywords = keywords;
        this.cities = [ ... new Set(cities) ];
        this.zips = [ ... new Set(zips) ];

        console.log(421, "zip count", this.zips.length);
        console.log(422, "keyword count", this.keywords.length);
    })

      this.selectedCityName = "";
      this.selectedZip = "";

    
    //console.log(426, "city count", this.cities)

  }

  selectAllZips($event){
    $event.preventDefault();
    
    //const selectedOptions = this.zipSelect.nativeElement.;

    // Extract the values of selected options and store them in the 'zips' array
    //let allZips = Array.from(selectedOptions).map((option: any) => option.value);

    let keywords = this.keyword.split(",").map(keyword => keyword.trim());
    for(var keyword of keywords){
      for(let zip of this.zips){
        const kw = `${keyword} in ${this.selectedCityName}, ${this.selectedState}. ${zip}`;
        this.keywords.unshift(kw);     
      }

    }

  }

  clearKeywords($event){
    if($event !== null){
      $event.preventDefault();  
    }
    
    this.allKeywordsAr = [];
    this.keywords = [];
  }

  removePreviouslyScrapedKeywords($event){
    $event.preventDefault();
    let previousSearched = [];
    this.service.getArray("jobs", { }, true, 9999).subscribe((data: any) => {
      console.log(224, data)
      for(let job of data){
        previousSearched = previousSearched.concat(job["keywords"])
      }
      console.log(231, previousSearched);
      this.keywords = this.keywords.filter(keyword => !previousSearched.includes(keyword));

    })


    
  }

  postalCodeStartRange = "";
  postalCodeEndRange = "";

  async findPostalCodeRangeCities(){
    console.log(571, this.postalCodeStartRange, this.postalCodeEndRange);

    console.log(1717, "selectAllStates");

    let collection = "uszips";
    if(this.selectedCountry == "Germany"){
      collection = "germanzips3"
    }    

    let url = `/api/administration/getCitiesInZipRange/collection/${collection}/postalCodeStartRange/${this.postalCodeStartRange}/postalCodeEndRange/${this.postalCodeEndRange}`

    let cities: any = await this.http.get(url).toPromise();
    cities = cities['cities'];

    let citiesAr = [];
    let zipsAr = [];
    console.log(1649, cities);

    for(let city of cities){
      zipsAr.push(city['zip']);
    }

    let keywords = this.keyword.split(",").map(keyword => keyword.trim());
    for(var keyword of keywords){

      for(let city of cities){
        const kw = `${keyword} in ${city.city}, ${city.state}. ${city.zip}`;
        this.keywords.unshift(kw);     
      }
    }

         for(let city of cities){
              citiesAr.push(city["city"])
          }
    citiesAr = [ ... new Set(citiesAr) ]
    this.cities = citiesAr;

  this.zips = zipsAr;



    // }

    // console.log(584, cities);

  }

}
