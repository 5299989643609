import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SharedService } from '../../shared.service';
import { BaseService } from "../../legacy/base/base.service"
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WebsocketService} from '@websocket'
import { Location } from '@angular/common';
import { CdkDragMove } from '@angular/cdk/drag-drop';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

@Component({
  selector: 'app-unmatched-incoming-call',
  templateUrl: './unmatched-incoming-call.component.html',
  styleUrl: './unmatched-incoming-call.component.css'
})

export class UnmatchedIncomingCallComponent implements OnInit {

  searchTerm: string = '';
  selectedLanguage: any; // Assuming you have types or interfaces defined
  selectedTeam: any; // Assuming you have types or interfaces defined

  httpOptions = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    "withCredentials": true
  };

  contactsAr = [];

  constructor(private http: HttpClient, private dataService: DataService) {

  }

  async searchFunction() {
      // Implement your search logic here
    if(this.addressSearchTerm.length > 1){
      return await this.addressSearchFunction();
    }

      var api_url = `api/agents/lookupContactBySearch/searchTerm/${this.searchTerm}`;
      let results = await this.http.get(api_url, this.httpOptions).toPromise();
      this.contactsAr = results['result'];
    }

  addressSearchTerm = "";
  bRateLimit = false;
  async addressSearchFunction(){

    console.log(66, "addressSearchFunction");

    this.bRateLimit = true;

    setTimeout( () => {
      this.bRateLimit = false;
    }, 400);

    setTimeout( async () => {
      if(this.bRateLimit == false){
        var api_url = `api/agents/searchByTitleAndAddress`;
        let results = await this.http.post(api_url, {
          "titleSearchTerm": this.searchTerm,
          "addressSearchTerm": this.addressSearchTerm
        }, this.httpOptions).toPromise();
        this.contactsAr = results['result'];        
      }
    }, 800);


  }

  // this.dataService.setIncomingCall(bestMatches);
  async selectContact($event, contact){
    $event.preventDefault();
    
    let incomingNumber = this.dataService.formatPhoneNumber(this.incomingCallNumber);
    var api_url = `api/calls/findBestMatches/remoteCallerNumber/${contact.phone}`;
    let bestMatches = await this.http.get(api_url).toPromise();

    if(bestMatches["incoming_call"]["exact_match"] == true){
      // Load Exact Match Screen
      this.isVisible = false;
      
      let id = bestMatches["incoming_call"]["coldlead"]["_id"];
      let result = await this.http.put(`api/datasource/coldleads/array/id/${id}`, {
          key: "additionalPhones",
          value: incomingNumber,
        }).toPromise();

      if(Array.isArray(bestMatches["incoming_call"]["coldlead"]["additionalPhones"])){
        bestMatches["incoming_call"]["coldlead"]["additionalPhones"].push(incomingNumber);  
      } else {
        bestMatches["incoming_call"]["coldlead"]["additionalPhones"] = [ incomingNumber ];
      }

      this.dataService.setIncomingCall(bestMatches);
    } else {
      // Load No Exact Match Screen
    }
  }

  listDispositions(event: any, type: string) {
      // Your logic to handle listing different types of dispositions
      console.log(`Listing dispositions for type: ${type}`);
    }

  draggingDisabled: boolean = false;

  isVisible: boolean = false; // Controls the visibility, true by default

  toggleVisibility(): void {
      this.isVisible = false; // Hides the component
  }

  incomingCallNumber = "";

  ngOnInit(): void {
    this.dataService.getUnmatchedIncomingCall().subscribe( incomingCallInfo => {
      this.isVisible = true;
      this.incomingCallNumber = incomingCallInfo;
    });
  }

  top: number = 0; // Default position
  left: number = 0; // Default position

  onDragMoved(event: CdkDragMove) {

  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }

  addPhoneNumber(){
    
  }
}