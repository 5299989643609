import { Component } from '@angular/core';

@Component({
  selector: 'disposition',
  templateUrl: './disposition.component.html',
  styleUrl: './disposition.component.css'
})
export class DispositionComponent {

}
