import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  OnInit,
  OnChanges,
  QueryList,
  ElementRef,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { SharedService } from "@app/shared.service";
import { DataService } from "@services/data.service";

import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

import { FormControl } from "@angular/forms";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.css"],
})

export class InfoComponent implements OnChanges, OnInit {
  currentAudioFile = "";
  loadAudioFile = false;
  tracks = Array.from({ length: 200 }, () => ({ url: "" }));
  partialTracks = Array.from({ length: 200 }, () => ({ url: "" }));
  openMap: boolean = true;
  fullReset: boolean = true;

  @ViewChildren("audioPlayers") audioPlayers: QueryList<ElementRef>;
  @ViewChildren("partialAudioPlayers")
  partialAudioPlayers: QueryList<ElementRef>;

  scrollToTop(): void {}

  maxWidth: string = "500px";
  expanded: boolean = false;
  btnText = "Expand";

  expandContent() {
    this.dataService.togglePanelSize(this.expanded);
    if (this.expanded) {
      //this.maxWidth = "500px";
      this.btnText = "Expand";
    } else {
      //this.maxWidth = "";
      this.btnText = "Shrink";
    }
    this.expanded = !this.expanded;
  }

  async playPartialAudio(call_session_id, playerIndex: number, partial = false ) {
    var endpoint = `api/calls/getCallRecording/call_record_id/${call_session_id}`;

    let call_recording_info = await this.http.get(endpoint).toPromise();

    try {
      let download_urls = call_recording_info["download_urls"];
      let keys = Object.keys(download_urls);
      console.log(keys);
      var url = download_urls[keys[0]];
    } catch (err) {}

    console.log(93, url);

    let pindex = 0;
    let answer = 0;
    for (let i = 0; i < this.callLogs.length; i++) {
      //console.log(97,  this.callLogs[i]);
      if (!Array.isArray(this.callLogs[i].callRecordings)) {
        continue;
      }

      for (let y = 0; y < this.callLogs[i].callRecordings.length; y++) {
        let callRecording = this.callLogs[i]["callRecordings"][y];
        console.log(99, callRecording);
        if (callRecording["recording_id"] == call_session_id) {
          answer = pindex;
        } else {
          pindex++;
        }
      }
    }

    this.partialTracks[answer].url = url;

    const audioPlayerRef = this.partialAudioPlayers.toArray()[answer];

    if (audioPlayerRef && audioPlayerRef.nativeElement) {
      // Check if source is already set, if not, set it here and load

      if (
        !audioPlayerRef.nativeElement.src ||
        audioPlayerRef.nativeElement.src !== this.partialTracks[answer].url
      ) {
        audioPlayerRef.nativeElement.src = this.partialTracks[answer].url;
        audioPlayerRef.nativeElement.load();
      }
      // Attempt to play
      audioPlayerRef.nativeElement
        .play()
        .then(() => {
          console.log("Playback started successfully");
        })
        .catch((e) => {
          console.error("Playback failed:", e);
          // Handle or log the error, possibly retry or provide user feedback
        });
    }
  }

  async playAudio(call_session_id, playerIndex: number, partial = false) {
    let index = playerIndex;

    let endpoint = `api/calls/getCallRecordingInfo`;
    if (partial === true) {
      endpoint = `api/calls/getCallRecordingInfoPartial`;
    }
    let call_recording_info = await this.http
      .post(endpoint, {
        call_record_id: call_session_id,
      })
      .toPromise();

    try {
      let download_urls =
        call_recording_info["call_recording"]["download_urls"];
      let keys = Object.keys(download_urls);

      const audioPlayerRef = this.audioPlayers.toArray()[index];

      var url = download_urls[keys[0]];
    } catch (err) {}

    this.tracks[playerIndex].url = url;

    const audioPlayerRef = this.audioPlayers.toArray()[playerIndex];

    if (audioPlayerRef && audioPlayerRef.nativeElement) {
      // Check if source is already set, if not, set it here and load

      if (
        !audioPlayerRef.nativeElement.src ||
        audioPlayerRef.nativeElement.src !== this.tracks[playerIndex].url
      ) {
        audioPlayerRef.nativeElement.src = this.tracks[playerIndex].url;
        audioPlayerRef.nativeElement.load();
      }
      // Attempt to play
      audioPlayerRef.nativeElement
        .play()
        .then(() => {
          console.log("Playback started successfully");
        })
        .catch((e) => {
          console.error("Playback failed:", e);
          // Handle or log the error, possibly retry or provide user feedback
        });
    }
  }

  async onPlay(call_session_id, index) {
    let call_recording_info = await this.http
      .post(`api/calls/getCallRecordingInfo`, {
        call_record_id: call_session_id,
      })
      .toPromise();

    try {
      let download_urls =
        call_recording_info["call_recording"]["download_urls"];
      let keys = Object.keys(download_urls);

      const audioPlayerRef = this.audioPlayers.toArray()[index];

      let url = download_urls[keys[0]];
      this.tracks[index] = url;
      audioPlayerRef.nativeElement.load();
      audioPlayerRef.nativeElement
        .play()
        .catch((e) => console.error("Playback failed:", e));
      //his.currentAudioFile = url;
      //this.loadAudioFile = true;
    } catch (err) {}
    //console.log(39, call_recording_info);
  }

  onPause() {
    console.log("Audio paused");
    this.currentAudioFile = "";
    this.loadAudioFile = false;
  }

  onEnded() {
    this.currentAudioFile = "";
    this.loadAudioFile = false;
  }

  onTimeUpdate(event: any) {
    console.log("Current time: ", event.target.currentTime);
  }

  recordingsLoaded = false;

  current_id = "";
  imagesAr = [];
  result: any = {};
  call_recordings = [];
  objectKeys = Object.keys;

  fullName = "";

  callRecords = [];
  callLogs: any = [];
  callRecordings: string[] = [];
  userInfo = null;

  @ViewChild("accordionPhotos") collapseFour!: ElementRef;

  scrollToView(element: HTMLElement): void {
    setTimeout(() => {
      const options: ScrollIntoViewOptions = {
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      };
      element.scrollIntoView(options);
      console.log(element);
    }, 750);
  }

  async ngOnInit() {
    let profile = await this.http
      .get(`/api/main/user/profile/getProfile`)
      .toPromise();

    this.openMap = localStorage.getItem("openMap") === "true";
    this.fullReset = localStorage.getItem("fullReset") === "true";

    this.dataService.getUserInfo().subscribe((data) => {
      this.userInfo = data;

    });

    if (profile["profile"]) {
      this.fullName = profile["profile"]["fullName"];
    }
  }

  @ViewChild("floatButtons") floatButtons!: ElementRef;
  private originalOffsetTop!: number;

  ngAfterViewInit() {
    //this.originalOffsetTop = this.floatButtons.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll() {
    // const currentScrollPosition = window.pageYOffset;
    // if (currentScrollPosition >= this.originalOffsetTop) {
    //   this.floatButtons.nativeElement.classList.add('fixed-top');
    // } else {
    //   this.floatButtons.nativeElement.classList.remove('fixed-top');
    // }
  }

  contactPhoneNumbers = [];

  toggleMap(event: any) {
    this.openMap = event.target.checked;
    localStorage.setItem("openMap", String(this.openMap));
  }

  toggleReset(event){
    this.fullReset = event.target.checked;
    localStorage.setItem("fullReset", String(this.fullReset));    
  }

  async ngOnChanges(changes: SimpleChanges) {

    if (changes["parentEvent"]) {
      this.parentEvent.subscribe(async (event) => {
        //this.resetPanel();

        this.current_id = event["id"];
        try {
          let result: any = await this.http
            .get(`api/datasource/coldleads/id/${this.current_id}`).toPromise();

            this.result = result["coldleads"];
        } catch(err){

        }
      });
  }
}

  async fetchUpdatedRecordingUrls(call_recordings: any) {
    let call_recording_ids = [];
    for (let call_record of call_recordings) {
      call_recording_ids.push(call_record["call_session_id"]);
    }

    let all_records = await this.http
      .post(`api/calls/getCallRecordingInfo`, {
        call_record_id: call_recording_ids,
      })
      .toPromise();

    this.call_recordings = all_records["call_records"];
  }

  @ViewChild("accordionPhotos") private accordionPhotos!: ElementRef;
  @ViewChild("photosBtn") private photosBtn!: ElementRef;

  @ViewChild("callLogsBtn") private callLogsBtn!: ElementRef;
  @ViewChild("callLogsAccordian") private callLogsAccordian!: ElementRef;

  @ViewChild("contactInfoBtn") private contactInfoBtn!: ElementRef;
  @ViewChild("contactInfoAccordian") private contactInfoAccordian!: ElementRef;

  resetPanel() {
    return;
    
    this.current_id = "";
    this.imagesAr = [];
    this.contact = {
      first_name: "",
      last_name: "",
      extension: "",
      apemail: "",
      email: "",
      phone: "",
      title: "",
      additionalPhones: [],
      group: "",
    };
    this.note = "";
    this.selectedCoolingPeriod = "";
    this.result = {};
    this.events = [];
    this.call_recordings = [];
    this.appointmentId = null;
    this.callLogs = [];
    this.tracks = Array.from({ length: 200 }, () => ({ url: "" }));
    this.partialTracks = Array.from({ length: 200 }, () => ({ url: "" }));

    this.appointmentTime = "";
    this.appointmentDay = "";
    this.selectedCoolingPeriod = "";

    this.contactPhoneNumbers = [];

    const accordion: HTMLElement = this.accordionPhotos.nativeElement;
    const accordionBtn: HTMLElement = this.photosBtn.nativeElement;
    if (accordion.classList.contains("show")) {
      accordion.classList.remove("show");
      accordionBtn.classList.add("collapsed");
    }

    if (this.callLogsAccordian.nativeElement.classList.contains("show")) {
      this.callLogsAccordian.nativeElement.classList.remove("show");
      this.callLogsBtn.nativeElement.classList.add("collapsed");
    }

    if (this.contactInfoAccordian.nativeElement.classList.contains("show")) {
      this.contactInfoAccordian.nativeElement.classList.remove("show");
      this.contactInfoBtn.nativeElement.classList.add("collapsed");
    }
  }

  async onfNameChange() {
    try {
      let patchData = { contactFirstName: this.contact.first_name };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactFirstName"] = this.contact.first_name;
      this.result = { patchData, ...this.result };
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onlNameChange() {
    try {
      let patchData = { contactLastName: this.contact.last_name };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactLastName"] = this.contact.last_name;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onExtensionChange() {
    try {
      let patchData = { contactExtension: this.contact.extension };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactExtension"] = this.contact.extension;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onEmailChange() {
    try {
      let patchData = { contactEmail: this.contact.email };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactEmail"] = this.contact.email;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onAPEmailChange() {
    try {
      let patchData = { contactAPEmail: this.contact.apemail };
      console.log(439, patchData);
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactAPEmail"] = this.contact.apemail;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  trackByFn(index: any, item: any) {
    return index; // or item.id if your items have unique ids
  }

  onAdditionalPhoneChange(index: number) {
    // Set the phone number array
    //console.log(189, this.contact.additionalPhones[index]);
    // if(this.additionalPhones[i].length == 0){
    //   // Add a new array
    // } else {
    //   // Update an array element
    // }
  }

  async savePhone(index) {
    // /datasource/user/array/id/604fae76fe82bb0a6438164f
    this.contact.additionalPhones[index] = this.dataService.formatPhoneNumber(
      this.contact.additionalPhones[index]
    );

    this.contactPhoneNumbers = [];
    this.contactPhoneNumbers.push(this.contact.phone);
    this.contactPhoneNumbers = this.contactPhoneNumbers.concat(
      this.contact.additionalPhones
    );

    let result = await this.http
      .put(`api/datasource/coldleads/array/id/${this.current_id}`, {
        key: "additionalPhones",
        value: this.contact.additionalPhones[index],
      })
      .toPromise();

    let callLogs: any = await this.http
      .post(`api/administration/getCallLogs`, {
        phoneNumbers: this.contactPhoneNumbers,
      })
      .toPromise();
    this.callLogs = callLogs["callLogs"];

    alert("Phone Number Saved");

  }

  addSecondaryNumber(): void {
    this.contact.additionalPhones.push("");
  }

  async onPhoneChange() {
    try {
      let patchData = { "scrape_result.phone": this.contact.phone };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onTitleChange() {
    try {
      let patchData = { "scrape_result.title": this.contact.title };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onGroupChange() {
    try {
      let patchData = { "scrape_result.group": this.contact.group };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onDropdownChange() {
    let cooldownUntil = "";
    let now = dayjs();

    switch (this.selectedCoolingPeriod) {
      
      case "1 week":
        cooldownUntil = now.add(1, "week").format();
        break;
      case "1 month":
        cooldownUntil = now.add(1, "month").format();
        break;
      case "6 weeks":
        cooldownUntil = now.add(6, "week").format();
        break;
      case "3 months":
        cooldownUntil = now.add(3, "month").format();
        break;
      case "6 months":
        cooldownUntil = now.add(6, "month").format();
        break;
      case "12 months":
        cooldownUntil = now.add(12, "month").format();
        break;
      case "Forever":
        //cooldownUntil = now.add(100, 'year').format();
        break;
    }

    let patchData = { cooldown: cooldownUntil };
    if (this.selectedCoolingPeriod == "Forever") {
      patchData["archived"] = now.add(100, "year");
      delete patchData["cooldown"];
    }

    console.log(209, this.selectedCoolingPeriod, patchData);

    try {
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    this.dataService.refreshTable();
    this.resetPanel();
    this.closePanelAction(null);
  }

  contact = {
    first_name: "",
    last_name: "",
    apemail: "",
    extension: "",
    email: "",
    phone: "",
    title: "",
    group: "",
    additionalPhones: [],
  };

  selectedCoolingPeriod = "";

  // Define cooling period options
  coolingPeriodOptions = [
    "1 week",
    "1 month",
    "6 weeks",
    "3 months",
    "6 months",
    "12 months",
  ];

  events = [];

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private dataService: DataService
  ) {}

  @Input() parentEvent;

  handleEvent($event) {
    console.log(30, $event);
  }

  closePanelAction($event) {
    this.resetPanel();
    this.closePanel.emit(true);
  }

  @Input() set panelId(value: string) {
    this._panelId = value;
    //this.onDataChange();
  }

  get panelData(): string {
    return this._panelId;
  }

  _panelId: string = "abcd";

  onDataChange() {}

  @Input() childVar: string;
  @Output() closePanel = new EventEmitter<any>();
  @Output() childEvent = new EventEmitter<any>();

  note = "";

  previousNotes = [];

  async addNote() {
    // /datasource/user/array/id/604fae76fe82bb0a6438164f
    let now = dayjs();
    //console.log("Current time: ", now.format());

    let submission = await this.http
      .post(`api/datasource/coldleads/array/id/${this.current_id}`, {
        key: "notes",
        value: {
          content: this.note,
          date: `${this.fullName} - ${now.format("MM-DD-YYYY")}`,
        },
      })
      .toPromise();

    this.previousNotes.push({
      content: this.note,
      date: `${this.fullName} - ${now.format("MM-DD-YYYY")}`,
    });
    this.note = "";
  }

  async addToHistory(eventType: string) {
    let now = dayjs();
    let submission = await this.http
      .post(`api/datasource/coldleads/array/id/${this.current_id}`, {
        key: "events",
        value: {
          type: eventType,
          date: `${this.fullName} - ${now.format("MM-DD-YYYY")}`,
        },
      })
      .toPromise();

    this.events.push({
      type: eventType,
      date: `${this.fullName} - ${now.format("MM-DD-YYYY")}`,
    });
  }

  updateContact() {}

  updateExtension() {}

  updateEmail() {}

  setCoolingOffPeriod() {}

  playingAudioId: string | null = null;
  audio: HTMLAudioElement | null = null;

  async getRecordingLink(call_record_id: string) {
    let call_recording_info = await this.http
      .post(`api/calls/getCallRecordingLink`, {
        call_record_id: call_record_id,
      })
      .toPromise();

    return call_recording_info["call_record_mp3"];
  }

  async getRecordingLinkPartial(call_record_id: string) {
    let call_recording_info = await this.http
      .post(`api/calls/getCallRecordingInfoPartial`, {
        call_record_id: call_record_id,
      })
      .toPromise();
    console.log(493, call_recording_info["call_record_mp3"]);
    //let url = call_recording_info["call_recording"].download_urls.mp3;

    return call_recording_info["call_record_mp3"];
  }

  async playPauseAudio($event, call_record_id: string) {
    $event.preventDefault();

    let call_recording_info = await this.http
      .post(`api/calls/getCallRecordingInfo`, {
        call_record_id: call_record_id,
      })
      .toPromise();

    let url = "";
    if (call_recording_info["call_recording"].download_urls.mp3) {
      url = call_recording_info["call_recording"].download_urls.mp3;
    } else if (call_recording_info["call_recording"].download_urls.wav) {
      url = call_recording_info["call_recording"].download_urls.wav;
    } else {
      // Handle case where neither URL is available
    }

    console.log(334, url);

    if (this.playingAudioId === call_record_id) {
      if (this.audio?.paused) {
        this.audio.play();
      } else {
        this.audio?.pause();
      }
    } else {
      if (this.audio) {
        this.audio.pause();
      }
      this.playingAudioId = call_record_id;
      this.audio = new Audio(url);
      this.audio.play();
      this.audio.onended = () => {
        this.playingAudioId = null;
      };
    }
  }

  async playPauseAudioPartial($event, call_record_id: string) {
    $event.preventDefault();

    let call_recording_info = await this.http
      .post(`api/calls/getCallRecordingInfoPartial`, {
        call_record_id: call_record_id,
      })
      .toPromise();

    let url = "";
    if (call_recording_info["call_recording"].download_urls.mp3) {
      url = call_recording_info["call_recording"].download_urls.mp3;
    } else if (call_recording_info["call_recording"].download_urls.wav) {
      url = call_recording_info["call_recording"].download_urls.wav;
    } else {
      // Handle case where neither URL is available
    }

    console.log(334, url);

    if (this.playingAudioId === call_record_id) {
      if (this.audio?.paused) {
        this.audio.play();
      } else {
        this.audio?.pause();
      }
    } else {
      if (this.audio) {
        this.audio.pause();
      }
      this.playingAudioId = call_record_id;
      this.audio = new Audio(url);
      this.audio.play();
      this.audio.onended = () => {
        this.playingAudioId = null;
      };
    }
  }

  placeOrder() {
    console.log(962, this.result);
    this.dataService.updateOrderCustomer(this.result);
  }

  getBrowserTimezoneOffset() {
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offset / 60));
    const offsetMinutes = Math.abs(offset % 60);
    const offsetSign = offset > 0 ? "-" : "+"; // Inverting the sign because getTimezoneOffset returns the difference in minutes between UTC and local time

    return `${offsetSign}${offsetHours
      .toString()
      .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;
  }

  appointmentId = null;
  appointmentTime = "";
  appointmentDay = "";

  async setAppointmentTime() {
    const timeZone = this.result["scrape_result"]["timezone"];
    const appointmentTimeInTimeZone = dayjs.tz(
      `${dayjs().format("YYYY-MM-DD")}T${this.appointmentTime}`,
      timeZone
    );
    const appointmentTimeInUTC = appointmentTimeInTimeZone.utc();

    let appointment = {
      appointmentTime: appointmentTimeInUTC.format("YYYY-MM-DDTHH:mm:ss.sssZ"),
      contact_id: this.result["_id"],
      contact_title: this.contact.title,
      contact_phone: this.contact.phone,
      contact_name: this.contact.first_name,
      contacts_timezone: timeZone,
    };

    if (this.appointmentId == null) {
      let record = await this.http
        .post(`api/datasource/appointments`, appointment)
        .toPromise();
      this.appointmentId = record["appointments"]["inserted_id"];
    } else {
      let record = await this.http
        .patch(`api/datasource/appointments/id/${this.appointmentId}`, {
          key: "appointmentTime",
          value: appointmentTimeInUTC.format("YYYY-MM-DDTHH:mm:ss.sssZ"),
        })
        .toPromise();
    }
  }

  async setAppointmentDay() {
    const timeZone = this.result["scrape_result"]["timezone"];
    let appointment = {
      appointmentDay: this.appointmentDay,
      contact_id: this.result["_id"],
      contact_title: this.contact.title,
      contact_phone: this.contact.phone,
      contact_name: this.contact.first_name,
      contacts_timezone: timeZone,
    };

    if (this.appointmentId == null) {
      let record = await this.http
        .post(`api/datasource/appointments`, appointment)
        .toPromise();
      this.appointmentId = record["appointments"]["inserted_id"];
    } else {
      let record = await this.http
        .patch(`api/datasource/appointments/id/${this.appointmentId}`, {
          key: "appointmentDay",
          value: this.appointmentDay,
        })
        .toPromise();
    }
  }

  // Push the email to the quickbooks customer
  async saveAPEmail() {
    console.log(882, this.contact.apemail, this.contact.title);
    let callLogs: any = await this.http
      .post(`api/administration/addAPEMailToQuickbooks`, {
        apEmail: this.contact.apemail,
        primaryEmail: this.contact.email,
        displayName: this.contact.title,
      })
      .toPromise();
    alert("Saved");
  }

  edit = false;
  async editContact() {
    this.edit = !this.edit;
  }

  async saveContact() {
    let complete_address: any = {
      borough: "",
      city: this.result["scrape_result"]["complete_address"]["city"],
      street: this.result["scrape_result"]["complete_address"]["street"],
      postal_code:
        this.result["scrape_result"]["complete_address"]["postal_code"],
      state: this.result["scrape_result"]["complete_address"]["state"],
      country: this.result["scrape_result"]["complete_address"]["country"],
    };

    let address = `${complete_address.street}, ${complete_address.city}, ${complete_address.state} ${complete_address.postal_code}`;
    this.result["scrape_result"]["address"] = address;
    try {
      let patchData = {
        "scrape_result.complete_address": complete_address,
        "scrape_result.title": this.result["scrape_result"]["title"],
        "scrape_result.address": address,
      };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
    console.log(918, complete_address);

    this.edit = !this.edit;
  }

  async archive() {
    let cooldownUntil = "";
    let now = dayjs();

    let patchData = { archived: now.add(100, "year") };

    try {
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    this.dataService.refreshTable();
    this.resetPanel();
    this.closePanelAction(null);
  }

  date = new FormControl(new Date());
  time = new FormControl("");
  times = ["08:00", "09:00", "10:00"]; // Add more times as needed
}
