<li class="message-item">
  <span>
    <div>
      <div class="audio-container">
        <button class="button-style" (click)="$event.stopPropagation(); playPartialAudio(payload['call_session_id'])">Load</button>
        <audio (click)="$event.stopPropagation()" channels="1" #audioPlayer class="audio-control" controls="controls" preload="auto">
          <source [src]="url" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>

      <h4>{{from | formatPhone }}</h4>
      <p>{{message}}</p>
      <p>{{time}}</p>
    </div>
    <mat-icon (click)="onDelete(payload['call_session_id'])">delete</mat-icon> <!-- Icon is now correctly positioned -->
  </span>
</li>

<li>
  <hr class="dropdown-divider">
</li>