        <li class="nav-item dropdown pe-3">

          <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
            
            <div class="image-container">
              <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle">
              <div *ngIf="!bHeaderImage" class="text-overlay">{{fInitial}}&nbsp;{{lInitial}}</div>
            </div>
            <span class="d-none d-md-block dropdown-toggle ps-2">{{firstName}}&nbsp;{{lastName}}</span>
          </a>

          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li class="dropdown-header">
              <h6>{{firstName}}&nbsp;{{lastName}}</h6>
              <span>{{accountType}}</span>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li>
              <a class="dropdown-item d-flex align-items-center" routerLink="/main/user/profile">
                <i class="bi bi-person"></i>
                <span>{{"My Profile" | translate }}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li>
              <a class="dropdown-item d-flex align-items-center" routerLink="/main/user/settings">
                <i class="bi bi-gear"></i>
                <span>{{"Account Settings" | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item d-flex align-items-center" routerLink="/main/account/billing">
                <i class="bi bi-gear"></i>
                <span>{{"Billing" | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item d-flex align-items-center" routerLink="/main/help/dashboard">
                <i class="bi bi-question-circle"></i>
                <span>{{"Need Help?" | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>

            <li>
              <a class="dropdown-item d-flex align-items-center" href="#" (click)="signOut($event)">
                <i class="bi bi-box-arrow-right"></i>
                <span>{{"Sign Out" | translate}}</span>
              </a>
            </li>

          </ul>
        </li>