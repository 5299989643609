export const environment = {
  production: false,
  development: true,
  apiBase: '/api',
  v1: '/api',
  websocketBase: 'https://app.contentbounty.com',
  env: 'devlopment',
  apiBase2: '/v2.0/api',
  productName: "scraperCRM",
  calls: true,
  staticStorageBase: 'https://google-maps-crm.saas-product.com',
  connection_id: '2521353397009909402',
  webhookDomain: 'beta.saas-product.com',
  voice_profile: '2398177520051554045',
  sipDomain: 'callwhiserping.saas-product.com.sip.telnyx.com'
};
