import { Component } from '@angular/core';

@Component({
  selector: 'app-ownership',
  templateUrl: './ownership.component.html',
  styleUrl: './ownership.component.css'
})
export class OwnershipComponent {

}
