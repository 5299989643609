      <table class="table table-striped">
         <thead>
            <tr>
               <th scope="col"><table-loading-button [disabled]=true #tableloading>&nbsp;</table-loading-button></th>
               <th scope="col">Email</th>
               <th scope="col">First Name</th>
               <th scope="col">Last Name</th>
               <th scope="col">Plan</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let account of referredAccounts; let rowIndex = index">
            <th scope="row">&nbsp;</th>
            <td>{{account.email}}</td>
            <td>{{account.first_name}}</td>
            <td>{{account.last_name}}</td>
            <td>{{account.plan}}</td>
            </tr>
            <tr></tr>
         </tbody>
      </table>