import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SharedService } from '../shared.service';
import { BaseService } from "../legacy/base/base.service"
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WebsocketService} from '@websocket'
import { Location } from '@angular/common';
import { CdkDragMove } from '@angular/cdk/drag-drop';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

declare var google: any;
declare let MeasureTool: any;

@Component({
  selector: 'map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrl: './map-overlay.component.css'
})

export class MapOverlayComponent implements OnInit {
  map: google.maps.Map;
  measureTool: any; // Adjust this type as needed
  draggingDisabled: boolean = false;

  isVisible: boolean = false; // Controls the visibility, true by default

    toggleVisibility(): void {
      this.isVisible = false; // Hides the component
    }

  constructor(private sharedService: SharedService) {
     this.sharedService.getMapCenter().subscribe(gps_coords => {
      if(gps_coords !== null){
        this.changeMapCenter(gps_coords['latitude'], gps_coords['longtitude']);
        console.log(40, this.isVisible)
      }
     });
  }

  ngOnInit(): void {
    //this.isVisible = localStorage.getItem("openMap") === "true";
    this.loadGoogleMapsScript().then(() => {
      this.initMap();
    });
    this.restorePosition();
  }

  loadGoogleMapsScript(): Promise<void> {
    return new Promise((resolve) => {
      if (typeof google !== 'undefined' && google.maps) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCAD5wq5OhfSviTUeiYzcAgZtieva3m8V8&libraries=geometry'; // Include geometry library
      document.head.appendChild(script);
      script.onload = () => {
        resolve();
      };
    });
  }

  // Function to change the map's center
  changeMapCenter(lat: number, lng: number): void {
    const newCenter = new google.maps.LatLng(lat, lng);
    this.map.setCenter(newCenter);
    this.isVisible = true;
  }

  initMap(): void {

  // window.addEventListener('error', function(event) {
  //     if (event.message.includes('khms1.googleapis.com')) {
  //       console.log('Prevented logging for khms1.googleapis.com error');
  //       event.preventDefault();
  //     }
  //   });

    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      //center: { lat: 0, lng: 0 },
      zoom: 25,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      clickableIcons: true,
      tilt: 0,
      styles: [{
        featureType: "poi.business",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
      }],
      disableDefaultUI: false
    });

    // Initialize MeasureTool after the map is loaded
    this.measureTool = new MeasureTool(this.map, {
      showSegmentLength: true,
      unit: MeasureTool.UnitTypeId.METRIC,
      contextMenu: false // Customize based on your preferences
    });

    this.measureTool.addListener('measure_change', (e) => {
      console.log('Area:', e.result.area, 'Perimeter:', e.result.length);
      // For example, show a notification with the area
      if(e.result.area.toFixed(2) > 0){
        alert(`Measured Area: ${e.result.area.toFixed(2)} square meters`);
        this.endMeasurement();  
      }
      
    });

    // Set up listener to enable dragging outside map and disable on map
    google.maps.event.addListener(this.map, 'mousedown', () => {
      this.draggingDisabled = true;
    });
    google.maps.event.addListener(this.map, 'mouseup', () => {
      this.draggingDisabled = false;
    });

  }

  startMeasurement(): void {
    // Starts the measurement tool
    this.measureTool.start();
  }

  endMeasurement(): void {
    // Ends the measurement tool
    this.measureTool.end();
  }

  top: number = 0; // Default position
  left: number = 0; // Default position

  onDragMoved(event: CdkDragMove) {
    const element = event.source.getRootElement();
    const boundingClientRect = element.getBoundingClientRect();
    const parentPosition = this.getPosition(element);
    let top = boundingClientRect.top - parentPosition.top;
    let left = boundingClientRect.left - parentPosition.left;
    
    this.savePosition(top, left); // Save the new position

  }

  savePosition(top, left) {
    localStorage.setItem('mapOverlayPosition', JSON.stringify({ top: top, left: left }));
  }

  restorePosition() {
    const position = JSON.parse(localStorage.getItem('mapOverlayPosition'));
    if (position) {
      this.top = position.top;
      this.left = position.left;
    }

  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }
}