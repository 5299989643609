          <div class="card">
            <form name="addPost" (ngSubmit)="leaveComment()">
            <div class="card-body">
              <h5 class="card-title">Community Forum</h5>
              <p>Welcome to our forum! Feel free to ask questions, share your thoughts, and engage in discussions on any topic you'd like. Start a new thread or join existing discussions to connect with fellow members and explore various subjects.</p>

              <!-- Vertically centered Modal -->
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#forum">
                Start New Thread
              </button>
              <div class="modal fade" appMoveModalToBody  id="forum" tabindex="-1">
                
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Start New Thread</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row mb-4">
                      <label for="inputText" class="col-sm-2 col-form-label">Subject</label>
                      <div class="col-sm-10">
                        <input id="subject" name="subject" [(ngModel)]="subject" type="text" class="form-control">
                      </div>
                    </div>
                    <div class="row mb-3">
                  <label class="col-sm-2 col-form-label">&nbsp;</label>
                  <div class="col-sm-10">
                    <select name="mySelect" class="form-select" aria-label="Default select example" [(ngModel)]="selectedCategory">
                      <option value="default">Choose Category</option>
                      <option *ngFor="let option of categories" [ngValue]="option">{{option}}</option>
                    </select>
                  </div>
                </div>
                      <div class="comment-container">
                        <div class="form-group">
                          
                          <label for="comment">Write Your Post</label>
                          <textarea id="comment" name="comment" [(ngModel)]="comment" class="form-control" rows="15" placeholder="Type your comment here"></textarea>
                          
                        
                        </div>
                      </div>                    </div>
                    <div class="modal-footer">
                      <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" class="btn btn-primary">Post</button>
                    </div>
                  </div>
                </div>
              </div><!-- End Vertically centered Modal-->

            </div>
          </form>
          </div>

<mat-drawer-container class="sidebar-container" autosize>
  <mat-drawer #drawer class="sidebar-sidenav" mode="side" position="end">
    <div class="content-wrapper">
      <div #targetDiv><app-thread (closePanel)="closePanel($event)" [parentEvent]="parentEvent" [panelId]="panelId"></app-thread></div>
    </div>
  </mat-drawer>

<app-flextable #flextable
	(activePanel)='activePanel($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
  (headerButtonClicked)='headerButtonClicked($event)'
	[options]="
		{
			datasource: 'forums',

      scope: 'all',

      filters: [
        {
            'filter': { 'category': '' },
            'label': 'Category',
            'valueKeys': [],
            'valueIds': [],
            'datasource': 'forums',
            'key': 'category',
            'distinct': 'category',
            'all':true
          }
      ],

      sort: { '_id': 'desc' },

			bAllowDeletion: false,

          	linkColumns: [
            	{
              		column: 'Subject',
              		path: '/main/forum/thread/'
            	}
          	],

			rowButtons: [
				{
					'column': 'subject',
					'buttonName': 'Reply',
					'api': 'v2.0',
					'action': 'reply'
				}
			],

			columnNameRewrites: [
          		'Subject'
        	],
			
			columns: [
			    'subject'
			],

      aggregate: [
        {
          $lookup: {
            from: 'users',
            localField: 'created_by',
            foreignField: '_id',
            as: 'user_info'
          }
        },
        {
          $unwind: '$user_info'
        },
        {
          $addFields: {
            'created_by_name': {
              $concat: ['$user_info.first_name', ' ', '$user_info.last_name']
            },
            'numberOfReplies': {
              $size: { $ifNull: [ '$replies', [] ] }
            }
          }
        },
        {
          $project: {
            _id: 1,
            createdAt: 1,
            created_by: 1,
            created_by_name: 1,
            modifiedAt: 1,
            modified_by: 1,
            owner: 1,
            post: 1,
            replies: 1,
            selected: 1,
            subject: 1,
            numberOfReplies: 1
          }
        }
      ]

		}"
></app-flextable>

</mat-drawer-container>