

    <div class="pagetitle">
      <h1>Link Guidelines</h1>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html">Home</a></li>
          <li class="breadcrumb-item">Help</li>
          <li class="breadcrumb-item active">Link Guidelines</li>
        </ol>
      </nav>
      
    </div>

    <section class="section">
    <div class="blog-container">
      <h1>Minimum Link Requirements</h1>
        <ol>
          <li>10,000 visitors per month</li>
          <li>"Legitimate" monetization strategy</li>
          <li>No Paid Guest Posts</li>
          <li>#1 Keywords (Ahrefs)</li>
          <li>Minimum Site Age of 1 Year</li>
          <li>Non-manipulated DR</li>
          <li>Quality Content</li>
          <li>Exceptions</li>
        </ol>

    <h2 #minimum_visitors >Minimum Visitors</h2>
    <p>We have minimum visitor requirements to eliminate most PBN's, low quality sites and sites that are spun up primarily to sell Guest Posts and Links.</p> 

    <h2 #legitimate_monetization >Legitimate Monetization</h2>
    <p>This exchange is designed to be used by Niche Websites primarily engaged in the business of producing valuable content.</p> 
    <p>Many sites exist solely to be part of a link exchange network, or to sell guest posts and links or other strategies that are not allowed here.  We need to be able to determine a monetization strategy that is compatible with the types of sites we allow on this marketplace</p>

    <h2 #legitimate_monetization >No Paid Guest Posts</h2>
    <p>While we reserve the right to change this policy depending on community feedback, if your site publicly accepts Guest Posts or has a "Write For Us" section, it will be rejected.</p> 
    <p>The reason being that sites that do not accept guest posts tend to be of a much higher quality than sites that do.</p>

    <h2 #legitimate_monetization >#1 Keywords</h2>
    <p>The site needs to have some #1 keywords.</p> 
    <p>If the site under consideration doesn't have enough #1 keywords, it hasn't developed enough authority to be able to offer valuable links</p>

    <h2 #legitimate_monetization >Minimum of 1 Year Old</h2>
    <p>The site needs to be at least one year old.</p> 
    <p>Newer sites aren't likely to offer enough value</p>


    <h2 #legitimate_monetization >Non Manipulated DR</h2>
    <p>It's very easy to manipulate DR for people who know how to do it.  I've seen sites that are 3 months old with 54 DR but no #1 keywords and 40 viitors a month.</p> 
    <p>Sites that have an off-balance DR to traffic ratio will be rejected.</p>

    <h2 #legitimate_monetization >Quality Content</h2>
    <p>While subjective, sites that produce low quality content will be rejected.</p> 
    <p>What's low quality?  In general, we will attempt to answer the following questions about the content:</p>
        <ol>
          <li>Was this content made using AI with little human oversight or editing?</li>
          <li>Does the content respond to the search intent of a visitor?</li>
          <li>Is the content presented in an aesthetically acceptable way?</li>
          <li>Would an average human being find the content useful?</li>
          <li>And any subjective determination by us that the content is low quality</li>
        </ol>
    <h2 #legitimate_monetization >Exceptions</h2>
    <p>We will consider exceptions on a case by case basis.</p> 
    <p>Feel free to send us a message in the Support Chat if you want to get a site pre-approved.</p>
    </div> 
    </section>

