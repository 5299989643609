import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '../../../shared.service';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-all-missed-calls',
  templateUrl: './all-missed-calls.component.html',
  styleUrl: './all-missed-calls.component.css'
})

export class AllMissedCallsComponent implements OnInit {

  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService) { }

  missed_calls = [];
  ngOnInit(){
    this.getMissedCalls();
  }

  async getMissedCalls(){
    var api_url = `api/calls/getMissedCalls`;
    let missed_calls = await this.http.get(api_url).toPromise();  
    for(var missed_call of missed_calls['missed_calls']){
      missed_call['from'] = this.dataService.formatPhoneNumber(missed_call['from']);
      missed_call['to'] = this.dataService.formatPhoneNumber(missed_call['to']);
    }
    this.missed_calls = missed_calls["missed_calls"].reverse();

    console.log(23, this.missed_calls);
  }

  async searchNumber(phoneNumber){
    

    var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.phone" + '/' + phoneNumber;

    //this.router.navigate([`main/search`]);
    let result = await this.http.get(api_url).toPromise();
    this.sharedService.updateSearchResults(result, phoneNumber);
  }
}
