import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrl: './notification-popup.component.css'
})

export class NotificationPopupComponent implements OnInit {

  draggingDisabled: boolean = false;
  isVisible: boolean = false;
  inputPhoneNumber = "";
  userInfo: any = {};

  message: string = "";
  title: string = "";
  code: string = "";

  data: any = {}

  bIsAppointment = false;
  constructor(private dataService: DataService, private http: HttpClient, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.dataService.loadPopupMessage().subscribe(data => {
      console.log(36, "Popup Message!", data);
      this.message = data.message;
      this.title = data.title;
      if(typeof data.code !== 'undefined')
        this.code = data.code;
      this.isVisible = true;;
      this.bIsAppointment = false;
    });

    this.dataService.loadAppointmentMessage().subscribe(data => {
      console.log(38, "Appointment Message!", data);
      this.bIsAppointment = true;
      this.message = data.message;
      this.title = data.title;
      if(typeof data.code !== 'undefined')
        this.code = data.code;
      this.isVisible = true;
      this.data = data;
    });

  }

  loadContact($event, contact_id){
    $event.preventDefault();
    console.log(57, contact_id);
    this.dataService.openPanel(contact_id);
    this.toggleVisibility();
  }

  toggleVisibility(): void {
    this.isVisible = false;
  }

}