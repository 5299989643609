import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MainComponent } from './main/main.component';
import { Child1Component } from './main/content/child1/child1.component';
import { Child2Component } from './main/content/child2/child2.component';
import { NotFoundComponent } from './not-found/not-found.component'
import { ProfileComponent } from './main/user/profile/profile.component'
import { SettingsComponent } from './main/user/settings/settings.component'
import { BillingComponent } from './main/account/billing/billing.component'
import { UpgradePlanComponent } from './main/account/billing/upgrade-plan/upgrade-plan.component'
import { UsersComponent } from './main/content/sysadmin/users/users.component' 
import { AccountsComponent } from './main/content/sysadmin/accounts/accounts.component' 
import { ApikeysComponent } from './main/content/sysadmin/apikeys/apikeys.component' 
import { PlanmanagementComponent } from './main/content/sysadmin/planmanagement/planmanagement.component' 
import { ReportsComponent } from './main/content/sysadmin/reports/reports.component' 
import { TransactionsComponent } from './main/content/sysadmin/transactions/transactions.component' 
import { SubscriptionsComponent } from './main/content/sysadmin/subscriptions/subscriptions.component' 
import { HelpDashboardComponent } from './main/content/help/dashboard/dashboard.component'

/* Plan Features Pages */
import { FreePlanPageComponent } from './main/content/plans/free/free.component'
import { ProPlanPageComponent } from './main/content/plans/pro/pro.component'
import { EnterprisePlanPageComponent } from './main/content/plans/enterprise/enterprise.component'

/* Legal Pages */
import { TermsComponent } from './main/content/terms/terms.component'
import { PrivacyComponent } from './main/content/terms/privacy/privacy.component'

/* Legacy Components */
import { SharedService } from './legacy/_services/shared.service';

import { FormsModule } from "@angular/forms"

/* Admin */
import { PlanComponent } from './main/content/sysadmin/planmanagement/plan/plan.component';
import { QualityCheckComponent } from './main/content/sysadmin/quality-check/quality-check.component';

/* Affiliate */
import { CookiesComponent } from './main/content/affiliate/cookies2/cookies2.component'
import { DomainsComponent } from './main/content/affiliate/domains/domains.component'
import { GetpaidComponent } from './main/content/affiliate/getpaid/getpaid.component'
import { SignupsComponent } from './main/content/affiliate/signups/signups.component'
import { TrialsComponent } from './main/content/affiliate/trials/trials.component'

/* Home Routes */
import { AffiliateHomeComponent } from './main/content/affiliate/home/home.component';
import { UserHomeComponent } from './main/content/user/home/home.component';
import { AdminHomeComponent } from './main/content/sysadmin/home/home.component';

/* Notifications and Messages */
import { NotificationsComponent } from './main/notifications/notifications.component';
import { MessagesComponent } from './main/messages/messages.component';

/* Community Forum */
import { ForumComponent } from './main/forum/forum.component'
import { ThreadComponent } from './main/forum/thread/thread.component'

/* Prompt Playground */
import { PromptsComponent } from './main/content/sysadmin/prompts/prompts.component';
import { PromptPlaygroundComponent } from './reusable/ui/prompt-playground/prompt-playground.component';

/* Help */
import { LinkGuidelinesComponent } from './main/content/help/link-guidelines/link-guidelines.component'
import { FaqComponent } from './main/content/help/faq/faq.component'
import { VoteComponent } from './main/content/help/vote/vote.component'
import { RoadmapComponent } from './main/content/help/roadmap/roadmap.component'
import { OnboardingComponent } from './main/content/help/onboarding/onboarding.component'
import { HowtouseComponent } from './main/content/help/howtouse/howtouse.component'

/* Website */
import { WebsiteComponent } from './website/website.component'

import { YourfullschoolComponent } from './website/yourfullschool/yourfullschool.component'

import { LinkAlgorithmComponent } from './website/link-algorithm/link-algorithm.component'

import { ContactsComponent } from './main/content/contacts/contacts.component'
import { ScrapeDataComponent } from './main/content/scrape-data/scrape-data.component'
import { CallScriptsComponent } from './main/content/call-scripts/call-scripts.component'
import { TextTemplatesComponent } from './main/content/text-templates/text-templates.component'
import { SnailMailComponent } from './main/content/snail-mail/snail-mail.component'
import { EmailComponent } from './main/content/email/email.component'

import { AdminUsersComponent } from './main/content/admin/users/users.component'
import { EndlessScrollComponent } from './main/content/endless-scroll/endless-scroll.component'
import { ShortlistComponent } from './main/content/shortlist/shortlist.component';

import { ProductManagementComponent } from './main/content/admin/products/product-management/product-management.component'
import { LanguageManagementComponent } from './main/content/admin/languages/language-management/language-management.component'
import { OfficeManagementComponent } from './main/content/admin/offices/office-management/office-management.component'
import { CountryManagementComponent } from './main/content/admin/countries/country-management/country-management.component'
import { WarehouseManagementComponent } from './main/content/admin/warehouses/warehouse-management/warehouse-management.component'
import { TeamManagementComponent } from './main/content/admin/teams/team-management/team-management.component'
import { CurrencyManagementComponent } from './main/content/admin/currencies/currency-management/currency-management.component'
import { MyStatsComponent } from './main/content/agents/my-stats/my-stats.component'
import { MyOrdersComponent } from './main/content/agents/my-orders/my-orders.component'
import { MyTeamsOrdersComponent } from './main/content/admin/orders/orders.component'

import { OfficeStatsComponent } from './main/content/admin/office-stats/office-stats.component'

import { AppointmentsComponent } from './main/content/agents/appointments/appointments.component'

import { SearchComponent } from './main/content/search/search.component'

import { PastDueComponent } from './main/content/agents/follow-ups/past-due/past-due.component' 

import { UpcomingComponent } from './main/content/agents/follow-ups/upcoming/upcoming.component' 

import { MissedCallsComponent } from './main/content/agents/missed-calls/missed-calls.component'

import { AllMissedCallsComponent } from './main/content/all-missed-calls/all-missed-calls.component';

import { MyCallsComponent } from './main/content/agents/my-calls/my-calls.component';

import { AgentCallsComponent } from './main/content/admin/agents/agent-calls/agent-calls.component';

import { DataCleanupComponent } from './main/content/admin/data-cleanup/data-cleanup.component';

import { AllContactsComponent } from './main/content/admin/all-contacts/all-contacts.component';

import { CallSearchComponent } from './main/content/admin/call-search/call-search.component';

import { ActivityViewerComponent } from './main/content/agents/activity-viewer/activity-viewer.component';

import { CompaniesComponent } from './main/content/admin/companies/companies.component';

import { DoNotCallComponent } from './main/content/admin/do-not-call/do-not-call.component';

import { FlaggedComponent } from './main/content/admin/flagged/flagged.component';

import { InfoFullPageComponent } from './main/content/contacts/info-full-page/info-full-page.component'


import { AdminReportsComponent } from './main/content/admin/reports/reports.component';

import { RedeemgiftcardComponent } from './redeemgiftcard/redeemgiftcard.component';

const routes: Routes = [  
  /* Public Routes */
  { path: 'application', component: WebsiteComponent },
  { path: 'yourfullschool', component: YourfullschoolComponent },
  { path: 'public/application/link-requirements', component: LinkGuidelinesComponent },
  { path: 'public/application/link-algorithm', component: LinkAlgorithmComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register-affiliate', redirectTo: 'register', pathMatch: 'full' },
  //{ path: 'redeem', component: RedeemgiftcardComponent, pathMatch: 'full' },
  { path: 'redeem/id/:id', component: RedeemgiftcardComponent },
  /* Authenticated Routes */
  {
    path: 'main/focus-mode',
    component: InfoFullPageComponent
  },
  {
    path: 'main',
    redirectTo: 'main/child1', // Redirect /main to /main/child1
    pathMatch: 'full'
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'terms/privacy',
    component: PrivacyComponent
  },
  { path: 'onboarding', component: OnboardingComponent },
  {
    path: 'main',
    component: MainComponent,
    children: [
      { path: 'agents/my-stats', component: MyStatsComponent },
      { path: 'agents/my-orders', component: MyOrdersComponent },
      { path: 'agents/follow-ups/past-due', component: PastDueComponent },
      { path: 'agents/follow-ups/upcoming', component: UpcomingComponent },
      { path: 'agents/missed-calls', component: AllMissedCallsComponent },
      { path: 'agents/missed-calls/:id', component: MissedCallsComponent },
      { path: 'agents/activity-viewer', component: ActivityViewerComponent },
      { path: 'agents/my-calls', component: MyCallsComponent },
      { path: 'agents/appointments', component: AppointmentsComponent },
      { path: 'admin/products/product-management', component: ProductManagementComponent}, 
      { path: 'admin/reports', component: AdminReportsComponent}, 
      { path: 'admin/flagged', component: FlaggedComponent}, 
      { path: 'admin/languages/language-management', component: LanguageManagementComponent}, 
      { path: 'admin/offices/office-management', component: OfficeManagementComponent}, 
      { path: 'admin/countries/country-management', component: CountryManagementComponent}, 
      { path: 'admin/warehouses/warehouse-management', component: WarehouseManagementComponent}, 
      { path: 'admin/teams/team-management', component: TeamManagementComponent}, 
      { path: 'admin/currencies/currency-management', component: CurrencyManagementComponent}, 
      { path: 'admin/orders/team-orders', component: MyTeamsOrdersComponent}, 
      { path: 'admin/office-manager/office-states', component: OfficeStatsComponent }, 
      { path: 'admin/agents/agent-calls/id/:id', component: AgentCallsComponent }, 
      { path: 'admin/data/data-cleanup', component: DataCleanupComponent },
      { path: 'admin/data/do-not-call', component: DoNotCallComponent },
      { path: 'admin/call-search/search/:search', component: CallSearchComponent },
      { path: 'admin/all-contacts', component: AllContactsComponent }, 
      { path: 'admin/companies/company-management', component: CompaniesComponent }, 
      { path: 'search', component: SearchComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'shortlist', component: ShortlistComponent },
      { path: 'endless-scroll', component: EndlessScrollComponent },
      { path: 'scrape-data', component: ScrapeDataComponent },
      { path: 'call-scripts', component: CallScriptsComponent },
      { path: 'text-templates', component: TextTemplatesComponent },
      { path: 'snail-mail', component: SnailMailComponent },
      { path: 'all-missed-calls', component: AllMissedCallsComponent},
      { path: 'email', component: EmailComponent },

      { path: 'upgrade', component: UpgradePlanComponent },

      /* Help */
      { path: 'faq', component: FaqComponent },
      { path: 'vote', component: VoteComponent },
      { path: 'howtouse', component: HowtouseComponent },

      /* Forum */
      { path: 'forum', component: ForumComponent },
      { path: 'forum/thread', component: ThreadComponent },
      { path: 'forum/thread/id/:id', component: ThreadComponent },

      /* Notifications */
      { path: 'notifications', component: NotificationsComponent },
      { path: 'messages', component: MessagesComponent },

      /* Home Routes */
      { path: 'affiliate/home', component: AffiliateHomeComponent },
      { path: 'admin/home', component: AdminHomeComponent },
      { path: 'user/home', component: UserHomeComponent },

      /* Test Routes To Delete */
      { path: 'child1', component: Child1Component },
      { path: 'child2', component: Child2Component },

      /* User Settings Routes */
      { path: 'user/profile', component: ProfileComponent },
      { path: 'user/settings', component: SettingsComponent },
      { path: 'account/billing', component: BillingComponent },

      /* SysAdmin Routes */
      { path: 'sysadmin/users', component: UsersComponent },
      { path: 'sysadmin/accounts', component: AccountsComponent },
      { path: 'sysadmin/subscriptions', component: SubscriptionsComponent },
      { path: 'sysadmin/apikeys', component: ApikeysComponent },
      { path: 'sysadmin/planmanagement', component: PlanmanagementComponent },
      { path: 'sysadmin/planmanagement/plan/id/:id', component: PlanComponent },
      { path: 'sysadmin/reports', component: ReportsComponent },
      { path: 'sysadmin/transactions', component: TransactionsComponent },
      { path: 'sysadmin/prompts', component: PromptsComponent },
      { path: 'sysadmin/prompts/id/:id', component: PromptPlaygroundComponent },
      { path: 'sysadmin/quality', component: QualityCheckComponent },

      /* Affiliate Routes */
      { path: 'affiliate/cookies', component: CookiesComponent },
      { path: 'affiliate/domains', component: DomainsComponent },
      { path: 'affiliate/signups', component: SignupsComponent },
      { path: 'affiliate/getpaid', component: GetpaidComponent },
      { path: 'affiliate/trials', component: TrialsComponent },
      { path: 'affiliate/connected', component: TrialsComponent },

      /* Help Content Routes */
      { path: 'help/dashboard', component: HelpDashboardComponent },

      /* Plan Features Pages */
      { path: 'plans/free', component: FreePlanPageComponent },
      { path: 'plans/pro', component: ProPlanPageComponent },
      { path: 'plans/enterprise', component: EnterprisePlanPageComponent },

      { path: 'admin/users', component: AdminUsersComponent }
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },

   { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'}), FormsModule],
  declarations: [

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
