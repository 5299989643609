import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit, FlexTableInterface {

  constructor() { }

  ngOnInit(): void {
  }

  headerButtonClicked(event: any){

  }

  tableButtonClicked(requestResult: any){

  }

}


// contentbounty-prod.traveldomainkeywords
// contentbounty-prod.privateschools