import { Component } from '@angular/core';

@Component({
  selector: 'app-office-manager',
  templateUrl: './office-manager.component.html',
  styleUrl: './office-manager.component.css'
})
export class OfficeManagerComponent {

}
