import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../../services/data.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {
  contactForm: FormGroup;
  draggingDisabled: boolean = false;
  isVisible: boolean = false;
  inputPhoneNumber = "";
  userInfo: any = {};


  constructor(private dataService: DataService, private http: HttpClient, private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      title: ['', Validators.required],
      phone: ['', Validators.required],
      complete_address: this.fb.group({
        street: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        postal_code: ['', Validators.required],
        country: ['', Validators.required]
      }),
      website: ['']
    });
  }

  ngOnInit() {
    this.dataService.getDialedNumber().subscribe(data => {
      this.checkIfContactExists(data);
    });

    this.dataService.getUserInfo().subscribe(data => {
      this.userInfo = data;
    });
  }

  toggleVisibility(): void {
    this.isVisible = false;
  }

  async checkIfContactExists(data: any = "") {

    if((this.userInfo.account_type == 'agent') || (this.userInfo.account_type == 'resale-agent'))
    {
      this.inputPhoneNumber = data;
      var api_url = `api/calls/checkContactExists/formattedNumber/${this.inputPhoneNumber}`;
      let result = await this.http.get(api_url).toPromise();
      if (result['contactExists'] == false) {
        //this.isVisible = true;
        
        this.contactForm.get('phone')?.setValue(data);
      }
    }
  }

  async saveContact() {
      if (this.contactForm.invalid) {
        this.contactForm.markAllAsTouched();  // Mark all fields as touched to trigger validation messages
        return;
      }

      let formData = this.contactForm.value;
      let complete_address = formData.complete_address;
      let address = `${complete_address.street}, ${complete_address.city}, ${complete_address.state} ${complete_address.postal_code}`;

      let call_recording_info = await this.http.post(`api/datasource/coldleads`, {
        "scrape_result": {
          "title": formData.title,
          "phone": formData.phone,
          "web_site": formData.website,
          'category': 'Manual Entry',
          'address': address,
          'complete_address': formData.complete_address
        },
        contactHistory: [],
        lastVisited: false,
        receivedResponse: false,
        doNotCall: false,
        emailScraped: false,
        voiceContactEstablished: false,
        agent_id: "$res.locals.user._id",
        legacyCalls: [],
        callLogs: [],
        events: [],
      }).toPromise();

      alert("Contact Added");
      //var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.title" + '/' + formData.title;
    }
}