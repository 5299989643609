import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrl: './date-filter.component.css'
})

export class DateFilterComponent {

  showCustomDateInputs = false;

  @Input() startDate: string;
  @Input() endDate: string;
  @Input() period: string;
  @Output() filterChanged = new EventEmitter<any>();

async filterCallTime($event, period){
  $event.preventDefault();
  this.period = period;
  this.filterChanged.emit( { "period": this.period } );
}

toggleCustomDateInputs($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.showCustomDateInputs = !this.showCustomDateInputs;
  }

  async onShowCustomDates($event){
    $event.preventDefault();
    $event.stopPropagation();
    this.period = "custom";
    this.filterChanged.emit( { "period": this.period, "startDate": this.startDate, "endDate": this.endDate } );
  }
}
