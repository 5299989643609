<div [style.visibility]="isVisible ? 'visible' : 'hidden'">
  <div class="backdrop"></div>
  <div class="map">
    <div cdkDragHandle class="map-header">
      <button class="btn" (click)="toggleVisibility()">Hide</button>
    </div>
    <div class="col mapcol">
      <div id="map" style="height: 800px; width: 100%;">
        <div style="max-height: 800px; overflow-y: auto;">
          <div class="card-body bg-light">
            <form [formGroup]="contactForm" (ngSubmit)="saveContact()">
              <div class="row mb-3">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="urlInput" class="form-label">URL</label>
                    <input type="text" class="form-control" id="urlInput" formControlName="website" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="textAreaInput" class="form-label">Input</label>
                    <textarea class="form-control" id="textAreaInput"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card-body">
                    <div class="mb-3">
                      <label for="titleInput" class="form-label">Title</label>
                      <input type="text" class="form-control" id="titleInput" formControlName="title" [ngClass]="{'is-invalid': contactForm.get('title')?.invalid && (contactForm.get('title')?.dirty || contactForm.get('title')?.touched)}" />
                      <div *ngIf="contactForm.get('title')?.invalid && (contactForm.get('title')?.dirty || contactForm.get('title')?.touched)" class="invalid-feedback">
                        Title is required
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="phoneInput" class="form-label">Phone</label>
                      <input type="text" class="form-control" id="phoneInput" formControlName="phone" [ngClass]="{'is-invalid': contactForm.get('phone')?.invalid && (contactForm.get('phone')?.dirty || contactForm.get('phone')?.touched)}" />
                      <div *ngIf="contactForm.get('phone')?.invalid && (contactForm.get('phone')?.dirty || contactForm.get('phone')?.touched)" class="invalid-feedback">
                        Phone is required
                      </div>
                    </div>
                    <div formGroupName="complete_address">
                      <div class="mb-3">
                        <label for="addressInput" class="form-label">Address</label>
                        <input type="text" class="form-control" id="addressInput" formControlName="street" [ngClass]="{'is-invalid': contactForm.get('complete_address.street')?.invalid && (contactForm.get('complete_address.street')?.dirty || contactForm.get('complete_address.street')?.touched)}" />
                        <div *ngIf="contactForm.get('complete_address.street')?.invalid && (contactForm.get('complete_address.street')?.dirty || contactForm.get('complete_address.street')?.touched)" class="invalid-feedback">
                          Street is required
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="cityInput" class="form-label">City</label>
                        <input type="text" class="form-control" id="cityInput" formControlName="city" [ngClass]="{'is-invalid': contactForm.get('complete_address.city')?.invalid && (contactForm.get('complete_address.city')?.dirty || contactForm.get('complete_address.city')?.touched)}" />
                        <div *ngIf="contactForm.get('complete_address.city')?.invalid && (contactForm.get('complete_address.city')?.dirty || contactForm.get('complete_address.city')?.touched)" class="invalid-feedback">
                          City is required
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="stateInput" class="form-label">State</label>
                        <input type="text" class="form-control" id="stateInput" formControlName="state" [ngClass]="{'is-invalid': contactForm.get('complete_address.state')?.invalid && (contactForm.get('complete_address.state')?.dirty || contactForm.get('complete_address.state')?.touched)}" />
                        <div *ngIf="contactForm.get('complete_address.state')?.invalid && (contactForm.get('complete_address.state')?.dirty || contactForm.get('complete_address.state')?.touched)" class="invalid-feedback">
                          State is required
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="zipInput" class="form-label">Postal Code</label>
                        <input type="text" class="form-control" id="zipInput" formControlName="postal_code" [ngClass]="{'is-invalid': contactForm.get('complete_address.postal_code')?.invalid && (contactForm.get('complete_address.postal_code')?.dirty || contactForm.get('complete_address.postal_code')?.touched)}" />
                        <div *ngIf="contactForm.get('complete_address.postal_code')?.invalid && (contactForm.get('complete_address.postal_code')?.dirty || contactForm.get('complete_address.postal_code')?.touched)" class="invalid-feedback">
                          Postal Code is required
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="countryInput" class="form-label">Country</label>
                        <input type="text" class="form-control" id="countryInput" formControlName="country" [ngClass]="{'is-invalid': contactForm.get('complete_address.country')?.invalid && (contactForm.get('complete_address.country')?.dirty || contactForm.get('complete_address.country')?.touched)}" />
                        <div *ngIf="contactForm.get('complete_address.country')?.invalid && (contactForm.get('complete_address.country')?.dirty || contactForm.get('complete_address.country')?.touched)" class="invalid-feedback">
                          Country is required
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>