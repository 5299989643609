<div class="btn-group">
	<button type="button" class="btn btn-info btn-sm me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
	  <div class="circle" *ngIf="circle">
        <i [ngClass]="{'material-icons': idle, 'progress-indicator': loading, 'animate-checkbox': success, 'animate-error': failure}">{{material_icon}}</i>
      </div>
		{{btnName | translate }}
    </button>
	<ul class="dropdown-menu">
	    <li *ngFor="let action of actions">
	        <a class="dropdown-item" href="#" (click)="notifyParentOfEvent($event, action)">
	            {{ action.name | translate }}
	        </a>
	    </li>
	</ul>
</div>