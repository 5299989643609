import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-report-picker',
  templateUrl: './report-picker.component.html',
  styleUrl: './report-picker.component.css'
})

export class ReportPickerComponent {

  @Output() reportChange: EventEmitter<any> = new EventEmitter();

  reports: any = [
    {
      report_id: 1,
      reportName: "Commission"
    },
    {
      report_id: 2,
      reportName: "Attendance"
    },
    // {
    //   report_id: 2,
    //   reportName: "Sales"
    // },
    // {
    //   report_id: 3,
    //   reportName: "Stats"
    // }
  ]

  async onReportChange(event){
    const selectedReport = (event.target as HTMLSelectElement).value;
    const report = this.reports.find(r => r.reportName === selectedReport);
    this.reportChange.emit(report);
  }

}
