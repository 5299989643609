<!-- <div class="position-fixed map" [cdkDragDisabled]="draggingDisabled" cdkDrag cdkDragBoundary=".drag-boundary" [ngStyle]="{'visibility': isVisible ? 'visible' : 'hidden', 'top': top+'px', 'left': left+'px'}" (cdkDragMoved)="onDragMoved($event)"> -->

<div class="position-fixed map" [ngStyle]="{'visibility': isVisible ? 'visible' : 'hidden'}" [cdkDragDisabled]="draggingDisabled" cdkDrag cdkDragBoundary=".drag-boundary" (cdkDragMoved)="onDragMoved($event)">
    
    <div cdkDragHandle class="map-header">
        <button class="btn" (click)="startMeasurement()">Start Measurement</button>
        <button class="btn" (click)="endMeasurement()">End Measurement</button>
        <button class="btn" (click)="toggleVisibility()">Hide</button>
    </div>
    <div class="col mapcol">
        <div id="map" style="height: 500px; width: 100%;"></div>
    </div>
</div>
