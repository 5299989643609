import { Component } from '@angular/core';

@Component({
  selector: 'app-missed-calls',
  templateUrl: './missed-calls.component.html',
  styleUrls: ['./missed-calls.component.css']
})

export class MissedCallsComponent {

}
