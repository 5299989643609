import { Component } from '@angular/core';

@Component({
  selector: 'app-link-algorithm',
  templateUrl: './link-algorithm.component.html',
  styleUrls: ['./link-algorithm.component.css']
})
export class LinkAlgorithmComponent {

}
