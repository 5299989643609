 <button #openModal type="button" class="btn btn-secondary" style="display: none" data-bs-toggle="modal" data-bs-target="#country">{{"Open" | translate }}</button>

<app-flextable #flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
    (rowLinkClicked)='rowLinkClicked($event)'
	[options]="
		{
			datasource: 'countries',

			bAllowDeletion: true,

			headerButtonsAr: [
				'Add Country'
			],

            linkColumnPreventDefault: true,
            
            linkColumns: [ {
                     column: 'CountryName',
                     path: '/main/sysadmin/planmanagement/plan/'
                }
            ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

            columnNameRewrites: [
                'Selected', 'Country Name', 'Country Code'
            ],

			scope: 'account',
			
			columns: [
				'selected',
				'countryName',
				'countryShortCode'
			]
		}"

></app-flextable>

<div class="modal fade" appMoveModalToBody  id="country" tabindex="-1">
    <form name="addCountry">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Country</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">
                        <label for="country_name" class="form-label">Country</label>
                        <input id="country_name" name="country_name" [(ngModel)]="payload.countryName" type="text" class="form-control">
                    </div>
                    <div class="mb-4">
                        <label for="country_code" class="form-label">Country Short Code</label>
                        <input id="country_code" name="country_code" [(ngModel)]="payload.countryCode" type="text" class="form-control">
                    </div>
                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="submit" class="btn btn-primary">Add Language</button> -->
                    <network-request-button #submitForm (result)="waitForResponse($event)" [request]="endpoint" [payload]="payload" [verb]="verb">{{buttonAction | translate}}</network-request-button>
                </div>
            </div>
        </div>
    </form>
</div>