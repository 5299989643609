import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HttpClient } from '@angular/common/http';
import { FlextableComponent } from "@flextable/flextable.component"
import { SharedService } from '../../../../shared.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})

export class AppointmentsComponent implements OnInit, AfterViewInit, FlexTableInterface {

  panelId = "";
  startRecordingOnOrder: boolean = false;
  displayCallScript: boolean = false;
  startAutoDialerOnFirstCall = false;
  bIsAutoDialing = false;
  currentRowCalling = null;
  @ViewChild('flextable') flextable: FlextableComponent;
  @ViewChild('openscript') openscript: ElementRef;
  @ViewChild('closeOrderPopup') closeOrderPopup: ElementRef;
  
  @ViewChild('drawer') drawer: MatDrawer;
  ngAfterViewInit() {
    console.log(23, this.drawer);
  }

  bOnActiveCall = false;
  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService) {
    this.sharedService.getCallStatus().subscribe(callStatus => {


      if(callStatus == "destroy"){
        this.bOnActiveCall = false;
        if(this.startAutoDialerOnFirstCall == true){

          let rowData = this.flextable.getRowByIndex(this.currentRowCalling + 1);

          setTimeout( () => {

            let callInfo = { "_id": rowData["_id"], buttonName: "Call", row: rowData, rowIndex: this.currentRowCalling + 1 }
            this.sharedService.sendData( callInfo );
          }, 2500)
          this.parentEvent.emit( { id: rowData['_id'] } );
          this.flextable.selectRow( this.currentRowCalling + 1 );
          this.currentRowCalling = this.currentRowCalling + 1;         
        }
      }

      if(callStatus == "new"){
        this.bOnActiveCall = true;
      }
      
    });
  }

  appointmentRowLinkClicked($event){
    console.log(61, $event);
    this.dataService.openPanel($event.rowData.contact_id);
  }

  scripts = [];
  products = [];
  intro: string = "";
  provocativeQuestion: string = "";
  pitch: string = "";

  refreshedFilterOptions: any;

  ngOnInit(): void {

    // const dropdownCategory = localStorage.getItem('dropdownCategory');
    // if (dropdownCategory) {
    //   console.log(66, dropdownCategory)
    // }

    // const dropdownCity = localStorage.getItem('dropdownCity');
    // if (dropdownCity) {

    // }

    // const dropdownState = localStorage.getItem('dropdownState');
    // if (dropdownState) {
    //   console.log(76, dropdownState)
    // }

    let combinedFilter = JSON.parse(localStorage.getItem('contactPageAggregate') || '{}');

    this.refreshedFilterOptions = combinedFilter;

      this.http.get('/api/datasource/call_scripts/max_records/1000').subscribe(
      response => {
        this.scripts = response["call_scripts"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );

       this.sharedService.getProducts().subscribe(data => this.products = data);

      // setTimeout( () =>{
      //   //
      //   let savedFilters = JSON.parse(localStorage.getItem('contactsSavedFilters') || '[]');
      //   for(let savedFilter of savedFilters){
      //     //console.log(101, savedFilter);
      //     this.flextable.updateFilterByIndex(savedFilter['filterIndex'], savedFilter['value']);

      //     let contactPageAggregate = JSON.parse(localStorage.getItem('contactPageAggregate') || '[]');
      //     //console.log(105, contactPageAggregate);
      //   }
      // }, 3250);

      console.log(103, this.drawer);
  }

  getProducts(){

 
  }

  @Output() public parentEvent = new EventEmitter<any>();

  sendEventToChild(_id: String) {
    const eventData = 'Custom event data from parent';
    this.parentEvent.emit(_id);
  }

  activePanel(componentInfo: any){
    console.log(28)
    this.panelId = componentInfo._id;
    if(this.drawer.opened){
      setTimeout( () => {
        
      }, 1500)      
    }

    this.drawer.toggle()
  }

  handleEvent(eventData: string) {
    console.log('Received event data in parent:', eventData);
  }

  closePanel(newVar: string) {
     this.drawer.toggle();
  }
  
  headerButtonClicked(event: any){

    if(event == 'Dials'){

    }
    
    if(event == 'Convos'){

    }

    if(event == "Conversions"){

    }

  }

  tableButtonClicked(requestResult: any) {


    // Assuming requestResult contains a property 'phoneNumber'
    let action = "tel";
    
    if(requestResult.buttonName == "Text"){
      action = "sms:"
    }
    
    if(requestResult.buttonName == "Email"){
      return;
    }

    let phoneNumber = requestResult.row.phone;
    navigator.clipboard.writeText(phoneNumber);

    //if(this.startRecordingOnOrder == true){
      // let telLink = document.createElement('a');
      // telLink.href = `${action}:${phoneNumber}`;
      // telLink.style.display = 'none'; // Hide the element
      // document.body.appendChild(telLink);
      // telLink.click();
      // document.body.removeChild(telLink);  
    //} else {

    console.log(200, requestResult);

    let callRequest = { ... requestResult }
    callRequest["row"]["phone"] = callRequest["row"]["contact_phone"]
    this.sharedService.sendData( requestResult );

    // this.selectedRow = $event.rowIndex;


    this.parentEvent.emit( { id: requestResult['row']['_id'] } );

    this.flextable.selectRow(requestResult["rowIndex"]);

    this.currentRowCalling = requestResult["rowIndex"]

      // this.intro = this.scripts[0].intro;
      // this.provocativeQuestion = this.scripts[0].provocativeQuestion;
      // this.pitch = this.scripts[0].pitch;
      // if(this.displayCallScript){
      //   this.openscript.nativeElement.click();
      // }

  }

  selectedRow = null;
  rowLinkClicked($event){

    this.selectedRow = $event.rowIndex;

    if(this.drawer.opened === false){
      this.drawer.toggle();  
    }
    
    console.log(227, $event);

    this.parentEvent.emit($event);
  }

  handleNotification($event){
    if($event.event == "placeOrder"){
      this.customer = $event.contact;
      console.log(115, this.customer);
    }
    this.flextable.refreshTable()

  }

  rowDeletedEvent(rowIndex){
    let deletedRow = rowIndex;
    console.log(99, deletedRow, this.selectedRow);
    if(deletedRow == this.selectedRow){
      if(this.drawer.opened === true){
        this.drawer.toggle();  
      }    
    }
  }

  cartItems = [];
  onAddToCart($event){
    var product_id = $event.target.value;
    console.log(167, product_id);
    for(var product of this.products){
      if(product._id == product_id){
        product["qty"] = 25;
        this.cartItems.push(product);
      }
    }
  }

  customer: any = { }
  
deleteItem(index: number): void {
    this.cartItems.splice(index, 1);
  }

edit = false;
orderPopupClosed(): void {
    console.log('Modal closed!');
    // Your logic here
    this.cartItems = [];
      this.edit = false;
  }

saveContact(){
  this.edit = false;
}

editContact(){
  this.edit = true;
}

async placeOrder(){
    let call_recording_info = await this.http.post(`api/datasource/orders`,       
    { 
        "customer_id": this.customer._id, 
        "order": this.cartItems, 
        title: this.customer.scrape_result.title, 
        address: this.customer.scrape_result.address, 
        contactName: this.customer.contactName, 
        contactEmail: this.customer.contactEmail, 
        contactPhone: this.customer.scrape_result.phone, 
        "approved":"pending" 
      } ).toPromise();

      this.closeOrderPopup.nativeElement.click();
}

async headerDropdownChanged($event){
  console.log(275, $event);

  let combinedFilter = JSON.parse(localStorage.getItem('combinedFilter') || '{}');
  
  let contactPageAggregate = $event["aggregate"];
  localStorage.setItem('contactPageAggregate', JSON.stringify(contactPageAggregate));

  let savedFilters = JSON.parse(localStorage.getItem('contactsSavedFilters') || '[]');

  savedFilters.push($event['dropdownData']);

  console.log(289, savedFilters);

  let savedAr = savedFilters;
  const uniqueByLabel = savedAr.reduce((acc, current) => {
    if (!acc.tempMap[current.label]) {
      acc.unique.push(current);
      acc.tempMap[current.label] = true;
    }
    return acc;
  }, { unique: [], tempMap: {} }).unique;

  console.log(uniqueByLabel);

  localStorage.setItem('contactsSavedFilters', JSON.stringify(savedAr));

  //let dropdowns = JSON.parse(localStorage.getItem('dropdowns') || '[]');

  // if($event["dropdownData"] && $event["dropdownData"]["label"] == "Category"){
  //   dropdowns = dropdowns.filter(d => d.label !== "Category");
  //   dropdowns.push({ label: "Category", value: $event["value"] });
  // }
  // if($event["dropdownData"] && $event["dropdownData"]["label"] == "City"){
  //   dropdowns = dropdowns.filter(d => d.label !== "City");
  //   dropdowns.push({ label: "City", value: $event["value"] });
  // }
  // if($event["label"] == "State"){
  //   dropdowns = dropdowns.filter(d => d.label !== "State");
  //   dropdowns.push({ label: "State", value: $event["value"] });
  // }

  //localStorage.setItem('dropdowns', JSON.stringify(dropdowns));

  // if($event["dropdownData"]["label"] == "Category"){
  //   localStorage.setItem('dropdownCategory', $event["value"]);
  // }  
  // if($event["dropdownData"]["label"] == "City"){
  //   localStorage.setItem('dropdownCity', $event["value"]);
  // }  
  // if($event["label"] == "State"){
  //   localStorage.setItem('dropdownState', $event["value"]);
  // }  

  // 
}

}
