<div [style.visibility]="isVisible ? 'visible' : 'hidden'">
  <div class="backdrop" (click)="toggleVisibility()"></div>
  <div class="popup">
    <div class="popup-header">
      <h2>{{ title | translate }}</h2>
    </div>
    <div *ngIf="!bIsAppointment" class="popup-body">
      <p>{{ message | translate }}</p>
    </div>
      <div *ngIf="bIsAppointment" class="appointment-card">
          <h2 class="appointment-title">{{ data.title }}</h2>
          <p class="appointment-time"><strong>Time:</strong> {{ data.appointmentTime | date: 'short' }}</p>
          
          <div class="contact-info">
            <p><strong>Title:</strong> {{ data.contact_title }}</p>
              <p><strong>Phone:</strong> {{ data.contact_phone }}</p>
              
          </div>
      </div>
    <div class="popup-footer">
      <button *ngIf="bIsAppointment" class="btn btn-primary" (click)="loadContact($event, data.contact_id)">{{"Open Contact" | translate }}</button>&nbsp;
      <button class="btn btn-primary" (click)="toggleVisibility()">{{"OK" | translate }}</button>
    </div>
  </div>
</div>
