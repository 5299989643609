import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'team-picker',
  templateUrl: './team-picker.component.html',
  styleUrl: './team-picker.component.css'
})
export class TeamPickerComponent implements OnInit{

  @Input("selectedTeam") selectedTeam = "";
  @Output() selectedTeamChange = new EventEmitter<any>();

  teams = [];
  team_id = "";

  constructor(private http: HttpClient, private sharedService: SharedService ) { 
  }

  ngOnInit(){
    this.loadTeams();
  }

  async loadTeams(){
    this.sharedService.getTeams().subscribe(data => this.teams = data);
    this.teams.unshift({'team_id': '', team_name: 'All'});
  }

  async onTeamChange($event){
    this.selectedTeamChange.emit(this.selectedTeam);
  }
}
