<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Caller ID</th>
      <th scope="col">From</th>
      <th scope="col">To</th>
      <th scope="col">Start Time</th>
<!--       <th scope="col">End Time</th>
      <th scope="col">Hangup Cause</th> -->
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let call of missed_calls; let i = index">
      <th scope="row">{{i + 1}}</th>
      <td>{{call.caller_id_name}}</td>
      <td><a [routerLink]="['/main/search']" (click)="searchNumber(call.from)">{{call.from}}</a></td>
      <td>{{call.to}}</td>
      <td>{{call.start_time | date: 'medium'}}</td>
<!--       <td>{{call.end_time | date: 'medium'}}</td>
      <td>{{call.hangup_cause}}</td> -->
    </tr>
  </tbody>
</table>
