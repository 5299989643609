<!-- 

	    arrayAsIcon: [ {
	    	 column: 'images',
	    	 icon: 'image'
	      }
	    ],

	    hiddenColumns: {
      	 header: true,
		     column: 'Images'
      },
-->


<mat-drawer-container class="sidebar-container" autosize>
	
   <mat-drawer #drawer class="sidebar-sidenav" mode="side" position="end">
      <div class="content-wrapper">
         <div #targetDiv><app-info (closePanel)="closePanel($event)" [parentEvent]="parentEvent" [panelId]="panelId" (childEvent)="handleNotification($event)"></app-info></div>
      </div>
   </mat-drawer>

<!-- 	<div class="row">
	  <div class="col">
	    <input type="checkbox" [(ngModel)]="startRecordingOnOrder"> Start Recording On Order
	  </div>
	  <div class="col">
	    <input type="checkbox" [(ngModel)]="startAutoDialerOnFirstCall"> Start Auto Dialer on First Call
	  </div>
	  <div class="col">
	    <input type="checkbox" [(ngModel)]="displayCallScript"> Display Call Script
	  </div>

	</div> -->



	<app-flextable #flextable
		(headerButtonClicked)='headerButtonClicked($event)'
		(rowTableBtnClicked)='tableButtonClicked($event)'
		(rowLinkClicked)='rowLinkClicked($event)'
		[options]="
			{
				datasource: 'coldleads',

				rowButtons: [
					{
						'column': 'name',
						'buttonName': 'Call',
						'api': '',
						'action': 'call'
					}
				],

				bAllowDeletion: true,

				filter: {
					visible: {
						$ne: false 
					}, 
					archived: { $ne: true }
				}, 

				columns: [
					'name',
					'phone',
					'city',
					'state',
					'category',
					'images',
				],

				linkColumnPreventDefault: true,

		    linkColumns: [ {
		    	column: 'Name',
		      path: '/main/sysadmin/planmanagement/plan/'
		     }
		    ],

		    iconColumns: [ {
		      column: 'images',
		       icon: 'edit',
		       }
		    ],

      hiddenColumns: {
         'Images': { header: true }
      },

	      scope: 'account',

      	aggregate: [
						{
						    $match: {
						        $and: [
						            {
						                $or: [  
						                    { cooldown: { $exists: false } },
						                    { cooldown: { $lte: '$$NOW' } }
						                ]
						            },
						            {
						                $or: [  
						                    { prequalificationStatus: 'shortlist' },
						                    { prequalificationStatus: 'worthTrying' }
						                ]
						            },
						            { agent_id: '$res.locals.user._id' }
						        ]
						    }
						},
					  {
					    $project: {
					      _id: 1,
					      borough: '$scrape_result.complete_address.borough',
					      phone: '$scrape_result.phone',
					      street: '$scrape_result.complete_address.street',
					      city: '$scrape_result.complete_address.city',
					      postal_code: '$scrape_result.complete_address.postal_code',
					      state: '$scrape_result.complete_address.state',
					      country: '$scrape_result.complete_address.country',
					      name: '$scrape_result.title',
					      category: '$scrape_result.category',
					      timezone: '$scrape_result.timezone'
					    }
					  }
				],

				filters: [
					{
				      'filter': { 'scrape_result.category': '' },
				      'label': 'Category',
				      'valueKeys': [],
				      'valueIds': [],
				      'datasource': 'coldleads',
				      'key': 'scrape_result.category',
				      'distinct': 'scrape_result.category',
				      'all':false
					},
					{
				      'filter': { 'scrape_result.complete_address.city': '' },
				      'label': 'City',
				      'valueKeys': [],
				      'valueIds': [],
				      'datasource': 'coldleads',
				      'key': 'scrape_result.complete_address.city',
				      'distinct': 'scrape_result.complete_address.city',
				      'all':false
					},
					{
				      'filter': { 'scrape_result.complete_address.state': '' },
				      'label': 'State',
				      'valueKeys': [],
				      'valueIds': [],
				      'datasource': 'coldleads',
				      'key': 'scrape_result.complete_address.state',
				      'distinct': 'scrape_result.complete_address.state',
				      'all':false
					}
				]	
			}"
	></app-flextable>

</mat-drawer-container>

            <div class="card-body">
              <!-- Vertically centered Modal -->
              <button type="button" #openscript class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shortlist" style="display:none"></button>
              <div class="modal fade" appMoveModalToBody  id="shortlist" tabindex="-1">
                
                <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Call Script</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="shortlistModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row mb-3">
                      <label for="inputText" class="col-sm-2 col-form-label">Subject</label>
                      <div class="col-sm-10">
                        
                        <select name="mySelect" class="form-select" aria-label="Default select example" >
                      <option value="default">Choose Category</option>
                     
                    </select>
                      </div>
                    </div>
                
							<div *ngIf="scripts && scripts.length > 0">
							    <div *ngFor="let section of scripts[0]['sections']" class="form-group mb-2">
							        <!-- <input placeholder="Section Label" type="text" class="form-control mb-2" [(ngModel)]="section.section_name"> -->
							        <textarea class="form-control" rows="5" [(ngModel)]="section.section_text" placeholder="Section Text">{{section.section_text}}</textarea>
							    </div>
							</div>
  


                                         
                      </div>
                    <div class="modal-footer">
                      <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="shortlistModal">Close</button>
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Vertically centered Modal-->
            </div>

