import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class MainComponent implements OnInit {

  private channel: BroadcastChannel;
  private tabId: string;

  private masterTab = false;

  maxWidth = "500px";

  constructor(
    private http: HttpClient,
    private router: Router,
    private dataService: DataService) { 
    this.channel = new BroadcastChannel('app_channel');
    this.tabId = new Date().getTime().toString();
  }

  ngOnInit(): void {

    this.dataService.newPanelId().subscribe( contactId => {
      if(contactId === false){
        this.closePanel(null);
      } else {
        this.activePanel(contactId);  
      }
    });



    this.dataService.getTogglePanelSize().subscribe( bExpanded => {
      console.log(41, bExpanded);
      if(!bExpanded){
        // Make the panel bigger
        this.maxWidth = "1000px";

      } else {
        this.maxWidth = "500px";
        // Make panel default size
      }
    });

    // this.dataService.closePanelAction().subscribe( () => {
    //   console.log(35, "Close Panel");
    //   this.closePanel(null);
    // });

    //const tmp = this.dataService.getSharedData();
    this.dataService.getDialpadReset().subscribe( bState => {

      if(bState == true){
        this.dialPad = false;
        setTimeout( () => {
          this.dialPad = true;      
        }, 3500)
      }
    })


    let masterTabStatus = localStorage.getItem('masterTab');
    if(masterTabStatus == null){
      localStorage.setItem('masterTab', this.tabId);
      
    }

    window.addEventListener('beforeunload', (e) => {
        // Cancel the event as stated by the standard.

      if(this.masterTab == true){
        //alert("Closing this tab will make it so you can't receive incoming calls, even if you have other CRM tabs open")
        localStorage.removeItem('masterTab');  
      }

        //e.preventDefault();
        // Chrome requires returnValue to be set.
        //e.returnValue = '';


    });

    // setTimeout( () => {
    //   this.dialPad = true;
    // }, 10000)

  }

  handleStorageEvent = (event: StorageEvent) => {
    if (event.key === 'newTab') {
      // A new tab has been detected
      console.log(`New tab detected with ID: ${event.newValue}`);
    }
  };

  ngOnDestroy(): void {
    if(this.masterTab == true){
      alert("Closing this tab will make it so you can't receive incoming calls, even if you have other CRM tabs open")
      localStorage.removeItem('masterTab');  
    }
  }

  dialPad = true;

  @ViewChild('drawer') drawer: MatDrawer;
  @Output() public parentEvent = new EventEmitter<any>();
  panelId = "";

  closePanel($event) {
     this.drawer.toggle();
  }

  handleNotification($event){

  }

  activePanel(id){

    this.panelId = id;

    if(this.drawer.opened === false){
      this.drawer.toggle();  
    }
    
    this.parentEvent.emit({ id: id });

    //this.drawer.toggle()
  }
}
