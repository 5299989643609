<app-company-picker [disableAutoSelect]="true" (companyChanged)="onCompanySelected($event)"></app-company-picker>
<office-picker (officeChanged)="onOfficeSelected($event)"></office-picker>
<team-picker (selectedTeamChange)="onTeamSelected($event)"></team-picker>
<app-report-picker (reportChange)="onReportSelected($event)"></app-report-picker>
<div class="row mb-4" *ngIf="selectedReport.reportName === 'Commission'">
  <label for="salesCommission" class="col-sm-2 col-form-label">{{ "Sales Commission (%)" | translate }}</label>
  <div class="col-sm-2">
    <input type="number" id="salesCommission" class="form-control" [(ngModel)]="salesCommissionPercentage">
  </div>
</div>

<div class="row mb-4" *ngIf="selectedReport.reportName === 'Commission'">
  <label for="resalesCommission" class="col-sm-2 col-form-label">{{ "Resales Commission (%)" | translate }}</label>
  <div class="col-sm-2">
    <input type="number" id="resalesCommission" class="form-control" [(ngModel)]="resalesCommissionPercentage">
  </div>
</div>
<div class="row mb-4">
  <label for="companyDropdown" class="col-sm-2 col-form-label">{{ "Dates" | translate }}</label>
  <div class="col-sm-2">
  <app-date-picker (datesChanged)="onDateRangeChanged($event)" [resetOnGo]="false" [bRange]="true"></app-date-picker>
  </div>
</div>

<div class="row">
  <div class="col-sm-10 offset-sm-2">
    <button type="button" class="btn btn-primary" (click)="generateReport()">{{ "Generate Report" | translate }}</button>
  </div>
</div>
<div class="alert alert-danger mt-3" *ngIf="showAlert">
  {{ alertTitle | translate }}
</div>

<div *ngIf="results" class="container mt-5">
  <div *ngIf="results.approved?.length" class="mb-4">
    <div class="card">
      <div class="card-header bg-success text-white">
        <h4 class="mb-0">{{ "Approved" | translate }}</h4>
      </div>
      <div class="card-body">
        <div *ngFor="let approval of results.approved">
          <div *ngIf="approval.Sales?.length" class="mb-4">
            <h5 class="text-primary mt-3">{{ "Sales" | translate }}</h5>
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>{{ "Sales Agent" | translate }}</th>
                  <th>{{ "Real Name" | translate }}</th>
                  <th>{{ "Total Sales Value" | translate }}</th>
                  <th>{{ "Commission" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sale of approval.Sales">
                  <td>{{ sale._id }}</td>
                  <td>{{ sale.creatorFirstName }} {{ sale.creatorLastName }}</td>
                  <td>{{ sale.totalSalesValue | currency }}</td>
                  <td>{{ sale.commission | currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="approval.Resales?.length" class="mb-4">
            <h5 class="text-warning">{{ "Resales" | translate }}</h5>
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>{{ "Sales Agent" | translate }}</th>
                  <th>{{ "Real Name" | translate }}</th>
                  <th>{{ "Total Sales Value" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let resale of approval.Resales">
                  <td>{{ resale._id }}</td>
                  <td>{{ resale.creatorFirstName }} {{ resale.creatorLastName }}</td>
                  <td>{{ resale.totalSalesValue | currency }}</td>
                  <td>{{ resale.commission | currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<div *ngIf="results.paid?.length" class="mb-4">
  <div class="card">
    <div class="card-header bg-info text-white">
      <h4 class="mb-0">{{ "Paid" | translate }}</h4>
    </div>
    <div class="card-body">
      <div *ngFor="let payment of results.paid">
        <div *ngIf="payment.Sales?.length" class="mb-4">
          <h5 class="text-primary mt-3">{{ "Sales" | translate }}</h5>
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th>{{ "Sales Agent" | translate }}</th>
                <th>{{ "Real Name" | translate }}</th>
                <th>{{ "Total Sales Value" | translate }}</th>
                <th>{{ "Commission" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sale of payment.Sales">
                <td>{{ sale._id }}</td>
                <td>{{ sale.creatorFirstName }} {{ sale.creatorLastName }}</td>
                <td>{{ sale.totalSalesValue | currency }}</td>
                <td>{{ sale.commission | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="payment.Resales?.length" class="mb-4">
          <h5 class="text-warning">{{ "Resales" | translate }}</h5>
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th>{{ "Sales Agent" | translate }}</th>
                <th>{{ "Real Name" | translate }}</th>
                <th>{{ "Total Sales Value" | translate }}</th>
                <th>{{ "Commission" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let resale of payment.Resales">
                <td>{{ resale._id }}</td>
                <td>{{ resale.creatorFirstName }} {{ resale.creatorLastName }}</td>
                <td>{{ resale.totalSalesValue | currency }}</td>
                <td>{{ resale.commission | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="results.talkTime?.length" class="mb-4">
  <div class="card">
    <div class="card-header bg-info text-white">
      <h4 class="mb-0">{{ "Talk Time" | translate }}</h4>
    </div>
    <div class="card-body">
      <div class="mb-4">
        <h5 class="text-primary mt-3">{{ "Agent Talk Time" | translate }}</h5>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th>{{ "Agent Name" | translate }}</th>
              <th>{{ "Total Talk Time" | translate }}</th>
              <th>{{ "Distinct Calls Count" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let talk of results.talkTime[0].details">
              <td>{{ talk.agent_name }}</td>
              <td>{{ talk.total_talk_time }}</td>
              <td>{{ talk.distinct_calls_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-4">
        <h5 class="text-primary mt-3">{{ "Summary" | translate }}</h5>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th>{{ "Total Talk Time (minutes)" | translate }}</th>
              <th>{{ "Total Talk Time (hours:mm)" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ results.talkTime[0].summary_total_talk_time_seconds / 60 }}</td>
              <td>{{ results.talkTime[0].summary_total_talk_time }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="results.attendance?.length" class="mb-4">
  <div class="card">
    <div class="card-header bg-warning text-white">
      <h4 class="mb-0">{{ "Attendance" | translate }}</h4>
    </div>
    <div class="card-body">
      <div *ngFor="let agent of results.attendance">
        <h5 class="text-primary mt-3">{{ agent.full_name }}</h5>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th>{{ "Date" | translate }}</th>
              <th>{{ "Day of Week" | translate }}</th>
              <th>{{ "Status" | translate }}</th>
            </tr>
          </thead>
          <tbody>
<tr *ngFor="let att of agent.attendance" [ngClass]="{'hidden': (att.day_of_week === 'Saturday' || att.day_of_week === 'Sunday' || att.status === 'Present')}">
  <td>{{ att.date }}</td>
  <td>{{ att.day_of_week }}</td>
  <td>{{ att.status }}</td>
</tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


</div>