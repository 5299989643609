import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AudioService {

  audioFiles = {
    "disconnected": "disconnected.m4a",
    "dropped": "dropped.m4a",
    "hungup": "hungup.m4a",
    "inbound_call": "inbound_call.m4a",
    "outbound_call": "outbound_call.m4a",
    "pound":"pound.m4a",
    "waiting":"waiting.wav",
    "*":"*.m4a", 
    "0":"0.m4a", 
    "1":"1.m4a", 
    "2":"2.m4a", 
    "3":"3.m4a", 
    "4":"4.m4a", 
    "5":"5.m4a", 
    "6":"6.m4a", 
    "7":"7.m4a", 
    "8":"8.m4a", 
    "9":"9.m4a",
    "#":"pound.m4a", 
    "hangup":"hangup.m4a",
    "cause_code_18":"rejected.m4a",
    "busy":"busy.m4a",
    "unavailable":"unavailable.m4a",
    "single_ring":"single_ring.m4a"
  }

  private audioPlayer: HTMLAudioElement;

  constructor() {
    this.initAudioPlayer();
  }

  private initAudioPlayer() {
    if (!this.audioPlayer) {
      this.audioPlayer = new Audio();
    }
  }

  playDelayed(soundName, delay =500){
    // if(delay === false){
    //   return;
    // }
    
    setTimeout( () => {
      this.play(soundName);
    }, delay)
  }

  play(soundName, to =null) {

    try {
      let language = localStorage.getItem('selectedLanguage');
      let countryCode = localStorage.getItem('countryCode');
      let directory = "languages";
      let sub_directory = language;

      if (soundName === "outbound_call") {
        directory = "countryCode";
        sub_directory = countryCode;
      }

      let soundFile = '';
      try {
        soundFile = `assets/audio/${directory}/${sub_directory}/${this.audioFiles[soundName]}`;
      } catch (err) {
        console.log(58, "audio.service.ts", "Unable to play sound", soundName, err);
      }

      this.audioPlayer.onerror = (event) => {
        console.log(63, "audio.service.ts", "Unable to play sound", soundName, soundFile, event);
      };

    if(to !== null){
      // We need to determine the country code, as this is a ringback.


                let ctryCode = '+1'

                // Detect the country code of the destination, and play that ringback...
                //aa console.log(652, `https://www.scrapercrm.com/assets/audio/outbound_call.`);

                const countryCodes = [
                  '1',    // USA, Canada, and NANP countries
                  '7',    // Russia, Kazakhstan
                  '20',   // Egypt
                  '27',   // South Africa
                  '30',   // Greece
                  '31',   // Netherlands
                  // Add other country codes here
                  //'41',   // Switzerland
                  //'44',   // Austria
                  '44',   // United Kingdom
                  '49',   // Germany
                  '81',   // Japan
                  '86',   // China
                  '91',   // India
                  '212',  // Morocco
                  '213',  // Algeria
                  '234',  // Nigeria
                  '852',  // Hong Kong
                  '971',  // UAE
                  // Complete this list with all known country codes
                ];

                function extractCountryCode(phoneNumber) {
                    // Remove any non-numeric characters except the leading +
                    phoneNumber = phoneNumber.replace(/[^0-9+]/g, '');

                    // Start by removing the '+' sign
                    if (phoneNumber.startsWith('+')) {
                        phoneNumber = phoneNumber.slice(1);
                    }

                    // Check possible lengths of country codes
                    for (let length = 1; length <= 4; length++) {
                        const code = phoneNumber.slice(0, length);
                        if (countryCodes.includes(code)) {
                            return code;
                        }
                    }

                    // If no match found
                    return '49';
                }

                let countryCode = extractCountryCode(to);

                

                soundFile = `/assets/audio/countryCode/+${countryCode}/${this.audioFiles[soundName]}`;
    }

      this.audioPlayer.src = soundFile;
      this.audioPlayer.load();
      this.audioPlayer.currentTime = 0;

      this.audioPlayer.play().catch((err) => {
        //console.log(71, "audio.service.ts", "Unable to play sound", soundName, err);
      });

    } catch (err) {
      //console.log(71, "audio.service.ts", "Unable to play sound", soundName, err);
    }
  }

  pause() {

    try {
      this.audioPlayer.pause();
      this.audioPlayer.currentTime = 0;
    } catch(err){
      console.log(59, "audio.service.ts", "Unable to pause sound", err);
    }

  }

  setSource(src: string) {
    this.audioPlayer.src = src;
    console.log("Source set to:", src);
  }

}

