import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { FlextableComponent } from "../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../shared.service';
import { DataService } from '@services/data.service'

import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit, FlexTableInterface {

  panelId = "";
  startRecordingOnOrder: boolean = false;
  displayCallScript: boolean = false;
  startAutoDialerOnFirstCall = false;
  bIsAutoDialing = false;
  currentRowCalling = null;
  @ViewChild('flextable') flextable: FlextableComponent;
  @ViewChild('openscript') openscript: ElementRef;
  @ViewChild('closeOrderPopup') closeOrderPopup: ElementRef;

  bOnActiveCall = false;
  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService) {

    // Figure out why I'm doing this
    this.sharedService.getCallStatus().subscribe(callStatus => {

      if(callStatus == "destroy"){
        this.bOnActiveCall = false;
        if(this.startAutoDialerOnFirstCall == true){

          let rowData = this.flextable.getRowByIndex(this.currentRowCalling + 1);

          setTimeout( () => {

            let callInfo = { "_id": rowData["_id"], buttonName: "Call", row: rowData, rowIndex: this.currentRowCalling + 1 }
            this.sharedService.sendData( callInfo );
          }, 2500)
          this.parentEvent.emit( { id: rowData['_id'] } );
          this.flextable.selectRow( this.currentRowCalling + 1 );
          this.currentRowCalling = this.currentRowCalling + 1;         
        }
      }

      if(callStatus == "new"){
        this.bOnActiveCall = true;
      }
      
    });
  }

  scripts = [];
  products = [];
  intro: string = "";
  provocativeQuestion: string = "";
  pitch: string = "";

  searchResults: Object;
  searchTerm: Object;

  userInfo: any = null;

  ngOnInit(): void {

      this.dataService.notifyOfRefreshRequest().subscribe( bRefreshRequest => {

        //this.flextable.refreshTableStayOnPage();
      })

      this.dataService.getUserInfo().subscribe(data => {

        this.userInfo = data;


      })

      this.sharedService.bClearSearch.subscribe(bClearSearch => {
        // A flag to clear search results

        this.flextable.clear();

      })

    this.sharedService.searchTerm.subscribe(searchTerm => {

      this.searchTerm = searchTerm;
      //localStorage.setItem("lastSearchTerm", JSON.stringify(searchTerm));

    })

    //console.log(73, JSON.parse( localStorage.getItem('lastSearch') ) ) 

      this.sharedService.searchResults.subscribe(searchResults => {
     
      let headerInfo = {
        "headers": [
            "name",
            "phone",
            "city",
            "state",
            "category",
            "images"
        ],
        "addDataForm": [
            {
                "field_label": "Name",
                "field_name": "name",
                "type": "String",
                "formType": {
                    "controlType": "text"
                }
            },
            {
                "field_label": "Phone",
                "field_name": "phone",
                "type": "String",
                "formType": {
                    "controlType": "text"
                }
            },
            {
                "field_label": "City",
                "field_name": "city",
                "type": "String",
                "formType": {
                    "controlType": "text"
                }
            },
            {
                "field_label": "State",
                "field_name": "state",
                "type": "String",
                "formType": {
                    "controlType": "text"
                }
            },
            {
                "field_label": "Category",
                "field_name": "category",
                "type": "String",
                "formType": {
                    "controlType": "text"
                }
            },
            {
                "field_label": "Images",
                "field_name": "images",
                "type": "Array",
                "formType": {
                    "controlType": "select",
                    "options": [
                        "https://lh5.googleusercontent.com/p/AF1QipPRfdxqDeu9RORjMwY7FZNAEIV6f3Uo-mrvlnCf=w447-h298-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipMdDCln83j4OpDMeFdKs90S_jp5iKEOIoXre6O3=w224-h397-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipMXWPUHbx2Evy7TQ2WSVqKnMz7shDukUPdMa5jo=w224-h398-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipNosBBTkcC6vcEcJiYpwO_ti5ZpX7Tu4mUPRMxj=w447-h298-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipMmjjty1f5L-VgKcWGYXQEhqtR8GV490o03chLn=w447-h298-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipMEL1KYs_NXOQVZk7ljTNfTI2ZG3j55T-jRYS9l=w447-h298-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipMp3ys5mvCAYiuNZGVbIKLXgEejuECh8lfM-8CM=w224-h398-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipNBpmbTFXOus6NEoPlJWpg1GzQWFpq2-EWClRdd=w224-h398-k-no",
                        "https://lh5.googleusercontent.com/p/AF1QipM-6J9WocFOf2W4cVQjhnGSMI9vWYKOU1G1RPEc=w447-h298-k-no"
                    ]
                }
            }
        ],
        "displayHeaders": [
            "Name",
            "Phone",
            "City",
            "State",
            "Category",
            "Images"
        ]
      }


      this.searchResults = { ... headerInfo, ... searchResults }


      //localStorage.setItem("lastSearch", JSON.stringify(this.searchResults));
      if(typeof this.searchResults !== 'undefined'){
        if(typeof this.flextable !== 'undefined'){
          this.flextable.setData(this.searchResults)    
        }
      }
      
    });

      this.http.get('/api/datasource/call_scripts/max_records/1000').subscribe(
      response => {

        this.scripts = response["call_scripts"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );

       this.sharedService.getProducts().subscribe(data => this.products = data);
  }

  getProducts(){
      // this.http.get('/api/datasource/products/max_records/1000/account').subscribe(
      // response => {

      //   this.products = response["products"];
      //   },
      //   error => {
      //     console.error('Error starting scraping', error);
      //   }
      // );    
  }

  @ViewChild('drawer') drawer: MatDrawer;
  @Output() public parentEvent = new EventEmitter<any>();

  sendEventToChild(_id: String) {
    const eventData = 'Custom event data from parent';
    this.parentEvent.emit(_id);
  }

  activePanel(componentInfo: any){
    console.log(28)
    this.panelId = componentInfo._id;
    if(this.drawer.opened){
      setTimeout( () => {
        
      }, 1500)      
    }

    this.drawer.toggle()
  }

  handleEvent(eventData: string) {
    console.log('Received event data in parent:', eventData);
  }

  closePanel(newVar: string) {
     this.drawer.toggle();
  }
  
  headerButtonClicked(event: any){
    console.log(52, event);
    if(event == 'Dials'){

    }
    
    if(event == 'Convos'){

    }

    if(event == "Conversions"){

    }

  }

  tableButtonClicked(requestResult: any) {


    // Assuming requestResult contains a property 'phoneNumber'
    let action = "tel";
    
    if(requestResult.buttonName == "Text"){
      action = "sms:"
    }
    
    if(requestResult.buttonName == "Email"){
      return;
    }

    let phoneNumber = requestResult.row.phone;
    navigator.clipboard.writeText(phoneNumber);

    //if(this.startRecordingOnOrder == true){
      // let telLink = document.createElement('a');
      // telLink.href = `${action}:${phoneNumber}`;
      // telLink.style.display = 'none'; // Hide the element
      // document.body.appendChild(telLink);
      // telLink.click();
      // document.body.removeChild(telLink);  
    //} else {

    this.sharedService.sendData( requestResult );

    // this.selectedRow = $event.rowIndex;

    // if(this.drawer.opened === false){
    //   this.drawer.toggle();  
    // }
    
    this.parentEvent.emit( { id: requestResult['row']['_id'] } );

    this.flextable.selectRow(requestResult["rowIndex"]);

    this.currentRowCalling = requestResult["rowIndex"]

      // this.intro = this.scripts[0].intro;
      // this.provocativeQuestion = this.scripts[0].provocativeQuestion;
      // this.pitch = this.scripts[0].pitch;
      // if(this.displayCallScript){
      //   this.openscript.nativeElement.click();
      // }

  }

  selectedRow = null;
  rowLinkClicked($event){
    this.dataService.openPanel($event.rowData._id);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    "withCredentials": true
  };

  async handleNotification($event){
    if($event.event == "placeOrder"){
      this.customer = $event.contact;
      console.log(115, this.customer);
    }

    this.flextable.bIsLoading = true;
    var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.title" + '/' + this.searchTerm

    let result = await this.http.get(api_url, this.httpOptions).toPromise();
    console.log(314, this.searchTerm);
    this.selectedRow = null;
    this.sharedService.updateSearchResults(result, this.searchTerm);

    this.flextable.selectRow(null);
      if(this.drawer.opened === true){
        this.drawer.toggle();  
      } 

    this.flextable.bIsLoading = false;
  }

  rowDeletedEvent(rowIndex){
    let deletedRow = rowIndex;
    console.log(99, deletedRow, this.selectedRow);
    if(deletedRow == this.selectedRow){
      if(this.drawer.opened === true){
        this.drawer.toggle();  
      }    
    }
  }

  cartItems = [];
  onAddToCart($event){
    var product_id = $event.target.value;
    console.log(167, product_id);
    for(var product of this.products){
      if(product._id == product_id){
        product["qty"] = 25;
        this.cartItems.push(product);
      }
    }
  }

  customer: any = { }
  
deleteItem(index: number): void {
    this.cartItems.splice(index, 1);
  }

edit = false;
orderPopupClosed(): void {
    console.log('Modal closed!');
    // Your logic here
    this.cartItems = [];
      this.edit = false;
  }

saveContact(){
  this.edit = false;
}

editContact(){
  this.edit = true;
}

async placeOrder(){

    if(this.cartItems.length == 0){
      alert("You cart cannot be empty to place an order");
      return;
    }

    //this.dataService.placeOrder(this.cartItems, this.customer, this.userInfo)
    
    // let call_recording_info = await this.http.post(`api/datasource/orders`, 
    // { 
    //   "customer_id": this.customer._id, 
    //   "order": this.cartItems, 
    //   title: this.customer.scrape_result.title, 
    //   address: this.customer.scrape_result.address, 
    //   contactFirstName: this.customer.contactFirstName,
    //   contactLastName: this.customer.contactLastName,
    //   contactEmail: this.customer.contactEmail, 
    //   contactPhone: this.customer.scrape_result.phone, 
    //   "approved":"pending"
    // } ).toPromise();

    this.closeOrderPopup.nativeElement.click();
}

}
