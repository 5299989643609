import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-canvas',
  templateUrl: './child2.component.html',
  styleUrls: ['./child2.component.css']
})

export class Child2Component implements AfterViewInit {
  @ViewChild('myCanvas') canvasRef: ElementRef;
  private ctx: CanvasRenderingContext2D;

  @ViewChild('parentFrame') parentFrameRef!: ElementRef;

  // Square properties
  squareSize = 100;
  squares = [
    { color: 'red', x: 50, y: 50, isDragging: false, dragOffsetX: 0, dragOffsetY: 0 },
    { color: 'blue', x: 200, y: 200, isDragging: false, dragOffsetX: 0, dragOffsetY: 0 }
  ];

  drawSquare(square){
    this.ctx.fillStyle = square.color;
    this.ctx.fillRect(square.x, square.y, this.squareSize, this.squareSize);
    return square;
  }

// ngAfterViewInit() {
//   const canvas = this.canvasRef.nativeElement;
//   this.ctx = canvas.getContext('2d');

//   // Adjust canvas dimensions
//   canvas.width = canvas.offsetWidth;
//   canvas.height = canvas.offsetHeight;



//   // Draw the squares
//   this.squares.forEach(square => {
//    this.drawSquare(square);
//   });

//   this.squares.push( this.drawSquare( { color: 'yellow', x: 150, y: 150, isDragging: false, dragOffsetX: 0, dragOffsetY: 0 } ) );


//   // Draw a dotted line between the squares
//   const drawLine = () => {
//     const startX = this.squares[0].x + this.squareSize;
//     const startY = this.squares[0].y + this.squareSize / 2;
//     const endX = this.squares[1].x;
//     const endY = this.squares[1].y + this.squareSize / 2;

//     this.ctx.strokeStyle = 'green';
//     this.ctx.lineWidth = 3;
//     this.ctx.setLineDash([5, 5]); // Set the line to be dotted
//     this.ctx.beginPath();
//     this.ctx.moveTo(startX, startY);
//     this.ctx.lineTo(endX, endY);
//     this.ctx.stroke();
//   };

//   // Mouse event listeners
//   canvas.addEventListener('mousedown', (event) => {
//     const { offsetX, offsetY } = event;
//     this.squares.forEach(square => {
//       if (
//         offsetX >= square.x &&
//         offsetX <= square.x + this.squareSize &&
//         offsetY >= square.y &&
//         offsetY <= square.y + this.squareSize
//       ) {
//         square.isDragging = true;
//         square.dragOffsetX = offsetX - square.x;
//         square.dragOffsetY = offsetY - square.y;
//       }
//     });
//   });

//   canvas.addEventListener('mousemove', (event) => {
//     this.squares.forEach(square => {
//       if (square.isDragging) {
//         const { offsetX, offsetY } = event;
//         square.x = offsetX - square.dragOffsetX;
//         square.y = offsetY - square.dragOffsetY;
//         this.ctx.clearRect(0, 0, canvas.width, canvas.height);
//         this.squares.forEach(square => {
//           this.ctx.fillStyle = square.color;
//           this.ctx.fillRect(square.x, square.y, this.squareSize, this.squareSize);
//         });
//         drawLine();
//       }
//     });
//   });

//   canvas.addEventListener('mouseup', () => {
//     this.squares.forEach(square => {
//       square.isDragging = false;
//     });
//   });

//   // Initial line drawing
//   drawLine();
// }

/* Makes squares with arrows that drag */
// ngAfterViewInit() {
//   const canvas = this.canvasRef.nativeElement;
//   this.ctx = canvas.getContext('2d');

//   // Adjust canvas dimensions
//   canvas.width = canvas.offsetWidth;
//   canvas.height = canvas.offsetHeight;

//   // Square properties
//   const squareSize = 100;
//   const minDistance = squareSize * 1.5; // Minimum distance for arrowhead visibility

//   // Create the squares
//   const squares = [
//     this.createSquare('red', 50, 50),
//     this.createSquare('blue', 200, 200),
//     this.createSquare('green', 350, 350),
//     // Add more squares dynamically if needed
//   ];

//   // Draw the squares
//   squares.forEach(square => {
//     this.ctx.fillStyle = square.color;
//     this.ctx.fillRect(square.x, square.y, squareSize, squareSize);
//   });

//   // Draw lines between each pair of squares with arrowheads
//   const drawLines = () => {
//     this.ctx.strokeStyle = 'green';
//     this.ctx.lineWidth = 3;

//     for (let i = 0; i < squares.length - 1; i++) {
//       const startSquare = squares[i];
//       const endSquare = squares[i + 1];

//       const startX = startSquare.x + squareSize;
//       const startY = startSquare.y + squareSize / 2;
//       const endX = endSquare.x;
//       const endY = endSquare.y + squareSize / 2;

//       const dx = endX - startX;
//       const dy = endY - startY;
//       const distance = Math.sqrt(dx * dx + dy * dy);

//       if (distance < minDistance) {
//         continue; // Skip drawing the line if the squares are too close together
//       }

//       const arrowSize = 60;

//       // Draw the line
//       this.ctx.beginPath();
//       this.ctx.moveTo(startX, startY);
//       this.ctx.lineTo(endX, endY);
//       this.ctx.stroke();

//       // Draw the arrowhead
//       const angle = Math.atan2(dy, dx);
//       const arrowX = endX - Math.cos(angle) * arrowSize;
//       const arrowY = endY - Math.sin(angle) * arrowSize;

//       this.ctx.save();
//       this.ctx.translate(arrowX, arrowY);
//       this.ctx.rotate(angle);
//       this.ctx.beginPath();
//       this.ctx.moveTo(-arrowSize, -arrowSize / 2);
//       this.ctx.lineTo(0, 0);
//       this.ctx.lineTo(-arrowSize, arrowSize / 2);
//       this.ctx.closePath();
//       this.ctx.fillStyle = 'green';
//       this.ctx.fill();
//       this.ctx.restore();
//     }
//   };

//   // Mouse event listeners
//   canvas.addEventListener('mousedown', (event) => {
//     const { offsetX, offsetY } = event;
//     squares.forEach(square => {
//       if (
//         offsetX >= square.x &&
//         offsetX <= square.x + squareSize &&
//         offsetY >= square.y &&
//         offsetY <= square.y + squareSize
//       ) {
//         square.isDragging = true;
//         square.dragOffsetX = offsetX - square.x;
//         square.dragOffsetY = offsetY - square.y;
//       }
//     });
//   });

//   canvas.addEventListener('mousemove', (event) => {
//     squares.forEach(square => {
//       if (square.isDragging) {
//         const { offsetX, offsetY } = event;
//         square.x = offsetX - square.dragOffsetX;
//         square.y = offsetY - square.dragOffsetY;
//         this.ctx.clearRect(0, 0, canvas.width, canvas.height);
//         squares.forEach(square => {
//           this.ctx.fillStyle = square.color;
//           this.ctx.fillRect(square.x, square.y, squareSize, squareSize);
//         });
//         drawLines();
//       }
//     });
//   });

//   canvas.addEventListener('mouseup', () => {
//     squares.forEach(square => {
//       square.isDragging = false;
//     });
//   });

//   // Initial line drawing
//   drawLines();
// }

/* When you click on a square, it makes a new square */
// ngAfterViewInit() {
//   const canvas = this.canvasRef.nativeElement;
//   this.ctx = canvas.getContext('2d');

//   // Adjust canvas dimensions
//   canvas.width = canvas.offsetWidth;
//   canvas.height = canvas.offsetHeight;

//   // Square properties
//   const squareSize = 100;
//   const minDistance = squareSize * 1.5; // Minimum distance for arrowhead visibility

//   // Create the squares
//   const squares = [
//     this.createSquare('red', 50, 50),
//     this.createSquare('blue', 200, 200),
//     this.createSquare('green', 350, 350),
//     // Add more squares dynamically if needed
//   ];

//   // Draw the squares
//   squares.forEach(square => {
//     this.ctx.fillStyle = square.color;
//     this.ctx.fillRect(square.x, square.y, squareSize, squareSize);
//   });

//   // Draw lines between each pair of squares with arrowheads
//   const drawLines = () => {
//     this.ctx.strokeStyle = 'green';
//     this.ctx.lineWidth = 3;

//     for (let i = 0; i < squares.length - 1; i++) {
//       const startSquare = squares[i];
//       const endSquare = squares[i + 1];

//       const startX = startSquare.x + squareSize;
//       const startY = startSquare.y + squareSize / 2;
//       const endX = endSquare.x;
//       const endY = endSquare.y + squareSize / 2;

//       const dx = endX - startX;
//       const dy = endY - startY;
//       const distance = Math.sqrt(dx * dx + dy * dy);

//       if (distance < minDistance) {
//         continue; // Skip drawing the line if the squares are too close together
//       }

//       const arrowSize = 60;

//       // Draw the line
//       this.ctx.beginPath();
//       this.ctx.moveTo(startX, startY);
//       this.ctx.lineTo(endX, endY);
//       this.ctx.stroke();

//       // Draw the arrowhead
//       const angle = Math.atan2(dy, dx);
//       const arrowX = endX - Math.cos(angle) * arrowSize;
//       const arrowY = endY - Math.sin(angle) * arrowSize;

//       this.ctx.save();
//       this.ctx.translate(arrowX, arrowY);
//       this.ctx.rotate(angle);
//       this.ctx.beginPath();
//       this.ctx.moveTo(-arrowSize, -arrowSize / 2);
//       this.ctx.lineTo(0, 0);
//       this.ctx.lineTo(-arrowSize, arrowSize / 2);
//       this.ctx.closePath();
//       this.ctx.fillStyle = 'green';
//       this.ctx.fill();
//       this.ctx.restore();
//     }
//   };

//   // Mouse event listeners
//   canvas.addEventListener('mousedown', (event) => {
//     const { offsetX, offsetY } = event;

//     if (offsetX >= squares[squares.length - 1].x &&
//       offsetX <= squares[squares.length - 1].x + squareSize &&
//       offsetY >= squares[squares.length - 1].y &&
//       offsetY <= squares[squares.length - 1].y + squareSize) {

//       const newColor = getRandomColor();
//       const newX = Math.random() * (canvas.width - squareSize);
//       const newY = Math.random() * (canvas.height - squareSize);

//       const newSquare = this.createSquare(newColor, newX, newY);
//       squares.push(newSquare);

//       this.ctx.fillStyle = newColor;
//       this.ctx.fillRect(newX, newY, squareSize, squareSize);

//       drawLines();
//     }
//   });

//   // Utility function to generate a random color
//   const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   // Initial line drawing
//   drawLines();
// }

/* Creates new squares, and when it would be off screen, goes to the next line */
// ngAfterViewInit() {
//   const canvas = this.canvasRef.nativeElement;
//   this.ctx = canvas.getContext('2d');

//   // Adjust canvas dimensions
//   canvas.width = canvas.offsetWidth;
//   canvas.height = canvas.offsetHeight;

//   // Square properties
//   const squareSize = 80; // Adjust the square size as desired
//   const minDistance = squareSize * 1.5; // Minimum distance for arrowhead visibility
//   const lineColor = 'green'; // Adjust the line color as desired
//   const lineWidth = 3; // Adjust the line width as desired
//   const arrowSize = 60; // Adjust the arrow size as desired
//   const maxFrameWidth = canvas.width - squareSize; // Maximum x position within the frame

//   // Create the squares
//   let squares = [
//     this.createSquare('red', 50, 50),
//     this.createSquare('blue', 150, 50), // Adjust the initial position of squares as desired
//     this.createSquare('green', 250, 50),
//     // Add more squares dynamically if needed
//   ];

//   // Draw the squares
//   const drawSquares = () => {
//     squares.forEach(square => {
//       this.ctx.fillStyle = square.color;
//       this.ctx.fillRect(square.x, square.y, squareSize, squareSize);
//     });
//   };

//   // Draw lines between each pair of squares with arrowheads
//   const drawLines = () => {
//     this.ctx.strokeStyle = lineColor;
//     this.ctx.lineWidth = lineWidth;

//     for (let i = 0; i < squares.length - 1; i++) {
//       const startSquare = squares[i];
//       const endSquare = squares[i + 1];

//       const startX = startSquare.x + squareSize / 2;
//       const startY = startSquare.y + squareSize / 2;
//       const endX = endSquare.x + squareSize / 2;
//       const endY = endSquare.y + squareSize / 2;

//       const dx = endX - startX;
//       const dy = endY - startY;

//       const distance = Math.sqrt(dx * dx + dy * dy);
//       const arrowVisible = distance > minDistance;

//       // Draw the line
//       this.ctx.beginPath();
//       this.ctx.moveTo(startX, startY);
//       this.ctx.lineTo(endX, endY);
//       this.ctx.stroke();

//       if (arrowVisible) {
//         // Draw the arrowhead
//         const angle = Math.atan2(dy, dx);
//         const arrowX = endX - Math.cos(angle) * arrowSize;
//         const arrowY = endY - Math.sin(angle) * arrowSize;

//         this.ctx.save();
//         this.ctx.translate(arrowX, arrowY);
//         this.ctx.rotate(angle);
//         this.ctx.beginPath();
//         this.ctx.moveTo(-arrowSize, -arrowSize / 2);
//         this.ctx.lineTo(0, 0);
//         this.ctx.lineTo(-arrowSize, arrowSize / 2);
//         this.ctx.closePath();
//         this.ctx.fillStyle = lineColor;
//         this.ctx.fill();
//         this.ctx.restore();
//       }
//     }
//   };

//   // Mouse event listeners
//   let selectedSquare = null;
//   let dragOffsetX = 0;
//   let dragOffsetY = 0;

//   canvas.addEventListener('mousedown', (event) => {
//     const { offsetX, offsetY } = event;

//     selectedSquare = squares.find(square =>
//       offsetX >= square.x &&
//       offsetX <= square.x + squareSize &&
//       offsetY >= square.y &&
//       offsetY <= square.y + squareSize
//     );

//     if (selectedSquare) {
//       dragOffsetX = offsetX - selectedSquare.x;
//       dragOffsetY = offsetY - selectedSquare.y;
//     }
//   });

//   canvas.addEventListener('mousemove', (event) => {
//     if (selectedSquare) {
//       const { offsetX, offsetY } = event;
//       selectedSquare.x = offsetX - dragOffsetX;
//       selectedSquare.y = offsetY - dragOffsetY;
//       this.ctx.clearRect(0, 0, canvas.width, canvas.height);
//       drawSquares();
//       drawLines();
//     }
//   });

//   canvas.addEventListener('mouseup', () => {
//     selectedSquare = null;
//   });

//   canvas.addEventListener('dblclick', (event) => {
//     const { offsetX, offsetY } = event;

//     const lastSquare = squares[squares.length - 1];
//     let newX = lastSquare.x + squareSize + 20; // Adjust the horizontal spacing as desired
//     let newY = lastSquare.y;

//     if (newX > maxFrameWidth) {
//       newX = 0;
//       newY += 200; // Adjust the vertical spacing as desired
//     }

//     const newColor = getRandomColor();
//     const newSquare = this.createSquare(newColor, newX, newY);
//     squares.push(newSquare);

//     this.ctx.fillStyle = newColor;
//     this.ctx.fillRect(newX, newY, squareSize, squareSize);

//     drawLines();
//   });

//   // Utility function to generate a random color
//   const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   // Initial square and line drawing
//   drawSquares();
//   drawLines();
// }
/* Doesn't really work but I'm saving it */
// ngAfterViewInit() {
//   const canvas = this.canvasRef.nativeElement;
//   this.ctx = canvas.getContext('2d');

//   // Adjust canvas dimensions
//   canvas.width = canvas.offsetWidth;
//   canvas.height = canvas.offsetHeight;

//   // Square properties
//   const squareSize = 80; // Adjust the square size as desired
//   const minDistance = squareSize * 1.5; // Minimum distance for arrowhead visibility
//   const lineColor = 'green'; // Adjust the line color as desired
//   const lineWidth = 3; // Adjust the line width as desired
//   const arrowSize = 60; // Adjust the arrow size as desired
//   const maxFrameWidth = canvas.width - squareSize; // Maximum x position within the frame

//   // Create the squares
//   let squares = [
//     this.createSquare('red', 50, 50),
//     this.createSquare('blue', 150, 50), // Adjust the initial position of squares as desired
//     this.createSquare('green', 250, 50),
//     // Add more squares dynamically if needed
//   ];

//   // Draw the squares
//   const drawSquares = () => {
//     squares.forEach(square => {
//       this.ctx.fillStyle = square.color;
//       this.ctx.fillRect(square.x, square.y, squareSize, squareSize);
//     });
//   };

//   // Draw lines between each pair of squares with arrowheads
//   const drawLines = () => {
//     this.ctx.strokeStyle = lineColor;
//     this.ctx.lineWidth = lineWidth;

//     for (let i = 0; i < squares.length - 1; i++) {
//       const startSquare = squares[i];
//       const endSquare = squares[i + 1];

//       const startX = startSquare.x + squareSize / 2;
//       const startY = startSquare.y + squareSize / 2;
//       const endX = endSquare.x + squareSize / 2;
//       const endY = endSquare.y + squareSize / 2;

//       const dx = endX - startX;
//       const dy = endY - startY;

//       const distance = Math.sqrt(dx * dx + dy * dy);
//       const arrowVisible = distance > minDistance;

//       // Draw the line
//       this.ctx.beginPath();
//       this.ctx.moveTo(startX, startY);
//       this.ctx.lineTo(endX, endY);
//       this.ctx.stroke();

//       if (arrowVisible) {
//         // Draw the arrowhead
//         const angle = Math.atan2(dy, dx);
//         const arrowX = endX - Math.cos(angle) * arrowSize;
//         const arrowY = endY - Math.sin(angle) * arrowSize;

//         this.ctx.save();
//         this.ctx.translate(arrowX, arrowY);
//         this.ctx.rotate(angle);
//         this.ctx.beginPath();
//         this.ctx.moveTo(-arrowSize, -arrowSize / 2);
//         this.ctx.lineTo(0, 0);
//         this.ctx.lineTo(-arrowSize, arrowSize / 2);
//         this.ctx.closePath();
//         this.ctx.fillStyle = lineColor;
//         this.ctx.fill();
//         this.ctx.restore();
//       }
//     }
//   };

//   // Mouse event listeners
//   let selectedSquare = null;
//   let dragOffsetX = 0;
//   let dragOffsetY = 0;

//   canvas.addEventListener('mousedown', (event) => {
//     const { offsetX, offsetY } = event;

//     selectedSquare = squares.find(square =>
//       offsetX >= square.x &&
//       offsetX <= square.x + squareSize &&
//       offsetY >= square.y &&
//       offsetY <= square.y + squareSize
//     );

//     if (selectedSquare) {
//       dragOffsetX = offsetX - selectedSquare.x;
//       dragOffsetY = offsetY - selectedSquare.y;
//     }
//   });

//   canvas.addEventListener('mousemove', (event) => {
//     if (selectedSquare) {
//       const { offsetX, offsetY } = event;
//       selectedSquare.x = offsetX - dragOffsetX;
//       selectedSquare.y = offsetY - dragOffsetY;
//       this.ctx.clearRect(0, 0, canvas.width, canvas.height);
//       drawSquares();
//       drawLines();
//     }
//   });

//   canvas.addEventListener('mouseup', () => {
//     selectedSquare = null;
//   });

//   canvas.addEventListener('dblclick', (event) => {
//     const { offsetX, offsetY } = event;

//     const lastSquare = squares[squares.length - 1];
//     let newX;
//     let newY = lastSquare.y;

//     if (lastSquare.x > 0) {
//       newX = lastSquare.x - squareSize - 20; // Adjust the horizontal spacing as desired
//     } else {
//       newX = maxFrameWidth;
//       newY += squareSize + 20; // Adjust the vertical spacing as desired
//     }

//     const newColor = getRandomColor();
//     const newSquare = this.createSquare(newColor, newX, newY);
//     squares.push(newSquare);

//     this.ctx.fillStyle = newColor;
//     this.ctx.fillRect(newX, newY, squareSize, squareSize);

//     drawLines();
//   });

//   // Utility function to generate a random color
//   const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   // Initial square and line drawing
//   drawSquares();
//   drawLines();
// }

setCanvasSize() {
    const parentFrame = this.parentFrameRef.nativeElement as HTMLElement;
    const canvas = this.canvasRef.nativeElement as HTMLCanvasElement;

    canvas.width = parentFrame.clientWidth;
    canvas.height = parentFrame.clientHeight;
  }

/* Almost perfect */
ngAfterViewInit() {
  
  this.setCanvasSize();

  const canvas = this.canvasRef.nativeElement;
  this.ctx = canvas.getContext('2d');

  // Adjust canvas dimensions
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;

  // Square properties
  const squareSize = 80; // Adjust the square size as desired
  const minDistance = squareSize * 1.5; // Minimum distance for arrowhead visibility
  const lineColor = 'green'; // Adjust the line color as desired
  const lineWidth = 3; // Adjust the line width as desired
  const arrowSize = 60; // Adjust the arrow size as desired
  const maxFrameWidth = canvas.width - squareSize; // Maximum x position within the frame

  // Create the squares
  let squares = [
    this.createSquare('red', 50, 50),
    this.createSquare('blue', 150, 50), // Adjust the initial position of squares as desired
    this.createSquare('green', 250, 50),
    // Add more squares dynamically if needed
  ];

  // Draw the squares
  const drawSquares = () => {
    squares.forEach(square => {
      this.ctx.fillStyle = square.color;
      this.ctx.fillRect(square.x, square.y, squareSize, squareSize);
    });
  };

  // Draw lines between each pair of squares with arrowheads
  const drawLines = () => {
    this.ctx.strokeStyle = lineColor;
    this.ctx.lineWidth = lineWidth;

    for (let i = 0; i < squares.length - 1; i++) {
      const startSquare = squares[i];
      const endSquare = squares[i + 1];

      const startX = startSquare.x + squareSize / 2;
      const startY = startSquare.y + squareSize / 2;
      const endX = endSquare.x + squareSize / 2;
      const endY = endSquare.y + squareSize / 2;

      const dx = endX - startX;
      const dy = endY - startY;

      const distance = Math.sqrt(dx * dx + dy * dy);
      const arrowVisible = distance > minDistance;

      // Draw the line
      this.ctx.beginPath();
      this.ctx.moveTo(startX, startY);
      this.ctx.lineTo(endX, endY);
      this.ctx.stroke();

      if (arrowVisible) {
        // Draw the arrowhead
        const angle = Math.atan2(dy, dx);
        const arrowX = endX - Math.cos(angle) * arrowSize;
        const arrowY = endY - Math.sin(angle) * arrowSize;

        this.ctx.save();
        this.ctx.translate(arrowX, arrowY);
        this.ctx.rotate(angle);
        this.ctx.beginPath();
        this.ctx.moveTo(-arrowSize, -arrowSize / 2);
        this.ctx.lineTo(0, 0);
        this.ctx.lineTo(-arrowSize, arrowSize / 2);
        this.ctx.closePath();
        this.ctx.fillStyle = lineColor;
        this.ctx.fill();
        this.ctx.restore();
      }
    }
  };

  // Mouse event listeners
  let selectedSquare = null;
  let dragOffsetX = 0;
  let dragOffsetY = 0;

  canvas.addEventListener('mousedown', (event) => {
    const { offsetX, offsetY } = event;

    selectedSquare = squares.find(square =>
      offsetX >= square.x &&
      offsetX <= square.x + squareSize &&
      offsetY >= square.y &&
      offsetY <= square.y + squareSize
    );

    if (selectedSquare) {
      dragOffsetX = offsetX - selectedSquare.x;
      dragOffsetY = offsetY - selectedSquare.y;
    }
  });

  canvas.addEventListener('mousemove', (event) => {
    if (selectedSquare) {
      const { offsetX, offsetY } = event;
      selectedSquare.x = offsetX - dragOffsetX;
      selectedSquare.y = offsetY - dragOffsetY;
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawSquares();
      drawLines();
    }
  });

  canvas.addEventListener('mouseup', () => {
    selectedSquare = null;
  });

  let currentRow = 1; // Keeps track of the current row number
  canvas.addEventListener('dblclick', (event) => {
    console.log(774)
    const { offsetX, offsetY } = event;

    const lastSquare = squares[squares.length - 1];
    let newX;
    let newY = lastSquare.y;

    if (currentRow % 2 === 1) {
      newX = lastSquare.x + squareSize + 20; // Adjust the horizontal spacing as desired
      if (newX > maxFrameWidth) {
        newX = lastSquare.x;
        newY += squareSize + 20; // Adjust the vertical spacing as desired
        currentRow++;
      }
    } else {
      newX = lastSquare.x - squareSize - 20; // Adjust the horizontal spacing as desired
      if (newX < 0) {
        newX = lastSquare.x;
        newY += squareSize + 20; // Adjust the vertical spacing as desired
        currentRow++;
      }
    }

    const newColor = getRandomColor();
    const newSquare = this.createSquare(newColor, newX, newY);
    squares.push(newSquare);

    this.ctx.fillStyle = newColor;
    this.ctx.fillRect(newX, newY, squareSize, squareSize);

    drawLines();
  });

  // Utility function to generate a random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Initial square and line drawing
  drawSquares();
  drawLines();
}


private createSquare(color: string, x: number, y: number) {
  return { color, x, y };
}


}