import { Component } from '@angular/core';

@Component({
  selector: 'app-past-due',
  templateUrl: './past-due.component.html',
  styleUrls: ['./past-due.component.css']
})
export class PastDueComponent {

}
