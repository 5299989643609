/*
    This class is intended to handle string and number formatting in an international
    environment
*/

import { DataService } from '@services/data.service';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export class Internationalization {

  constructor() { 
    //this.dataService = new DataService(null)
  }

  /*
    France: +33 X XX XX XX XX
    Germany: +49 XXXX XXXXXXX
    Austria: +43 XXXX XXXXXX
    US: +1 (XXX) XXX-XXXX
  */

  formatNumberByCountryCode(digits, countryCode =''){

    if(digits[0] === '+'){
      // If it starts with a plus sign, we assume the first digits are a country code
      // and phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL) can handle it
      const phoneUtil = PhoneNumberUtil.getInstance();
      const parsedNumber = phoneUtil.parseAndKeepRawInput(digits);
      return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
    }

    digits = this.cleanPhoneNumber(digits);


    if(countryCode == ''){
      countryCode = this.detectCountry(digits)
    }

    if(countryCode === ''){
      // We need to do some additional checking.
    }

    if(countryCode == ""){
      let savedCC = localStorage.getItem('countryCode');
      countryCode = this.detectCountry(savedCC);

    }

    switch(countryCode){
    case 'na':
      return this.formatAnyNumber(digits, 'US');

    case 'ch':
      return this.formatAnyNumber(digits, 'CH');

    case 'de':
      return this.formatAnyNumber(digits, 'DE');

    case 'at':
      return this.formatAnyNumber(digits, 'AT');

    case 'fr':
      return this.formatAnyNumber(digits, 'FR');
    }
  }

  cleanPhoneNumber(phoneNumber: string): string {
      return phoneNumber.replace(/[^\d+]/g, '');
  }

  getCountryCodeDigits(countryCode =''){
    countryCode = countryCode.toLowerCase();

    switch(countryCode){
    case 'na':
      return '1';
    case 'de':
      return '49';
    case 'fr':
      return '33';
    case 'ch':
      return '41'
    case 'at':
      return '43'
    }
  }

  detectCountry(phoneNumber: string): string {
    const cleanedNumber = this.cleanPhoneNumber(phoneNumber);

    if (cleanedNumber.startsWith('+1')) {
        return 'na';
    } else if (cleanedNumber.startsWith('+49')) {
        return 'de';
    } else if (cleanedNumber.startsWith('+33')) {
        return 'fr';
    } else if (cleanedNumber.startsWith('+43')) {
        return 'at';
    } else if (cleanedNumber.startsWith('+41')) {
        return 'ch';
    } else {
    }

    let dialPadCountryCode = localStorage.getItem('countryCode');

    switch(dialPadCountryCode){
      case "+1":
        return 'na';
      case "+49":
        return 'de';
      case "+43":
        return 'at';
      case "+41":
        return "ch";
    }
  }

  formatNorthAmericaNumber(phoneNumber: string): string {
    const regexPattern = /^\+1 \d{3}-\d{3}-\d{4}$/;
    if (regexPattern.test(phoneNumber)) {
      return phoneNumber;
    }

    // Remove 1 or +1 if the country code is provided
    phoneNumber = phoneNumber.replace(/^(1|\+1)/, "");

    // Remove non-digit characters from the input
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Assuming cleanedPhoneNumber is now a 10-digit string
    const areaCode = cleanedPhoneNumber.substring(0, 3);
    const firstPart = cleanedPhoneNumber.substring(3, 6);
    const secondPart = cleanedPhoneNumber.substring(6);

    // Format: +1 XXX-XXX-XXXX
    return `+1 ${areaCode}-${firstPart}-${secondPart}`;
  }

  testNumbers = [
    '+49 3744 215308',
    '+49 3744 365670',
    '+49 3744 214164',
    '+49 3744 309313',
    '+49 3744 2550',
    '+49 34343 51341',
    '+49 34343 840850',
    '+49 162 6967767',
    '+49 34343 51321',
    '+49 34343 55700',
    '+49 34343 91917',
    '+49 3525 730326',
    '+49 34343 51304',
    '+49 351 89564034',
    '+49 351 8334555',
    '+49 37344 133970',
    '+49 3733 61160',
    '+49 35951 35006',
    '+49 37367 87952',
    '+49 37367 777240',
    '+49 37367 77457',
    '+49 37367 86484',
    '+49 37367 9695',
    '+49 37367 774979',
    '+49 37367 83828',
    '+49 37367 9822',
    '+49 35772 46710',
    '+49 35772 44665',
    '+49 37467 66580',
    '+49 37754 144270',
    '+49 37754 2969',
    '+49 37754 32236',
    '+49 375 671229',
    '+49 375 606950',
    '+49 375 671112',
    '+49 35723 928131',
    '+49 34364 88612',
    '+49 34298 49657',
    '+49 35264 90702',
    '+49 1525 4127525',
    '+49 3525 518585',
    '+49 35264 90811',
    '+49 35264 90783',
    '+49 35264 98166',
    '+49 3525 731866',
    '+49 35205 54718',
    '+49 35205 169074',
    '+49 35205 645503',
    '+49 35205 54134',
    '+49 37204 50200',
    '+49 35936 34304',
    '+49 35936 37502',
    '+49 35936 31174',
    '+49 35936 336227',
    '+49 35936 33420',
    '+49 35936 37396',
    '+49 35936 30081',
    '+49 35936 30291',
    '+49 1525 7967235',
    '+49 3761 4243000',
    '+49 3761 7118505',
    '+49 35823 86357',
    '+49 35823 86598',
    '+420 487 954 180',
    '+49 37342 149759',
    '+49 37342 14426',
    '+49 37342 72',
    '+49 37342 279997',
    '+49 37342 7686',
    '+49 37342 7937',
    '+49 37342 14303',
    '+420 483 367 303',
    '+420 487 954 111',
    '+49 3583 690432',
    '+49 3583 690410',
    '+49 3521 7433381',
    '+49 35842 2240',
    '+49 35842 27473',
    '+49 35842 25046',
    '+49 35842 26193',
    '+49 35841 6300',
    '+420 487 521 841',
    '+420 477 111 111',
    '+420 483 345 111',
    '+49 35872 133497',
    '+49 35872 32501',
    '+49 34342 51315',
    '+49 34342 51381',
    '+49 341 9724930',
    '+49 34342 52967',
    '+49 34342 51900',
    '+49 34342 51884',
    '+49 37600 2318',
    '+49 3721 23237',
    '+49 3721 3590806',
    '+49 3721 24263',
    '+49 3721 23500',
    '+49 371 2435690',
    '+49 3721 265041',
    '+49 371 52468130'
]

  formatGermanNumber(rawNumber: string): string {

    var formattedNumber = "";
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parse(rawNumber, 'DE'); // 'DE' is the country code for Germany
      formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    } catch (error) {
      console.error('Error parsing phone number:', error);
      formattedNumber = 'Invalid phone number';
    }

    return formattedNumber;

  }

  formatAnyNumber(rawNumber, countryCode){

    let countryCodeDigits = this.getCountryCodeDigits(countryCode);

    var cleanedNumber = rawNumber.replace(/\D/g, '');

    if(cleanedNumber.startsWith(countryCodeDigits)){
      cleanedNumber = cleanedNumber.substring(countryCodeDigits.length).trim();
    }

    var formattedNumber = "";
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parse(cleanedNumber, countryCode.toUpperCase());
      formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    } catch (error) {
      console.error('Error parsing phone number:', error);
      formattedNumber = 'Invalid phone number';
    }


    return formattedNumber;    
  }

  formatAustrianNumber(digits: string): string {
      // Austrian format: +43 XXX XXXXXXX
      // This is a general format; actual format may vary based on number type
      return digits.replace(/(\+43)(\d{3})(\d{4,6})/, '$1 $2 $3');
  }

  formatFrenchNumber(digits: string): string {
      // French format: +33 X XX XX XX XX
      // This assumes the standard 10 digit number (excluding the country code)
      return digits.replace(/(\+33)(\d)(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 $6');
  }


}
