import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

@Component({
  selector: 'app-redeemgiftcard',
  templateUrl: './redeemgiftcard.component.html',
  styleUrl: './redeemgiftcard.component.css'
})

export class RedeemgiftcardComponent implements OnInit {

  id: string | null = null;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  giftcard: any = {}

  brands = [];
  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    //console.log('ID from URL:', this.id);

    //var api_url = `api/public/giftcards/getGiftcard/`;

    //giftcard_id/${this.id}`
    //var result = await this.http.post(api_url, { giftcard_id: this.id }).toPromise();
    //console.log(22, result);
    //this.giftcard = result['giftcard'];

    var api_url = `api/public/giftcards/getBrands/`;

    //giftcard_id/${this.id}`
    var result = await this.http.post(api_url, { giftcard_id: this.id }).toPromise();
    console.log(22, result);
    this.brands = result['brands'];

  }

  redeemed: boolean = false;

  async redeem(amt: number, brand_id: string){
    // You can add logic here to handle the redemption, e.g., calling a service.
    //console.log(`Redeeming a $${amt} gift card`, brand_id);

    // Set the redeemed flag to true to hide the gift cards and show the confirmation message.
    //this.redeemed = true;
    var api_url = `api/public/giftcards/redeem/giftcard_id/${this.id}/brandId/${brand_id}`;

    //giftcard_id/${this.id}`
    var result = await this.http.post(api_url, { giftcard_id: this.id }).toPromise();

    window.open(result['gift_link'], "_blank");

    console.log(59, result);

  }

}
