<div class="container" style="width: 900px;" [style.max-width]="maxWidth">
    <div class="position-relative" id="mainContainer">
        <button type="button" class="btn top-0 start-0 m-2" (click)="closePanelAction($event)">
            &#x2715;
        </button>
        <!-- Expand Button -->
        <!-- <button type="button" (click)="expandContent()">{{btnText | translate }}</button>&nbsp; -->

        <input type="checkbox" [checked]="openMap" (change)="toggleMap($event)" />&nbsp;{{"Open Map" | translate }}
        <input type="checkbox" [checked]="fullReset" (change)="toggleReset($event)" />&nbsp;{{"Full Reset" | translate }}
        <app-info-accordion [result]="result" [fullReset]="fullReset" [openMap]="openMap"></app-info-accordion>

    </div>
</div>
