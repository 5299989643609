import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "@app/shared.service";
import { DataService } from "@services/data.service";
import { TranslateService } from '@ngx-translate/core';

declare var google: any;

@Component({
  selector: 'app-info-full-page',
  templateUrl: './info-full-page.component.html',
  styleUrls: ['./info-full-page.component.css']
})

export class InfoFullPageComponent implements OnInit, AfterViewInit {
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  @ViewChild('map2', { static: true }) mapElement2: ElementRef;
  map: any;
  map2: any;

  leadInfo: any = { };

  streetViewService: google.maps.StreetViewService;
  panorama: google.maps.StreetViewPanorama;

  currentLanguageLabel: string = '🇺🇸 English'; // Default language

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private dataService: DataService,
    private translate: TranslateService,
  ) {}

  bStreetViewResults = true;
  ngOnInit() {
    this.getFirstContactId();
    this.dataService.loadNextContact().subscribe(next_contact => {
      console.log(34, "next contact", next_contact)
      this.leadInfo = next_contact["coldleads"];
       this.map2.setCenter({ lat: this.leadInfo.scrape_result.latitude, lng: this.leadInfo.scrape_result.longtitude });
        this.map.setCenter({ lat: this.leadInfo.scrape_result.latitude, lng: this.leadInfo.scrape_result.longtitude });

              const radius = 50; // Search within 50 meters of the specified location.

      // Attempt to find a street view panorama
      // this.streetViewService.getPanorama({
      //   location: this.map.getCenter(),
      //   radius: radius
      // }, this.processSVData.bind(this));

      this.bStreetViewResults = true;
      this.getPanorama(500);

      console.log(47, this.leadInfo?.scrape_result?.images)

      
    })

    this.dataService.notifyOfRefreshRequest().subscribe( bRefreshRequest => {
        console.log(58, "Table Refresh Requested");
        this.getFirstContactId();
    })

    this.loadLanguagePreference();
  }

  async getFirstContactId(){
    let aggregate =  [
      {
        $match: { 
            $and: [
                {
                    $or: [  
                        { cooldown: { $exists: false } },
                        { cooldown: { $lte: '$$$NOW' } }
            ]
        },
        { agent_id: '$user' },
        { archived: { $exists: false } }
      ]
    }
   }
   ];

    let results = await this.sharedService.aggregate("coldleads", aggregate, 1, "account");

    //return results["_id"];
    let contact_id = results["_id"];
    this.leadInfo = results;
    this.dataService.openPanel(contact_id)
    console.log(34, this.leadInfo)
    this.initMap(); // Now initializing the map after view is fully initialized
    setTimeout( () => {
      this.initMap2();  
    }, 2000)

  }

  getPanorama(radius){
      this.streetViewService.getPanorama({
        location: this.map.getCenter(),
        radius: radius
      }, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          this.processSVData(data, status);
        } else {
          this.bStreetViewResults = false;
          console.error('Failed to fetch Street View data:', status);
          // Handle the error accordingly, e.g., notify the user or attempt a retry
        }
      });
  }

  async loadContact(contact_id){

        try {
          let result: any = await this.http
            .get(`api/datasource/coldleads/id/${contact_id}`).toPromise();

            this.leadInfo = result["coldleads"];
            this.dataService.openPanel(contact_id)
            console.log(34, this.leadInfo)
            this.initMap(); // Now initializing the map after view is fully initialized
            setTimeout( () => {
              this.initMap2();  
            }, 2000)
            
        } catch(err){

        }
  }

  ngAfterViewInit() {
    this.loadGoogleMapsScript().then(() => {
      console.log(26, "ngAfterViewInit");
      //this.loadContact('662178596daa709614673504');
    }).catch(error => console.error('Error loading Google Maps:', error));

   
  }

  private loadLanguagePreference(): void {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    if (selectedLanguage && languageLabel[selectedLanguage]) {
      this.currentLanguageLabel = languageLabel[selectedLanguage];
    }

    this.translate.setDefaultLang(selectedLanguage);


    // Add any additional initialization logic here for applying the stored language preference
  }

  async loadNextContact(id){
    console.log(417, id)
    let next_contact = await this.http.get(`api/agents/getNextContact/contact_id/${id}`).toPromise();    
    console.log(419, next_contact);

    //this.loadContact(next_contact["coldleads"]["_id"], next_contact);
    this.dataService.setNextContact(next_contact);

  }


loadGoogleMapsScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (typeof google !== 'undefined' && google.maps) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCAD5wq5OhfSviTUeiYzcAgZtieva3m8V8&libraries=places'; // Removed callback=initMap
    document.head.appendChild(script);
    script.onload = () => resolve();
    script.onerror = (e) => reject(e);
  });
}

  initMap(): void {
    if (this.mapElement) {
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        center: { lat: this.leadInfo.scrape_result.latitude, lng: this.leadInfo.scrape_result.longtitude },
        zoom: 25,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      this.streetViewService = new google.maps.StreetViewService();
      const radius = 50; // Search within 50 meters of the specified location.

      // Attempt to find a street view panorama
      this.streetViewService.getPanorama({
        location: this.map.getCenter(),
        radius: radius
      }, this.processSVData.bind(this));
    } else {
      console.error('Map element not yet available.');
    }
  }

  initMap2(): void {
    console.log(92)
    if (this.mapElement2) {
      this.map2 = new google.maps.Map(this.mapElement2.nativeElement, {
        center: { lat: this.leadInfo.scrape_result.latitude, lng: this.leadInfo.scrape_result.longtitude },
        zoom: 22,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        tilt: 0
      });

    // Ensure the map is resized once it is visible
    setTimeout(() => {
      google.maps.event.trigger(this.map2, 'resize');
      // Re-center the map after resize
      this.map2.setCenter({ lat: this.leadInfo.scrape_result.latitude, lng: this.leadInfo.scrape_result.longtitude });
    }, 500); // Adjust timeout as needed

    } else {
      console.error('Map element not yet available.');
    }
  }

  processSVData(data, status) {
    if (status === google.maps.StreetViewStatus.OK) {
      this.panorama = this.map.getStreetView();
      this.panorama.setPosition(data.location.latLng);
      this.panorama.setPov({
        heading: 270,
        pitch: 0
      });
      this.panorama.setVisible(true);
    } else {
      console.error('Street View data not found for this location.');
    }
  }
}
