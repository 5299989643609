import { Component } from '@angular/core';

@Component({
  selector: 'app-permissions-cell',
  templateUrl: './permissions-cell.component.html',
  styleUrls: ['./permissions-cell.component.css']
})
export class PermissionsCellComponent {

}
