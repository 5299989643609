import { Component } from '@angular/core';

@Component({
  selector: 'app-office-stats',
  templateUrl: './office-stats.component.html',
  styleUrls: ['./office-stats.component.css']
})
export class OfficeStatsComponent {

}
