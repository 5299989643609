import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
@Component({
  selector: 'app-do-not-call',
  templateUrl: './do-not-call.component.html',
  styleUrl: './do-not-call.component.css'
})

export class DoNotCallComponent {

  domains ="";
  phoneNumbers ="";

  constructor(public http: HttpClient) { }

  async save(){
    await this.http.post(`/api/administration/data-cleanup/doNotCall`, {
      "domains":this.domains,
      "phoneNumbers": this.phoneNumbers
    }).toPromise();
  }

}
