import { Component, HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from "./legacy/base/base.component"
import { BaseService } from "./legacy/base/base.service"
import { filter } from 'rxjs/operators';
import { SharedService } from './shared.service'

import { io } from "socket.io-client";
import { WebsocketService } from '@websocket'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'saas-product';

  publicRoutes = ["register", "login"];

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent) {
    console.log('Escape key pressed', event);
    // Add your custom logic here
    //this.sharedService.sendCmd({ "cmd":"disconnect"})
  }

private idleTimeout: any;
private inactiveTimeout: any;
private idleTimeLimit: number = 1000 * 60 * 15; // 15 minutes
private inactiveTimeLimit: number = 1000 * 60 * 60; // 60 minutes in milliseconds
private bIsIdle = false;
private bIsInactive = false;

@HostListener('document:mousemove')
@HostListener('document:keydown')
@HostListener('document:mousedown')
@HostListener('document:touchstart')
resetTimers() {
  // Handle idle state reset
  if (this.bIsIdle) {
    this.bIsIdle = false;
    console.log('User is no longer idle');
    this.sharedService.sendCmd({ "cmd": "noLongerIdle" });
  }

  // Handle inactive state reset
  if (this.bIsInactive) {
    this.bIsInactive = false;
    console.log('User is no longer inactive');
    this.sharedService.sendCmd({ "cmd": "noLongerIdle" });
  }

  this.clearTimers();
  this.idleTimeout = setTimeout(() => this.onIdle(), this.idleTimeLimit);
  this.inactiveTimeout = setTimeout(() => this.onInactive(), this.inactiveTimeLimit);
}

clearTimers() {
  if (this.idleTimeout) {
    clearTimeout(this.idleTimeout);
  }
  if (this.inactiveTimeout) {
    clearTimeout(this.inactiveTimeout);
  }
}

private onIdle() {
  console.log('User is idle');
  this.sharedService.sendCmd({ "cmd": "onIdle" });
  this.bIsIdle = true;
}

private onInactive() {
  console.log('User is inactive');
  this.sharedService.sendCmd({ "cmd": "onInactive" });
  this.bIsInactive = true;
}


ngOnInit() {
  this.resetTimers(); // Reset both idle and inactive timers
}

ngOnDestroy() {
  this.clearTimers(); // Clear both idle and inactive timers
}

  constructor(cookieService: CookieService, private router: Router, private sharedService: SharedService, private websocketService: WebsocketService) {

    const currentPath = this.router.url;

    //this.initSocket();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentPath = event.urlAfterRedirects;
      const queryParams = new URLSearchParams(currentPath.split('?')[1]);


      let currentPathStr = currentPath.split('?')[0].replace(/^\/|\/$/g, '');

      const queryParamsObject = {};
      currentPath.split('?')[1]?.split('&').forEach(param => {
        const [key, value] = param.split('=');
        queryParamsObject[key] = value;
      });

      const cookieExists: boolean = cookieService.check('Authorization');
      if (cookieExists || this.publicRoutes.includes(currentPathStr)) {
        //this.router.navigate(['/' + currentPathStr], { queryParams: queryParamsObject });
      } else {

        // 6/9/24 -- I'm getting complains about people being logged out, so I am going to temporarily disable this
        //this.router.navigate(['/login'], { queryParams: queryParamsObject });
      }

    });

    return;

    }

//     private socket;

//     public initSocket(): void {
    
//         console.log(61, "Init Socket Called");

//         this.socket = io(window.location.origin, { path: '/socket.io' });
        
//         this.socket.on('connect', ($socket) => {
//             this.websocketService.notifyConnected();
//         });

//         this.socket.on('disconnect', () => {
//             console.log('Websocket Disconnected');
//             this.websocketService.notifyDisconnected();
//         });

//         this.socket.on('error', (error: any) => {
//             console.error('Websocket Error:', error);
//         });

//         this.socket.on('data', (data: any) => {
//             console.log(78, data);
//             this.websocketService.sendData(data);
//         });

//         this.socket.on('request_call_status', (data: any) => {
//             this.websocketService.updateUserOnCallStatus(data);
//         });

//         this.websocketService.getCallStatus().subscribe(bOnCallStatus => {

//           const encodedStr = this.getCookie('Authorization');
//           const base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
//           const decodedStr = atob(base64Str);
//           const authStr = decodedStr.split(':')[0];
//           let user_id = authStr.split('@%40@')[1];
//           this.socket.emit("set_call_status", { user_id: user_id, bOnCallStatus: bOnCallStatus } );

//         });

//         this.websocketService.getCallStatusUpdates().subscribe(user_idsAr => {
//             //console.log(94, "Request to get updates on these users", user_idsAr)
//             this.socket.emit("request_call_status", { "user_ids": user_idsAr } );
//         })

//         this.websocketService.getCmds().subscribe(data => {

//             // This is a passthrough function to send websocket data to the server
//             // the data object is expected to be in a format that the server can parse 
//             // and understand
//             this.socket.emit(data["cmd"], data["filter"]);
//         });

//     }

// getCookie(name: string): string | null {
//     const nameEQ = name + "=";
//     const ca = document.cookie.split(';');
//     for(let i=0;i < ca.length;i++) {
//         let c = ca[i];
//         while (c.charAt(0)==' ') c = c.substring(1,c.length);
//         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
//     }
//     return null;
//   }

  }












