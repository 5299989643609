import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '@network-request-button/network-request-button.component'
import { FlextableComponent } from "@flextable/flextable.component"
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'app-office-management',
  templateUrl: './office-management.component.html',
  styleUrls: ['./office-management.component.css']
})
export class OfficeManagementComponent implements OnInit {

  constructor(private renderer: Renderer2, public http: HttpClient, private sharedService: SharedService) {}

  endpoint = "/api/datasource/offices";

  payload: any = {
    officeCountry: '',
    officeAddress: '',
    officeLanguage: '',
    officeCity: ''
  }

  countries = [];
  languages = [];

  async ngOnInit() {
    this.sharedService.getCountries().subscribe(data => this.countries = data);
    this.sharedService.getLanguages().subscribe(data => this.languages = data);

    console.log(32, this.languages)
  }

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('submitForm') submitForm: NetworkRequestButtonComponent;
  @ViewChild('flextable') flextable: FlextableComponent;

  verb = "POST";

  buttonAction = "Add Office"
  rowLinkClicked($event){
    
    this.buttonAction = "Save"
    this.payload = { ... $event.rowData }    
    console.log(45, this.payload);
    this.verb = "PUT"

    this.endpoint = "/api/datasource/offices/id/" + this.payload["_id"];
    this.openModal.nativeElement.click();
  }

  async waitForResponse(response: any){

    if(response == false){
      console.error("Request Failure");
      return;
    }

    this.dismissModal();

    this.flextable.refreshTable();
  }

  headerButtonClicked(event: any){
    this.openModal.nativeElement.click();
  }

  tableButtonClicked(requestResult: any){
    alert( JSON.stringify(requestResult ) );
  }

  dismissModal() {
    const modalElement: HTMLElement = this.myModal.nativeElement;
    this.renderer.selectRootElement(modalElement, true).dispatchEvent(new Event('click'));
  }

}
