<!-- <mat-form-field>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date" (dateInput)="onDatesChanged()" [formControl]="startDate">
    <input matEndDate placeholder="End date" (dateInput)="onDatesChanged()" [formControl]="endDate">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
 -->

<input #datepickerInput type="text" value="Select Date" class="form-control">