   <app-flextable #flextable
   (headerButtonClicked)='headerButtonClicked($event)'
   (rowTableBtnClicked)='tableButtonClicked($event)'
   (rowLinkClicked)='rowLinkClicked($event)'
   (rowDeletedEvent)='rowDeletedEvent($event)'
   [options]="
   {
   datasource: 'coldleads',

   'all':'all',

   manualData: true,

   useProgressBar: true,
         
   rowButtons: [
   {
   'column': 'name',
   'buttonName': 'Call',
   'api': '',
   'action': 'call'
   }
   ],
   bAllowDeletion: false,
   filter: {
   visible: {
   $ne: false 
   }, 
   archived: { $ne: true }
   }, 
   columns: [
   'name',
   'phone',
   'city',
   'state',
   'category',
   'images',
   ],
   linkColumnPreventDefault: true,
   linkColumns: [ {
      column: 'Name',
      path: '/main/sysadmin/planmanagement/plan/'
      }
      ],
      iconColumns: [ {
      column: 'images',
      icon: 'edit',
      }
      ],
      hiddenColumns: {
         'Images': { header: true }
      },
      scope: 'account',
      aggregate: [
      {
      $match: { 
      $and: [
      {
      $or: [  
      { cooldown: { $exists: false } },
      { cooldown: { $lte: '$$NOW' } }
      ]
      },
      { agent_id: '$res.locals.user._id' },
      { archived: { $exists: false } }
      ]
      }
   },
   {
   $project: {
      _id: 1,
      borough: '$scrape_result.complete_address.borough',
      phone: '$scrape_result.phone',
      street: '$scrape_result.complete_address.street',
      city: '$scrape_result.complete_address.city',
      postal_code: '$scrape_result.complete_address.postal_code',
      state: '$scrape_result.complete_address.state',
      country: '$scrape_result.complete_address.country',
      name: '$scrape_result.title',
      category: '$scrape_result.category',
      images: {
      $map: {
      input: '$scrape_result.images',
      as: 'imageObj',
      in: '$$imageObj.image'
      }
      },
      timezone: '$scrape_result.timezone'
      }
      }
   ]
   }"
   ></app-flextable>
