import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-call-search',
  templateUrl: './call-search.component.html',
  styleUrl: './call-search.component.css'
})
export class CallSearchComponent  implements OnInit {

  @ViewChildren('audioPlayers') audioPlayers: QueryList<ElementRef>;
  @ViewChildren('playIcons') playIcons: QueryList<ElementRef>;

currentAudioFile = "";
loadAudioFile = false;
tracks = Array.from({ length: 200 }, () => ({ url: '' }));

 private paramsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private dataService: DataService, public http: HttpClient) { }

  all_calls = [];
  ngOnInit(){
    console.log(23, "init");
    const id = this.route.snapshot.params['search'];
    this.getAllCalls(id);

    this.paramsSubscription = this.route.params.subscribe(params => {
      const searchId = params['search']; // Adjust 'searchId' based on your route configuration
      this.getAllCalls(searchId);
    });

  }

  async getAllCalls(id){
    var api_url = 'api/administration/searchAllCalls/digits/' + id;

    let all_calls = await this.http.get(api_url).toPromise();  
    for(var call of all_calls['all_calls']){
      call['from'] = this.dataService.formatPhoneNumber(call['from']);
      call['to'] = this.dataService.formatPhoneNumber(call['to']);
    }
    this.all_calls = all_calls["all_calls"]


  }


  async searchNumber(phoneNumber){
    

  //   var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.phone" + '/' + phoneNumber;

  //   //this.router.navigate([`main/search`]);
  //   let result = await this.http.get(api_url).toPromise();
  //   this.sharedService.updateSearchResults(result, phoneNumber);
  }

  async playAudio(call_session_id, index){

    var api_url = `api/calls/getCallRecordingLink/call_record_id/${call_session_id}`;

    let audio_link = await this.http.get(api_url).toPromise();  

    try {
      let download_urls = audio_link["download_urls"];
      let keys = Object.keys(download_urls);

      var audioPlayerRef = this.audioPlayers.toArray()[index];
      var url = download_urls[keys[0]];

      this.tracks[index].url = url;
    } catch(err) {
        console.log(76, err);
        return;
    }

    if (audioPlayerRef && audioPlayerRef.nativeElement) {
      // Check if source is already set, if not, set it here and load

      if (!audioPlayerRef.nativeElement.src || audioPlayerRef.nativeElement.src !== this.tracks[index].url) {
        audioPlayerRef.nativeElement.src = this.tracks[index].url;
        audioPlayerRef.nativeElement.load();
      }
      // Attempt to play

      audioPlayerRef.nativeElement.play().then(() => {
        console.log('Playback started successfully');
      }).catch(e => {
        console.error('Playback failed:', e);
        // Handle or log the error, possibly retry or provide user feedback
      });
    }

  }

  onPause(index){
    var audioPlayerRef = this.audioPlayers.toArray()[index];
  }

  onEnded(index){
    var audioPlayerRef = this.audioPlayers.toArray()[index];
  }

  onPlay(index){
    var audioPlayerRef = this.audioPlayers.toArray()[index];
  }

}