import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css'
})

export class AdminReportsComponent {

  reportMatchStage = []
  selectedReport:any = {};
  showAlert: boolean = false;
  selectedDates: any = null;
  constructor(private http: HttpClient) { }

  onDateRangeChanged($event){
    console.log(19, $event);
    this.selectedDates = $event;
    this.results = null;
  }

  onCompanySelected($event){
    console.log(16, $event);
    let matchElement = { company_id: $event}
    this.reportMatchStage.push(matchElement);
    this.results = null;
  }

  onOfficeSelected($event){
    console.log(20, $event);
    let matchElement = { office_id: $event}
    this.reportMatchStage.push(matchElement);
    this.results = null;
  }

  onTeamSelected($event){

    let matchElement = { team_id: $event}
    this.reportMatchStage.push(matchElement);
    this.results = null;
  }

  onReportSelected($event){

    this.selectedReport = $event;
    this.results = null;
  }

  onDatesSelected($event){
    console.log(32, $event);
    this.results = null;
  }

  results: any = null;

  alertTitle = "Please select a report to generate."
  salesCommissionPercentage = 0.05
  resalesCommissionPercentage = 0.025

  async generateReport() {

    var report = '';
    if(this.selectedReport !== null){
      report = this.selectedReport.reportName;
      this.showAlert = false;
    } else {
      this.showAlert = true;
      return;
    }

    if(this.selectedDates !== null){
      this.showAlert = false;
    } else {
      this.alertTitle = "Please Select Dates"
      this.showAlert = true;
      return;
    }

    let url = `/api/administration/reports/${report.toLowerCase()}Report`
    console.log(74, this.selectedDates);
    this.results = await this.http.post(url, { "matchStage": this.reportMatchStage, ... this.selectedDates, saleCommission: this.salesCommissionPercentage, resaleCommission: this.resalesCommissionPercentage }).toPromise();

    
    console.log(56, url);
      // if (this.selectedReport) {
      //   // Logic to generate the report based on the selected report
      //   console.log('Generating report:', this.selectedReport);
      // } else {
      //   console.log('Please select a report to generate.');
      // }
  }


}
