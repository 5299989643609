<div>
	<button 
	class="button" 
	name=""
    id="" 
    type="button"
    (click)="toggleTimeTracking()"
    >
     {{btnTitle}}
    </button>
    Time: {{time}}
</div>