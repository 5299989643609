import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies2',
  templateUrl: './cookies2.component.html',
  styleUrls: ['./cookies2.component.css']
})
export class CookiesComponent {

	links = [];

	getLink(){
		
	}

}
