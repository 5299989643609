import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Internationalization } from '@internationalization'
@Injectable({
  providedIn: 'root'
})

/*
    Messages and Notifications are real-time events that we may want to notify the user as they happen.
    There are multiple ways to accomplish this, but the two most common are polling and websockets.  

    I decided against websockets at this time to minimize the complexity of the framework.  I may
    revisit the issue in the future, but for now we're going to use polling.  

    For my purposes, polling every 60 seconds is more than sufficient.  Adjust up or down based
    on the use cases, but this isn't intended to be used as a chat so I can't imagine many use cases
    where you need more frequent updates than that.  If you do need real time or near-real time, I
    might suggest implementing websockets if this hasn't been done already.
*/

export class DataService {

  private triggerApiRequestSource = new Subject<void>();
  triggerApiRequest$ = this.triggerApiRequestSource.asObservable();

  triggerRequest() {
    this.triggerApiRequestSource.next();
  }
  
  private sharedData: any;

  private userPlan: String = 'free'

  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();

  private pollFrequency: number = 60000; // The time, in milliseconds, to poll.  

  private intlNumber: Internationalization;

  constructor( private http: HttpClient) { 
    this.intlNumber = new Internationalization()
  }

  // Any notifications
  private notificationsAr: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public publicNotificationsAr$: Observable<any> = this.notificationsAr.asObservable();

  // Any messages
  private messagesAr: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public publicMessagesAr$: Observable<any> = this.messagesAr.asObservable();

  // Display First / Last Name
  private fName: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private lName: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public public_fName$: Observable<any> = this.fName.asObservable();
  public public_lName$: Observable<any> = this.lName.asObservable();

  // Points and Account Balance
  private pointsBalance: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private cashBalance:  BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public public_pointsBalance$: Observable<any> = this.pointsBalance.asObservable();
  public public_cashBalance$: Observable<any> = this.cashBalance.asObservable();

  // Account Type
  private accountType: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public public_accountType$: Observable<any> = this.accountType.asObservable();

  private first_name = "";
  private last_name = "";

private loginInfo = new BehaviorSubject<any>(null);

  getUserInfo() {
    return this.loginInfo.asObservable();
  }

  setUserInfo(userInfo: any){

    this.loginInfo.next(userInfo);
  }

  setNames(fName: string, lName: string, accountType: string){
    this.fName.next(fName);
    this.lName.next(lName);
    this.first_name = fName;
    this.last_name = lName;
    this.accountType.next(accountType);

  }

  getFullName(){
    return `${this.first_name} ${this.last_name}`
  }

  setBalances(balance: number, points: number){
    this.pointsBalance.next(points);
    this.cashBalance.next(balance);
  }

  async refreshNotifications(){
      const notifications: any = await this.http.get('/api/notifications').toPromise();
      this.notificationsAr.next(notifications);

      this.setPlan(notifications['plan']);
      this.setAttachedPaymentMethod(notifications['paymentMethodAttached'])

      const messages: any = await this.http.get('/api/messages').toPromise();
      this.messagesAr.next(messages)
  }

  paywallStartDate: Date = new Date(2023, 8, 15);

  // Intended to be called once
  startPolling() {
    return false;
    
    setInterval( async () => {

      const notifications: any = await this.http.get('/api/notifications').toPromise();
      this.notificationsAr.next(notifications);

      this.setPlan(notifications['plan']);
      this.setAttachedPaymentMethod(notifications['paymentMethodAttached']);
      this.setPaywallStart(notifications['paywallStartDate'])

      const messages: any = await this.http.get('/api/messages').toPromise();
      this.messagesAr.next(messages)

      // Update the notifications and messages array...  
    }, this.pollFrequency);
  }
  // The profile name of the user
  // private profileName: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // public publicProfileName$: Observable<any> = this.profileName.asObservable();
  
  setPaywallStart(startDate: string){
    if(this.bPaywallDateSet === false){
      this.paywallStartDate = new Date(startDate);
      this.bPaywallDateSet = true;
    }
    
  }

  bPaywallDateSet = false;
  getPaywallStart(){
    if(this.bPaywallDateSet === true){
      return this.paywallStartDate;
    }
    return null;
  }

  setSharedData(data: any) {
    this.sharedData = data;

    //this.updateProfileName("Adam Arthur")
  }

  getSharedData(): any {
    //console.log(32)
    //this.updateProfileName("Adam Arthur");
    return this.sharedData;
  }

  // updateProfileName(profileName: any){
  //   console.log(35, "Updating Profile Name", profileName);
  // 	this.profileName.next(profileName);
  // }

  updateData(data: any) {

    this.dataSubject.next(data);
    //this.dataSubject.next(data);

    
    //this.updateProfileName("Adam Arthur")
  }

  private chatButtonClickSubject = new Subject<void>();

  notifyChatButtonClick(): void {
    this.chatButtonClickSubject.next();
  }

  getChatButtonClickObservable() {
    return this.chatButtonClickSubject.asObservable();
  }

  setPlan(userPlan: String){

    this.userPlan = userPlan
  }

  getPlan(){
    return this.userPlan;
  }

  bPaymentMethodAttached: Boolean = false;

  isPaymentMethodAttached(){
    return this.bPaymentMethodAttached;
  }

  setAttachedPaymentMethod(isPaymentMethodAttached: Boolean){
    this.bPaymentMethodAttached = isPaymentMethodAttached;
  }

  getProductName(){
    return environment.productName;
  }

  isPhoneNumber(input) {

      let startsWithPlus = input.startsWith('+');
      
      if (startsWithPlus) {
          return true; // Immediately return true if it starts with '+'
      }
      
      // Test the input against valid characters regex
      const validCharactersRegex = /^[+\d\s().-]+$/;
      let isValidCharacters = validCharactersRegex.test(input);
      
      return isValidCharacters; // Return the result of the regex test
  }

  selectedCountryCode: any = "";
  setCountryCode(cc){
    this.selectedCountryCode = cc;
    localStorage.setItem('countryCode', cc);
  }

  getCountryCode(){
    console.log(218, this.selectedCountryCode);
    return localStorage.getItem('countryCode')
  }

  formatPhoneNumber(phoneNumber: string): string {

    return this.intlNumber.formatNumberByCountryCode(phoneNumber);

  }

  async placeOrder(cartItems, customer, userInfo, company_id, warehouse_id, selectedDisposition ="Sale", selectedUser ="", selectedUserName =""){

    if(cartItems.length == 0){
      alert("You cart cannot be empty to place an order");
      return;
    }

    let total = 0;

    for(var item of cartItems){
      total += (item.qty * item.product_unit_price)
    }

    let salesPerson = userInfo.firstName + " " + userInfo.lastName;

    console.log(265, selectedDisposition, selectedUser);

    let order = { 
        "customer_id": customer._id, 
        "order": cartItems, 
        title: customer.scrape_result.title, 
        address: customer.scrape_result.address, 
        contactFirstName: customer.contactFirstName, 
        contactLastName: customer.contactLastName, 
        contactEmail: customer.contactEmail, 
        contactPhone: customer.scrape_result.phone, 
        salesAgent: salesPerson,
        totalValue: total,
        "approved":"pending",
        "invoice":"0",
        company_id: company_id,
        warehouse_id: warehouse_id,
        orderType: selectedDisposition
    }

    if(selectedUser !== ""){
      if(selectedDisposition == "Resale"){
        order["resale_user_id"] = selectedUser        
      }
    }

    if(selectedUserName !== ""){
      if(selectedDisposition == "Resale"){
        order["resale_user_name"] = selectedUserName        
      }
    }

    console.log(294, order);
    
    let call_recording_info = await this.http.post(`api/datasource/orders`, order).toPromise();

    //console.log(273, call_recording_info);

    this.addToArray("coldleads", customer._id, "orders", call_recording_info['orders']['inserted_id']);
    this.patchDocument("coldleads", customer._id, "status", "customer");
  }

  async updateOrder(orderInfo){
     let updatedOrderInfo = await this.http.post(`api/administration/updateExistingOrder`, orderInfo).toPromise();
     this.refreshMyOrders();
  }

  async addToArray(datasource, id, key, value, pluralize = false) {
    var api_url = 'api/datasource/' + datasource + '/array' + '/id/' + id;

    if (pluralize == true)
      api_url += "/pluralize";

    let result = await this.http.post(api_url, { "key": key, "value": value }).toPromise()

  }

  async patchDocument(datasource, id, key, value, pluralize =false) {
    var api_url = 'api/datasource/' + datasource  + '/id/' + id;

    if (pluralize == true)
      api_url += "/pluralize";

    let result = await this.http.patch(api_url, { "key": key, "value": value }).toPromise()


  }

    private incomingCallData = new Subject<any>();

    setIncomingCall(incomingCallData){
      console.log(282, "incoming data set");
      this.incomingCallData.next(incomingCallData);
    }

    getIncomingCall(){
      return this.incomingCallData.asObservable();
    }

    private unMatchedIncomingCall = new Subject<any>();
    openUnmatchedIncomingCallModal(incomingCallNumber){
      console.log(292, "Incoming Call Number", incomingCallNumber);
      this.unMatchedIncomingCall.next(incomingCallNumber);

    }

    getUnmatchedIncomingCall(){
      return this.unMatchedIncomingCall.asObservable();
    }

    private bResetDialPad = new Subject<any>();
    resetDialPad(bState){
      this.bResetDialPad.next(bState);
    }

    getDialpadReset(){
      return this.bResetDialPad.asObservable();
    }

    private openInfoPanel = new Subject<any>();

    openPanel(rowId){
      this.openInfoPanel.next(rowId);
    }

    closePanelAction(){
      this.openInfoPanel.next(false);
    }

    newPanelId(){
      return this.openInfoPanel.asObservable();
    }

    private bRefreshTable = new Subject<boolean>();
    refreshTable(){

      this.bRefreshTable.next(true)
    }

    notifyOfRefreshRequest(){
      return this.bRefreshTable.asObservable();
    }

    private orderCustomer: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    getOrderCustomer() {
      return this.orderCustomer.asObservable();
    }

    // Function to update the order customer
    updateOrderCustomer(customer: any) {
      this.orderCustomer.next(customer);
    }

    private orderCustomerId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    editOrder(customer_id: any) {
      this.orderCustomerId.next(customer_id);
    }

    getEditOrder() {
      return this.orderCustomerId.asObservable();
    }

    private bRefreshMyOrders = new Subject<boolean>();
    updateMyOrders(){
      return this.bRefreshMyOrders.asObservable();
    }

    refreshMyOrders(){
      console.log(401, "Setting bRefreshMyOrders as true")
      this.bRefreshMyOrders.next(true);
    }

    // Function to clear the order customer
    clearOrderCustomer() {
      this.orderCustomer.next(null);
    }

    private bAutoDial = new Subject<boolean>();
    notifyAutoDialRequest(){
      return this.bAutoDial.asObservable();
    }

    autoDialNextNumber(){
      this.bAutoDial.next(true)
    }

    private loadContact = new Subject<any>();
    setNextContact(contact: any){
      return this.loadContact.next(contact)
    }

    loadNextContact(){
      return this.loadContact.asObservable();
    }

    private popupMessage = new Subject<any>();
    setPopupMessage(message: any){
      return this.popupMessage.next(message);
    }

    loadPopupMessage(){
      return this.popupMessage.asObservable();
    }

    private appointmentMessage = new Subject<any>();

    setAppointmentMessage(message: any){
      return this.appointmentMessage.next(message);
    }

    loadAppointmentMessage(){
      return this.appointmentMessage.asObservable();
    }

    setDialerNumber(formattedNumber: any){
      return this.dialerNumber.next(formattedNumber)
    }

    private dialerNumber = new Subject<any>();
    getDialerNumber(){
      return this.dialerNumber.asObservable();
    }
    
    setDialedNumber(formattedNumber: any){
      return this.dialedNumber.next(formattedNumber)
    }

    private dialedNumber = new Subject<any>();
    getDialedNumber(){
      return this.dialedNumber.asObservable();
    }

    private editUser = new Subject<any>();
    getEditUserInfo(){
      return this.editUser.asObservable();
    }

    setEditUserInfo(userInfo: any){
      this.editUser.next(userInfo);
    }

    private bPanelExpanded = new Subject<any>();
    togglePanelSize(bPanelExpanded: any){
      this.bPanelExpanded.next(bPanelExpanded)
    }

    getTogglePanelSize(){
      return this.bPanelExpanded.asObservable();
    }
}
