<div [style.visibility]="isVisible ? 'visible' : 'hidden'">
  <div class="backdrop"></div>
  <div class="map" [cdkDragDisabled]="draggingDisabled" cdkDrag (cdkDragMoved)="onDragMoved($event)">
    <div cdkDragHandle class="map-header">
      <button class="btn" (click)="toggleVisibility()">Hide</button>
    </div>
    <div class="col mapcol">
      <div id="map" style="height: 800px; width: 100%;">
        <div style="max-height:420px; overflow-y: auto;">
        <div class="card-body bg-light">

<div class="container py-4">
    <div class="row">
        <div class="col">
            <div class="alert alert-warning" role="alert">
                <h4 class="alert-heading">No exact match found!</h4>
                <h2>{{incomingCallNumber | formatPhone}}</h2>
                <p>Please try searching our database to find the caller information.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="search-bar w-75">
		        <form class="search-form d-flex align-items-center" method="POST" action="#" (ngSubmit)="searchFunction()">
		            <!-- <label for="citySearchInput" class="form-label me-2">City:</label> -->
		            <input [(ngModel)]="searchTerm" (input)="searchFunction()" type="text" id="citySearchInput" name="query" placeholder="Search by name" title="Enter title to search" class="form-control" />
		            <button type="submit" title="Search" class="btn"><i class="bi bi-search"></i></button>
		        </form>
            </div>
        </div>

		<div class="col">
		    <div class="search-bar w-75">
		        <form class="search-form d-flex align-items-center" method="POST" action="#" (ngSubmit)="searchFunction()">
		            <!-- <label for="citySearchInput" class="form-label me-2">City:</label> -->
		            <input [(ngModel)]="addressSearchTerm" (input)="addressSearchFunction()" type="text" id="citySearchInput" name="query" placeholder="Search by address" title="Enter city to search" class="form-control" />
		            <button type="submit" title="Search" class="btn"><i class="bi bi-search"></i></button>
		        </form>
		    </div>
		</div>


    </div>
</div>






        </div>
      </div>
        <div  style="max-height: 6000px; overflow-y: auto;">
          <div class="card-body">
            <h5 class="card-title">Search Results</h5>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Contact</th>
                  </tr>
                </thead>
                <tbody>
                	
						<tr *ngFor="let contact of contactsAr; let i = index">
						  <th scope="row">{{ i + 1 }}</th>
						  <a href="#" (click)="selectContact($event, contact)">
						  	<td class="max-width-cell">{{ contact.title }}</td>
						  </a>
						  <td class="max-width-cell">{{ contact.phone }}</td>
						  <td class="max-width-cell">{{ contact.address }}</td>
						  <td class="max-width-cell">{{ contact.contactFirstName }}&nbsp;{{ contact.contactLastName }}</td>
						</tr>
					
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div></div>