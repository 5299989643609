<div class="accordion" id="accordionExample" style="height: 1000px;">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
            <button #accordionInfoBtn class="accordion-button" (click)="isInfoOpen = !isInfoOpen" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {{"Address, Website & Google Maps Link" | translate }}
            </button>
        </h2>
        <div id="collapseOne" #infoAccordion class="accordion-collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionInfo">
            <div class="accordion-body">
                <div class="card mb-3">
                    <div class="card-header">
                        {{ result?.["scrape_result"]?.["title"] || 'Not available' | translate }} - 🗺️
                        <a href="#" (click)="openGoogleMaps($event, result?.['scrape_result']?.['cid'])" target="_blank" class="card-text">{{"Google Maps Link" | translate}}</a> || <a href="#" (click)="openGoogleReviews($event, result?.['scrape_result']?.['reviews_link'])" target="_blank" class="card-text">{{"Reviews" | translate}}</a> || 🌐
                        <ng-container *ngIf="result?.['scrape_result']?.['web_site']">
                            <a href="{{ result?.['scrape_result']?.['web_site'] }}" target="_blank" class="card-text">{{"Website" | translate}}</a>
                        </ng-container>
                    </div>

                    <div class="card-body">
                        <h5 class="card-title">{{"Address" | translate}}</h5>

                        <div *ngIf="!edit">
                            <p class="card-text">{{ result?.["scrape_result"]?.["address"] || 'Not available' }}&nbsp;<a href="javascript:void(0)" (click)="editContact()" class="card-link">{{"Edit" | translate}}</a></p>
                        </div>

                        <div *ngIf="edit">
                            <div class="card" *ngIf="result?.scrape_result">
                                <div class="card-body">
                                    <div class="mb-3">
                                        <label for="titleInput" class="form-label">{{"Title" | translate}}</label>
                                        <input type="text" class="form-control" id="titleInput" [(ngModel)]="result.scrape_result.title" [value]="result?.scrape_result?.title || 'Default Title'" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="addressInput" class="form-label">{{"Address" | translate }}</label>
                                        <input type="text" class="form-control" id="addressInput" [(ngModel)]="result.scrape_result.complete_address.street" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="cityInput" class="form-label">{{"City" | translate }}</label>
                                        <input type="text" class="form-control" id="cityInput" [(ngModel)]="result.scrape_result.complete_address.city" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="stateInput" class="form-label">{{"State" | translate }}</label>
                                        <input type="text" class="form-control" id="stateInput" [(ngModel)]="result.scrape_result.complete_address.state" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="postalCodeInput" class="form-label">{{"Postal Code" | translate }}</label>
                                        <input type="text" class="form-control" id="postalCodeInput" [(ngModel)]="result.scrape_result.complete_address.postal_code" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="countryInput" class="form-label">{{"Country" | translate }}</label>
                                        <input type="text" class="form-control" id="countryInput" [(ngModel)]="result.scrape_result.complete_address.country" />
                                    </div>
                                    <button type="button" class="btn btn-primary" (click)="saveContact()">{{"Save" | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div *ngIf="userInfo.team_id == '65e334ea8d05c2e2171b24d3'">
                

<!-- <div class="container">
  <div *ngIf="germanTeamSuggest?.results?.length; else noData">
    <div class="card mb-3" *ngFor="let result of germanTeamSuggest.results">
      <div class="card-header d-flex justify-content-between align-items-center">
        {{ result.name }}<p class="card-text">{{ result.description }}</p>
        <a [href]="result.url" target="_blank" class="btn btn-primary">View Details</a>
      </div>
    </div>
  </div>
  <ng-template #noData>
    <p>{{ "No Sales Data Found" | translate }}</p>
  </ng-template>
</div>
            -->         </div>

                <ul class="list-group m-2" *ngIf="result?.appointments?.length">
                    <li class="list-group-item d-flex justify-content-between align-items-center" style="background-color: lightcoral;" *ngFor="let appointment of result.appointments">
                        <div style="display: flex; align-items: center;">
                            <div style="width: 180px;">
                                <span class="badge bg-primary">
                                    {{"Appointment" | translate }}
                                </span>
                            </div>
                            <span style="margin-right: 20px;">{{ appointment.appointmentTime | date:'short' }}</span>
                            <i class="material-icons" (click)="deleteAppointment(appointment)" style="cursor: pointer;">delete</i>
                        </div>
                    </li>
                </ul>

                <ul class="list-group m-2" *ngIf="result?.cooldownHistory?.length">
                    <li class="list-group-item d-flex justify-content-between align-items-center" style="background-color: lightblue;" *ngFor="let appointment of result.cooldownHistory">
                        <div style="display: flex; align-items: center;">
                            <div style="width: 180px;">
                                <span class="badge bg-primary">
                                    {{"Cooldown" | translate }}
                                </span>
                            </div>
                            <span style="margin-right: 20px;">{{ appointment.appointmentTime | date:'short' }}</span>
                            <i class="material-icons" (click)="deleteCooldown(appointment)" style="cursor: pointer;">delete</i>
                        </div>
                    </li>
                </ul>

                <button type="button" class="btn btn-success" (click)="call()">{{"Call" | translate }}</button>
                <div class="mb-3 mt-2">
                    <!--                             <button (click)="goFullScreen()">Maximize Screen</button>
                            <button (click)="exitFullScreen()">Exit Fullscreen</button> -->

                    <!--                             <button type="button" class="btn btn-primary btn-sm me-2 mt-2" (click)="addToHistory('VM')">{{"VM" | translate }}</button>
                            <button type="button" class="btn btn-secondary btn-sm me-2 mt-2" (click)="addToHistory('Convo')">{{"Convo" | translate }}</button>
                            <button type="button" class="btn btn-success btn-sm me-2 mt-2" (click)="addToHistory('Email')">{{"Email" | translate }}</button>
                            <button type="button" class="btn btn-info btn-sm me-2 mt-2" (click)="addToHistory('Text')">{{"Text" | translate }}</button> 
                            <button type="button" class="btn btn-info btn-sm me-2 mt-2" (click)="archive()">{{"Archive" | translate }}</button>-->
                    <button type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#order" (click)="placeOrder()">{{"Order" | translate }}</button>
                    <button type="button" class="btn btn-primary btn-sm me-2" (click)="displayNote()">{{"Add Note" | translate }}</button>

                    <div class="btn-group">
                        <button type="button" class="btn btn-info btn-sm me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            {{"Notes" | translate }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let note of notes">
                                <a class="dropdown-item" href="#" (click)="saveNote($event, note.id)">
                                    {{ note.key | translate }}
                                </a>
                            </li>
                        </ul>

                        <!-- <app-disposition-dropdown></app-disposition-dropdown> -->
                    </div>

                    <div class="btn-group">
                        <button type="button" class="btn btn-info btn-sm me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            {{"Action" | translate }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let action of actions">
                                <a class="dropdown-item text-success" href="#" (click)="saveAction($event, action)">
                                    {{ action.key | translate }}
                                </a>
                            </li>
                        </ul>

                        <!-- <app-disposition-dropdown></app-disposition-dropdown> -->
                    </div>

                    <div class="btn-group">
                        <button type="button" class="btn btn-info btn-sm me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            {{"Automation" | translate }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let action of automations">
                                <a class="dropdown-item text-success" href="#" (click)="triggerAutomation ($event, action)">
                                    {{ action.key | translate }}
                                </a>
                            </li>
                        </ul>


                        <!-- <app-disposition-dropdown></app-disposition-dropdown> -->
                    </div>
                        <div class="mt-2">
                            <a href="https://go.planetmarketing.com/widget/booking/xvrX0d0paOD2vLaW9Y1B" target="_blank" class="link-primary">
                                Booking Link
                            </a>
                        </div>
                        <div class="mt-2">
                            
                                <i 
        class="bi" 
        [class.bi-bookmark-fill]="result['bFlagAccountForReview']" 
        [class.bi-bookmark]="!result['bFlagAccountForReview']" 
        (click)="flagAccountForReview()">
    </i>&nbsp;Flag For Review
<i 
        class="bi" 
        [class.bi-star-fill]="result['bSetAsFavorite']" 
        [class.bi-star]="!result['bSetAsFavorite']" 
        (click)="toggleFavoriteAccount()">
    </i>&nbsp;Favorite
                        </div>

                </div>
                <div *ngIf="bAddNote" class="mb-3 p-2">
                    <label for="noteInput" class="form-label">{{"Note" | translate }}</label>
                    <textarea class="form-control" id="noteInput" rows="3" [(ngModel)]="note"></textarea>
                    <div class="form-check mt-2">
                        <input class="form-check-input" type="checkbox" id="defaultNoteCheck" [(ngModel)]="bSaveToDefaultAction" />
                        <label class="form-check-label" for="defaultNoteCheck">
                            {{ "Save To Default Notes" | translate }}
                        </label>
                    </div>
                    <button type="button" class="btn btn-success mt-2" (click)="addNote()">{{"Save Note" | translate }}</button>
                </div>

                <div *ngIf="previousNotes.length > 0" class="mb-3 p-2">
                    <div *ngFor="let note of previousNotes" class="card mb-2 position-relative">
                        <i class="material-icons" (click)="removeNote(note)" style="position: absolute; top: 10px; right: 10px; cursor: pointer;">delete</i>
                        <div class="card-body">
                            <p class="card-text">{{ note.content }}</p>
                            <footer class="blockquote-footer">{{getNameComponent(note.date)}},&nbsp;{{ getIsoDateString(note.date) | date:'short' }}</footer>
                        </div>
                    </div>
                </div>
                <div class="mb-3 p-2">
                    <label for="coolingPeriod" class="form-label">{{"Let Prospect Cool For" | translate }} ...</label>
                    <select class="form-select" id="coolingPeriod" [(ngModel)]="selectedCoolingPeriod" (change)="onSetCooldownPeriod()">
                        <option value="">{{"Select period" | translate}}...</option>
                        <option value="1 hour">{{"1 Hour" | translate }}</option>
                        <option value="10 hour">{{"Tomorrow Morning" | translate}}</option>
                        <option value="1 week">{{"1 Day" | translate}}</option>
                        <option value="1 week">{{"1 Week" | translate}}</option>
                        <option value="1 month">{{"1 Month" | translate}}</option>
                        <option value="6 weeks">{{"6 Weeks" | translate}}</option>
                        <option value="3 months">{{"3 Months" | translate}}</option>
                        <option value="6 months">{{"6 Months" | translate}}</option>
                        <option value="12 months">{{"12 Months" | translate}}</option>
                        <option value="Forever">{{"Forever" | translate}}</option>
                    </select>
                </div>
                <div class="mb-3 p-2">
                    <label for="coolingPeriod" class="form-label">{{"Best Time To Call Back" | translate}}</label>
                    <!-- <input #datepickerInput type="text" class="form-control"> -->
                    <app-date-picker [bTime]="true" (datesChanged)="handleDateChange($event)" [reset]="resetTrigger"></app-date-picker>
                </div>

                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let event of events">
                        <div>
                            <div style="width: 80px;">
                                <span
                                    class="badge"
                                    [ngClass]="{
                                              'bg-primary': event.type === 'VM',
                                              'bg-secondary': event.type === 'Convo',
                                              'bg-success': event.type === 'Email',
                                              'bg-info': event.type === 'Text'
                                              }"
                                >
                                    {{ event.type }}
                                </span>
                            </div>
                            <span>{{getNameComponent(event.date)}},&nbsp;{{ getIsoDateString(event.date) | date:'short' }}</span>
                        </div>
                        <i class="material-icons" (click)="removeEvent(event)" style="cursor: pointer;">delete</i>
                    </li>
                </ul>
            </div>
        </div>
    </div>
        <div class="accordion-item" *ngIf="ordersAr.length > 0">
        <h2 class="accordion-header" id="headingThree">
            <button #orderInfoBtn (click)="  bAreOrdersOpen = !bAreOrdersOpen" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                {{"Past Orders" | translate }}
            </button>
        </h2>
<div id="collapseFive" [class.show]="bAreOrdersOpen" #orderInfoAccordian class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionContactInfo">
    <div class="accordion-body">
        <div class="row">
            <div class="col mb-1 p-2">
                <label for="contactName" class="form-label">{{ "Orders" | translate }}</label>
                <div *ngFor="let order of ordersAr" class="mb-4 p-3 border rounded">
                    <h5 class="mb-3"><b>{{ "Order" | translate }}</b> {{"placed at"}} <i>{{ order.createdAt | date: 'dd/MM/yyyy hh:mm a' }}</i></h5> 
                    <p><strong>{{ "Invoice" | translate }}:</strong> {{ order.invoice }}</p>
                    <p><strong>{{ "Order Type" | translate }}:</strong> {{ order.orderType }}</p>
                    <p><strong>{{ "Status" | translate }}:</strong> {{ order.approved }}</p>
                    <p><strong>{{ "Sales Agent" | translate }}:</strong> {{ order.salesAgent }}</p>
                    <p><strong>{{ "Total Value" | translate }}:</strong> {{ order.totalValue | currency }}</p>
                    <p><strong>{{ "Label Printed Date" | translate }}:</strong> {{ order.labelPrintedDate | date:'short' }}</p>
                    <p><strong>{{ "Order Tracking Numbers" | translate }}:</strong> 

<span *ngFor="let trackingNumber of order.orderTrackingNumbers; let I = index">
                            <a [href]="getFedExTrackingLink(trackingNumber)" target="_blank">&nbsp;{{ trackingNumber }}</a>
                            <span *ngIf="I < order.orderTrackingNumbers.length - 1">, </span>
                        </span>
                    </p>
                    <p><button type="button" class="btn btn-primary btn-sm me-2" (click)="saveAction($event, { id: 5, orderId: order['_id'] })">{{"Send Return Label" | translate }}</button></p>
                    <div *ngIf="order.order && order.order.length" class="mt-3">
                        <h6>{{ "Order Details" | translate }}</h6>
                        <div *ngFor="let item of order.order" class="mb-2 p-2 border rounded bg-light">
                            <p class="mb-1"><strong>{{ "Product Name" | translate }}:</strong> {{ item.product_name }}</p>
                            <p class="mb-1"><strong>{{ "Product Category" | translate }}:</strong> {{ item.productCategory }}</p>
                            <p class="mb-1"><strong>{{ "Unit Price" | translate }}:</strong> {{ item.product_unit_price | currency }}</p>
                            <p class="mb-1"><strong>{{ "Quantity" | translate }}:</strong> {{ item.qty }}</p>
                            <p class="mb-1"><strong>{{ "Total Price" | translate }}:</strong> {{ item.qty * item.product_unit_price | currency }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
            <button #contactInfoBtn (click)="isContactOpen = !isContactOpen" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                {{"Contact Info" | translate }}
            </button>
        </h2>
        <div id="collapseThree" [class.show]="isContactOpen" #contactInfoAccordian class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionContactInfo">
            <div class="accordion-body">
                <div class="row">
                    <div class="col mb-1 p-2">
                        <label for="contactName" class="form-label">{{"First Name" | translate }}</label>
                        <input type="text" class="form-control" id="firstName" [(ngModel)]="contact.first_name" (ngModelChange)="onfNameChange()" />
                    </div>
                    <div class="col mb-1 p-2">
                        <label for="contactName" class="form-label">{{"Last Name" | translate }}</label>
                        <input type="text" class="form-control" id="lastName" [(ngModel)]="contact.last_name" (ngModelChange)="onlNameChange()" />
                    </div>
                </div>
                <div class="mb-1 p-2">
                    <label for="contactEmail" class="form-label">{{"Contact Email" | translate }}</label>
                    <input type="email" class="form-control" id="contactEmail" [(ngModel)]="contact.email" (ngModelChange)="onEmailChange()" />
                </div>
                <div class="mb-1 p-2 d-flex align-items-center">
                    <label for="contactEmail" class="form-label">{{"AP Email" | translate }}</label>
                    <input type="tel" class="form-control" id="apEmail" [(ngModel)]="contact.apemail" (ngModelChange)="onAPEmailChange()" name="APEmail" />
                    <button type="button" class="btn btn-primary ms-2" (click)="saveAPEmail()">{{"Save" | translate }}</button>
                </div>

                <div class="mb-1 p-2 d-flex align-items-center">
                    <label for="contactPhone" class="form-label">{{"Primary Phone" | translate }}</label>
                    <input class="form-control" id="primaryPhone" [(ngModel)]="contact.phone" (ngModelChange)="onPhoneChange()" />
                </div>
                <div class="mb-1 p-2 d-flex align-items-center">
                    <input type="checkbox" id="isCellPhone" [(ngModel)]="contact.isCellPhone" (change)="onCellPhoneChange()" />
                    <label for="isCellPhone" class="form-label">{{"Is this a cell phone?" | translate }}</label>
                </div>

                <div *ngFor="let phone of contact.additionalPhones; let i = index; trackBy: trackByFn" class="mb-1 p-2 d-flex align-items-center">
                    <label for="secondaryPhone{{i}}" class="form-label">{{"Phone" | translate }} {{i + 1}}</label>
                    <input type="tel" class="form-control" id="secondaryPhone{{i}}" [(ngModel)]="contact.additionalPhones[i]['number']" (ngModelChange)="onAdditionalPhoneChange(i)" name="secondaryPhone{{i}}" />
                    <button type="button" class="btn btn-primary ms-2" (click)="savePhone(i)">{{"Save" | translate }}</button>
                    <button type="button" class="btn btn-primary ms-2" (click)="deletePhone(i)">
                        <i class="fas fa-trash"></i>
                    </button>
                    <input type="checkbox" id="isCellPhone{{i}}" [(ngModel)]="contact.additionalPhones[i].isCellPhone" />
                    <label for="isCellPhone{{i}}" class="form-label">{{"Is this a cell phone?" | translate }}</label>
                
                </div>
                


                <div class="mb-1 p-2">
                    <a href="#" (click)="findPossibleExtensions(); $event.preventDefault()">{{"Find Extensions" | translate }}</a>
                </div>
                <div *ngIf="possibleExtensionsAr?.length > 0">
                    <ul>
                        <li *ngFor="let number of possibleExtensionsAr">
                            {{ number }}
                            <button (click)="addSecondaryExtension(number)">Add</button>
                        </li>
                    </ul>
                </div>
                <div class="mb-1 p-2">
                    <a href="#" (click)="addSecondaryNumber(); $event.preventDefault()">{{"Add Number" | translate }}</a>
                </div>
                <div class="mb-1 p-2">
                    <label for="contactTitle" class="form-label">{{"Title" | translate }}</label>
                    <input class="form-control" id="primaryTitle" [(ngModel)]="contact.title" (ngModelChange)="onTitleChange()" />
                </div>
                <div class="mb-1 p-2">
                    <label for="contactTitle" class="form-label">{{"Group" | translate }}</label>
                    <input class="form-control" id="primaryTitle" [(ngModel)]="contact.group" (ngModelChange)="onGroupChange()" />
                </div>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
            <button #callLogsBtn (click)="isCallLogsOpen = !isCallLogsOpen" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                {{"Call Logs" | translate }}
            </button>
        </h2>
        <div id="collapseTwo" #callLogsAccordian class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionCallLogs">
            <div class="accordion-body">
                <div *ngIf="bShowCallLogs; else noCallLogs">

                    <div *ngFor="let call of callLogs; let i= index;" class="row mb-2">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">{{call.alias}} (<small>{{ call.first_name }}&nbsp;{{ call.last_name }}</small>)</h5>
                                
                                {{ call['start_time'] | date: 'fullDate' }} at {{ call['start_time'] | date: 'shortTime' }}<br />
                                {{ call['formattedNumber'] }} - <i>{{ call['direction'] }}</i>

                                <p><span> </span></p>
                                <!--<div *ngIf="call.duration !== '00:00:00'"> -->
                                <h5 class="mb-2">{{"Full Call Recording" | translate }}</h5>
                                <div class="audio-container">
                                    {{ call.duration }}&nbsp;

                                    <button class="button-style" (click)="playAudio(call['call_session_id'], i, false)">Load</button>
                                    <audio
                                        #audioPlayers
                                        class="audio-control"
                                        controls
                                        controlsList="nodownload"
                                        (contextmenu)="disableContextMenu($event)"
                                        preload="auto"
                                        (pause)="onPause()"
                                        (ended)="onEnded()"
                                        (timeupdate)="onTimeUpdate($event)"
                                    >
                                        <source [src]="tracks[i].url" type="audio/mpeg" />
                                        {{"Your browser does not support the audio tag" | translate }}.
                                    </audio>
                                </div>
                                <div *ngIf="userInfo.account_type !== 'agent'">
                                    <a *ngIf="tracks[i].url != ''" [href]="tracks[i].url" download class="btn btn-link">
                                        <i class="bi bi-download"></i>
                                    </a>
                                </div>
                                <!-- </div> -->
                                <div *ngIf="call.duration === '00:00:00'">{{"Call To Short For Recording" | translate }}</div>
                                <h5 *ngIf="call['callRecordings']" class="mb-2">{{"Partial Call Recordings" | translate }}</h5>
                                <span *ngFor="let recording of call['callRecordings']">
                                    <div class="audio-container">
                                        <div *ngIf="call.duration !== '00:00:00'">
                                            <button class="button-style" (click)="playPartialAudio(recording['recording_id'], i, true)">{{"Load" | translate }}</button>
                                            <audio #partialAudioPlayers class="audio-control" controls="controls" channels="1" preload="auto">
                                                <source [src]="tracks[i].url" type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noCallLogs>
                    <div class="alert alert-info">{{"No Call Logs" | translate }}</div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
            <button
                #photosBtn
                (click)="isCallLogsOpen = !isContactOpen"
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                (click)="scrollToView(accordionPhotos)"
            >
                {{"Photos" | translate }}
            </button>
        </h2>
        <div #accordionPhotos id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionPhotos">
            <div class="accordion-body">
                <div id="images">
                    <div *ngFor="let img of imagesAr">
                        <div class="row justify-content-center">
                            <div class="col-auto">
                                <div class="card mb-4" style="width: 300px; max-width: 300px;">
                                    <img [src]="img['image']" referrerpolicy="no-referrer" class="card-img-top" alt="Image" />
                                    <div class="card-body">
                                        <p class="card-text">{{ img['title'] }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
            <button
                #giftcardsBtn
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
                (click)="scrollToView(accordionGiftcards)"
            >
                {{"Giftcards" | translate }}
            </button>
        </h2>

        <div #accordionGiftcards id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionGiftcards">
            <div class="accordion-body">
                <div id="giftcards">
                 <div class="row">

                <div class="col mb-1 p-2">
                    <div class="form-control-plaintext">
                        {{ contact.first_name }} {{ contact.last_name }}
                    </div>
                </div>
                <div class="col mb-1 p-2">
                    <div class="form-control-plaintext">
                        {{ contact.email }}
                    </div>
                </div>
                </div><div class="row">
                <div class="col mb-1 p-2 d-flex align-items-center">
                    <input type="number" class="form-control me-2" placeholder="Enter amount" [(ngModel)]="giftcardAmount" />
                    <button type="button" class="btn btn-primary" (click)="sendGiftcard()">Send Giftcard</button>
                </div>
                                   
                </div>
            </div>
        </div>

    </div>

</div>



        
