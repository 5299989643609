<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#users" (click)="clearPayload()">
 {{"Add User" | translate}}
</button>

<button #editUser type="button" style="display:none" data-bs-toggle="modal" data-bs-target="#editUsers">
 {{"Edit User" | translate}}
</button>

<app-flextable
   #flextable
   (headerButtonClicked)='headerButtonClicked($event)'
   (tableBtnClicked)='tableButtonClicked($event)'
   (rowLinkClicked)='rowLinkClicked($event)'
   (rowDeletedEvent)='rowDeletedEvent($event)'
   [options]="{
      datasource: 'users',
      bAllowDeletion: true,
      linkColumnPreventDefault: true,
      linkColumns: [{ column: 'Email', path: '/main/sysadmin/planmanagement/plan/' }],
      filter: { visible: { $ne: false }, archived: { $ne: true } }, 
      scope: 'account',
      columns: ['selected', 'first_name', 'last_name', 'email', 'account_type', 'number'],
      hiddenColumns: {
         'Number': {
               header: true
            }
      }
   }"
></app-flextable>




