import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataService } from './services/data.service'
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router, private dataService: DataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

    const authReq = req.clone({
      headers: req.headers.set('X-Language', selectedLanguage)
    });

    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status == 218) {
            // 218 is an unused status code, so we use it here to 
            // give the backend an easy way to display important
            // information to the user.
            event['body']['error'] = false;
            this.dataService.setPopupMessage(event.body);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {

        if (error.status === 401) {
          // Redirect to the login page
          this.router.navigate(['/login']);
        }

        if (error.status === 418) {
          // Handle status code 418 and access the error body
          const errorMessage = error.error ? error.error.message : '418 Error';
          this.dataService.setPopupMessage({ title: error.error.title, message: errorMessage });
          return;
      }

        return throwError(error);
      })
    );
  }
}
