import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-cleanup',
  templateUrl: './data-cleanup.component.html',
  styleUrl: './data-cleanup.component.css'
})

export class DataCleanupComponent implements OnInit {

  selectedOption: string = 'Delete Categories';

  all_categories = [];
  merge_categories = [];
  filteredCategories = [...this.all_categories]; // Initially, show all categories
  filterKeyword = '';

  selectedTeam = "";
  selectedLanguage = "";

  filterOptions() {

    this.filteredCategories = this.all_categories.filter(option => 
      option.toLowerCase().includes(this.filterKeyword.toLowerCase())
    );
  }

  selectCategoriesDisabled = false;
  @ViewChild('mySelect') mySelect: ElementRef;

  constructor(private route: ActivatedRoute, public http: HttpClient) { }

  ngOnInit(){
    //this.getAllCategories();
    //this.getLowVolumeCategories();
  }

  async getAllCategories(){
    if(this.selectedTeam == ""){
      alert("Please select a team");
      return;
    }

    var api_url = `api/administration/data-cleanup/getAllScrapedCategories/team_id/${this.selectedTeam}`;
    let result = await this.http.get(api_url).toPromise();  

    this.all_categories = result['categories'];
    this.selectCategoriesDisabled = false;
    this.filteredCategories = result['categories'];
  }
    
  getSelectedOptions() {
    const selectedOptions = Array.from(this.mySelect.nativeElement.selectedOptions)
                                  .map((option: HTMLOptionElement) => option.value);
    return selectedOptions;
  }

  async delete() {
    
    if (confirm("This action is permanent and cannot be undone.")) {
      // Code block to execute if user confirms
      var api_url = `api/administration/data-cleanup/removeSelectedCategories`;
      this.selectCategoriesDisabled = true;
      let result = await this.http.post(api_url, { categoriesToRemove: this.getSelectedOptions() }).toPromise();  

      //this.loadLowVolume();
      this.getAllCategories();
    }
  }

  async archive(){

  }

  contactsSelected = 0;
  selectedCategory = "";
  selectedCategories = [];
  lowVolumeCategories = [];

  async createListCategorySelected(){

    var count = 0;

    console.log(63, this.selectedCategory)   

    switch(this.selectedMode){

    case "search_term":
      count = await this.getSearchTermCount();
      break;
    case "titles":
      count = await this.getSearchTermCount();
      break;
    case "category":
      count = await this.getCategoryCount();
      break;
    case "categories":
      count = await this.getCategoryCount();
      break;
    }

    
    this.contactsSelected += count;
     
    this.selectedCategories = this.selectedCategories.concat(this.selectedCategory);
  }

  async getCategoryCount(){
    var api_url = `api/administration/data-cleanup/getCategoryCount/categoryName/${this.selectedCategory}`;
    console.log(69, api_url);
    let result = await this.http.get(api_url).toPromise();      
    return result["count"];
  }    

  async getSearchTermCount(){
    var api_url = `api/administration/data-cleanup/getSearchTermCount/searchTerm/${this.selectedCategory}`;
    console.log(69, api_url);
    let result = await this.http.get(api_url).toPromise();      
    return result["count"];
  }    


  async getLowVolumeCategories(){
    var api_url = `api/administration/data-cleanup/getLowVolumeCategories/maxDocuments/15`;
    let result = await this.http.get(api_url).toPromise();    

    return result['lowVolumeCategories'];

  }

  async loadLowVolume(){
    this.all_categories = [];
    this.selectCategoriesDisabled = true;
    this.all_categories = await this.getLowVolumeCategories();
    this.selectCategoriesDisabled = false;
  }

  maxNumberContacts = 3000;

  generatedLists = []

  async runList(){
    var api_url = `api/administration/data-cleanup/getIdsByCategories`;
    let obj: any = { categories: this.selectedCategories }
    let key = "category_ids";

    if(this.selectedMode == "search_term"){
      api_url = `api/administration/data-cleanup/getIdsBySearchTerm`;
      obj = { search_terms: this.selectedCategories }
      key = "search_term_ids";
    }
    
    let result = await this.http.post(api_url, obj ).toPromise();    
    //console.log(108, result);
    let categories = result[key];
    
    let split = this.createRoundRobinSubstrings(categories, Math.ceil(this.contactsSelected / this.maxNumberContacts))
    
    for(let i = 0; i < split.length; i++){
      let list = split[i]
      let genlist = {
        "name": this.myListName + " - " + String(i+1),
        "ids": list
      }
      this.generatedLists.push(genlist);
    }

    for(var list of this.generatedLists){
      console.log(150, list);
    }


  }

  async clearList(){
    this.selectedCategories = [];
    this.selectedCategory = null;
    this.generatedLists = [];
    this.contactsSelected = 0;
  }

  async saveLists(){
    var api_url = `api/administration/data-cleanup/saveList`;

    for(var list of this.generatedLists){
      console.log(166, list.length, list[0]);
    }
    let result = await this.http.post(api_url, { listsAr: this.generatedLists, team_id: this.selectedTeam, language: this.selectedLanguage } ).toPromise();
    alert("Lists Saved");      
  }

// This function takes a big array of strings, n number of desired sub arrays
// It creates n number of substrings, distributing the array elements evenly.

createRoundRobinSubstrings(strAr, n) {
  let result = Array.from({ length: n }, () => []); // Initialize n sub-arrays
  let totalElements = Math.ceil(strAr.length); // Calculate total elements to distribute
  let perArray = Math.ceil(totalElements / n); // Calculate elements per array

  for (let i = 0; i < totalElements; i++) {
    let currentElementIndex = (i * n) % strAr.length; // Calculate the index of the current element in strAr
    let targetArrayIndex = Math.floor(i / perArray); // Determine which sub-array to place the current element
    if (targetArrayIndex < n) { // Ensure we don't exceed the number of sub-arrays
      result[targetArrayIndex].push(strAr[currentElementIndex]);
    }
  }
    
    return result;
  }

  myListName = "";



  listsAr = [];
  office_users = [];
  selectedOfficeUser = "test";
  selectedList = "";
  selectedListId = "";

  async onTeamChanged($event){
    this.selectedTeam = $event;
    this.loadPanel();
  }

/*
async loadPanel(){
    var api_url = `api/administration/data-cleanup/getLists/team_id/${this.selectedTeam}`;
    let result = await this.http.get(api_url).toPromise();
    this.listsAr = result['lists'].sort((a, b) => {
      const nameA = a.name.match(/(\d+)$/); // Extract ending number if present
      const nameB = b.name.match(/(\d+)$/); // Extract ending number if present

      if (nameA && nameB) {
        const numA = parseInt(nameA[0], 10);
        const numB = parseInt(nameB[0], 10);
        if (numA !== numB) return numA - numB; // Sort by number if numbers are different
      }
      
      return a.name.localeCompare(b.name); // Fallback to alphabetic sort
    });

    var url = `/api/administration/live-listening/getTeamUsers/team_id/${this.selectedTeam}`;
    var results = await this.http.get(url).toPromise();
    this.office_users = results['team_users'];
    console.log(175, this.office_users);
}
*/

  async loadPanel(){
      this.loadTeamLists();
      this.loadTeamUsers();
  }

  async loadTeamLists(){
      var api_url = `api/administration/data-cleanup/getLists/team_id/${this.selectedTeam}`;
      let result = await this.http.get(api_url).toPromise();
      this.listsAr = result['lists'].sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically
  }

  async loadTeamUsers(){
      var url = `/api/administration/live-listening/getTeamUsers/team_id/${this.selectedTeam}`;
      var results = await this.http.get(url).toPromise();
      this.office_users = results['team_users'];    
  }

  getFullName(user): string {
    return `${user.first_name} ${user.last_name}`;
  }

  async assignList(){
    if(Array.isArray(this.selectedOfficeUser)){
      this.selectedOfficeUser = this.selectedOfficeUser[0]
    }

    let list_id = "";
    let agent_id = "";

    for(var list of this.listsAr){
      if(this.myListName == list.name){
        list_id = list._id;
      }
    }

    for(var user of this.office_users){
      console.log(198, user.last_name, this.selectedOfficeUser)
      if(this.selectedOfficeUser.indexOf(user.last_name) !== -1){
          if(this.selectedOfficeUser.indexOf(user.first_name) !== -1){
            agent_id = user._id
          }
        }

    
    }

    //this.getAssignedLists(this.selectedOfficeUser)

    this.assignedLists.push({ name: this.myListName, _id: list_id });

    var api_url = `api/administration/data-cleanup/assignList`;
    let result = await this.http.post(api_url, { list_id: list_id, agent_id: agent_id, agent_name: this.selectedOfficeUser } ).toPromise();

    await this.loadPanel();

  }

  selectedAgentsList = "";

  async unassignList(){
    // let user = "";
    // if(Array.isArray(this.selectedOfficeUser)){
    //   user = this.selectedOfficeUser[0]
    // } else {
    //   user = this.selectedOfficeUser;
    // }

    let listToUnassign = "";
    if(Array.isArray(this.selectedAgentsList)){
      listToUnassign = this.selectedAgentsList[0]
    } else {
      listToUnassign = this.selectedAgentsList;
    }

    let list_id = listToUnassign;
    let agent_id = "";

    if(Array.isArray(this.selectedOfficeUser)){
      this.selectedOfficeUser = this.selectedOfficeUser[0]
    } else {
      this.selectedOfficeUser = this.selectedAgentsList;
    }

    for(var user of this.office_users){
      console.log(198, user.last_name, this.selectedOfficeUser)
      if(this.selectedOfficeUser.indexOf(user.last_name) !== -1){
          if(this.selectedOfficeUser.indexOf(user.first_name) !== -1){
            agent_id = user._id
          }
        }
    }

    var api_url = `api/administration/data-cleanup/unassignList`;
    let result = await this.http.post(api_url, { list_id: list_id, agent_id: agent_id } ).toPromise();

    console.log(314, "List to Unassign", listToUnassign, "user_id", agent_id);
    await this.loadPanel();
    await this.getAssignedLists(agent_id);
    
  }

  selectedMode = "category";

  async onModeChange($event){
    console.log(217, this.selectedMode)

    var api_url = ``;
    var title = '';

    switch(this.selectedMode){

    case "search_term":
      api_url = `api/administration/data-cleanup/getAllScrapedSearchTerms`;
      break;
    case "titles":
      api_url = `api/administration/data-cleanup/getAllScrapedCategoriesByTitle/titleKeyword/stucco`;
      break;
    case "category":
      return this.getAllCategories();
      break;
    case "categories":
      return this.getAllCategories();
      break;
    }

    let result = await this.http.post(api_url, { "team_id": this.selectedTeam}).toPromise();  

    this.all_categories = result['categories'];
    this.selectCategoriesDisabled = false;
    this.filteredCategories = result['categories'];      
    
  }

  async deleteLists(){
    var deleteListsAr: any = this.myListName;
    if(!Array.isArray(deleteListsAr)){
      deleteListsAr = [ this.myListName ];
    }
    console.log(311, deleteListsAr);
    var api_url = `api/administration/data-cleanup/deleteLists`;
    let result = await this.http.post(api_url, { "deleteListsAr": deleteListsAr}).toPromise();  
    this.loadPanel();
  }

  selectedAgentId = "";

  onSelectedOfficeUserChange(event: any) {
      console.log('Selected Office User Changed:', event[0]);
      let selectedUser = event[0]
      let agent_id = "";
      for(var user of this.office_users){
        if(selectedUser.indexOf(user.last_name) !== -1){
          if(selectedUser.indexOf(user.first_name) !== -1){
            agent_id = user._id
            this.selectedAgentId = agent_id;
          }
        }
      }
      this.getAssignedLists(agent_id);
      this.getContactsAssignedToAgent();
  }

  assignedLists = [];
  async getAssignedLists(user){
    this.assignedLists = [];
    var api_url = `api/administration/data-cleanup/getAssignedLists`;
    let result = await this.http.post(api_url, { "user_id": user}).toPromise();      
    this.assignedLists = result["assigned_lists"];
    console.log(344, result)
  }

  contactsInList = 0;
  async getContactsInList($event){
    var api_url = `api/administration/data-cleanup/getListCount/listName/${this.myListName}`;
    let result = await this.http.get(api_url).toPromise();      
    this.contactsInList = result['listCount']
  }

  contactsAssignedToAgent = 0;
  async getContactsAssignedToAgent(){
    var api_url = `api/administration/data-cleanup/getContactCountAssignedToAgent/agent_id/${this.selectedAgentId}`;
    console.log(425, api_url);
    let result = await this.http.get(api_url).toPromise();      
    this.contactsAssignedToAgent = result['contactCount']
  }

  contactsInAssignedList = 0;
  async getContactsInAssignedList(){
    var api_url = `api/administration/data-cleanup/getListCountById/list_id/${this.selectedAgentsList}`;
    console.log(434, api_url);
    let result = await this.http.get(api_url).toPromise();      
    this.contactsInAssignedList = result['listCount'] 
    //this.contactsAssignedToAgent = result['contactCount']
  }  
}
