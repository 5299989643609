import { Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ViewChildren, ElementRef, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QueryList } from '@angular/core';

@Injectable()
export class BaseAccordionComponent implements OnInit, OnDestroy {

  result: any = {};
  resetTrigger = false;
  // Properties
  currentAudioFile = "";
  loadAudioFile = false;
  maxWidth = "500px";
  expanded = false;
  btnText = "Expand";
  recordingsLoaded = false;
  current_id = "";
  fullName = "";
  selectedCoolingPeriod = "";
  events: any[] = [];
  note = "";
  playingAudioId: string | null = null;
  audio: HTMLAudioElement | null = null;
  appointmentId = null;
  appointmentTime = "";
  appointmentDay = "";
  edit = false;
  date = new FormControl(new Date());
  time = new FormControl("");
  times = ["08:00", "09:00", "10:00"];
  private originalOffsetTop!: number;

  // Arrays
  tracks: { url: string }[] = Array.from({ length: 200 }, () => ({ url: "" }));
  partialTracks: { url: string }[] = Array.from({ length: 200 }, () => ({ url: "" }));
  imagesAr: any[] = [];
  call_recordings: any[] = [];
  objectKeys = Object.keys;
  callRecords: any[] = [];
  callLogs: any = [];
  callRecordings: string[] = [];
  contactPhoneNumbers: any = []
  previousNotes = [];

  // Objects
  contact = {
    first_name: "",
    last_name: "",
    apemail: "",
    extension: "",
    email: "",
    phone: "",
    title: "",
    group: "",
    additionalPhones: [
            { number: "", isCellPhone: false }
        ],
    isCellPhone: false
  };

  // Enums or Fixed values
  coolingPeriodOptions = [
    "1 week",
    "1 month",
    "6 weeks",
    "3 months",
    "6 months",
    "12 months",
  ];

  constructor() {}

  ngOnInit() {
    // Initialization logic here
    this.current_id = this.result["_id"];
  }

  ngOnDestroy() {
    // Cleanup logic here
  }

  // Other methods can be added here
}
