<div class="container mt-3">
    <!-- First Row -->
    <div class="row mb-3">
        <div class="col">
            <select class="form-select" [(ngModel)]="selectedOption">
                <option>{{"Select option" | translate }}</option>
                <option value="Delete Categories" selected>{{"Delete Categories" | translate }}</option>
                <option value="Merge Categories">{{"Merge Categories" | translate }}</option>
                <option value="Create List">{{"Create List" | translate }}</option>
                <option value="Assign List">{{"Assign List" | translate }}</option>
            </select>
        </div>
    </div>

    <team-picker [(selectedTeam)]="selectedTeam" (selectedTeamChange)="onTeamChanged($event)"></team-picker>
    <!-- <language-picker [(selectedLanguage)]="selectedLanguage"></language-picker> -->
    <div class="row mb-4">
      <label for="modeDropdown" class="col-sm-2 col-form-label">{{"Mode" | translate }}</label>
      <div class="col-sm-10">
        <select id="modeDropdown" name="mode" class="form-control w-50" [(ngModel)]="selectedMode" (change)="onModeChange($event)">
          <option value="category">{{"Category" | translate }}</option>
          <option value="search_term">{{"Search Term" | translate }}</option>
          <option value="categories">{{"Categories" | translate }}</option>
          <option value="titles">{{"Title" | translate }}</option>
        </select>
      </div>
    </div>
    <!-- Action Buttons Row -->
    <div *ngIf="selectedOption == 'Delete Categories'">
        <div class="row mb-3">
            <div class="col text-center">
                <button type="button" (click)="delete()" class="btn btn-danger me-2">{{"Delete" | translate }}</button>
                <button type="button" (click)="loadLowVolume()" class="btn btn-danger me-2">{{"Show Low Volume" | translate }}</button>
                <button type="button" (click)="archive()" class="btn btn-secondary ms-2">{{"Archive" | translate }}</button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <select [disabled]="selectCategoriesDisabled" class="form-select" multiple style="height: 800px;" #mySelect>
                    <option *ngFor="let option of all_categories" [value]="option">{{option}}</option>
                </select>
            </div>
        </div>
    </div>

    <!-- Action Buttons Row -->
    <div *ngIf="selectedOption == 'Merge Categories'">
        <div class="row mb-3">
            <div class="col text-center">
                <button type="button" (click)="delete()" class="btn btn-danger me-2">{{"Merge Categories" | translate }}</button>
                <div class="col-12" style="width: 300px;">
                    <input type="text" class="form-control" placeholder="Category Name" />
                </div>
                <!-- <button type="button" (click)="archive()" class="btn btn-secondary ms-2">Archive</button> -->
            </div>
        </div>

        <div class="row"></div>
        <div class="row">
            <div class="col">
                <select [disabled]="selectCategoriesDisabled" class="form-select" multiple style="height: 800px;" #mySelect>
                    <option *ngFor="let option of all_categories" [value]="option">{{option}}</option>
                </select>
            </div>

            <div class="col">
                <select [disabled]="selectCategoriesDisabled" class="form-select" multiple style="height: 800px;" #mySelect>
                    <option *ngFor="let option of all_categories" [value]="option">{{option}}</option>
                </select>
            </div>
        </div>
    </div>

    <div *ngIf="selectedOption == 'Create List'">
        <div class="row mb-3">
            {{"CREATE A LIST" | translate }}
        </div>
        <div class="row">
            <div class="col">
                <input type="text" [(ngModel)]="filterKeyword" (input)="filterOptions()" class="form-control mb-2" placeholder="Filter options..." />
                <select [disabled]="selectCategoriesDisabled" class="form-select" (change)="createListCategorySelected()" [(ngModel)]="selectedCategory" multiple style="height: 800px;" #mySelect>
                    <option *ngFor="let option of filteredCategories" [value]="option">{{option}}</option>
                </select>
            </div>

            <div class="col">
                <div class="mb-3">
                    <label for="contactSelectionDisplay" class="form-label">{{"Number of Contacts Selected" | translate }}</label>
                    <input type="text" class="form-control" [value]="contactsSelected" id="contactSelectionDisplay" disabled />
                </div>
                <div class="mb-3">
                    <label for="maxContacts" class="form-label">{{"Max Number of Contacts" | translate }}</label>
                    <input type="number" [(ngModel)]="maxNumberContacts" class="form-control" id="maxContacts" placeholder="3000" />
                </div>
                <div class="mb-3">
                    <label for="listName" class="form-label">{{"List Name" | translate }}</label>
                    <input type="text" [(ngModel)]="myListName" class="form-control" id="listName" placeholder="Enter list name" />
                </div>
                <div class="mb-3 form-check">
                    <input type="checkbox" class="form-check-input" id="exclusiveList" />
                    <label class="form-check-label" for="exclusiveList">{{"Make List Exclusive" | translate }}</label>
                </div>

                <!-- <textarea class="form-select" style="height: 150px;"></textarea> -->
                <select [disabled]="selectCategoriesDisabled" class="form-select" multiple style="height: 150px;" #mySelect>
                    <!-- Options will be dynamically inserted here -->
                    <option *ngFor="let option of selectedCategories" [value]="option">{{option}}</option>
                </select>

                <div class="mb-3">
                    <!-- <div class="col text-center"> -->
                    <button type="button" (click)="runList()" class="btn btn-danger mt-2">{{"Run List" | translate }}</button>
                    <button type="button" (click)="deleteLists()" class="btn btn-danger mt-2">{{"Delete Lists" | translate }}</button>
                    <!-- <button type="button" (click)="archive()" class="btn btn-secondary ms-2">Archive</button> -->
                    <!-- </div> -->
                </div>
                <div class="mb-3">
                    <!-- <div class="col text-center"> -->
                    <button type="button" (click)="clearList()" class="btn btn-danger mt-2">{{"Clear List" | translate }}</button>
                    <!-- <button type="button" (click)="archive()" class="btn btn-secondary ms-2">Archive</button> -->
                    <!-- </div> -->
                </div>
            </div>

            <div class="col">
                <select class="form-select" multiple style="height: 800px;">
                    <option *ngFor="let list of this.generatedLists" [value]="list.name">{{list.name}}</option>
                </select>
                <div class="mb-3">
                    <!-- <div class="col text-center"> -->
                    <button type="button" (click)="saveLists()" class="btn btn-danger mt-2">{{"Save List" | translate }}</button>
                    <!-- <button type="button" (click)="archive()" class="btn btn-secondary ms-2">Archive</button> -->
                    <!-- </div> -->
                </div>
            </div>

            <div class="col"></div>
        </div>
    </div>
</div>

<div *ngIf="selectedOption == 'Assign List'">
        
        <div class="row">
                        <div class="col">
                            <span class="mb-3">{{"Assign" | translate }}</span>
                <select class="form-select" multiple style="height: 800px;" [(ngModel)]="selectedOfficeUser" (ngModelChange)="onSelectedOfficeUserChange($event)">
                    <option *ngFor="let user of this.office_users" [value]="getFullName(user)">{{user.first_name}} {{user.last_name}}</option>
                </select>
                <div class="mb-3">
                </div>
            </div>

            <div class="col">
                <div class="mb-3">
                    <label for="contactSelectionDisplay" class="form-label">{{"Name of Agent" | translate }}</label>
                    <input disabled type="text" class="form-control" (ngModelChange)="onSelectedOfficeUserChange($event)" [(ngModel)]="selectedOfficeUser" id="contactSelectionDisplay" disabled />
                </div>
                <div class="mb-3">
                    <label for="listName" class="form-label">{{"The List Called" | translate }}:</label>
                    <input disabled type="text" (ngModelChange)="getContactsInList($event)" [(ngModel)]="myListName" class="form-control" id="listName" placeholder="Enter list name" />
                </div>

                <div class="mb-3">
                    <label for="listName" class="form-label">{{"Contacts In List" | translate }}:</label>
                    <input disabled type="text" [(ngModel)]="contactsInList" class="form-control" id="contactsInList" placeholder="Select An List From The Right" />
                </div>

                <div class="mb-3">
                    <label for="listName" class="form-label">{{"Contacts Assigned To Agent" | translate }}:</label>
                    <input disabled type="text" [(ngModel)]="contactsAssignedToAgent" class="form-control" id="contactsAssignedToAgent" placeholder="Select An Agent From The Left" />
                </div>


                <div class="mb-3">
                    <label for="listName" class="form-label">{{"Contacts In Assigned List" | translate }}:</label>
                    <input disabled type="text" [(ngModel)]="contactsInAssignedList" class="form-control" id="contactsAssignedToAgent" placeholder="Select A List From Assigned Lists" />
                </div>

                <div class="mb-3">
                    <label for="listName" class="form-label">{{"Assigned Lists" | translate }}:</label>
                    <select class="form-select" multiple style="height: 300px;"[(ngModel)]="selectedAgentsList"  (change)="getContactsInAssignedList()" >
                        <option *ngFor="let assignedList of this.assignedLists" [value]="assignedList._id">{{assignedList.name}}</option>
                    </select>
                </div>

                <div class="mb-3">

                    <button type="button" (click)="assignList()" class="btn btn-danger mt-2">{{"Assign" | translate }}</button>
                    <button type="button" (click)="unassignList()" class="btn btn-danger mt-2">{{"Un Assign" | translate }}</button>
                </div>
            </div>

            <div class="col">
                <select [disabled]="selectCategoriesDisabled" class="form-select" (change)="getContactsInList($event)" [(ngModel)]="myListName" multiple style="height: 800px;" #mySelect>
                    <option *ngFor="let list of listsAr" [value]="list.name">{{list.name}}</option>
                </select>
            </div>

            <div class="col"></div>
        </div>
</div>
