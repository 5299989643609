<button #placeOrderBtn type="button" data-bs-toggle="modal" style="display:none" data-bs-target="#order"></button>

<div *ngIf="labelsAr?.length > 0" class="popup">
  <button class="close-btn" (click)="closePopup()">X</button>
  <ul>
    <li *ngFor="let label of labelsAr">
      <a href="#" (click)="openLink($event, label)">{{ label }}</a>
    </li>
  </ul>
</div>




   <app-flextable #flextable
      (headerButtonClicked)='headerButtonClicked($event)'
      (rowTableBtnClicked)='tableButtonClicked($event)'
      (rowLinkClicked)='rowLinkClicked($event)'
      (rowDeletedEvent)='rowDeletedEvent($event)'
      [options]="
         {
            datasource: 'orders',

            'bEnableDateRange': true,
         
            dateFilter: { createdAt : { $lte : '', $gte: '' } },

            actionButtons: [{
               'column': 'title',
               'buttonName': 'Actions',
               actions: [
                        { id: 2, name: 'Pre-Approve' },
                        { id: 1, name: 'Approve' },
                        { id: 2, name: 'Reject' },
                        { id: 6, name: 'View Order' },
                        { id: 3, name: 'Send To Customer' },
                        { id: 4, name: 'Create Fedex Shipping Label' },
                        { id: 5, name: 'Create Fedex Return' },
                        { id: 7, name: 'Mark as paid' },
               ]
            }],

            filters: [
               {
                  'filter': { 'approved': '' },
                  'label': 'Status',
                  'valueKeys': [],
                  'valueIds': [],
                  'datasource': 'orders',
                  'key': 'approved',
                  'distinct': 'approved',
                  'all':'custom'
               }
               ],

            keyValueFilters: [
               {
                  'filter': { 'companyName': { '$exists': true } },
                  'label': 'Company Name',
                  'valueKeys': [],
                  'valueIds': [],
                  'datasource': 'companies',
                  'key': 'companyName',
                  'query': 'companyName',
                  'scope':'account',
                  'propName': 'company_id'
               },
               {
                  'filter': { 'account_type': 'agent' },
                  'label': 'Agent Name',
                  'valueKeys': [],
                  'valueIds': [],
                  'datasource': 'users',
                  'key': 'alias',
                  'propName': 'created_by',
                  'scope':'account'
               }                  
            ],

            bAllowDeletion: true,

            linkColumnPreventDefault: true,
                  linkColumns: [ {
                     column: 'Title',
                     path: '/main/sysadmin/planmanagement/plan/'
                  }
            ],

            columnBadges: {
               'approved': {
                column: 'approved',
                color: 'red',
                states: {
                  'complete': { color: 'white', icon_color: '#93C572', material_icon: 'check_circle', display_text: false, tooltip: 'This transaction is complete' },
                  'invoice_sent': { color: 'green', material_icon: 'send', tooltip: 'We are waiting on the seller to complete an action'  },
                  'approved': { color: 'blue', material_icon: 'thumb_up', tooltip: 'We are waiting on the buyer to complete an action'  },
                  'pending': { icon_color: '#FF7F50', color: 'teal', material_icon: 'hourglass_full', tooltip: 'This exchange is in escrpw'   },
                  'rejected': { color: 'red', material_icon: 'hourglass_empty', tooltip: 'This link opportunity was rejected for not meeting our standards'  },
                  'incomplete': { color: '#FF7F50', material_icon: 'hourglass_empty', tooltip: 'This order is missing information'  },
                  'return': { color: '#FF7F50', material_icon: 'hourglass_empty', tooltip: 'This order is awaiting a return'  },
                  'paid': { color: '#007F00', material_icon: 'hourglass_empty', tooltip: 'This order is missing information'  },
                  'shipped': { color: '#1E90FF', icon_color: '#FFFFAF', material_icon: 'local_shipping', tooltip: 'This order has shipped'  },
                  'void': { color: '#1E90FF', icon_color: '#FFFFAF', material_icon: 'local_shipping', tooltip: 'This order is voided'  },
                  'preapproved': { color: '#1E90FF', icon_color: '#FFFFAF', material_icon: 'local_shipping', tooltip: 'This order is pre-approved'  },
                  
               },
               index: 0
               }
            },

		   	hiddenColumns: {
               'Customer Id': {
                  header: true
               }
		   	},

            filter: {
               visible: {
                  $ne: false 
               }, 
               archived: { $ne: true },
            }, 

            scope: 'account',
            
            columnNameRewrites: [
               'Account Name', 'Agent', 'Total', 'Status', 'Address', 'Invoice', 'Date'
            ],

            columns: [
               'title',
               'salesAgent',
               'totalValue',
               'approved',
               'address',
               'invoice',
               'createdAt',
               'customer_id'
            ]
         }"

   ></app-flextable>


