import { Pipe, PipeTransform } from '@angular/core';
import { Internationalization } from '@internationalization'

@Pipe({
  name: 'formatPhone'
})

export class FormatPhonePipe implements PipeTransform {
  // transform(rawNum: string): string {
  //   if (!rawNum) { return ''; }
  //   const cleaned = ('' + rawNum).replace(/\D/g, '');
  //   const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  //   }
  //   return rawNum;
  // }

  transform(rawNum: string): string {
    if (!rawNum) { return ''; }
    var intlNumber = new Internationalization();

    return intlNumber.formatNumberByCountryCode(rawNum);
  }

}


// 