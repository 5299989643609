import { Component } from '@angular/core';

@Component({
  selector: 'app-all-contacts',
  templateUrl: './all-contacts.component.html',
  styleUrl: './all-contacts.component.css'
})
export class AllContactsComponent {

}
