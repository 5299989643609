<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">{{"Title" | translate }}</th>
      <th scope="col">{{"Direction" | translate }}</th>
      <th scope="col">{{"From" | translate }}</th>
      <th scope="col">{{"To" | translate }}</th>
      <th scope="col">{{"Start Time" | translate}}</th>
<!--       <th scope="col">End Time</th>
      <th scope="col">Hangup Cause</th> -->
    </tr>
  </thead>
<tbody>
  <tr *ngFor="let call of all_calls; let i = index">

<td>
  <div class="d-flex align-items-center">
    <a #playIcons class="fa fa-play-circle" aria-hidden="true" (click)="playAudio(call['call_session_id'], i)">&nbsp;Dl&nbsp;</a>
    <audio #audioPlayers class="audio-control" channels="1" controls preload="auto"
      (pause)="onPause(i)"
      (ended)="onEnded(i)"
      (play)="onPlay(i)"
    ></audio>
  </div>
</td>

  
    
    <td *ngIf="call['title']" style="max-width:200px;">
  <a *ngIf="call?.to" [routerLink]="['/main/search']" (click)="searchNumber(call?.to)">
    {{call['title']}}
  </a>
</td>

    <td *ngIf="!call['title']">
    <a *ngIf="call?.to" [routerLink]="['/main/search']" (click)="searchNumber(call?.to)">
      {{"No Contact In Database" | translate }}     
    </a>
  </td>

    <td>{{call.from}}</td>
    <td>{{call.to}}</td>
    <td>{{call.start_time | date: 'medium'}}</td>
    <!-- <td>{{call.end_time | date: 'medium'}}</td>
    <td>{{call.hangup_cause}}</td> -->
  </tr>
</tbody>
</table>
