import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.css']
})

export class NotificationBellComponent implements OnInit {

	@Input() title: string = '';
	@Input() notification: string = '';
	@Input() time: string = '';
	@Input() icon: string = '';
  @Input() path: string = '';
  @Input() refDocId: string = '';
  @Input() notification_id: string  = '';
  @Output() notifyDeletion: EventEmitter<any> = new EventEmitter();
  routerPath = '';
  isDeleted = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.routerPath = this.path + "/" + this.refDocId;
  }

  async trashNotification($event){
    $event.stopPropagation();
    $event.preventDefault();
    console.log(27, this.notification_id);

    var api_url ='api/datasource/notifications/id/' + this.notification_id;
    console.log(34, api_url)
    let result = await this.http.delete(api_url).toPromise();
    this.isDeleted = true;
    this.notifyDeletion.emit({ notification_id: this.notification_id });
  }

}
