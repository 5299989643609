import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

/* As of Angular 14, this is the best-practices way to make forms in Angular */
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { AsideComponent } from './main/aside/aside.component';
import { ContentComponent } from './main/content/content.component';
import { FooterComponent } from './main/footer/footer.component';
import { Child1Component } from './main/content/child1/child1.component';
import { Child2Component } from './main/content/child2/child2.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NotFoundComponent } from './not-found/not-found.component';
import { NetworkRequestButtonComponent } from './reusable/ui/network-request-button/network-request-button.component';
import { MessageItemComponent } from './main/header/message-item/message-item.component';
import { NotificationBellComponent } from './main/header/notification-bell/notification-bell.component';
import { ProfilePicComponent } from './main/header/profile-pic/profile-pic.component';
import { SettingsComponent } from './main/user/settings/settings.component';
import { ProfileComponent } from './main/user/profile/profile.component';
import { BillingComponent } from './main/account/billing/billing.component';
import { UsersComponent } from './main/content/sysadmin/users/users.component';
import { AccountsComponent } from './main/content/sysadmin/accounts/accounts.component';
import { SubscriptionsComponent } from './main/content/sysadmin/subscriptions/subscriptions.component';
import { ApikeysComponent } from './main/content/sysadmin/apikeys/apikeys.component';
import { PlanmanagementComponent } from './main/content/sysadmin/planmanagement/planmanagement.component';
import { ReportsComponent } from './main/content/sysadmin/reports/reports.component';
import { TransactionsComponent } from './main/content/sysadmin/transactions/transactions.component';
import { HelpDashboardComponent } from './main/content/help/dashboard/dashboard.component';
import { FreePlanPageComponent } from './main/content/plans/free/free.component';
import { ProPlanPageComponent } from './main/content/plans/pro/pro.component';
import { EnterprisePlanPageComponent } from './main/content/plans/enterprise/enterprise.component';
import { TermsComponent } from './main/content/terms/terms.component';
import { PrivacyComponent } from './main/content/terms/privacy/privacy.component';

import { FlextableComponent } from './reusable/ui/flextable/flextable.component';

/* Angular Material Imports */
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import { NativeDateAdapter } from '@angular/material/core';

import { ReplaceUnderscorePipe } from './legacy/custom_pipe/allpipe';
import { ViewDropdownComponent } from './legacy/flex-components/view-dropdown/view-dropdown.component';
import { FlexDropdownComponent } from './legacy/flex-components/flex-dropdown/flex-dropdown.component';
import { DynamicPriceComponent } from './legacy/flex-components/dynamic-price/dynamic-price.component';
import { FlexMenuComponent } from './legacy/flex-components/flex-menu/view-dropdown.component';
import { FileUploadComponent } from './legacy/file-upload/file-upload.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgLetModule } from 'ng-let';
import { TableLoadingButtonComponent } from './reusable/ui/table-loading-button/table-loading-button.component';
import { PlanComponent } from './main/content/sysadmin/planmanagement/plan/plan.component';
import { PanelComponent } from './main/content/sysadmin/subscriptions/panel/panel.component';
import { DomainsComponent } from './main/content/affiliate/domains/domains.component';
import { SignupsComponent } from './main/content/affiliate/signups/signups.component';
import { GetpaidComponent } from './main/content/affiliate/getpaid/getpaid.component';
import { TrialsComponent } from './main/content/affiliate/trials/trials.component';
import { CookiesComponent } from './main/content/affiliate/cookies2/cookies2.component';
import { AffiliateHomeComponent } from './main/content/affiliate/home/home.component';
import { UserHomeComponent } from './main/content/user/home/home.component';
import { AdminHomeComponent } from './main/content/sysadmin/home/home.component';
import { GenericLoadingButtonComponent } from './reusable/ui/generic-loading-button/generic-loading-button.component';
import { AddDomainComponent } from './main/content/affiliate/domains/add-domain/add-domain.component';
import { NotificationsComponent } from './main/notifications/notifications.component';
import { MessagesComponent } from './main/messages/messages.component';
import { ButtonComponent } from './reusable/ui/trash/button/button.component';
import { ForumComponent } from './main/forum/forum.component';
import { ThreadComponent } from './main/forum/thread/thread.component';
import { RowbuttonComponent } from './reusable/ui/flextable/rowbutton/rowbutton.component';
import { ChatComponent } from './chat/chat.component';
import { PromptPlaygroundComponent } from './reusable/ui/prompt-playground/prompt-playground.component';
import { PromptsComponent } from './main/content/sysadmin/prompts/prompts.component';
import { VideoComponentComponent } from './reusable/ui/video-component/video-component.component';

import { VariableStateButtonComponent } from './reusable/ui/buttons/variable-state-button/variable-state-button.component';

import { JoyrideModule } from 'ngx-joyride';

import { TableBadgesComponent } from './reusable/ui/buttons/table-badges/table-badges.component';
import { SnakeCasePupePipe } from './snake-case-pupe.pipe';
import { CustomDatePipe } from './custom-date.pipe';

import { PermissionsCellComponent } from './reusable/ui/flextable/permissions-cell/permissions-cell.component';
import { QualityCheckComponent } from './main/content/sysadmin/quality-check/quality-check.component';
import { UpgradePlanComponent } from './main/account/billing/upgrade-plan/upgrade-plan.component';
import { FaqComponent } from './main/content/help/faq/faq.component';
import { VoteComponent } from './main/content/help/vote/vote.component';
import { BallotComponent } from './main/content/help/vote/ballot/ballot.component';
import { RoadmapComponent } from './main/content/help/roadmap/roadmap.component';
import { OnboardingComponent } from './main/content/help/onboarding/onboarding.component';
import { HowtouseComponent } from './main/content/help/howtouse/howtouse.component';
import { WebsiteComponent } from './website/website.component';
import { PreregisterComponent } from './website/preregister/preregister.component';
import { LinkAlgorithmComponent } from './website/link-algorithm/link-algorithm.component';
import { YourfullschoolComponent } from './website/yourfullschool/yourfullschool.component';
import { ContactsComponent } from './main/content/contacts/contacts.component';
import { ScrapeDataComponent } from './main/content/scrape-data/scrape-data.component';
import { CallScriptsComponent } from './main/content/call-scripts/call-scripts.component';
import { TextTemplatesComponent } from './main/content/text-templates/text-templates.component';
import { SnailMailComponent } from './main/content/snail-mail/snail-mail.component';
import { EmailComponent } from './main/content/email/email.component';
import { InfoComponent } from './main/content/contacts/info/info.component'
import { AdminUsersComponent } from './main/content/admin/users/users.component';
import { EndlessScrollComponent } from './main/content/endless-scroll/endless-scroll.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShortlistComponent } from './main/content/shortlist/shortlist.component';
import { CallsComponent } from './calls/calls.component';

import { SafeUrlPipe } from './safe-url';
import { FormatPhonePipe } from './format-phone.pipe';
import { ProductManagementComponent } from './main/content/admin/products/product-management/product-management.component';
import { WarehouseManagementComponent } from './main/content/admin/warehouses/warehouse-management/warehouse-management.component';
import { LanguageManagementComponent } from './main/content/admin/languages/language-management/language-management.component';
import { OfficeManagementComponent } from './main/content/admin/offices/office-management/office-management.component';
import { CountryManagementComponent } from './main/content/admin/countries/country-management/country-management.component';
import { TeamManagementComponent } from './main/content/admin/teams/team-management/team-management.component';
import { CurrencyManagementComponent } from './main/content/admin/currencies/currency-management/currency-management.component';
import { MyOrdersComponent } from './main/content/agents/my-orders/my-orders.component';
import { MyStatsComponent } from './main/content/agents/my-stats/my-stats.component';
import { MyTeamsOrdersComponent } from './main/content/admin/orders/orders.component';
import { OfficeStatsComponent } from './main/content/admin/office-stats/office-stats.component';
import { AppointmentsComponent } from './main/content/agents/appointments/appointments.component'
import { MissedCallsComponent } from './main/content/agents/missed-calls/missed-calls.component'
import { SearchComponent } from './main/content/search/search.component'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AllMissedCallsComponent } from './main/content/all-missed-calls/all-missed-calls.component';
import { MyCallsComponent } from './main/content/agents/my-calls/my-calls.component';
import { AgentCallsComponent } from './main/content/admin/agents/agent-calls/agent-calls.component';
import { DataCleanupComponent } from './main/content/admin/data-cleanup/data-cleanup.component';
import { AllContactsComponent } from './main/content/admin/all-contacts/all-contacts.component';
import { CallSearchComponent } from './main/content/admin/call-search/call-search.component';

import { HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './custom-http.interceptor';
import { OwnershipComponent } from './main/dashboards/ownership/ownership.component';
import { TechnicalSupportComponent } from './main/dashboards/technical-support/technical-support.component';
import { OfficeManagerComponent } from './main/dashboards/office-manager/office-manager.component';
import { SupervisorComponent } from './main/dashboards/supervisor/supervisor.component';
import { AgentComponent } from './main/dashboards/agent/agent.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LanguageSelectComponent } from './reusable/ui/dropdowns/language-select/language-select.component';
import { CountrySelectComponent } from './reusable/ui/dropdowns/country-select/country-select.component';
import { OfficePickerComponent } from './reusable/data/office-picker/office-picker.component';
import { TeamPickerComponent } from './reusable/data/team-picker/team-picker.component';
import { SupervisorPickerComponent } from './reusable/data/supervisor-picker/supervisor-picker.component';
import { LanguagePickerComponent } from './reusable/data/language-picker/language-picker.component';
import { MapOverlayComponent } from './map-overlay/map-overlay.component';
import { DispositionComponent } from './main/content/agents/disposition/disposition.component';
import { ActivityViewerComponent } from './main/content/agents/activity-viewer/activity-viewer.component';
import { CompaniesComponent } from './main/content/admin/companies/companies.component';
import { DoNotCallComponent } from './main/content/admin/do-not-call/do-not-call.component';
import { IncomingCallsComponent } from './calls/incoming-calls/incoming-calls.component';
import { UnmatchedIncomingCallComponent } from './calls/unmatched-incoming-call/unmatched-incoming-call.component';
import { DateFilterComponent } from './reusable/ui/widgets/date-filter/date-filter.component';
import { OrderFormComponent } from './reusable/ui/widgets/order-form/order-form.component';
import { CompanyPickerComponent } from './reusable/data/company-picker/company-picker.component';
import { WarehousePickerComponent } from './reusable/data/warehouse-picker/warehouse-picker.component';
import { MoveModalToBodyDirective } from './directives/move-modal-to-body.directive';
import { DatePickerComponent } from './reusable/ui/date-picker/date-picker.component';

import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { InfoFullPageComponent } from './main/content/contacts/info-full-page/info-full-page.component';
import { InfoAccordionComponent } from './reusable/ui/widgets/info-accordion/info-accordion.component';

import { TwemojiDirective } from './twemoji.directive';
import { DispositionDropdownComponent } from './reusable/ui/widgets/disposition-dropdown/disposition-dropdown.component';
import { UserPickerComponent } from './reusable/data/user-picker/user-picker.component';
import { DropdownButtonComponent } from './reusable/ui/buttons/dropdown-button/dropdown-button.component';
//import { MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatIconModule, MatButtonModule } from '@angular/material';

import { AdminReportsComponent } from './main/content/admin/reports/reports.component';
import { ReportPickerComponent } from './reusable/data/report-picker/report-picker.component';
import { AddContactComponent } from './main/content/agents/add-contact/add-contact.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { EditUserComponent } from './main/content/admin/users/edit-user/edit-user.component';
import { AddUserComponent } from './main/content/admin/users/add-user/add-user.component';
import { FlaggedComponent } from './main/content/admin/flagged/flagged.component';
import { AnimatedNotificationComponent } from './animated-notification/animated-notification.component';
import { RedeemgiftcardComponent } from './redeemgiftcard/redeemgiftcard.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@NgModule({
  declarations: [
    TwemojiDirective,
    MoveModalToBodyDirective,
    SearchComponent,
    SafeUrlPipe,
    FormatPhonePipe,
    /* Angular Framework */
 
    MissedCallsComponent,
    /* Old Client Ports */
    ReplaceUnderscorePipe,
    ViewDropdownComponent,
    FlexDropdownComponent,
    DynamicPriceComponent,
    FlexMenuComponent,
    FileUploadComponent,
    AdminReportsComponent,
    /* Project */
    AppComponent,
    LoginComponent,
    RegisterComponent,
    MainComponent,
    HeaderComponent,
    AsideComponent,
    ContentComponent,
    FooterComponent,
    Child1Component,
    Child2Component,
    NotFoundComponent,
    NetworkRequestButtonComponent,
    MessageItemComponent,
    NotificationBellComponent,
    ProfilePicComponent,
    SettingsComponent,
    ProfileComponent,
    BillingComponent,
    UsersComponent,
    AccountsComponent,
    SubscriptionsComponent,
    ApikeysComponent,
    PlanmanagementComponent,
    ReportsComponent,
    TransactionsComponent,
    HelpDashboardComponent,
    FreePlanPageComponent,
    ProPlanPageComponent,
    EnterprisePlanPageComponent,
    TermsComponent,
    PrivacyComponent,
    FlextableComponent,
    TableLoadingButtonComponent,
    PlanComponent,
    PanelComponent,
    DomainsComponent,
    SignupsComponent,
    GetpaidComponent,
    TrialsComponent,
    CookiesComponent,
    AdminHomeComponent,
    UserHomeComponent,
    AffiliateHomeComponent,
    GenericLoadingButtonComponent,
    AddDomainComponent,
    NotificationsComponent,
    MessagesComponent,
    ButtonComponent,
    ForumComponent,
    ThreadComponent,
    RowbuttonComponent,
    ChatComponent,
    PromptPlaygroundComponent,
    VariableStateButtonComponent,
    TableBadgesComponent,
    SnakeCasePupePipe,
    CustomDatePipe,
    PermissionsCellComponent,
    QualityCheckComponent,
    UpgradePlanComponent,
    FaqComponent,
    VoteComponent,
    BallotComponent,
    RoadmapComponent,
    OnboardingComponent,
    HowtouseComponent,
    WebsiteComponent,
    PreregisterComponent,
    LinkAlgorithmComponent,
    YourfullschoolComponent,
    ContactsComponent,
    ScrapeDataComponent,
    CallScriptsComponent,
    TextTemplatesComponent,
    SnailMailComponent,
    EmailComponent,
    InfoComponent,
    AdminUsersComponent,
    EndlessScrollComponent,
    ShortlistComponent,
    CallsComponent,
    ProductManagementComponent,
    WarehouseManagementComponent,
    LanguageManagementComponent,
    OfficeManagementComponent,
    CountryManagementComponent,
    TeamManagementComponent,
    CurrencyManagementComponent,
    MyOrdersComponent,
    MyStatsComponent,
    MyTeamsOrdersComponent,
    OfficeStatsComponent,
    AppointmentsComponent,
    AllMissedCallsComponent,
    MyCallsComponent,
    AgentCallsComponent,
    DataCleanupComponent,
    AllContactsComponent,
    CallSearchComponent,
    OwnershipComponent,
    TechnicalSupportComponent,
    OfficeManagerComponent,
    SupervisorComponent,
    AgentComponent,
    LanguageSelectComponent,
    CountrySelectComponent,
    OfficePickerComponent,
    TeamPickerComponent,
    SupervisorPickerComponent,
    LanguagePickerComponent,
    MapOverlayComponent,
    DispositionComponent,
    ActivityViewerComponent,
    CompaniesComponent,
    DoNotCallComponent,
    IncomingCallsComponent,
    UnmatchedIncomingCallComponent,
    DateFilterComponent,
    OrderFormComponent,
    CompanyPickerComponent,
    WarehousePickerComponent,
    DatePickerComponent,
    InfoFullPageComponent,
    InfoAccordionComponent,
    DispositionDropdownComponent,
    UserPickerComponent,
    DropdownButtonComponent,
    ReportPickerComponent,
    AddContactComponent,
    NotificationPopupComponent,
    EditUserComponent,
    AddUserComponent,
    FlaggedComponent,
    AnimatedNotificationComponent,
    RedeemgiftcardComponent
  ],
  imports: [
    BrowserModule, 
    FormsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
  HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DragDropModule,
    InfiniteScrollModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    /* Angular Material */
    MatSidenavModule, 
    MatButtonModule,
    MatIconModule,   
    MatFormFieldModule,
    MatDatepickerModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatDialogModule,
    MatInputModule,
    MatStepperModule,
    /* A 3rd party module that allows us to use *ngLet to make Angular variables.  Very useful */
    NgLetModule,
    /* JoyRide */
    JoyrideModule.forRoot(),
  ],
  exports: [MoveModalToBodyDirective],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },   
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
