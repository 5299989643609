import { Component } from '@angular/core';

@Component({
  selector: 'app-link-guidelines',
  templateUrl: './link-guidelines.component.html',
  styleUrls: ['./link-guidelines.component.css']
})
export class LinkGuidelinesComponent {

}
