import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '@network-request-button/network-request-button.component'
import { FlextableComponent } from "@flextable/flextable.component"

@Component({
  selector: 'app-language-management',
  templateUrl: './language-management.component.html',
  styleUrls: ['./language-management.component.css']
})

export class LanguageManagementComponent implements OnInit {

  constructor(private renderer: Renderer2, public http: HttpClient) {}

  endpoint = "/api/datasource/languages";
  payload: any = { }

  ngOnInit(): void {

    this.payload = {
      languageName: this.languageName,
      languageShortCode: this.languageShortCode
    }

  }

  languageName = '';
  languageShortCode = '';

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('submitForm') submitForm: NetworkRequestButtonComponent;
  @ViewChild('flextable') flextable: FlextableComponent;

  verb = "POST";

  buttonAction = "Add Language"
  rowLinkClicked($event){
    
    this.buttonAction = "Save"
    this.payload = { ... $event.rowData }    
    console.log(45, this.payload);
    this.verb = "PUT"

    this.endpoint = "/api/datasource/languages/id/" + this.payload["_id"];
    this.openModal.nativeElement.click();
  }

  async waitForResponse(response: any){

    if(response == false){
      console.error("Request Failure");
      return;
    }

    this.dismissModal();

    this.flextable.refreshTable();
  }

  headerButtonClicked(event: any){
    this.openModal.nativeElement.click();
  }

  tableButtonClicked(requestResult: any){
    alert( JSON.stringify(requestResult ) );
  }

  dismissModal() {
    const modalElement: HTMLElement = this.myModal.nativeElement;
    this.renderer.selectRootElement(modalElement, true).dispatchEvent(new Event('click'));
  }

}
