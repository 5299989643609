import { Component, OnInit, ElementRef, ViewChild, ViewChildren, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from '../../../../shared.service';
import { DataService } from '../../../../services/data.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrl: './order-form.component.css'
})

export class OrderFormComponent implements OnInit {

  customer: any = { }

  @Input() products: any = { }
  @ViewChild('closeOrderPopup') closeOrderPopup: ElementRef;

  cartItems = []
  edit = false;
  warehouses = [];
  companies = [];
  productCategories = [];
  selectedCategory = "";
  masterProductList = [];
  selectedRow = null;
  currentCurrency: string = 'EUR';
  userInfo: any = {}
  warehouse_id = "";
  company_id = "";
  resale = false;
  selectedUser = "";
  selectedUserName = "";  
  updateOrder = false;
  order_id = "";

  initDropdowns = false;

  orderDispositions = [
    "Sale",
    "Resale",
    "Appointment"
  ];
  selectedDisposition = "Sale";

  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService){

  }

  async ngOnInit(){

    // We wait 25 seconds here because this component initialized on startup, but this data
    // will rarely, if ever, be needed in the first 15 seconds of initialization.  So, we wait
    // giving the app a little breathing room.
    setTimeout( async () => {
      this.sharedService.getWarehouses().subscribe(data => this.warehouses = data);
      this.sharedService.getCompanies().subscribe(data => this.companies = data);
      this.sharedService.getProducts().subscribe(data => {
        this.products = data

      this.masterProductList = this.products;
      this.productCategories = [...new Set(this.products.map(product => product.productCategory).filter(Boolean))];
      this.productCategories.unshift("All");
      this.initDropdowns = true;

      });

    },1500);

    this.dataService.getOrderCustomer().subscribe( customer => {
      this.customer = customer;
    });

    this.dataService.getUserInfo().subscribe(data => {
      this.userInfo = data;
    });

    this.dataService.getEditOrder().subscribe(async customer_id => {
      // Get the customer info
      // Load it up

      let url = `/api/datasource/orders/id/${customer_id}`

      if(customer_id == null){
        return;
      }

        let results = await this.http.get(url).toPromise();

        url = `/api/datasource/coldleads/id/${results['orders']['customer_id']}`
        let scrape_result = await this.http.get(url).toPromise();


        this.cartItems = results['orders']['order'];
        this.company_id = results['orders']['company_id'];
        this.warehouse_id = results['orders']['warehouse_id'];
        this.customer = scrape_result['coldleads'];
        this.customer.contactFirstName = results['orders']['contactFirstName'];
        this.customer.contactLastName = results['orders']['contactLastName'];
        this.customer.contactEmail = results['orders']['contactEmail'];
        this.customer.selectedDisposition = results['orders']['orderType'];
        this.selectedDisposition = results['orders']['orderType'];
        this.updateOrder = true;
        this.order_id = results['orders']['_id'];
        this.selectedUser = results['orders']['resale_user_id'];
    });

    if (this.orderDispositions && this.orderDispositions.length > 0) {
      this.selectedDisposition = this.orderDispositions[0];
    }
  }

  updatePrice(item: any) {
    console.log('Updated price:', item.product_unit_price);
    // Implement any other logic you need here
  }


  onCategoryChange($event){
    console.log(120, this.products)
    if(this.selectedCategory == "All"){
      this.products = this.masterProductList;
      return;
    }

    this.products = this.masterProductList.filter(product => 
    product.productCategory === this.selectedCategory);

  }

  onCompanySelected(company_id){
    this.company_id = company_id
  }

  onWarehouseSelected(warehouse_id){
    console.log(120, this.products)
    this.products = this.masterProductList.filter(product => product.warehouse_id === warehouse_id);
    this.productCategories = [...new Set(this.products.map(product => product.productCategory).filter(Boolean))];
    this.productCategories.unshift("All");
    this.warehouse_id = warehouse_id;

  }

  onAddToCart($event){
    var product_id = $event.target.value;
    for(var product of this.products){
      if(product._id == product_id){
        product["qty"] = 25;
        this.cartItems.push(product);
      }
      this.currentCurrency = product.product_currency;
    }
    console.log(143, this.currentCurrency);
  }

  deleteItem(index: number): void {
      this.cartItems.splice(index, 1);
  }

saveContact(){
  this.edit = false;
}

editContact(){
  this.edit = true;
}

orderPopupClosed(){
  this.cartItems = [];
  this.edit = false;
  this.customer = null;
  this.selectedUser = "";
  this.selectedUserName = "";
  this.selectedDisposition = "Sale"
  this.updateOrder = false;
  this.order_id = "";
}

userChanged($event){
  console.log(136, $event)
  this.selectedUser = $event['user_id'];
  this.selectedUserName = $event['userName'];
}

async placeOrder(){

    if(this.cartItems.length == 0){
      alert("You cart cannot be empty to place an order");
      return;
    }

    if(this.company_id == ""){
      alert("You must select a company to attach this order to");
      return;
    }

    if(this.warehouse_id == ""){
      alert("You must select a warehouse to attach this order to");
      return;
    }


    if(!this.customer.contactFirstName || this.customer.contactFirstName.length < 1){
      alert("You must have a valid first name");
      return;
    }

    if(!this.customer.contactLastName || this.customer.contactLastName.length < 1){
      alert("You must have a valid last name");
      return;
    }

    if(!this.customer.contactEmail || this.customer.contactEmail.length < 1){
      alert("You must have a valid email");
      return;
    }

    if(this.selectedDisposition !== 'Resale'){
      this.selectedUser = "";
    }

    //console.log(111, this.cartItems, this.customer, this.userInfo)
    console.log(169, this.selectedUser)
    this.dataService.placeOrder(this.cartItems, this.customer, this.userInfo, this.company_id, this.warehouse_id, this.selectedDisposition, this.selectedUser, this.selectedUserName)

    this.closeOrderPopup.nativeElement.click();
    
}

async updateExistingOrder(){

    let total = 0;

    for(var item of this.cartItems){
      total += (item.qty * item.product_unit_price)
    }

  let orderInfo = {
    order_id: this.order_id,
    cartItems:this.cartItems, 
    customer:this.customer, 
    userInfo:this.userInfo, 
    company_id:this.company_id, 
    warehouse_id:this.warehouse_id, 
    selectedDisposition:this.selectedDisposition, 
    selectedUser_id:this.selectedUser, 
    selectedUserName:this.selectedUserName,
    totalValue: total
  }

  this.dataService.updateOrder(orderInfo);

  this.closeOrderPopup.nativeElement.click();

}

async orderDispositionChanged(){
}

}
