import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../legacy/base/base.service';

@Component({
  selector: 'app-call-scripts',
  templateUrl: './call-scripts.component.html',
  styleUrls: ['./call-scripts.component.css']
})

export class CallScriptsComponent implements OnInit {

  intro: string = "";//Hi, my name is Adam, and I'm calling from a company called Stucco Leads on Autopilot.";
  provocativeQuestion: string = "";//I recognize that you're probably very busy, so I'll be respectful of your time.  I got a lead for stucco installation in Salt Lake, but I actually don't have a contractor there.  This particular lead is a little stale, but I figured I'd reach out to everyone in the local market to see if anyone's interested in getting leads.";
  pitch: string = "";//Well, essentially we're a digital marketing agency that focuses on contracting businesses, and we're basically perfected ad copy for stucco installation and repair, we're having a lot of success with stucco contractors in particular.  So my question to you is simple: how much revenue would you need to generate in new business to justify a $3,000 per month spend?";

  selectedService: string = 'Default';
  services: string[] = [];

  sections: { section_name: string, section_text: string}[] = [ ]

  scripts = [ ];

  constructor(private http: HttpClient, public service: BaseService) { }

  ngOnInit(){
      this.http.get('/api/datasource/coldleads/distinct/scrape_result.category/account').subscribe(
      response => {
        console.log(response);
        this.services = response["coldleads"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );

      this.http.get('/api/datasource/call_scripts/max_records/1000').subscribe(
      response => {
        console.log(response);
        this.scripts = response["call_scripts"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );
  }

  async addSection(){
    this.sections.push( { section_name: "", section_text: "" } );
  }

  async addScript(){
    if(this.selectedService == ""){
      alert("You must select a category from the dropdown");
      return;
    }

    let call_scripts = await this.http.post(`api/datasource/call_scripts/`,
      {
        "script_name": `${this.selectedService}`,
        "sections": this.sections
      }).toPromise();
    this.scripts.push(call_scripts);
  }

  async loadScript($event, script){
    $event.preventDefault();
    this.sections = script.sections;
  }
}
