 <div class="modal fade" appMoveModalToBody  id="order" tabindex="-1" aria-labelledby="orderLabel" aria-hidden="true" (hidden.bs.modal)="orderPopupClosed()">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="orderLabel">{{"Place Order" | translate }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div *ngIf="!edit">
                    <div class="card" *ngIf="customer?.scrape_result">
                        <div class="card-body">
                            <h5 class="card-title reset-heading">{{ customer?.scrape_result?.title }}</h5>
                            <h6 class="card-subtitle mb-2 text-muted reset-heading"><b>{{ customer?.scrape_result?.address }}</b></h6>
                            <h6 class="card-subtitle mb-2 text-muted reset-heading">
                                <i>{{ "c/o" | translate}}</i>&nbsp;{{ customer?.contactFirstName }}&nbsp;{{ customer?.contactLastName }}
                            </h6>
                            <h6 class="card-subtitle mb-2 text-muted reset-heading">{{ customer?.contactEmail }}</h6>
                            <h6 class="card-subtitle mb-2 text-muted reset-heading">{{ customer?.scrape_result?.phone }}</h6>
                            <a href="javascript:void(0)" (click)="editContact()" class="card-link">{{"Edit" | translate }}</a>
                        </div>
                    </div>
                </div>

            <div *ngIf="edit">
                <div class="card" *ngIf="customer?.scrape_result">
                    <div class="card-body">
                        <div class="mb-3">
                            <label for="titleInput" class="form-label">{{"Title" | translate }}</label>
                            <input type="text" class="form-control" id="titleInput" [(ngModel)]="customer.scrape_result.title" />
                        </div>
                        <div class="mb-3">
                            <label for="addressInput" class="form-label">{{"Address" | translate }}</label>
                            <input type="text" class="form-control" id="addressInput" [(ngModel)]="customer.scrape_result.address" />
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="contactFirstNameInput" class="form-label">{{"Contact First Name" | translate }}</label>
                                <input type="text" class="form-control" id="contactFirstNameInput" [(ngModel)]="customer.contactFirstName" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="contactLastNameInput" class="form-label">{{"Contact Last Name" | translate }}</label>
                                <input type="text" class="form-control" id="contactLastNameInput" [(ngModel)]="customer.contactLastName" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9 mb-3">
                                <label for="contactEmailInput" class="form-label">{{"Contact Email" | translate }}</label>
                                <input type="email" class="form-control" id="contactEmailInput" [(ngModel)]="customer.contactEmail" />
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="phoneInput" c
                                lass="form-label">{{"Phone" | translate }}</label>
                                <input type="tel" class="form-control" id="phoneInput" [(ngModel)]="customer.scrape_result.phone" />
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary" (click)="saveContact()">{{"Save" | translate }}</button>
                    </div>
                </div>
            </div>
                <div class="row" *ngIf="initDropdowns">
                    <div class="col-md-6" >
                        <app-company-picker (companyChanged)="onCompanySelected($event)"></app-company-picker>
                    </div>
                    <div class="col-md-6">
                        <app-warehouse-picker (warehouseChanged)="onWarehouseSelected($event)"></app-warehouse-picker>
                    </div>
                </div>


<div class="row mb-4">
  <div class="col-sm-2">
    <label for="categoryDropdown" class="form-label">{{ "Product Category" | translate }}</label>
  </div>
  <div class="col-sm-4">
    <select id="categoryDropdown" name="office" class="form-control" [(ngModel)]="selectedCategory" (change)="onCategoryChange($event)">
      <option *ngFor="let category of productCategories" [ngValue]="category">{{ category | translate }}</option>
    </select>
  </div>
  <div class="col-sm-2">
    <label for="dispositionDropdown" class="form-label">{{ "Order Disposition" | translate }}</label>
  </div>
  <div class="col-sm-2">
    <div class="d-flex align-items-center">
      <select id="dispositionDropdown" name="office" class="form-control flex-grow-1 me-2" [(ngModel)]="selectedDisposition" (change)="orderDispositionChanged()">
        <option *ngFor="let disposition of orderDispositions" [ngValue]="disposition">{{ disposition | translate }}</option>
      </select>
    </div>
    <div class="col-sm-6">
      <div name="selectUsers" *ngIf="selectedDisposition == 'Resale'" >
        <app-user-picker [selectedUserId]="selectedUser" (userChanged)="userChanged($event)"></app-user-picker>
      </div>
    </div>
  </div>
</div>




                <div class="row mb-3">≈
                    <label for="inputText" class="col-sm-2 col-form-label">{{"Add To Cart" | translate }}</label>
                    <div class="col-sm-10">
                        <select name="mySelect" class="form-select" aria-label="Default select example" (change)="onAddToCart($event)">
                            <option value="default">{{"Select Item" | translate }}</option>
                            <option *ngFor="let product of products" [value]="product._id">{{product.product_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="cart-container">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{"Product Name" | translate }}</th>
                                <th>{{"Unit Price" | translate }}</th>
                                <th>{{"Currency" | translate }}</th>
                                <th>{{"Qty" | translate }}</th>
                                <th>{{"Total Price" | translate }}</th>
                                <th>{{"Actions" | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of cartItems; let i = index">
                                <td>{{ item.product_name }}</td>
                                <td><input type="number" step="0.01" class="form-control" [(ngModel)]="item.product_unit_price" (ngModelChange)="updatePrice(item)" /></td>
                                <td>{{ item.product_currency }}</td>
                                <td>
                                    <input type="number" [(ngModel)]="item.qty" min="1" class="form-control" placeholder="Qty" />
                                </td>
                                <td>{{ (item.qty || 25) * item.product_unit_price | currency:currentCurrency:'symbol-narrow' }}</td>
                                <td>
                                    <button type="button" class="btn btn-danger btn-sm" (click)="deleteItem(i)">{{"Delete" | translate }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #closeOrderPopup>{{"Close" | translate }}</button>
                <button type="submit" *ngIf="!updateOrder" class="btn btn-primary" (click)="placeOrder()">{{"Place Order" | translate }}</button>
                <button type="submit" *ngIf="updateOrder" class="btn btn-primary" (click)="updateExistingOrder()">{{"Update Order" | translate }}</button>
            </div>
        </div>
    </div>
</div>
