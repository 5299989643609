<div class="blog-container">
    <h1>How To Use scraperCRM</h1>

    <h2><u>1. Search For Digital Assets</u></h2>
    <p>
    	<img src="assets/img/step1.webp" alt="">
	</p>
    
    <h2><u>2. See How Many Points You Need</u></h2>
    <p>
    	<img src="assets/img/step2.webp" alt="">
	</p>

    <h2><u>3. Get Points Immediately</u></h2><h3>By agreeing to provide a link in the future</h3>
    <p>
    	<img src="assets/img/step3.webp" alt="">
	</p>

    <h2><u>4. Propose an exchange</u></h2><h3>In case you don't have points.</h3><h5>This is good for sites that don't qualify to provide a link, or for sites that do not wish to put links on their site.</h5>
    <p>
    	<img src="assets/img/step4.webp" alt="">
	</p>
</div>
