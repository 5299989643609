<div class="container mt-4">
    <!-- Dropdown -->
	<div class="dropdown mb-3">
	    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
	        {{selectedService || 'Select Service'}}
	    </button>
	    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
	        <li *ngFor="let service of services">
	            <a class="dropdown-item" (click)="selectedService = service">{{service}}</a>
	        </li>
	    </ul>
	</div>

    <!-- Text areas and button -->
    <div class="mb-3">
<!--     <div class="form-group mb-2">
        <input for="introInput" placeholder="Intro" type="text" class="form-control mb-2"> 
        <textarea class="form-control" id="introInput" rows="3" [(ngModel)]="intro" placeholder="Enter Intro"></textarea>
    </div>
 -->
    <div *ngFor="let section of sections" class="form-group mb-2">
        <input placeholder="Section Label" type="text" class="form-control mb-2" [(ngModel)]="section.section_name">
        <textarea class="form-control" rows="3" [(ngModel)]="section.section_text" placeholder="Section Text">{{section.section_text}}</textarea>
    </div>

    <button class="btn btn-primary m-1" (click)="addSection()">Add Section</button>
<!--     <div class="form-group mb-2">
        <label for="questionInput">Provocative Question</label>
        <textarea class="form-control" id="questionInput" rows="3" [(ngModel)]="provocativeQuestion" placeholder="Enter Question"></textarea>
    </div>
    <div class="form-group mb-3">
        <label for="pitchInput">Pitch</label>
        <textarea class="form-control" id="pitchInput" rows="3" [(ngModel)]="pitch" placeholder="Enter Pitch"></textarea>
    </div> -->
    <button class="btn btn-primary m-1" (click)="addScript()">Add Script</button>
</div>

    <!-- List of Scripts -->
	<div>
	    <h3>Scripts</h3>
	    <ul class="list-group">
	        <li *ngFor="let script of scripts" class="list-group-item"><a href="#" (click)="loadScript($event, script)">{{script.script_name}}</a></li>
	    </ul>
	</div>

</div>



<!--             <div class="card-body">

              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#verticalycentered">
                Start New Thread
              </button>
              <div class="modal fade" appMoveModalToBody  id="verticalycentered" tabindex="-1">
                
                <div class="modal-dialog modal-dialog-centered modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Call Script</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row mb-3">
                      <label for="inputText" class="col-sm-2 col-form-label">Subject</label>
                      <div class="col-sm-10">
                        
                        <select name="mySelect" class="form-select" aria-label="Default select example" >
                      <option value="default">Choose Category</option>
                     
                    </select>
                      </div>
                    </div>
                    
                <div class="comment-container">
                        <div class="form-group">
                          
                          <label for="comment">Intro</label>
                          <textarea id="comment" name="comment"  class="form-control" rows="5" ></textarea>
                          
                        
                        </div>
                      </div>  
                <div class="comment-container mt-4">
                        <div class="form-group">
                          
                          <label for="comment">Provocative Question</label>
                          <textarea id="comment" name="comment"  class="form-control" rows="5" ></textarea>
                          
                        
                        </div>
                      </div>  
                 <div class="comment-container mt-4">
                        <div class="form-group">
                          
                          <label for="comment">Pitch</label>
                          <textarea id="comment" name="comment"  class="form-control" rows="5" ></textarea>
                          
                        
                        </div>
                      </div>  
                                         
                      </div>
                    <div class="modal-footer">
                      <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->



