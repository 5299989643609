import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '../../../../../reusable/ui/network-request-button/network-request-button.component'
import { FlextableComponent } from "../../../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../../../shared.service';
import { AdminManagement } from '../../admin.component'

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.css']
})

export class ProductManagementComponent implements OnInit {

  constructor(private renderer: Renderer2, public http: HttpClient, private sharedService: SharedService) {}

  endpoint = "/api/datasource/products";
  payload: any = {
  }

  countries = [];
  languages = [];
  warehouses = [];
  supervisors = [];
  selectedCountry = "";


  async ngOnInit() {
    this.sharedService.getCountries().subscribe(data => this.countries = data);
    this.sharedService.getLanguages().subscribe(data => this.languages = data);
    this.sharedService.getWarehouses().subscribe(data => this.warehouses = data);
    this.supervisors = await this.sharedService.getSupervisors();
    console.log(32, this.supervisors);
  }

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('submitForm') submitForm: NetworkRequestButtonComponent;
  @ViewChild('flextable') flextable: FlextableComponent;

  verb = "POST";

  buttonAction = "Add Product"
  rowLinkClicked($event){
    
    this.buttonAction = "Save"
    this.payload = { ... $event.rowData }    
    console.log(45, this.payload);
    this.verb = "PUT"

    this.endpoint = "/api/datasource/products/id/" + this.payload["_id"];
    this.openModal.nativeElement.click();
  }

  async waitForResponse(response: any){

    if(response == false){
      console.error("Request Failure");
      return;
    }

    this.dismissModal();

    this.flextable.refreshTable();
  }

  headerButtonClicked(event: any){
    this.openModal.nativeElement.click();
  }

  tableButtonClicked(requestResult: any){
    alert( JSON.stringify(requestResult ) );
  }

  dismissModal() {
    const modalElement: HTMLElement = this.myModal.nativeElement;
    this.renderer.selectRootElement(modalElement, true).dispatchEvent(new Event('click'));
  }

  onWarehouseChange(event: Event) {
    const selectedWarehouseId = (event.target as HTMLSelectElement).value;
    console.log('Selected warehouse ID:', selectedWarehouseId);

    // getProductCategoriesByWarehouse
    // Your logic here
    // For example, you can update other parts of your component based on the selected warehouse
  }

}
