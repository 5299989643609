 <button #openModal type="button" class="btn btn-secondary" style="display: none" data-bs-toggle="modal" data-bs-target="#warehouse">{{"Open" | translate }}</button>

<app-flextable #flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(rowLinkClicked)='rowLinkClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
	[options]="
		{
			datasource: 'warehouses',

			bAllowDeletion: true,

			headerButtonsAr: [
				'Add Warehouse'
			],

            linkColumnPreventDefault: true,
            
            linkColumns: [ {
                     column: 'WarehouseName',
                     path: '/main/sysadmin/planmanagement/plan/'
                }
            ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

            columnNameRewrites: [
                'Selected', 'Warehouse', 'City', 'Country', 'Emails', 'Address', 'Language'
            ],

			scope: 'account',
			
			columns: [
				'selected',
				'warehouseName',
				'warehouseCity',
				'warehouseCountry',
				'primaryOrderEmailAddresss',
				'warehouseAddress',
				'warehouseLanguage'
			]
		}"
></app-flextable>

<div class="modal fade" appMoveModalToBody  id="warehouse" tabindex="-1">
    <form name="addCountry">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Warehouse</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    

                    <div class="mb-4">
                        <label for="warehouse_name" class="form-label">Warehouse Name</label>
                        <input id="warehouse_name" name="warehouse_name" [(ngModel)]="payload.warehouseName" type="text" class="form-control">
                    </div>
                    <div class="mb-4">
                        <label for="warehouse_city" class="form-label">Warehouse City</label>
                        <input id="warehouse_city" name="warehouse_name" [(ngModel)]="payload.warehouseCity" type="text" class="form-control">
                    </div>
					<div class="mb-4">
					    <label for="countryDropdown" class="form-label">Country</label>
					    <select id="countryDropdown" name="country" class="form-control" [(ngModel)]="payload.warehouseCountry">
					        <option *ngFor="let country of countries" [ngValue]="country.countryCode">{{ country.countryName }}</option>
					    </select>
					</div>
                    <div class="mb-4">
	                     <label for="primary_language" class="form-label">Primary Language</label>
	                    <select id="languageDropdown" name="language" class="form-control" [(ngModel)]="payload.warehouseLanguage">
    						<option *ngFor="let language of languages" [ngValue]="language.languageShortCode">{{ language.languageName }}</option>
						</select>

                    </div>
	                <div class="mb-4">
	                        <label for="email_orders" class="form-label">Email Orders</label>
	                        <input id="email_orders" name="email_orders" [(ngModel)]="payload.primaryOrderEmailAddress" type="text" class="form-control">
	                </div>
	            	<div class="mb-4">
	                        <label for="email_orders_cc" class="form-label">CC Orders</label>
	                        <input id="email_orders_cc" name="email_orders" [(ngModel)]="payload.copyOrderEmailAddress" type="text" class="form-control">
	                </div>
                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="submit" class="btn btn-primary">Add Language</button> -->
                    <network-request-button #submitForm (result)="waitForResponse($event)" [request]="endpoint" [payload]="payload" [verb]="verb">{{buttonAction | translate}}</network-request-button>
                </div>
            </div>
        </div>
    </form>
</div>