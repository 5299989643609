import { Component, OnInit, Directive, ElementRef, Output, EventEmitter } from '@angular/core';
import { BaseService } from '../../../legacy/base/base.service'
import { FlexTableOptions } from '../../../reusable/ui/flextable/flextable-options';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-endless-scroll',
  templateUrl: './endless-scroll.component.html',
  styleUrls: ['./endless-scroll.component.css']
})

export class EndlessScrollComponent implements OnInit {

  constructor(private service: BaseService, public http: HttpClient) { }

  options!: Partial<FlexTableOptions>;

  leads = [];
  nextPage = '';

  async ngOnInit(){

  this.options = {
      bAllowDeletion: false,
      datasource: 'coldleads',
      noIdAggregate: true,
      columns: [
          '_id',
          'title',
          'scrape_result.images'
      ],

      scope: 'account',

      aggregate: [
        {
              $match: { 
                      $and: [
                          {
                              $or: [  
                                  { cooldown: { $exists: false } },
                                  { cooldown: { $lte: '$$NOW' } }
                              ]
                          },
                          { agent_id: '$res.locals.user._id' }
                      ]
              }
        },
        { 
            $project: {
                _id: 1, 
                title: "$scrape_result.title",
                phone: "$scrape_result.phone",
                address: "$scrape_result.address",
                google_maps_url: "$scrape_result.link",
                website: "$scrape_result.web_site",
                images: {
                    $concatArrays: [
                        { $ifNull: [{ $map: { input: "$scrape_result.images", as: "img", in: "$$img.image" } }, []] },
                        {
                            $reduce: {
                                input: "$scrape_result.user_reviews",
                                initialValue: [],
                                in: {
                                    $concatArrays: [
                                        "$$value", 
                                        { $ifNull: [{ $map: { input: "$$this.Images", as: "userImg", in: "$$userImg" } }, []] }
                                    ]
                                }
                            }
                        }
                    ]
                },
                user_reviews: "$scrape_result.user_reviews"
            }
        },
        { $unwind: "$images" },  // Unwind the images array
        { $group: { _id: "$_id", website: { $first: "$website"}, user_reviews: { $first: "$user_reviews"}, title: { $first: "$title" }, phone: { $first: "$phone" }, google_maps_url: { $first: "$google_maps_url" } , address: { $first: "$address" }, uniqueImages: { $addToSet: "$images" } } },
        { $project: { _id: 1, title: 1, phone: 1, address: 1, images: "$uniqueImages", website: 1, google_maps_url: 1, user_reviews: 1 } }  // Reassemble the document
    ]}

    let options = this.options;

    var data: any = await this.service.getInitialDataTableList(options.datasource, 10, options.columns, options.scope, options.filter, options.sort, options.aggregate);
    this.leads = data['coldleads'];
    this.nextPage = data["pagination"]["next_page_endpoint"];
    console.log(38, this.nextPage)
     this.parseLeads(data['coldleads'])
  }

  loadMoreImages() {
    this.service.loadingDataTablePagination(this.nextPage, this.options.columns).subscribe(
        (data: any) => {
            ;
            this.leads = [...this.leads, ... data['coldleads']];

            this.leads = Array.from(new Set(this.leads.map(lead => lead.scrape_data.phone_number)));

            this.nextPage = data["pagination"]["next_page_endpoint"];
            this.parseLeads(data['coldleads'])
    })
  }


  async parseLeads(leads){
    for(var lead of leads){
      let website_url = lead["website"];
      try {
        let imagesAr = await this.http.post(`/api/scraper/getImageArray`, { "website_url": website_url } ).toPromise();
        //console.log(92, imagesAr);
        lead['images'] = [ ... lead['images'], ... imagesAr['result'] ];
        lead['images'] = Array.from(new Set([...lead['images'], ...imagesAr['result']]));
        lead['images'] = lead['images'].slice(0, 20);
      } catch(err){

      }
      for(var user_review of lead["user_reviews"]){
        //console.log(108, user_review);
        if(Array.isArray(user_review["Images"])){
          lead['images'] = [ ... lead['images'], ... user_review["Images"] ]
        }
      }
      
    }
  }

  onImageClick(image, index){
    //this.images.splice(index, 1)
  }

  removeImage(index){
    //this.images.splice(index, 1)
  }

  async addToShortlist(lead){
    lead['disabled'] = true;
    let patchData = { "prequalificationStatus": "shortlist" };
    this.service.putDataById(lead._id, patchData).subscribe(
      (data: any) => {
        console.log(65, data);
      })
  }

  worthTrying(lead){
    lead['disabled'] = true;
    let patchData = { "prequalificationStatus": "worthTrying" };
    this.service.putDataById(lead._id, patchData).subscribe(
      (data: any) => {
        console.log(65, data);
      })
  }

  doNotBother(lead){
    lead['disabled'] = true;
    let patchData = { "prequalificationStatus": "doNotBother" };
    this.service.putDataById(lead._id, patchData).subscribe(
      (data: any) => {
        console.log(65, data);
      })
  }

}
