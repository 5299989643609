
<!-- <mat-drawer-container class="sidebar-container" autosize style="min-height: 800px;">
   <mat-drawer #drawer class="sidebar-sidenav" mode="side" position="end">
      <div class="content-wrapper">
         <div #targetDiv><app-info (closePanel)="closePanel($event)" [parentEvent]="parentEvent" [panelId]="panelId" (childEvent)="handleNotification($event)"></app-info></div>
      </div>
   </mat-drawer> -->

<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">{{"Title" | translate }}</th>
      <th scope="col">{{"Direction" | translate }}</th>
      <th scope="col">{{"From" | translate }}</th>
      <th scope="col">{{"To" | translate }}</th>
      <th scope="col">{{"Start Time" | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let call of all_calls; let i = index">
      <td>
        <div class="d-flex align-items-center">
          {{call.duration}}&nbsp;
          <a #playIcons class="fa fa-play-circle" channels="1" aria-hidden="true" (click)="playAudio(call['call_session_id'], i)">&nbsp;Dl&nbsp;</a>
          <audio #audioPlayers class="audio-control" controls preload="auto"
            (pause)="onPause(i)"
            (ended)="onEnded(i)"
            (play)="onPlay(i)">
          </audio>
        </div>
      </td>
      <td *ngIf="call['title']" style="max-width:200px;">
        <a id="call['_id']" *ngIf="call?.to" (click)="openPanel(call.contact_id)">
          {{call['title']}}
        </a>
      </td>
      <td *ngIf="!call['title']">
        <a style="color:red" #contactText [id]="call['formattedNumber']" *ngIf="call?.to" [routerLink]="['/main/search']" (click)="searchNumber(call?.to)">
          {{"No Contact In Database" | translate }}      
        </a>
      </td>
      <td><i>{{call.direction}}</i></td>
      <td>{{call.from}}</td>
      <td>{{call.to}}</td>
      <td>{{call.start_time | date: 'medium'}}</td>
    </tr>
  </tbody>
</table>
<nav aria-label="Page navigation">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Previous" (click)="loadPrevPage($event)">
        <span aria-hidden="true">&laquo; {{"Prev" | translate }}</span>
      </a>
    </li>
    <!-- Add dynamic page links here if needed -->
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next" (click)="loadNextPage($event)">
        <span aria-hidden="true">{{"Next" | translate }} &raquo;</span>
      </a>
    </li>
  </ul>
</nav>
<!-- </mat-drawer-container> -->
