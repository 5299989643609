<div class="card-body">
   <!-- Vertically centered Modal -->
   <button type="button" #openscript class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#disposition" style="display:none"></button>
   <div class="modal fade" appMoveModalToBody  id="disposition" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title">Call Script</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <div class="row mb-3">
                  <label for="inputText" class="col-sm-2 col-form-label">Subject</label>
                  <div class="col-sm-10">
                     <select name="mySelect" class="form-select" aria-label="Default select example" >
                        <option value="default">Choose Category</option>
                     </select>
                  </div>
               </div>

            </div>
            <div class="modal-footer">
               <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
               
            </div>
         </div>
      </div>
   </div>
</div>