import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'language-picker',
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.css'
})

export class LanguagePickerComponent implements OnInit{

  @Input("selectedLanguage") selectedLanguage = "";
  @Output() selectedLanguageChange = new EventEmitter<string>();

  languages: any[] = [];

  constructor(private http: HttpClient, private sharedService: SharedService ) { 
  }

  ngOnInit(){
    this.loadLanguages();
  }

  async loadLanguages(){
    this.sharedService.getLanguages().subscribe(data => this.languages = data);
    this.selectedLanguage = this.languages[0];
  }

onLanguageChange() {
    console.log('Selected Language:', this.selectedLanguage.toLowerCase());
    this.selectedLanguageChange.emit(this.selectedLanguage.toLowerCase());
  }
}
