import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '../../../../shared.service';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-my-calls',
  templateUrl: './my-calls.component.html',
  styleUrl: './my-calls.component.css'
})

export class MyCallsComponent  implements OnInit {

  constructor(private http: HttpClient, private sharedService: SharedService, private dataService: DataService, private router: Router) { }

  all_calls = [];
  ngOnInit(){
            const encodedStr = this.getCookie('Authorization');
          const base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
          const decodedStr = atob(base64Str);
          const authStr = decodedStr.split(':')[0];
          let user_id = authStr.split('@%40@')[1];

    this.router.navigate(['main/admin/agents/agent-calls/id/' + user_id])
  }

getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  // async getAllCalls(){
  //   var api_url = `api/calls/getAllCalls`;
  //   let all_calls = await this.http.get(api_url).toPromise();  
  //   for(var call of all_calls['all_calls']){
  //     call['from'] = this.dataService.formatPhoneNumber(call['from']);
  //     call['to'] = this.dataService.formatPhoneNumber(call['to']);
  //   }
  //   this.all_calls = all_calls["all_calls"].reverse();

  //   console.log(23, this.all_calls);
  // }

  // async searchNumber(phoneNumber){
    

  //   var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.phone" + '/' + phoneNumber;

  //   //this.router.navigate([`main/search`]);
  //   let result = await this.http.get(api_url).toPromise();
  //   this.sharedService.updateSearchResults(result, phoneNumber);
  // }
}