                        <div class="filter">
                            <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li class="dropdown-header text-start">
                                    <h6>{{ "Filter" | translate }}</h6>
                                </li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'today')" href="#">{{ "Today" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'yesterday')" href="#">{{ "Yesterday" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'week')" href="#">{{ "This Week" | translate }}</a></li>
                                
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'month')" href="#">{{ "This Month" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'last_month')" href="#">{{ "Last Month" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'year')" href="#">{{ "This Year" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="toggleCustomDateInputs($event)" href="#">{{ "Custom" | translate }}</a></li>
                                <li *ngIf="showCustomDateInputs">
                                    <div class="d-flex justify-content-between">
                                        <input type="date" class="form-control me-2" [(ngModel)]="startDate" placeholder="Start Date" />
                                        <input type="date" class="form-control ms-2" [(ngModel)]="endDate" placeholder="End Date" />
                                    </div>
                                    <button type="button" class="btn btn-primary ms-2" (click)="onShowCustomDates($event)">Show</button>
                                </li>
                            </ul>
                        </div>