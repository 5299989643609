
<app-header></app-header>
<app-aside></app-aside>


<main id="main" class="main">

	<mat-drawer-container class="sidebar-container" autosize style="min-height: 800px; height: 100%;" >
	    <mat-drawer #drawer class="sidebar-sidenav" mode="side" position="end" [style.width]="maxWidth" [style.min-width]="maxWidth" >
 	        <div class="content-wrapper">
	            <div #targetDiv><app-info (closePanel)="closePanel($event)" [parentEvent]="parentEvent" [panelId]="panelId" (childEvent)="handleNotification($event)"></app-info></div>
	        </div>
	    </mat-drawer>
		<router-outlet></router-outlet>
	</mat-drawer-container>
	
</main>
<app-order-form></app-order-form>
<app-calls *ngIf="dialPad"></app-calls>
<map-overlay></map-overlay>
<app-incoming-calls></app-incoming-calls>
<app-unmatched-incoming-call></app-unmatched-incoming-call> 
<app-add-contact></app-add-contact>
<app-edit-user></app-edit-user>
<app-add-user></app-add-user>
<app-notification-popup></app-notification-popup>
<!-- <app-info-full-page></app-info-full-page> -->
<!-- <app-info style="position: fixed; top: 10px; right: 10px; width: 500px; height: 400px; z-index: 1000; background-color: white; box-shadow: 0px 0px 8px rgba(0,0,0,0.5);"></app-info> -->

<!-- <app-canvas></app-canvas> -->

