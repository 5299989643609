<div class="container">

      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-4">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt="">
                  <span class="d-none d-lg-block">{{dataService.getProductName()}}</span>
                </a>
              </div><!-- End Logo -->




              <div class="card mb-3">

                <div class="card-body">

                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">{{ 'login_to_your_account' | translate }}</h5>
                    <p class="text-center small">{{ 'enter_username_and_pass' | translate }} </p>
                  </div>

                  <form class="row g-3 needs-validation" novalidate >

                    <div class="col-12"   *ngIf="!bRecoverAccount">{{ "" | translate }}       {{ "" | translate }} 
                      <label for="yourUsername" class="form-label">{{ "username" | translate }} </label>
                      <div class="input-group has-validation">
                        <span class="input-group-text" id="inputGroupPrepend">&#64;</span>
                        <input type="text" name="username" class="form-control" id="yourUsername" required [(ngModel)]="loginData.userId" autocapitalize="none" autocorrect="off" spellcheck="false" autocomplete="off" (ngModelChange)="loginData.userId = loginData.userId.trim()" (keydown.enter)="tryLogin($event)">
                        <div class="invalid-feedback">{{ "Please enter your username." | translate }}</div>
                      </div>
                    </div>

<div class="col-12" *ngIf="!bRecoverAccount">
  <label for="yourPassword" class="form-label">{{ "password" | translate }}</label>
  <div class="input-group">
    <input [type]="showPassword ? 'text' : 'password'" name="password" class="form-control" id="yourPassword" required [(ngModel)]="loginData.pwd" spellcheck="false" autocomplete="off" (keydown.enter)="tryLogin($event)">
    <button type="button" class="btn btn-secondary" (click)="showPassword = !showPassword">
      {{showPassword ? 'Hide' : 'Show'}}
    </button>
  </div>
  <div class="invalid-feedback">{{ "Please enter your password!" | translate }}</div>
</div>


                    <div class="col-12" *ngIf="!bRecoverAccount" >
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="remember" value="true" [(ngModel)]="rememberMe" id="rememberMe">
                        <label class="form-check-label" for="rememberMe">{{ "remember_me" | translate }} </label>
                      </div>
                    </div>
                     <div class="col-12"  *ngIf="!bRecoverAccount" >
                      <network-request-button #login (result)="waitForLoginResponse($event)" [request]="authorizeEndpoint" [payload]="loginData" verb="POST">{{ "login" | translate }}</network-request-button>
                    </div>


              
<div class="col-12" *ngIf="bRecoverAccount">
   <p class="small mb-0">{{ "An email was sent to the address above.  Type in the code to change your password" | translate}}</p>
<form style="text-align:center">

    <div class="input-container">
      <input #firstDigit class="digit-input  form-control" type="text" maxlength="1" pattern="[0-9]" required>
    </div>
    <div class="input-container">
      <input class="digit-input  form-control" type="text" maxlength="1" pattern="[0-9]" required>
    </div>
    <div class="input-container">
      <input class="digit-input form-control" type="text" maxlength="1" pattern="[0-9]" required>
    </div>
    <div class="input-container">
      <input class="digit-input  form-control" type="text" maxlength="1" pattern="[0-9]" required>
    </div>
    <div class="input-container">
      <input class="digit-input  form-control" type="text" maxlength="1" pattern="[0-9]" required>
    </div>
  </form>
</div>
                

                    <div class="col-12" *ngIf="!bRecoverAccount" >
                      <!-- <p class="small mb-0">Don't have account? <a [routerLink]="['/application']" [queryParams]="{ref: 'login', ad: 'none'}">Apply Online</a></p> -->
                      <p class="small mb-0">{{ "dont_have_account" | translate }} <a href="" routerLink="/register">{{ "create_an_account" | translate }}</a></p>
                      <p class="small mb-0"><a href="" [routerLink]="['/register']" [queryParams]="{ account_type: 'affiliate' }">{{ "become_an_affiliate" | translate }} </a>| <a href="" (click)="recoverAccount($event)">{{ "recover_account" | translate }}</a></p>
                     </div>
                  </form>
  <!-- Language Dropdown -->
<div class="dropdown">
  <a class="nav-link dropdown-toggle" href="#" role="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
    <span appTwemoji id="currentLanguage">{{currentLanguageLabel}}</span>
  </a>
  <ul class="dropdown-menu" aria-labelledby="languageDropdown">
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'en')">🇺🇸 English</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'de')">🇩🇪 Deutsch</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'tr')">🇹🇷 Türkçe</a></li>
    <li><a appTwemoji class="dropdown-item" href="#" (click)="changeLanguage($event, 'fr')">🇫🇷 Français</a></li>
  </ul>
</div>
                </div>
              







              </div>












            </div>
          </div>
        </div>

      </section>

    </div>