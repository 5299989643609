import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SharedService } from '../../shared.service';
import { BaseService } from "../../legacy/base/base.service"
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WebsocketService} from '@websocket'
import { Location } from '@angular/common';
import { CdkDragMove } from '@angular/cdk/drag-drop';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

@Component({
  selector: 'app-incoming-calls',
  templateUrl: './incoming-calls.component.html',
  styleUrl: './incoming-calls.component.css'
})

export class IncomingCallsComponent implements OnInit {

  caller = {
    title: '',
    name: 'J',
    address: 'A',
    phone: '',
    additionalPhones: [],
    website: '',
    mapsLink: '',
    email: '',
    photoUrl: '',
    callHistory: [],
    ordersAr: [],
    contact_id: ''
  };

  draggingDisabled: boolean = false;

  isVisible: boolean = false; // Controls the visibility, true by default

  toggleVisibility(): void {
      this.isVisible = false; // Hides the component
  }

  constructor(private dataService: DataService) {

  }

  ngOnInit(): void {

    //this.caller = JSON.parse(localStorage.getItem("tmpData"));

    this.dataService.getIncomingCall().subscribe( incomingCallInfo => {
      this.isVisible = true;

      let leadInfo = incomingCallInfo["incoming_call"];
      let coldlead = leadInfo["coldlead"];
      let scrapeData = coldlead["scrape_result"];
      this.caller.title = scrapeData["title"] || "";
      this.caller.name = `${coldlead["contactFirstName"] || ""} ${coldlead["contactLastName"] || ""}`.trim();
      this.caller.phone = scrapeData["phone"] || "";


      if(Array.isArray(coldlead["additionalPhones"]))
        this.caller.additionalPhones = coldlead["additionalPhones"] || [];
        this.caller.address = scrapeData["address"] || "";
        this.caller.website = scrapeData["web_site"] || "";
        this.caller.email = coldlead["contactEmail"] || "";
        this.caller.callHistory = leadInfo["calls"] || [];
        this.caller.ordersAr = leadInfo["orders"] || [];
        this.caller.mapsLink = scrapeData["link"] || "";
        this.caller.contact_id = coldlead["_id"];

      //localStorage.setItem("tmpData", JSON.stringify(this.caller));

    })

  }

  top: number = 0; // Default position
  left: number = 0; // Default position

  onDragMoved(event: CdkDragMove) {

  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }

  addPhoneNumber($event){
    $event.preventDefault();
    alert("Not ready yet")
  }

  openPanel($event, contact_id){
    $event.preventDefault();
    this.isVisible = false;
    this.dataService.openPanel(contact_id);
  }
}