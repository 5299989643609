import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'app-warehouse-picker',
  templateUrl: './warehouse-picker.component.html',
  styleUrl: './warehouse-picker.component.css'
})

export class WarehousePickerComponent implements OnInit {

  warehouses = [];
  warehouse_id = "";
  @Output() warehouseChanged = new EventEmitter<string>();

  constructor(private http: HttpClient, private sharedService: SharedService ) { 

  }

  ngOnInit(){

    this.loadWarehouses();
    setTimeout( () => {
      this.loadSelectedWarehouse();
    }, 1500 )
    
  }

  async loadWarehouses(){
    this.sharedService.getWarehouses().subscribe(data => this.warehouses = data);
    this.warehouses.unshift({ warehouse_id: "", warehouseName: 'Select Warehouse' });
  }

  async onWarehouseChange($event){
    localStorage.setItem('selectedWarehouse', JSON.stringify(this.warehouse_id));
    this.warehouseChanged.emit(this.warehouse_id);
  }

  loadSelectedWarehouse() {
      const savedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));
      if (savedWarehouse) {

        this.warehouse_id = savedWarehouse;
        this.warehouseChanged.emit(this.warehouse_id);
      }
    }
    
}
