import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@app/shared.service';


@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrl: './user-picker.component.css'
})

export class UserPickerComponent implements OnInit {

  users = [];
  user_id = "";
  //selectedUserId = "";
  @Output() userChanged = new EventEmitter<object>();
  @Input() selectedUserId: string = "";

  constructor(private http: HttpClient, private sharedService: SharedService ) { 

  }

  ngOnInit(){
    this.loadUsers();
  }

  async loadUsers(){
    this.sharedService.getUsers().subscribe(data => this.users = data);
    this.users.unshift({ user_id: "", userName: 'Select User' });
    console.log(29, this.selectedUserId);
  }

  // component.ts
  onUserChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const userId = selectElement.value;
    const fullName = selectElement.options[selectElement.selectedIndex].text;
    console.log('User ID:', userId);
    console.log('Full Name:', fullName);
    this.userChanged.emit( { user_id: userId, userName: fullName } );
  }

  // async onUserChange(userId: string, fullName: string) {
  //   console.log('User ID:', userId);
  //   console.log('Full Name:', fullName);
  //    this.userChanged.emit( { user_id: this.user_id, userName: fullName } );
  // }

}
