 <button #openModal type="button" class="btn btn-secondary" style="display: none" data-bs-toggle="modal" data-bs-target="#teams">Open</button>

<app-flextable #flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
	(rowLinkClicked)='rowLinkClicked($event)'
	[options]="
		{
			datasource: 'teams',

			bAllowDeletion: true,

			headerButtonsAr: [
				'Add Team'
			],

            linkColumnPreventDefault: true,
            
            linkColumns: [ {
                     column: 'TeamName',
                     path: '/main/sysadmin/planmanagement/plan/'
                }
            ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

            columnNameRewrites: [
                'Selected', 'Team', 'City'
            ],

			scope: 'account',
			
			columns: [
				'selected',
				'teamName',
				'teamCity'
			]
		}"
></app-flextable>

<div class="modal fade" appMoveModalToBody  id="teams" tabindex="-1">
    <form name="addCountry">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{"Add Team" | translate }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    

                    <div class="mb-4">
                        <label for="team_name" class="form-label">{{"Team Name" | translate}}</label>
                        <input id="team_name" name="team_name" [(ngModel)]="payload.teamName" type="text" class="form-control">
                    </div>
                    <div class="mb-4">
                        <label for="team_city" class="form-label">{{"Team City" | translate}}</label>
                        <input id="team_city" name="team_name" [(ngModel)]="payload.teamCity" type="text" class="form-control">
                    </div>
					<div class="mb-4">
					    <label for="countryDropdown" class="form-label">{{"Country" | translate}}</label>
					    <select id="countryDropdown" name="country" class="form-control" [(ngModel)]="payload.teamCountry">
					        <option *ngFor="let country of countries" [ngValue]="country.countryCode">{{ country.countryName }}</option>
					    </select>
					</div>
                    <div class="mb-4">
	                     <label for="primary_language" class="form-label">{{"Primary Language" | translate}}</label>
	                    <select id="languageDropdown" name="language" class="form-control" [(ngModel)]="payload.teamLanguage">
    						<option *ngFor="let language of languages" [ngValue]="language.languageShortCode">{{ language.languageName }}</option>
						</select>

                    </div>

                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{"Close" | translate}}</button>
                    <!-- <button type="submit" class="btn btn-primary">Add Language</button> -->
                    <network-request-button #submitForm (result)="waitForResponse($event)" [request]="endpoint" [payload]="payload" [verb]="verb">{{buttonAction | translate}}</network-request-button>
                </div>
            </div>
        </div>
    </form>
</div>