
<app-flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
	[options]="
		{
			datasource: 'users',

			bAllowDeletion: true,

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

			scope: 'all',
			
			columns: [
				'selected',
				'first_name',
				'last_name',
				'email',
				'account_type',
				'role'
			]
		}"
></app-flextable>
