<div class="blog-container">
    <h1>Frequently Asked Questions</h1>
    <p>Welcome to our FAQ section! Here, you'll find answers to the most common questions about our scraperCRM. If you have any further inquiries, please don't hesitate to contact us, by clicking on the Support Chat button above.</p>

    <h2>What is scraperCRM?</h2>
    <p>scraperCRM is a moneyless marketplace that allows publishers to exchange digital assets like links, newsletters, ad-space, or anything else of value. You can do direct exchanges, or you can  use points to acquire the things you want.</p>


    <h2>Why Points?  Why not just let people pay with real money</h2>
    <p>Regulatory issues aside, allowing people to pay with money would enable large agencies and link vendors to enter the marketplace and simply purchase assets without contributing anything to the marketplace.  We're looking to build a community of like minded publishers with a healthy marketplace of valuable assets, and this risks attracting the wrong kinds of members.</p>


    <h2>How Can I Get Points?</h2>
    <p>There are two ways you can get points: agree to provide a link in the future or offer something of value to the marketplace in exchange for points.</p>

    <h2>How many points do I get for a link?</h2>
    <p>The value of links is determined algorithmically, by difficult-to-manipulate, objective measurements.</p>

    <h2>I have a high DR site but I'm not getting very many points for it.</h2>
    <p>DR, by itself, is not a good metric on the value of a link. It's easy to manipulate, and if you have a High DR site but low site traffic, that actually works against you because our algorithm will consider the DR to have been artificially manipulated.</p>

    <h2>I can sell my link elsewhere for much more than I'm getting in point value here.</h2>
    <p>This marketplace isn't appropriate for sites that monetize primarily through selling links, guest posts, PBN networks, etc. While we pass no judgment on any legal monetization strategy, Niche Site Marketplace is for publishers that don't generally sell links or guest posts. Or if they do, it's a small percentage of their overall revenue.</p>

    <h2>I'm in escrow on a transaction, but I need to back out of it for [insert reason]</h2>
    <p>If during an escrow, you need to withdraw from the transaction, you may cancel the transaction. Points will be automatically refunded, and the offer will be placed back onto the marketplace. You are free to withdraw without disclosing a reason.</p>

    <h2>What is the minimum traffic value to get automatic points?</h2>
    <p>Sites need to have a minimum of 10,000 monthly visitors, as estimated by ahrefs, to qualify. You can still post your links for sites that have less than 10,000 visitors; you just won't get free points for doing so.</p>

    <h2>scraperCRM is currently free.  How long will it be free for?</h2>
    <p>It's my intention to monetize the site by charging a monthly subscription fee, and this will happen as soon as it becomes commercially viable to do so.  There will be a minimum notice period of 14 days.</p>

    <h2>Do you accept AI sites?</h2>
    <p>Sites that use AI responsibly are fine.  Sites that appear to be abusing AI, which is an entirely subjective opinion on our part, won't be accepted.</p>

    <h2>Do you accept pSEO sites?</h2>
    <p>One of the factors we consider is the number of "no indexed" pages a site has.  If a site has too many non-indexed pages, the chances are very high that links on new content will be effectively worthless.  So by itself, being a pSEO site isn't a problem.  That said, many pSEO sites won't qualify because they have too many non-indexed pages or zero traffic pages.</p>

    <h2>Why do you reject sites?</h2>
	<ol>
	    <li>Sites that appear spammy</li>
	    <li>Suspected PBNs</li>
	    <li>Accepts Paid Guest Posts</li>
	    <li>Not enough #1 Keywords (Ahrefs)</li>
		<li>To New</li>
		<li>Artificially High DR</li>
		<li>Low Quality Content</li>
		<li>Suspected to monetize primarily through links</li>
		<li>Low Quality pSEO / AI Site</li>
		<li>Thousands of 0 traffic posts</li>
	</ol>

	<p>E-Commerce, Commercial-intent and other non-content sites are allowed, but in general need to offer some kind of value to the marketplace.</p>

</div>
