import { Directive, ElementRef, AfterViewInit } from '@angular/core';
declare var twemoji: any;

@Directive({
  selector: '[appTwemoji]'
})
export class TwemojiDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    // twemoji.parse(this.el.nativeElement, {
    //   folder: 'svg',
    //   ext: '.svg',
    //   className: 'twemoji'
    // });
  }
}
