import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { DataService } from '../services/data.service';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NetworkRequestButtonComponent } from '../reusable/ui/network-request-button/network-request-button.component'
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

  currentLanguageLabel: string = '🇺🇸 English'; // Default language

  @ViewChild('createAccount') createAccountButton: NetworkRequestButtonComponent;

registrationForm: FormGroup;
registerEndpoint = "/api/register";
registrationData: any = { }

// Our default account type.  Can be modified using query params
account_type = "";
role_superior ="";
superior_id ="";
team_id ="";
office_id ="";
selected_phone_number ="";

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public dataService: DataService) {}

  ngOnInit() {
this.loadLanguagePreference();
    const queryParams = this.route.snapshot.queryParams;

    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userId: ['', [Validators.required, Validators.email]],
      pwd: ['', Validators.required],
      termsAndConditions: [true, Validators.requiredTrue]
    });

    if(typeof queryParams['invite_code'] !== 'undefined'){
      console.log(54, "We have an invite code", queryParams['invite_code'])
      this.registrationForm.addControl('invite_code', new FormControl(queryParams['invite_code'], Validators.required));

    } else {
      console.log(57, "No invite code");
    }

    console.log(61, queryParams)
    if(queryParams['account_type']){
        this.account_type = queryParams['account_type'];
        this.registrationForm.addControl('account_type', new FormControl(queryParams['account_type'], Validators.required));
    }

    if(queryParams['role_superior']){
        this.role_superior = queryParams['role_superior'];
        this.registrationForm.addControl('role_superior', new FormControl(queryParams['role_superior'], Validators.required));
    }

    if(queryParams['superior_id']){
        this.superior_id = queryParams['superior_id'];
        this.registrationForm.addControl('superior_id', new FormControl(queryParams['superior_id'], Validators.required));
    }

    if(queryParams['team_id']){
        this.team_id = queryParams['team_id'];
        this.registrationForm.addControl('team_id', new FormControl(queryParams['team_id'], Validators.required));
    }

    if(queryParams['office_id']){
        this.office_id = queryParams['office_id'];
        this.registrationForm.addControl('office_id', new FormControl(queryParams['office_id'], Validators.required));
    }

    if(queryParams['selectedPhoneNumber']){
        this.selected_phone_number = queryParams['selectedPhoneNumber'];
        this.registrationForm.addControl('selectedPhoneNumber', new FormControl(queryParams['selectedPhoneNumber'], Validators.required));
    }
  }

  submitForm() {
    if (this.registrationForm.valid) {
      const formData = { ...this.registrationForm.value };

    }
  }

  async waitForRegistrationResponse(response: any){

    if(response == false){
      console.error("Login Failure");
      return;
    }


    this.dataService.setSharedData(response);
    this.dataService.setNames(response.fName, response.lName, "User");

    if(typeof response["redirect-override"] !== 'undefined'){
       window.location.href = response["redirect-override"];
       return;
    }  

    this.router.navigate([response.home]);

  }
  changeLanguage(event: Event, language: string): void {
    event.preventDefault();
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    this.currentLanguageLabel = languageLabel[language];
    localStorage.setItem('selectedLanguage', language);

    this.translate.use(language);

    // Add any additional logic here for applying the language changes in your application
  }

  private loadLanguagePreference(): void {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    if (selectedLanguage && languageLabel[selectedLanguage]) {
      this.currentLanguageLabel = languageLabel[selectedLanguage];
    }

    this.translate.setDefaultLang(selectedLanguage);


    // Add any additional initialization logic here for applying the stored language preference
  }
}
