
<app-animated-notification></app-animated-notification>


<header class="header fixed-top d-flex align-items-center justify-content-between">
<!-- <h1>{{ 'helloWorld' | translate }}</h1> -->
    <app-chat [visible]="true" [hidden]="false"></app-chat>
    <div class="d-flex align-items-center justify-content-between">
        <a href="#" (click)="navigateHome($event)" class="logo d-flex align-items-center">
            <img src="assets/img/logo.png" alt="" />
            <span class="d-none d-lg-block">{{dataService.getProductName()}}</span>
        </a>
        <i (click)="handleSidebarClick()" class="bi bi-list toggle-sidebar-btn"></i>
    </div>

        <div class="search-bar">
            <form class="search-form d-flex align-items-center" method="POST" action="#" (ngSubmit)="searchFunction()">
                <input [(ngModel)]="searchTerm" type="text" name="query" placeholder="Search" title="Enter search keyword" />
                <button type="submit" title="Search"><i class="bi bi-search"></i></button>

                <!-- Advanced Search Icon -->
                <a href="#" class="ms-4" data-bs-toggle="dropdown" aria-expanded="false" title="Advanced Search">
                    <i class="bi bi-gear-fill"></i>
                </a>

                <!-- Advanced Search Dropdown Menu -->
                <div class="dropdown-menu p-4" style="width: 650px;">
                    <!-- Your advanced search options here -->
                    <div class="mb-2">
                        <!-- <label for="advancedOption1" class="form-label">Language</label> -->
                        <language-picker [selectedLanguage]="selectedLanguage"></language-picker>
                    </div>
                    <div class="mb-3">
                        <!-- <label for="advancedOption2" class="form-label">Team</label> -->
                        <team-picker [(selectedTeam)]="selectedTeam"></team-picker>
                    </div>
                    <div class="mb-3 d-flex align-items-center">
                        <!-- <label for="advancedOption2" class="form-label me-2">Invoice</label> -->
                        <input [(ngModel)]="invoiceSearch" type="text" name="invoiceQuery" placeholder="Search by Invoice #" title="Enter search keyword" class="form-control w-50 me-2">
                        <button type="submit" (click)="searchByInvoice($event)" title="Search" class="btn p-0"><i class="bi bi-search"></i></button>
                    </div>



                    <div class="mb-2" >
                        
                      <button  (click)="preventDefault($event)"

                        class="dropdown-toggle btn p-0" 
                        type="button" 
                        id="dropdownMenuButton" 
                        aria-expanded="true" 
                        data-bs-toggle="dropdown">
                        {{"Dispositions" | translate }}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li *ngFor="let disposition of dispositions">
                          <a class="dropdown-item" href="#" (click)="listDispositions($event, disposition.key);">
                            {{ disposition.key | translate }}
                          </a>
                        </li>
                        <li>
                        
                      </ul>
                    </div>


            
        
                </div>
            </form>
        </div>

    <!--
  <div class="search-bar">
  <form method="POST" action="#" (ngSubmit)="searchFunction()">
    <div class="d-flex align-items-center mb-2">
      <input [(ngModel)]="searchTerm" type="text" name="query" placeholder="Search" title="Enter search keyword" class="form-control me-2">
      <button type="submit" title="Search" class="btn"><i class="bi bi-search"></i></button>
    </div>
    <div class="form-check d-inline-block me-2">
      <input class="form-check-input" type="checkbox" id="searchAll">
      <label class="form-check-label" for="searchAll">Search All</label>
    </div>
    <div class="form-check d-inline-block me-2">
      <input class="form-check-input" type="checkbox" id="readyToCall">
      <label class="form-check-label" for="readyToCall">Ready To Call</label>
    </div>
    <div class="form-check d-inline-block">
      <input class="form-check-input" type="checkbox" id="myContacts">
      <label class="form-check-label" for="myContacts">My Contacts</label>
    </div>
  </form>
</div>
-->

    <div class="search-bar">
<!--     <a routerLink="/main/forum" style="padding:3px;">
    <span class="badge bg-info text-dark d-inline-flex align-items-center">
      <i class="material-icons">forum</i>
      <span class="ms-2">{{"Community Forum" | translate }}</span>
    </span>
  </a>
        <a (click)="onChatButtonClick($event)" href="#" style="padding: 3px;">
            <span class="badge bg-success d-inline-flex align-items-center">
                <i class="material-icons">chat_bubble</i>
                <span class="ms-2">{{ 'support_chat' | translate }}</span>
            </span>
        </a> -->

        <button (click)="onDisconnectButtonClick($event)" [disabled]="disconnectEnabled" style="padding: 3px; border: none; background: none; cursor: pointer;" class="disconnect-button">
            <span class="badge bg-danger d-inline-flex align-items-center">
                <i class="material-icons">call_end</i>
                <span class="ms-2">{{ 'disconnect' | translate }}</span>
            </span>
        </button>

        <button *ngIf="!recordButtonEnabled" (click)="onRecordButtonClick($event)" [disabled]="disconnectEnabled" style="padding: 3px; border: none; background: none; cursor: pointer;" class="disconnect-button">
            <span class="badge bg-danger d-inline-flex align-items-center">
                <i class="material-icons">fiber_manual_record</i>
                <span class="ms-2">{{ 'record' | translate }}</span>
            </span>
        </button>

        <button *ngIf="recordButtonEnabled" (click)="onPauseButtonClick($event)" [disabled]="disconnectEnabled" style="padding: 3px; border: none; background: none; cursor: pointer;" class="disconnect-button">
            <span class="badge bg-warning d-inline-flex align-items-center">
                <i class="material-icons">pause</i>
                <span class="ms-2">{{ 'pause' | translate }}</span>
            </span>
        </button>

        <button *ngIf="!muteButtonEnabled" [disabled]="disconnectEnabled" (click)="onMuteButtonClick(false)" style="padding: 3px; border: none; background: none; cursor: pointer;" class="disconnect-button">
            <span class="badge bg-danger d-inline-flex align-items-center">
                <i class="material-icons">mic_off</i>
                <span class="ms-2">{{ 'mute' | translate }}</span>
            </span>
        </button>
        <button *ngIf="muteButtonEnabled" (click)="onMuteButtonClick(true)" style="padding: 3px; border: none; background: none; cursor: pointer;" class="disconnect-button">
            <span class="badge bg-warning d-inline-flex align-items-center">
                <i class="material-icons">mic_off</i>
                <span class="ms-2">{{ 'unmute' | translate }}</span>
            </span>
        </button>

        <!-- <button *ngIf="!bIsMuted" disabled  class="disconnect-button" (click)="toggleMuted()">
  <i class="bi bi-mic-mute"></i> Mute
</button> -->

        <!-- 
    <span joyrideStep="fourthStep" title="See Your Points" text="See how many points you have available here" style="margin:3px;" class="badge bg-warning d-inline-flex align-items-center">
      <i class="material-icons">star</i>
      <span class="ms-2">{{pointsBalance}}</span>
    </span>
  
      <span joyrideStep="fourthStep" title="See Your Points" text="See how many points you have available here" style="margin:3px;" class="badge text-dark d-inline-flex align-items-center">
      <i class="material-icons">access_time</i>
      <span class="ms-2">{{Countdown}}</span>
      </span> -->

        <!--     <span style="margin:3px;" class="badge bg-info d-inline-flex align-items-center">
      <i class="material-icons">account_balance_wallet</i>
      <span class="ms-2">{{cashBalance | currency }}</span>
    </span>   -->
    </div>

    <div class="dropdown">
        <button (click)="onAddContactButtonClick($event)" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 3px; border: none; background: none; cursor: pointer;" class="add-contact-button">
            <span class="badge bg-success d-inline-flex align-items-center">
                <i class="material-icons">person_add</i>
                <span class="ms-2">{{ 'add_contact' | translate }}</span>
            </span>
        </button>

        <div class="dropdown-menu p-4" aria-labelledby="dropdownMenuButton" style="width: 350px;">
            <div class="mb-3">
                <label for="websiteInput" class="form-label">{{ 'title' | translate }}</label>
                <input type="text" class="form-control" id="titleInput" [(ngModel)]="title" placeholder="Enter Title" />
            </div>
            <div class="mb-3">
                <label for="websiteInput" class="form-label">{{ 'website' | translate }}</label>
                <input type="url" class="form-control" id="websiteInput" [(ngModel)]="website" placeholder="Enter website" />
            </div>
            <div class="mb-3">
                <label for="phoneInput" class="form-label">{{ 'phone_number' | translate }}</label>
                <input type="tel" class="form-control" id="phoneInput" [(ngModel)]="phone" placeholder="Enter phone number" />
            </div>
            <button *ngIf="bDisplayValidateButton" type="button" class="btn btn-primary" (click)="validatePhoneInput($event)">{{ 'validate' | translate }}</button>
            <button *ngIf="!bDisplayValidateButton" type="button" class="btn btn-primary m-1" (click)="addContact($event)">{{ 'add_contact' | translate }}</button>
            <button *ngIf="!bDisplayValidateButton" type="button" class="btn btn-primary m-1" (click)="addContact($event)">{{ 'add_contact_call' | translate }}</button>


        </div>
    </div>
<nav class="header-nav ms-auto d-flex align-items-center justify-content-end" style="display: flex; flex-wrap: nowrap;">

  <!-- Language Dropdown -->
  <app-language-select></app-language-select>
  <ul class="d-flex align-items-center mb-0">
    <li class="nav-item d-block d-lg-none">
      <a class="nav-link nav-icon search-bar-toggle " href="#">
        <i class="bi bi-search"></i>
      </a>
    </li>

    <li class="nav-item dropdown">
      <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <i class="bi bi-bell"></i>
        <span class="badge bg-primary badge-number">{{notifications}}</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
        <li class="dropdown-header">
          You have {{notifications}} new notifications
          <a href="/main/notifications"><span class="badge rounded-pill bg-primary p-2 ms-2">{{ 'view_all' | translate }}</span></a>
        </li>
        <li><hr class="dropdown-divider"></li>

        <ng-container *ngFor="let notification of notificationsAr">
          <notification-bell [title]="notification.title" [icon]="notification.icon" [time]="notification.time" [notification]="notification.notification" (notifyDeletion)="notificationDeleted($event)" [refDocId]="notification.ref_doc_id" [notification_id]="notification._id" [path]="notification.path"></notification-bell>
        </ng-container>
        <li class="dropdown-footer">
          <a [routerLink]="['/main/notifications']">Show all notifications</a>
        </li>
      </ul>
    </li>

    <li class="nav-item dropdown">
      <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <i class="bi bi-chat-left-text"></i>
        <span class="badge bg-success badge-number">{{messagesCount}}</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
        <li class="dropdown-header">
          You have {{messagesCount}} new messages
          <a href="/main/messages"><span class="badge rounded-pill bg-primary p-2 ms-2">{{ 'view_all' | translate }}</span></a>
        </li>
        <li><hr class="dropdown-divider"></li>

<div class="container mt-3">
  <div class="d-flex flex-column align-items-center">
    <!-- Record Button -->
    <button class="btn btn-danger btn-lg" (click)="toggleRecording($event)">
      {{isRecording ? 'Stop Recording' : 'Start Recording'}}
    </button>

    {{ audioUrl }}
    
    <!-- Audio Playback -->
    <audio #audioPlayer controls channels="1" class="mt-3" (click)="stopClickPropagation($event)">
      <source [src]="audioUrl" type="audio/mp3">
      Your browser does not support the audio element.
    </audio>
  </div>
</div>

        <ng-container *ngFor="let message of messagesAr">
          <message-item [from]="message.payload.from" [payload]="message['payload']" message="New Voicemail"></message-item>
        </ng-container>
            <li class="dropdown-footer">
              <a [routerLink]="['/main/messages']">Show all messages</a>
            </li>
      </ul>
    </li>

    <li class="nav-item">
      <app-profile-pic></app-profile-pic>
    </li>
  </ul>
</nav>

</header>
