import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'app-company-picker',
  templateUrl: './company-picker.component.html',
  styleUrl: './company-picker.component.css'
})

export class CompanyPickerComponent implements OnInit {

  companies = [];
  company_id: any | null = null;

  @Output() companyChanged = new EventEmitter<string>();
  @Input() disableAutoSelect = false;

  constructor(private http: HttpClient, private sharedService: SharedService ) { 

  }

  ngOnInit(){
    this.loadCompanies();
    setTimeout( () => {
      this.loadSelectedCompany();
    }, 1500)
    
  }

  async loadCompanies(){
    this.sharedService.getCompanies().subscribe(data => this.companies = data);

    //this.companies.unshift({ company_id: "", companyName: 'Select Company' });
  }

  async onCompanyChange($event){
    localStorage.setItem('selectedCompany', JSON.stringify(this.company_id));
    this.companyChanged.emit(this.company_id);
  }

  loadSelectedCompany(){


      if(this.disableAutoSelect == true){
        return;
      }

      const savedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      if (savedCompany) {
        this.company_id = savedCompany;
        this.companyChanged.emit(this.company_id);
      }
  }
}
