<div class="pagetitle">
    <h1>{{"Dashboard" | translate}}</h1>
    <nav>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">{{"Home" | translate}}</a></li>
            <li class="breadcrumb-item active">{{"Dashboard" | translate}}</li>
        </ol>
    </nav>
</div>
<!-- End Page Title -->
<section class="section dashboard">
<!--     <div class="row mb-4">
        <label for="teamDropdown" class="col-sm-2 col-form-label">{{"Team" | translate}}</label>
        <div class="col-sm-10">
            <select id="teamDropdown" name="team" class="form-control w-50" [(ngModel)]="payload.team_id" (change)="onTeamChange($event)">
                <option *ngFor="let team of teams" [ngValue]="team.team_id">{{ team.team_name }}</option>
            </select>
        </div>
    </div> -->
    <div class="row mb-4">
        <label for="officeDropdown" class="col-sm-2 col-form-label">{{"Office" | translate}}</label>
        <div class="col-sm-10">
            <select  id="officeDropdown" name="office" class="form-control w-50" [(ngModel)]="payload.office_id" (change)="onOfficeChange($event)">
                <option *ngFor="let office of offices" [ngValue]="office.office_id">{{ office.office_name }}</option>
            </select>
        </div>
    </div>
<!--     <div class="row mb-4">
        <label for="officeDropdown" class="col-sm-2 col-form-label">{{"Supervisor" | translate}}</label>
        <div class="col-sm-10">
            <select id="supervisorDropdown" name="supervisor" class="form-control w-50" [(ngModel)]="payload.supervisor_id" (change)="onSupervisorChange($event)">
                <option *ngFor="let supervisor of supervisors" [ngValue]="supervisor.supervisor_id">{{ supervisor.first_name }}&nbsp;{{ supervisor.last_name }}</option>
            </select>
        </div>
    </div> -->
    <!-- <div class="row mb-4">
  <label for="officeDropdown" class="col-sm-2 col-form-label">Supervisor</label>
  <div class="col-sm-10">
    <select id="officeDropdown" name="office" class="form-control w-50" [(ngModel)]="payload.office_id" (change)="onOfficeChange($event)">
      <option *ngFor="let office of offices" [ngValue]="office.office_id">{{ office.office_name }}</option>
    </select>
  </div>
</div> -->

    <!-- Compact People Boxes -->
    <div *ngIf="office_users.length > 0">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{{"Office Users" | translate}}<span> | {{callTimePeriod | translate | titlecase }}</span></h5>


                        <app-date-filter (filterChanged)="handleBoxesFilterChange($event)"></app-date-filter>

<!--                         <div class="filter">
                            <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li class="dropdown-header text-start">
                                    <h6>{{ "Filter" | translate }}</h6>
                                </li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'today')" href="#">{{ "Today" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'yesterday')" href="#">{{ "Yesterday" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'week')" href="#">{{ "This Week" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'month')" href="#">{{ "This Month" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'last_month')" href="#">{{ "Last Month" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterCallTime($event, 'year')" href="#">{{ "This Year" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="toggleCustomDateInputs($event)" href="#">{{ "Custom" | translate }}</a></li>
                                <li *ngIf="showCustomDateInputs">
                                    <div class="d-flex justify-content-between">
                                        <input type="date" class="form-control me-2" [(ngModel)]="startDate" placeholder="Start Date" />
                                        <input type="date" class="form-control ms-2" [(ngModel)]="endDate" placeholder="End Date" />
                                    </div>
                                    <button type="button" class="btn btn-primary ms-2" (click)="onShowCustomDates($event)">Show</button>
                                </li>
                            </ul>
                        </div> -->
                        <div class="d-flex flex-wrap">
                            <div *ngFor="let user of office_users" class="p-2" style="position: relative;">
                                <div class="user-box" [id]="user._id" style="position: relative;">
                                    <!-- Status Circle -->
                                     <div [ngStyle]="{'background-color': user.userState}" style="position: absolute; top: 5px; left: 5px; width: 20px; height: 20px; border-radius: 50%;">
                                    </div>
                                    <a href="#" (click)="getUserInfo($event, user['_id'])">
                                        <div class="user-box-content">
    {{user.first_name}} {{user.last_name}}
    <span *ngIf="user.alias !== undefined" style="display: block; text-align: center; font-size: smaller;">
        <i>({{user.alias}})</i>
    </span>
</div>

                                        
                                    </a>
                                    <div
                                        *ngIf="user.orderCount > 0"
                                        style="
                                            position: absolute;
                                            bottom: -25px;
                                            left: 50%;
                                            transform: translateX(-50%);
                                            width: 50px;
                                            height: 50px;
                                            background-color: #ffaaff;
                                            border-radius: 50%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            font-size: 24px;
                                        "
                                    >
                                        <span style="display: block;">{{user.orderCount}}</span>
                                    </div> 
                                    <div
                                        *ngIf="user.resaleOrderCount > 0"
                                        style="
                                            position: absolute;
                                            top: -25px;
                                            left: 0%;
                                            transform: translateX(-25%);
                                            width: 50px;
                                            height: 50px;
                                            background-color: #aaffaa;
                                            border-radius: 50%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            font-size: 24px;
                                        "
                                    ><span style="display: block;">{{user.resaleOrderCount}}</span>
                                    </div>
                                    <div class="talk-time-info" style="z-index:500">
                                            🕒{{user?.talkTime?.averageTalkTimeMinutes || 0 | number:'1.0-0'}}m | ⏳{{user?.talkTime?.totalTalkTimeMinutes || 0 | number:'1.0-0'}}m | 📞{{user?.talkTime?.callCount || 0}}

                                        </div>
                                    <!-- Call Barging Button -->
                                    <a
                                        href="#"
                                        (click)="toggleCallBarging($event, user)"
                                        [style.background-color]="user.isBarging ? 'red' : '#4CAF50'"
                                        style="position: absolute; top: 5px; right: 5px; padding: 5px 10px; color: white; border-radius: 5px; font-size: 12px; cursor: pointer;"
                                    >
                                        📡
                                    </a>

                                    <a
                                        href="#"
                                        (click)="toggleCall($event, user)"
                                        style="position: absolute; top: 5px; right: 50px; padding: 5px 10px; color: white; border-radius: 5px; font-size: 12px; cursor: pointer;"
                                        [title]="user.telephony.number | formatPhone"
                                    >
                                        📞
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- End Compact People Boxes -->

<!--     <div class="card-body">
              <h5 class="card-title">Bar Chart</h5>
<ngx-charts-bar-vertical
  [view]="view"
  [scheme]="colorScheme"
  [results]="single"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical>
</div> -->

    <div class="row">
        <!-- Left side columns -->
        <div class="col-lg-8">
            <div class="row">
                <!-- Sales Card -->
                <div class="col-xxl-4 col-md-6">
                    <div class="card info-card sales-card">
                        <app-date-filter (filterChanged)="handleFilterSalesChange($event)"></app-date-filter>
                        <div class="card-body">
                            <h5 class="card-title">{{ "Sales" | translate }} <span>| {{salesTimePeriod | translate | titlecase }}</span></h5>
                            <div class="d-flex align-items-center">
                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i class="bi bi-cart"></i>
                                </div>
                                <div class="ps-3">
                                    <h6>{{salesToday}}</h6>
                                    <!-- <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Sales Card -->
                <!-- Revenue Card -->
                <div class="col-xxl-4 col-md-6">
                    <div class="card info-card revenue-card">
                        <app-date-filter (filterChanged)="handleBoxesFilterRevenueChange($event)"></app-date-filter>
                        <div class="card-body">
                            <h5 class="card-title">{{ "Revenue" | translate }} <span>| {{ revenueTimePeriod | translate | titlecase }} </span></h5>
                            <div class="d-flex align-items-center">
                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i class="bi bi-currency-dollar"></i>
                                </div>
                                <div class="ps-3">
                                    <h6>{{ revenueCurrentMonth | currency}}</h6>
                                    <!-- <span class="text-success small pt-1 fw-bold">8%</span> <span class="text-muted small pt-2 ps-1">increase</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Revenue Card -->
                <!-- Customers Card -->
                <div class="col-xxl-4 col-xl-12" *ngIf="userInfo.account_type == 'office-manager'">
                    <div class="card info-card customers-card">
                        <app-date-filter (filterChanged)="handleBoxesFilterCustomersChange($event)"></app-date-filter>

                        <div class="card-body">
                            <h5 class="card-title">{{ "Customers" | translate }} <span>| {{ customersTimePeriod | translate | titlecase }} </span></h5>
                            <div class="d-flex align-items-center">
                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i class="bi bi-people"></i>
                                </div>
                                <div class="ps-3">
                                    <h6>{{customersThisYear}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xxl-4 col-xl-12" *ngIf="userInfo.account_type == 'accounting' || userInfo.account_type == 'owner'">
                    <div class="card info-card revenue-card">
                        <app-date-filter (filterChanged)="handleBoxesFilterPaidChange($event)"></app-date-filter>
                        <div class="card-body">
                            <h5 class="card-title">{{ "Paid" | translate }} <span>| {{ paidTimePeriod | translate | titlecase }} </span></h5>
                            <div class="d-flex align-items-center">
                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i class="bi bi-currency-dollar"></i>
                                </div>
                                <div class="ps-3">
                                    <h6>{{ paidCurrentMonth | currency}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Customers Card -->
                <!-- Reports -->
                <!--               <div class="col-12">
                <div class="card">
                  <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>{{ "Filter" | translate }}</h6>
                      </li>
                      <li><a class="dropdown-item" href="#">{{ "Today" | translate</a></li>
                      <li><a class="dropdown-item" href="#">{{ "This Month" | translate }}</a></li>
                      <li><a class="dropdown-item" href="#">{{ "This Year" | translate }}</a></li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">Reports <span>/{{ "Today" | translate</span></h5>

                    <div id="reportsChart"></div>
                    <script>
                      document.addEventListener("DOMContentLoaded", () => {
                        new ApexCharts(document.querySelector("#reportsChart"), {
                          series: [{
                            name: 'Sales',
                            data: [31, 40, 28, 51, 42, 82, 56],
                          }, {
                            name: 'Revenue',
                            data: [11, 32, 45, 32, 34, 52, 41]
                          }, {
                            name: 'Customers',
                            data: [15, 11, 32, 18, 9, 24, 11]
                          }],
                          chart: {
                            height: 350,
                            type: 'area',
                            toolbar: {
                              show: false
                            },
                          },
                          markers: {
                            size: 4
                          },
                          colors: ['#4154f1', '#2eca6a', '#ff771d'],
                          fill: {
                            type: "gradient",
                            gradient: {
                              shadeIntensity: 1,
                              opacityFrom: 0.3,
                              opacityTo: 0.4,
                              stops: [0, 90, 100]
                            }
                          },
                          dataLabels: {
                            enabled: false
                          },
                          stroke: {
                            curve: 'smooth',
                            width: 2
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                          },
                          tooltip: {
                            x: {
                              format: 'dd/MM/yy HH:mm'
                            },
                          }
                        }).render();
                      });
                    </script>

                  </div>
                </div>
              </div> -->

                <div class="col-12">
                    <div class="card recent-sales overflow-auto">
<!--                         <div class="filter">
                            <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li class="dropdown-header text-start">
                                    <h6>{{ "Filter" | translate }}</h6>
                                </li>
                                <li><a class="dropdown-item" (click)="filterOrders($event, 'today')" href="#">{{ "Today" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterOrders($event, 'week')" href="#">{{ "This Week" | translate }}</a></li>
                                
                                <li><a class="dropdown-item" (click)="filterOrders($event, 'month')" href="#">{{ "This Month" | translate }}</a></li>
                                <li><a class="dropdown-item" (click)="filterOrders($event, 'year')" href="#">{{ "This Year" | translate }}</a></li>
                            </ul>
                        </div> -->
                        <div class="card-body">
                            <h5 class="card-title">{{"Recent Sales" | translate }}&nbsp;<span>|&nbsp;{{ ordersTimePeriod | translate | titlecase }} </span></h5>
                            <table class="table table-borderless datatable">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{{"Customer" | translate}}</th>
                                        <th scope="col">{{"Product" | translate}}</th>
                                        <th scope="col">{{"Price" | translate}}</th>
                                        <th scope="col">{{"Total" | translate}}</th>
                                        <th scope="col">{{"Status" | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let order of orders; let i = index">
                                        <th scope="row"></th>
                                        <td>{{order.title}}</td>
                                        <td><a href="#" class="text-primary">{{order.order[0].product_name}}</a></td>
                                        <td>${{order.order[0].product_unit_price}}</td>
                                        <td>${{order.order[0].product_unit_price * order.order[0].qty}}</td>
                                        <td>
                                            <span class="badge" [ngClass]="{'bg-success': order.approved == 'approved', 'bg-secondary': order.approved == 'shipped', 'bg-info': order.approved == 'preapproved', 'bg-warning': order.approved == 'pending', 'bg-danger': order.approved == 'rejected'}">
                                                {{order.approved | translate | titlecase}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- End Recent Sales -->
                <!-- Top Selling -->
                <!--               <div class="col-12">
                <div class="card top-selling overflow-auto">
                  <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>{{ "Filter" | translate }}</h6>
                      </li>
                      <li><a class="dropdown-item" href="#">{{ "Today" | translate }}</a></li>
                      <li><a class="dropdown-item" href="#">{{ "This Month" | translate }}</a></li>
                      <li><a class="dropdown-item" href="#">{{ "This Year" | translate }}</a></li>
                    </ul>
                  </div>
                  <div class="card-body pb-0">
                    <h5 class="card-title">Top Selling <span>| {{ "Today" | translate</span></h5>
                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Preview</th>
                          <th scope="col">Product</th>
                          <th scope="col">Price</th>
                          <th scope="col">Sold</th>
                          <th scope="col">{{ "Revenue" | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row"><a href="#"><img src="assets/img/product-1.jpg" alt=""></a></th>
                          <td><a href="#" class="text-primary fw-bold">Ut inventore ipsa voluptas nulla</a></td>
                          <td>$64</td>
                          <td class="fw-bold">124</td>
                          <td>$5,828</td>
                        </tr>
                        <tr>
                          <th scope="row"><a href="#"><img src="assets/img/product-2.jpg" alt=""></a></th>
                          <td><a href="#" class="text-primary fw-bold">Exercitationem similique doloremque</a></td>
                          <td>$46</td>
                          <td class="fw-bold">98</td>
                          <td>$4,508</td>
                        </tr>
                        <tr>
                          <th scope="row"><a href="#"><img src="assets/img/product-3.jpg" alt=""></a></th>
                          <td><a href="#" class="text-primary fw-bold">Doloribus nisi exercitationem</a></td>
                          <td>$59</td>
                          <td class="fw-bold">74</td>
                          <td>$4,366</td>
                        </tr>
                        <tr>
                          <th scope="row"><a href="#"><img src="assets/img/product-4.jpg" alt=""></a></th>
                          <td><a href="#" class="text-primary fw-bold">Officiis quaerat sint rerum error</a></td>
                          <td>$32</td>
                          <td class="fw-bold">63</td>
                          <td>$2,016</td>
                        </tr>
                        <tr>
                          <th scope="row"><a href="#"><img src="assets/img/product-5.jpg" alt=""></a></th>
                          <td><a href="#" class="text-primary fw-bold">Sit unde debitis delectus repellendus</a></td>
                          <td>$79</td>
                          <td class="fw-bold">41</td>
                          <td>$3,239</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> -->
                <!-- End Top Selling -->
            </div>
        </div>
        <!-- End Left side columns -->
        <!-- Right side columns -->
        <div class="col-lg-4">
            <!-- Recent Activity -->
            <div class="card">
                <!--               <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>{{ "Filter" | translate }}</h6>
                  </li>
                  <li><a class="dropdown-item" href="#">{{ "Today" | translate</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Month" | translate }}</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Year" | translate }}</a></li>
                </ul>
              </div> -->
                <div class="card-body">
                    <h5 class="card-title">{{"Recent Activity" | translate}} <span>| {{"Today" | translate}}</span></h5>
                    <div class="activity">
                        <div class="activity-item d-flex" *ngFor="let activity of recentActivities; let i = index">
                            <div class="activite-label">{{getRelativeTime(activity.occurred_at)}}</div>
                            <i class="bi bi-circle-fill activity-badge align-self-start" [ngClass]="getBadgeClass(i)"></i>
                            <div class="activity-content">
                                {{activity.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Recent Activity -->
            <!-- Budget Report -->
            <!--             <div class="card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>{{ "Filter" | translate }}</h6>
                  </li>
                  <li><a class="dropdown-item" href="#">Today</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Month" | translate }}</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Year" | translate }}</a></li>
                </ul>
              </div>
              <div class="card-body pb-0">
                <h5 class="card-title">Budget Report <span>| {{ "This Month" | translate }}</span></h5>
                <div id="budgetChart" style="min-height: 400px;" class="echart"></div>
                <script>
                  document.addEventListener("DOMContentLoaded", () => {
                    var budgetChart = echarts.init(document.querySelector("#budgetChart")).setOption({
                      legend: {
                        data: ['Allocated Budget', 'Actual Spending']
                      },
                      radar: {
                        // shape: 'circle',
                        indicator: [{
                            name: 'Sales',
                            max: 6500
                          },
                          {
                            name: 'Administration',
                            max: 16000
                          },
                          {
                            name: 'Information Technology',
                            max: 30000
                          },
                          {
                            name: 'Customer Support',
                            max: 38000
                          },
                          {
                            name: 'Development',
                            max: 52000
                          },
                          {
                            name: 'Marketing',
                            max: 25000
                          }
                        ]
                      },
                      series: [{
                        name: 'Budget vs spending',
                        type: 'radar',
                        data: [{
                            value: [4200, 3000, 20000, 35000, 50000, 18000],
                            name: 'Allocated Budget'
                          },
                          {
                            value: [5000, 14000, 28000, 26000, 42000, 21000],
                            name: 'Actual Spending'
                          }
                        ]
                      }]
                    });
                  });
                </script>
              </div>
            </div> -->
            <!-- End Budget Report -->
            <!-- Website Traffic -->
            <!--             <div class="card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>{{ "Filter" | translate }}</h6>
                  </li>
                  <li><a class="dropdown-item" href="#">Today</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Month" | translate }}</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Year" | translate }}</a></li>
                </ul>
              </div>
              <div class="card-body pb-0">
                <h5 class="card-title">Website Traffic <span>| Today</span></h5>
                <div id="trafficChart" style="min-height: 400px;" class="echart"></div>
                <script>
                  document.addEventListener("DOMContentLoaded", () => {
                    echarts.init(document.querySelector("#trafficChart")).setOption({
                      tooltip: {
                        trigger: 'item'
                      },
                      legend: {
                        top: '5%',
                        left: 'center'
                      },
                      series: [{
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                          show: false,
                          position: 'center'
                        },
                        emphasis: {
                          label: {
                            show: true,
                            fontSize: '18',
                            fontWeight: 'bold'
                          }
                        },
                        labelLine: {
                          show: false
                        },
                        data: [{
                            value: 1048,
                            name: 'Search Engine'
                          },
                          {
                            value: 735,
                            name: 'Direct'
                          },
                          {
                            value: 580,
                            name: 'Email'
                          },
                          {
                            value: 484,
                            name: 'Union Ads'
                          },
                          {
                            value: 300,
                            name: 'Video Ads'
                          }
                        ]
                      }]
                    });
                  });
                </script>
              </div>
            </div> -->
            <!-- End Website Traffic -->
            <!-- News & Updates Traffic -->
            <!--             <div class="card">
              <div class="filter">
                <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li class="dropdown-header text-start">
                    <h6>{{ "Filter" | translate }}</h6>
                  </li>
                  <li><a class="dropdown-item" href="#">Today</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Month" | translate }}</a></li>
                  <li><a class="dropdown-item" href="#">{{ "This Year" | translate }}</a></li>
                </ul>
              </div>
              <div class="card-body pb-0">
                <h5 class="card-title">News &amp; Updates <span>| Today</span></h5>
                <div class="news">
                  <div class="post-item clearfix">
                    <img src="assets/img/news-1.jpg" alt="">
                    <h4><a href="#">Nihil blanditiis at in nihil autem</a></h4>
                    <p>Sit recusandae non aspernatur laboriosam. Quia enim eligendi sed ut harum...</p>
                  </div>
                  <div class="post-item clearfix">
                    <img src="assets/img/news-2.jpg" alt="">
                    <h4><a href="#">Quidem autem et impedit</a></h4>
                    <p>Illo nemo neque maiores vitae officiis cum eum turos elan dries werona nande...</p>
                  </div>
                  <div class="post-item clearfix">
                    <img src="assets/img/news-3.jpg" alt="">
                    <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                    <p>Fugiat voluptas vero eaque accusantium eos. Consequuntur sed ipsam et totam...</p>
                  </div>
                  <div class="post-item clearfix">
                    <img src="assets/img/news-4.jpg" alt="">
                    <h4><a href="#">Laborum corporis quo dara net para</a></h4>
                    <p>Qui enim quia optio. Eligendi aut asperiores enim repellendusvel rerum cuder...</p>
                  </div>
                  <div class="post-item clearfix">
                    <img src="assets/img/news-5.jpg" alt="">
                    <h4><a href="#">Et dolores corrupti quae illo quod dolor</a></h4>
                    <p>Odit ut eveniet modi reiciendis. Atque cupiditate libero beatae dignissimos eius...</p>
                  </div>
                </div>

              </div>
            </div> -->
            <!-- End News & Updates -->
        </div>
        <!-- End Right side columns -->
    </div>
</div>
</section>
