import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SharedService } from '../../../../shared.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrl: './dropdown-button.component.css'
})

export class DropdownButtonComponent implements OnInit {

  constructor(private sharedService: SharedService) { }

  @Input() btnName: string = "";
  @Input() actions = [];
  @Input() row: any = {}
  @Output() tableBtnClicked: EventEmitter<any> = new EventEmitter<any>();


  material_icon = ""

  // Initial state
  idle: boolean = true;

  // Request Initiated
  loading: boolean = false;

  // Success
  success: boolean = false;

  // Failure
  failure: boolean = false;

  // Show the circle
  circle: boolean = false;
  reset(){
    this.idle = true;
    this.loading = false;
    this.success = false;
    this.failure = false;
    this.circle = false;
  }

  ngOnInit(): void {

  }

  async startLoader(){
    this.loading = true;
    this.circle = true;
  }

  private loadingIndicatorSubscription: Subscription;

  notifyParentOfEvent($event, action){
    $event.preventDefault();
     this.loadingIndicatorSubscription = this.sharedService.stopLoadingIndicator().subscribe( (data) => {
      console.log(58, data);
      this.reset();
       this.loadingIndicatorSubscription.unsubscribe();
    });
    this.startLoader();
    
    this.tableBtnClicked.emit( { id: action['id'], buttonName: this.btnName, content: action['name'] } );
    
  }

}
