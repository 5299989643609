import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

@Component({
  selector: 'message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.css']
})

export class MessageItemComponent implements OnInit {

	@Input() message: string = '';
	@Input() from: string = '';
	@Input() time: string = '';
  @Input() payload: any = {}
  @ViewChild("audioPlayer") audioPlayer: ElementRef;

  url = "";
  constructor(private http: HttpClient) { 

  }

  ngOnInit(): void {
  }

  async playPartialAudio(call_session_id) {

  // let pindex = 0;
  // let answer = 0;
  // for(let i = 0; i < this.callRecords.length; i++){
  //   if(this.callRecords[i]['recording_id'] == call_session_id){
  //     answer = pindex;
  //   } else {
  //     if(typeof this.callRecords[i]['recording_id'] !== 'undefined'){
  //       pindex++;
  //     }
  //   }
  // }
  
  let endpoint = `api/calls/getCallRecordingLink`
  
    let call_recording_info = await this.http.post(endpoint,
    {
        call_record_id: call_session_id
    }).toPromise();

    try {
      let download_urls = call_recording_info["download_urls"];
      let keys = Object.keys(download_urls);

      console.log(60, download_urls, keys);
      this.url = download_urls[keys[0]];
    } catch(err) { 
      console.log(60, err)
    }



    this.audioPlayer.nativeElement.load();

    this.audioPlayer.nativeElement.play().then(() => {
      console.log('Playback started successfully');
    }).catch(e => {
      console.error('Playback failed:', e);
      // Handle or log the error, possibly retry or provide user feedback
    });
  }

  @Output() delete = new EventEmitter<void>();
  async onDelete(call_session_id) {

    let endpoint = `api/calls/deleteVoicemail/call_session_id/${call_session_id}`
  
    await this.http.get(endpoint).toPromise();   
  }

}
