import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appMoveModalToBody]'
})
export class MoveModalToBodyDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const modal = this.el.nativeElement;
    if (modal) {
      this.renderer.removeChild(modal.parentNode, modal);
      this.renderer.appendChild(document.body, modal);
    }
  }
}
