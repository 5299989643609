import { Component, OnInit, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { differenceInMinutes, parseISO } from 'date-fns'; 
import { WebsocketService} from '@websocket'
import { SharedService } from '@app/shared.service';
import { Subscription } from 'rxjs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService as CallService } from '@services/shared.service'
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-child1',
  templateUrl: './child1.component.html',
  styleUrls: ['./child1.component.css']
})
export class Child1Component implements OnInit {

  boxesStartDate: any;
  boxesEndDate: any;
  boxesPeriod: any;

view: [number, number] = [700, 400]; // Chart size
// Chart options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Agent';
  showYAxisLabel = true;
  yAxisLabel = 'Minutes';

  colorScheme = 'vivid';

  // Sample data
  single = [];

  connectionSubscription: Subscription;
  isConnected: boolean;

  recentActivities = [];
  orders = [];
  revenueCurrentMonth = 0;
  paidCurrentMonth = 0;
  salesToday = 0;
  customersThisYear = 0;

  offices = [];
  teams = [];
  supervisors = [];
  payload: any = {}

  constructor(private dataService: DataService, private http: HttpClient, private router: Router, private sharedService: SharedService, private callService: CallService, private websocketService: WebsocketService, 
    private renderer: Renderer2, private el: ElementRef ) { 
  }

  onSelect(event) {

  }

  bUpdateUserStatRateLimit = false;
  userInfo = null;

  async ngOnInit() {

    this.dataService.getUserInfo().subscribe(data => {
      this.userInfo = data;
    });

    this.sharedService.getTeams().subscribe(data => this.teams = data);
    this.sharedService.getOffices().subscribe(data => this.offices = data);
    this.supervisors = await this.sharedService.getSupervisors();

        this.sharedService.getCmd().subscribe(cmd => {

          if(cmd["cmd"] == "disconnectBtnClicked"){
          if(this.isCallBarging === true){
            this.toggleCallBarging(null, null);
          }}

        });

    this.websocketService.getUserOnCallStatus().subscribe(userStatus => {

      let color = '#ffffff'
      if(userStatus.bOnCallStatus == true){
        color = '#00ff00';
      }

      console.log(91, userStatus, userStatus['talk_time'])

      if(this.bLiveFilter !== false) {
      
      for(var user of this.office_users){
        if(user["_id"] == userStatus['user_id']){
          user.userState = userStatus['userState'];

          if (userStatus['talk_time'] != null) {
            user["talkTime"] = userStatus['talk_time'];
          }

          if (userStatus["orderCount"] != null) {
            user["orderCount"] = userStatus["orderCount"];
          }

          if (userStatus["resaleOrderCount"] != null) {
            user["resaleOrderCount"] = userStatus["resaleOrderCount"];
          }

        }

      const element = this.el.nativeElement.ownerDocument.getElementById(userStatus['user_id']);
        if (element) {
          this.renderer.setStyle(element, 'background-color', color);
        }
      }

      }

      // if(userStatus.bOnCallStatus == false){
            
      //   // Fix this....

      //   // This is a temporary stop gap to stop this from causing massive server issues
      //   // if(this.bUpdateUserStatRateLimit === false){
      //   //   this.bUpdateUserStatRateLimit = true;
      //   //   this.updateUserStat(userStatus['user_id'])  
      //   // } else {
      //   //   setTimeout( () => {
      //   //     this.bUpdateUserStatRateLimit = false;
      //   //   }, 15000)
      //   // }
        

      // }

      // for(var user of this.office_users){
      //   if(user["_id"] == userStatus['user_id']){
      //     user.userState = userStatus['userState'];
      //   }
      // }

      // const element = this.el.nativeElement.ownerDocument.getElementById(userStatus['user_id']);
      //   if (element) {
      //     this.renderer.setStyle(element, 'background-color', color);
      //   }

    });

    this.connectionSubscription = this.websocketService.getConnectionStatus().subscribe(status => {

        console.log(151, "Connection status", status);

        this.isConnected = status;

        if(this.isConnected){
          this.websocketService.getData().subscribe(cmd => {
            if(cmd['event'] == 'request_call_logs'){

              

              this.recentActivities.unshift(cmd['payload']);
              if(this.recentActivities.length > 20){
                this.recentActivities = this.recentActivities.slice(0, 20);
              }
            }

            if(cmd['event'] == 'request_user_popups'){
              return;
            }
            
            var office_users_idsAr = this.office_users.map(user => user._id.toString());
            this.websocketService.requestCallStatusUpdates(office_users_idsAr);

          })

          //this.websocketService.sendCmd({ "cmd": "request_call_logs", "filter": {} } ); 

        } else {
          this.connectionSubscription.unsubscribe();

        }
    });

   


    // setTimeout( () => {
    //   
    // }, 500)

    this.selectedOffice = localStorage.getItem("lastOfficeSelected");
    this.selectedOfficeId = localStorage.getItem("lastOfficeSelectedId");

    if(this.selectedOffice == ""){
      return;
    } else {
      this.payload.office_id = this.selectedOfficeId;
      this.onOfficeChange(null);
    }

    //this.onOfficeChange(null);
  }

  async updateUserStat(user_id){
    var officeUsersIdsAr = [ user_id ];
    var url = `/api/administration/reports/getAgentsTalkTime/`;
 
    console.log(180, 'Update User Stat');

            var results = await this.http.post(url, { "period": this.callTimePeriod, "agentsIdsAr":officeUsersIdsAr}).toPromise();


            for(let result of results["result"]){
              for(var user of this.office_users){
                if(user._id == result.agent_id){

                  user["talkTime"] = result;
                }
              }
            }

            url = `/api/administration/reports/getAgentsSales/`;

            results = await this.http.post(url, { "period": this.callTimePeriod, "agentsIdsAr":officeUsersIdsAr}).toPromise();


            for(let result of results["result"]){

              for(var user of this.office_users){

                if(user._id == result._id){
                  
                  //user["orderCount"] = result["orderCount"];
                  //user["resaleOrderCount"] = result["resaleOrderCount"];
                  console.log(189, "Here", user._id, result);
                }
              }
            }
  }
  
  ngOnDestroy() {
      if (this.connectionSubscription) {
        this.connectionSubscription.unsubscribe();
      }
  }


  async getDashboard(office_id){
    let url = `/api/dashboards/getRecentActivities/office_id/${office_id}`;

    let results = await this.http.get(url).toPromise();
    this.recentActivities = results['dashboard']

    url = `/api/dashboards/getRecentOrders/office_id/${office_id}`;
    results = await this.http.get(url).toPromise();
    this.orders = results['orders'];

    this.filterRevenue(null, this.revenueTimePeriod);
    this.filterPaid(null, this.paidTimePeriod);
    
    this.filterSales(null, this.salesTimePeriod);
    this.filterCustomers(null, this.customersTimePeriod);

  }

getRelativeTime(dateTime: string) {
  if(typeof dateTime === 'undefined'){
    return "";
  }
    const minutesDiff = differenceInMinutes(new Date(), parseISO(dateTime));
    if (minutesDiff < 60) return `${minutesDiff} min`;
    if (minutesDiff < 1440) return `${Math.floor(minutesDiff / 60)} hrs`;
    if (minutesDiff < 43200) return `${Math.floor(minutesDiff / 1440)} days`;
    return `${Math.floor(minutesDiff / 43200)} weeks`;
  }

getBadgeClass(index: number) {
    const classes = ['text-success', 'text-danger', 'text-primary', 'text-info', 'text-warning', 'text-muted'];
    return classes[index % classes.length]; // Cycle through classes based on index
  }

callTimePeriod = 'today';

async filterCallTime(period){

  this.callTimePeriod = period;

    let officeUsersIdsAr = [];
    for(let office_user of this.office_users){
      officeUsersIdsAr.push(office_user["_id"]);
    }

    var url = `/api/administration/reports/getAgentsTalkTime/`;

    var results = await this.http.post(url, { "period": this.callTimePeriod, "agentsIdsAr":officeUsersIdsAr}).toPromise();


    for(let result of results["result"]){
      for(var user of this.office_users){
        if(user._id == result.agent_id){

          user["talkTime"] = result;
        }
      }
    }

    url = `/api/administration/reports/getAgentsSales/`;

    results = await this.http.post(url, { "period": period, "agentsIdsAr":officeUsersIdsAr}).toPromise();


    for(let result of results["result"]){

      for(var user of this.office_users){

        if(user._id == result._id){
          
          user["orderCount"] = result["orderCount"];
          user["resaleOrderCount"] = result["resaleOrderCount"];
        }
      }
    }



}

selectedTeam = "";
async onTeamChange(event: Event){
    //const selectElement = event.target as HTMLSelectElement;
    //this.payload.team_id = selectElement.value;
    // Implement additional logic here if needed
    if(this.selectedTeam == ""){
      return;
    }

    localStorage.setItem("lastTeamSelected", this.selectedTeam);
  }

  office_users = [];
  selectedOffice = "";
  selectedOfficeId = "";
  async onOfficeChange(event: Event){
    
    var office_name = "";
    for(var office of this.offices){

      if(office["office_id"] == this.payload.office_id){
        office_name = office["office_name"];
        this.selectedOfficeId = office["office_id"]
      }
    }

    this.selectedOffice = office_name;

    this.getDashboard(this.selectedOfficeId);
    localStorage.setItem("lastOfficeSelected", this.selectedOffice);
    localStorage.setItem("lastOfficeSelectedId", this.selectedOfficeId);


    var url = `/api/administration/live-listening/getOfficeUsers/office_id/${this.payload.office_id}`;
    var results = await this.http.get(url).toPromise();
    this.office_users = results['office_users'];

    var office_users_idsAr = this.office_users.map(user => user._id.toString());

    this.websocketService.requestCallStatusUpdates(office_users_idsAr);

    localStorage.setItem("lastOfficeSelected", this.selectedOffice);

    let ar = []
    let officeUsersIdsAr = [];
    for(let office_user of this.office_users){
      ar.push( { name: office_user.first_name, value: 100 } ) 
      officeUsersIdsAr.push(office_user["_id"]);
    }
    

    url = `/api/administration/reports/getAgentsTalkTime/`;

    results = await this.http.post(url, { "period": this.callTimePeriod, "agentsIdsAr":officeUsersIdsAr}).toPromise();

    for(let result of results["result"]){
      for(var user of this.office_users){
        if(user._id == result.agent_id){
          user["talkTime"] = result;
          //ar.push({name: user.first_name, value: 100})
        }
      }
    }

    this.single = ar;
    url = `/api/administration/reports/getAgentsSales/`;

    results = await this.http.post(url, { "period": this.callTimePeriod, "agentsIdsAr":officeUsersIdsAr}).toPromise();


    for(let result of results["result"]){

      for(var user of this.office_users){

        if(user._id == result._id){
          
          //user["orderCount"] = result["orderCount"];
          //user["resaleOrderCount"] = result["resaleOrderCount"];
        }
      }
    }

  }

  async onSupervisorChange(event: Event){

  }

  salesTimePeriod = 'today';
  async filterSales($event, period){

    if($event !== null){
      $event.preventDefault();  
    }
    
    this.salesTimePeriod = period;

    var url = `/api/dashboards/filterSales/period/${period}/filterKey/office_id/filterValue/${this.selectedOfficeId}`;
    var results = await this.http.get(url).toPromise();

    this.salesToday = results['filterSales'];

  }

  revenueTimePeriod = 'month'
  async filterRevenue($event, period){
    if($event !== null){
      $event.preventDefault();
    }
    this.revenueTimePeriod = period;

    var url = `/api/dashboards/filterRevenue/period/${period}/filterKey/office_id/filterValue/${this.selectedOfficeId}`;
    var results = await this.http.get(url).toPromise();
    this.revenueCurrentMonth = results['totalRevenue'];
  }

  paidTimePeriod = 'month'
  async filterPaid($event, period){
    if($event !== null){
      $event.preventDefault();
    }
    this.paidTimePeriod = period;

    var url = `/api/dashboards/filterPaid/period/${period}/filterKey/office_id/filterValue/${this.selectedOfficeId}`;
    var results = await this.http.get(url).toPromise();
    this.paidCurrentMonth = results['totalRevenue'];
  }

  customersTimePeriod = 'year'
  async filterCustomers($event, period){
    if($event !== null){
      $event.preventDefault();
    }
    this.customersTimePeriod = period;

    var url = `/api/dashboards/filterCustomers/period/${period}/filterKey/office_id/filterValue/${this.selectedOfficeId}`;
    var results = await this.http.get(url).toPromise();
    this.customersThisYear = results['customerCount'];
  }

  ordersTimePeriod = 'today'
  filterOrders($event, period){
    if($event !== null){
      $event.preventDefault();
    }
    this.ordersTimePeriod = period;
  }

  getUserInfo($event, user_id){
    $event.preventDefault();
    this.router.navigate(['main/admin/agents/agent-calls/id/' + user_id]);
  }

  startDate: string;
  endDate: string;

  showCustomDateInputs = false;

   toggleCustomDateInputs($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.showCustomDateInputs = !this.showCustomDateInputs;
  }

  filterSalesCustom() {
    if (!this.startDate || !this.endDate) {
      alert('Please select both start and end dates.');
      return;
    }
    console.log('Custom Date Range:', this.startDate, this.endDate);
    // Implement your custom date range filtering logic here
  }

  isCallBarging = false;
  toggleCallBarging($event, user){
    if($event !== null)
      $event.preventDefault();

    for(let usr of this.office_users){
      if(this.isCallBarging === true){
        if(usr["isBarging"] == true){
          if(user === null){
            user = usr;
          }

          if(usr["_id"] !== user["_id"]){
            alert("You cannot listen in on more than one call at a time");
            return;
          }
        }
      }
    }
    
    this.isCallBarging = !this.isCallBarging;

    user["isBarging"] = !user["isBarging"];

    let cmd = "startAdminCallBarge";
    if(this.isCallBarging === false){
      cmd = "endAdminCallBarge";

      this.websocketService.sendCmd({ "cmd": "end_call_barging", filter: { user_id: user["_id"] } } );
      return;
    }

    console.log(530, "sendCmd", cmd);
    this.sharedService.sendCmd({ "cmd" : cmd, "user": user })
  
  }

  toggleCall($event, user){


    $event.preventDefault();
    
    var data = {}
    data["row"] = {}
    data["row"]["phone"] = `+${user.telephony.number}`
    this.sharedService.sendData(data );
  }

  async handleFilterChange($event){

  }

  async onShowCustomDates(startDate, endDate){


      let officeUsersIdsAr = [];
      for(let office_user of this.office_users){


        officeUsersIdsAr.push(office_user["_id"]);
      }

      var url = `/api/administration/reports/getAgentsTalkTime/`;

      var results = await this.http.post(url, 
        { 
          "period": "custom", 
          "agentsIdsAr":officeUsersIdsAr,
          "custom_dates": 
              { 
                "start_date": startDate, 
                "end_date": endDate 
              } 
      }).toPromise();

      for(let result of results["result"]){
        for(var user of this.office_users){
          if(user._id == result.agent_id){

            user["talkTime"] = result;
          }
        }
      }

      url = `/api/administration/reports/getAgentsSales/`;

      results = await this.http.post(url, { "period": "custom", "agentsIdsAr":officeUsersIdsAr,
          "custom_dates": 
              { 
                "start_date": startDate, 
                "end_date": endDate 
              } }).toPromise();


      for(let result of results["result"]){

        for(var user of this.office_users){

          if(user._id == result._id){
            
            user["orderCount"] = result["orderCount"];
            user["resaleOrderCount"] = result["resaleOrderCount"];
          }
        }
      }

  }

  bLiveFilter = true;
  handleBoxesFilterChange($event){
    
    console.log(587, $event.period);
    
    if($event.period !== 'today'){
      this.bLiveFilter = false;
    } else {
      this.bLiveFilter = true;
    }
    

    if($event.period == "custom"){
      return this.onShowCustomDates($event.startDate, $event.endDate);  
    }


    return this.filterCallTime($event.period)

  }

  handleFilterSalesChange($event){

    if($event.period == "custom"){
      // Handle custom
    } else {
      this.filterSales(null, $event.period);  
    }

  }

  handleBoxesFilterRevenueChange($event)
  {

    if($event.period == "custom"){
      return this.onShowCustomRevenueDates($event.startDate, $event.endDate);  
    }
    return this.filterRevenue(null, $event.period)
  }

  handleBoxesFilterPaidChange($event)
  {

    if($event.period == "custom"){
      return this.onShowCustomRevenueDates($event.startDate, $event.endDate);  
    }
    return this.filterPaid(null, $event.period)
  }

  handleBoxesFilterCustomersChange($event)
  {
    if($event.period == "custom"){
      return this.onShowCustomerCustomersDates($event.startDate, $event.endDate);  
    }
    return this.filterCustomers(null, $event.period)
  }

  async onShowCustomRevenueDates(startDate, endDate){

    this.revenueTimePeriod = "custom";

    var url = `/api/dashboards/filterRevenue`;
    var results = await this.http.post(url,
      {
        period: "custom",
        filterKey: "office_id",
        filterValue: this.selectedOfficeId,
        startDate: startDate,
        endDate: endDate
      }).toPromise();
    this.revenueCurrentMonth = results['totalRevenue'];
  }


  async onShowCustomerCustomersDates(startDate, endDate){

    this.customersTimePeriod = "custom"

    var url = `/api/dashboards/filterCustomers`;
    var results = await this.http.post(url, {
        period: "custom",
        filterKey: "office_id",
        filterValue: this.selectedOfficeId,
        startDate: startDate,
        endDate: endDate      
    }).toPromise();
    this.customersThisYear = results['customerCount'];
  }

}
