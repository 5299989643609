<li class="notification-item" [class.bg-red]="isDeleted">
  <i class="bi material-icons">{{icon}}</i>
  <div>
    <h4>{{title}}</h4>
    <p><a [routerLink]="routerPath">Missed call from {{notification | formatPhone}}</a></p>
    <div class="d-flex align-items-center">
      <span class="me-auto">{{time}}</span> <!-- Using span instead of p -->
      <span class="material-icons" style="cursor: pointer;" (click)="trashNotification($event)">delete</span>
    </div>
  </div>
</li>

<li>
  <hr class="dropdown-divider">
</li>
