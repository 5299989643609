import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HttpClient } from '@angular/common/http';
import { FlextableComponent } from "../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../shared.service';

@Component({
  selector: 'app-shortlist',
  templateUrl: './shortlist.component.html',
  styleUrls: ['./shortlist.component.css']
})

export class ShortlistComponent implements OnInit, FlexTableInterface {

  panelId = "";

  @ViewChild('flextable') flextable: FlextableComponent;
  @ViewChild('openscript') openscript: ElementRef;
  @ViewChild('closeOrderPopup') closeOrderPopup: ElementRef;

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.sharedService.getCallStatus().subscribe(callStatus => {
      if(callStatus == "destroy"){
        this.bOnActiveCall = false;
        if(this.startAutoDialerOnFirstCall == true){
          let rowData = this.flextable.getRowByIndex(this.currentRowCalling + 1);
          setTimeout( () => {
            let callInfo = { "_id": rowData["_id"], buttonName: "Call", row: rowData, rowIndex: this.currentRowCalling + 1 }
            this.sharedService.sendData( callInfo );
          }, 2500)
          this.parentEvent.emit( { id: rowData['_id'] } );
          this.flextable.selectRow( this.currentRowCalling + 1 );
          this.currentRowCalling = this.currentRowCalling + 1;         
        }
      }
      if(callStatus == "new"){
        this.bOnActiveCall = true;
      }
    });
  }

  scripts = [];
  intro: string = "";
  provocativeQuestion: string = "";
  pitch: string = "";
  startRecordingOnOrder: boolean = false;
  displayCallScript: boolean = false;
  delayInSeconds: number = 15;
  bOnActiveCall = false;

  bIsAutoDialing = false;
  currentRowCalling = null;
  startAutoDialerOnFirstCall = false;


  ngOnInit(): void {
      this.http.get('/api/datasource/call_scripts/max_records/1000').subscribe(
      response => {
        console.log(response);
        this.scripts = response["call_scripts"];
        },
        error => {
          console.error('Error starting scraping', error);
        }
      );
       this.sharedService.getProducts().subscribe(data => this.products = data);
  }

  getProducts(){

  }

  @ViewChild('drawer') drawer: MatDrawer;
  @Output() public parentEvent = new EventEmitter<any>();

  sendEventToChild(_id: String) {
    const eventData = 'Custom event data from parent';
    this.parentEvent.emit(_id);
  }

  activePanel(componentInfo: any){
    console.log(28)
    this.panelId = componentInfo._id;
    if(this.drawer.opened){
      setTimeout( () => {
        
      }, 1500)      
    }

    this.drawer.toggle()
  }

  handleEvent(eventData: string) {
    console.log('Received event data in parent:', eventData);
  }

  closePanel(newVar: string) {
     this.drawer.toggle();
  }
  
  headerButtonClicked(event: any){

    if(event == 'Dials'){

    }
    
    if(event == 'Convos'){

    }

    if(event == "Conversions"){

    }

  }

  tableButtonClicked(requestResult: any) {


    // Assuming requestResult contains a property 'phoneNumber'
    let action = "tel";
    
    if(requestResult.buttonName == "Text"){
      action = "sms:"
    }
    
    if(requestResult.buttonName == "Email"){
      return;
    }

    let phoneNumber = requestResult.row.phone;
    navigator.clipboard.writeText(phoneNumber);

    //if(this.startRecordingOnOrder == true){
      // let telLink = document.createElement('a');
      // telLink.href = `${action}:${phoneNumber}`;
      // telLink.style.display = 'none'; // Hide the element
      // document.body.appendChild(telLink);
      // telLink.click();
      // document.body.removeChild(telLink);  
    //} else {
      this.sharedService.sendData( requestResult );
    //}

      
    // this.selectedRow = $event.rowIndex;

    if(this.drawer.opened === false){
      this.drawer.toggle();  
    }
    
    this.parentEvent.emit( { id: requestResult['row']['_id'] } );

    this.flextable.selectRow(requestResult["rowIndex"]);

    this.currentRowCalling = requestResult["rowIndex"]

      // this.intro = this.scripts[0].intro;
      // this.provocativeQuestion = this.scripts[0].provocativeQuestion;
      // this.pitch = this.scripts[0].pitch;
      // if(this.displayCallScript){
      //   this.openscript.nativeElement.click();
      // }

  }

  selectedRow = null;
  rowLinkClicked($event){

    this.selectedRow = $event.rowIndex;


    if(this.drawer.opened === false){
      this.drawer.toggle();  
    }
    
    this.parentEvent.emit($event);
  }

  handleNotification($event){
    if($event.event == "placeOrder"){
      this.customer = $event.contact;
      console.log(115, this.customer);
    }
    this.flextable.refreshTable()

  }

  rowDeletedEvent(rowIndex){
    let deletedRow = rowIndex;
    console.log(99, deletedRow, this.selectedRow);
    if(deletedRow == this.selectedRow){
      if(this.drawer.opened === true){
        this.drawer.toggle();  
      }    
    }
  }

  cartItems = [];
  products = [];
  onAddToCart($event){
    var product_id = $event.target.value;
    console.log(167, product_id);
    for(var product of this.products){
      if(product._id == product_id){
        product["qty"] = 25;
        this.cartItems.push(product);
      }
    }
  }

  customer: any = { }
  
deleteItem(index: number): void {
    this.cartItems.splice(index, 1);
  }

edit = false;
orderPopupClosed(): void {
    console.log('Modal closed!');
    // Your logic here
    this.cartItems = [];
      this.edit = false;
  }

saveContact(){
  this.edit = false;
}

editContact(){
  this.edit = true;
}

async placeOrder(){
    let call_recording_info = await this.http.post(`api/datasource/orders`,       
    { 
        "customer_id": this.customer._id, 
        "order": this.cartItems, 
        title: this.customer.scrape_result.title, 
        address: this.customer.scrape_result.address, 
        contactFirstName: this.customer.contactFirstName, 
        contactLastName: this.customer.contactLastName, 
        contactEmail: this.customer.contactEmail, 
        contactPhone: this.customer.scrape_result.phone, 
        "approved":"pending" 
      } ).toPromise();

    this.closeOrderPopup.nativeElement.click();
}
}
