<!-- Chat GPT Prompt
We are mocking up an HTML UI, in the latest version of Angular.

In this task we will be creating the HTML for a component.  We're creating
an interace that allows a user to scrape data from google maps.

Here's what we need:

1) Everything is inside a bootstrap container, and is responsive.
2) A text input that accepts "Target Keyword"
3) Beneighth that, four columns
   - the first column has a dropdown "Country" (I'll fill the country list later, just put USA, Canada, UK and Australia)
   - the second column has state (put a few sample cities, this will be handled in a future task)
   - the third column has cities
   - the 4th column has "keyword {city}, {state}"
-->

<div class="container">



        <div class="row">
            <div class="col-md-2">
            <label for="targetAgent">{{"Select Team" | translate}}</label>
            <select id="teamDropdown" name="targetAgent" class="form-control" [(ngModel)]="selectedTeam">

                <option *ngFor="let team of teams" [ngValue]="team._id">{{ team.teamName | translate }}</option>
            </select>
            </div>

            <div class="col-md-2">
            <label for="targetAgent">{{"Select Language" | translate }}</label>
            <select id="languageDropdown" name="targetAgent" class="form-control" [(ngModel)]="selectedLanguage">
                <option *ngFor="let language of languages" [ngValue]="language.languageShortCode">{{ language.languageName | translate }}</option>
            </select>

            </div>

            <div class="col-md-2">
            <label for="targetAgent">{{"Choose Name For Scrape Job" | translate}}</label>
            <input id="jobName" name="targetAgent" class="form-control" [(ngModel)]="jobName">
            </div>

<!--                 <div class="col-md-12">
                    <label for="targetKeyword">{{ "Inserted Records Must Include One Of These Words In Its Category" | translate }}</label>
                    <input type="text" class="form-control" id="targetKeyword" [(ngModel)]="required_category_keywords">
                </div> -->
    </div>
    <div class="row">
        <div class="col-md-12">
            <label for="targetKeyword">{{"Target Keyword"|translate}}</label>
            <textarea type="text" class="form-control" id="targetKeyword" placeholder="Enter target keyword" [(ngModel)]="keyword"></textarea>
        </div>
    </div>
    <div class="row">
    <div class="col-md-12 d-flex align-items-center">
        <button class="btn btn-primary mt-2" (click)="setRequiredCategories()">{{"Set Required Categories" | translate}}</button>
        <div class="form-check ml-3 mt-2">
            <input class="form-check-input" type="checkbox" id="exactMatchKeyword" (change)="toggleExactMatch($event)">
            <label class="form-check-label" for="exactMatchKeyword">{{"Exact Match Keyword" | translate}}</label>
        </div>

        <div class="col-md-12">
            <button class="btn btn-primary mt-2" (click)="performSampleScrape()">{{"Perform Sample Scrape" | translate}}</button>
        </div>

    </div>

    <div class="col-md-12">
        <div *ngFor="let category of requiredCategoryKeywords; let i = index" class="mb-2 d-inline-block" 
             [ngStyle]="{'width': '100%', 'margin-right': (i + 1) % 5 === 0 ? '0' : '1%'}">
            <label class="form-label">{{category}}</label>
            <textarea type="text" class="form-control" [(ngModel)]="categoryValues[category]" >{{categoryValues[category]}}</textarea>
        </div>
    </div>

    </div>

            <input  [(ngModel)]="postalCodeStartRange" id="postcalCodeStartRange">
            <label  for="exactMatchKeyword">{{"Postal Code Start Range" | translate}}</label>

            <input  [(ngModel)]="postalCodeEndRange" id="postalCodeEndRange">
            <label  for="exactMatchKeyword">{{"Postal Code End Range" | translate}}</label>

            <button class="btn btn-primary mt-2" (click)="findPostalCodeRangeCities()">{{"Find Postal Code Range Cities" | translate}}</button>

    <div class="row">
        <div class="col-md-12">
            <button class="btn btn-primary mt-2" (click)="startScraping()">{{"Start Scraping" | translate}}</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-2">
            <label for="country">{{"Country" | translate}}</label>
            <select class="form-control full-height-select" id="country" (change)="onCountryChange($event)" multiple>
                <option *ngFor="let country of countries">{{ country.countryName | translate }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="state">{{regionLabel}} - <a href="#" (click)="selectAllStates($event)">{{"Select All" | translate}}</a></label>
            <select class="form-control full-height-select" id="state" multiple #stateList (change)="onStateChange($event)">
                <option *ngFor="let state of states">{{ state }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="city">City - <a href="#" (click)="selectAllCities($event)">{{"Select All" | translate }}</a></label>
            <select class="form-control full-height-select" id="city" multiple #cityList (change)="onCityChange($event)">
                <option *ngFor="let city of cities">{{ city }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="city">{{postCodeLabel}} - <a href="#" (click)="selectAllZips($event)">{{"Select All" | translate }}</a></label>
            <select class="form-control full-height-select" id="city" multiple #zipSelect (change)="onZipChange($event)">
                <option *ngFor="let zip of zips">{{ zip }}</option>
            </select>
        </div>
		<div class="col-md-4">
		    <label for="keywordTemplate">{{"Keyword" | translate }} - <a href="#" (click)="clearKeywords($event)">{{"Clear" | translate }}</a> | <a href="#" (click)="removePreviouslyScrapedKeywords($event)">{{"Remove Previously Scraped Keywords" | translate }}</a></label>
		    <select class="form-control full-height-select" id="keywordTemplate" multiple>
		        <option *ngFor="let keyword of keywords">{{ keyword }}</option>
		    </select>
		</div>
    </div>

    <div *ngFor="let job of this.previousJobs" class="card mb-3 mt-3">
        <div class="card-header">Previous Scrape Jobs</div>
        <div class="card-body">
            <div *ngIf="job.keywords; else noKeywords">
                <p class="card-text"><strong>Keywords:</strong> {{ job.keywords.join(', ') }}</p>
            </div>
            <ng-template #noKeywords>
                <p class="card-text">No keywords available.</p>
            </ng-template>
        </div>
    </div>

</div>

