import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import en from 'air-datepicker/locale/en';
import AirDatepicker from 'air-datepicker';
dayjs.extend(utc);

import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.css'
})

export class DatePickerComponent implements OnInit, AfterViewInit, OnChanges {

  @Output() datesChanged = new EventEmitter<{ start_date: string, end_date: string }>();
  @Input() bRange: boolean = false;
  @Input() bTime: boolean = false;
  @Input() bGoBtn: boolean = false;
  @Input() reset: boolean = false; 
  @Input() resetOnGo: boolean = true; 

  @ViewChild('datepickerInput') datepickerInput: ElementRef;

  // Define form controls to bind to the input fields
  startDate = new FormControl();
  endDate = new FormControl();

  dateRange: any = null;
  airDatePicker: any = null;
  formattedDate: any = {};
  constructor() {}
  // Optional: Add a method to handle any specific actions on date change

  ngOnInit(){

  }

  ngAfterViewInit(){
    let startDate = new Date();
    startDate.setHours(8, 0, 0, 0);

  

    this.airDatePicker = new AirDatepicker(this.datepickerInput.nativeElement, {
      buttons: [
        { 
          content: 'Go', 
          onClick: (dp) => {  // Changed to an arrow function

            if (Array.isArray(this.formattedDate)) {
              if (this.formattedDate.length == 2) {
                this.onDatesChanged(this.formattedDate[0], this.formattedDate[1]);
                this.performReset();
              }
            }
            if (this.bRange == false) {
              console.log(62, this.formattedDate);
              this.onSingleDateChanged(this.formattedDate);
              this.performReset();
            }
          }
        }
      ],
      timepicker: this.bTime,
      range: this.bRange,
      locale: en,
      minutesStep: 15,
      onSelect: ({formattedDate, date, datepicker}) => {

        //this.startDate.value =
        this.formattedDate = formattedDate;

      }
    });
  
  }

onSingleDateChanged(startDate: string) {
  // Guess the user's timezone
  const userTimeZone = dayjs.tz.guess();

  // Initialize startDate in the guessed timezone and adjust to the start of the day
  const localStart = dayjs(startDate)
  console.log(93, `Local Timezone: ${userTimeZone}`);
  console.log(95, `Local Start Time: ${localStart.toISOString()}`); // This should show time in local TZ

  // Convert the local timezone date to UTC
  const utcStart = localStart.utc().toISOString();
  console.log(98, `UTC Adjusted Dates:`, { start_date: utcStart, end_date: null });

  // Emit the adjusted date in UTC
  this.datesChanged.emit({ start_date: utcStart, end_date: null });
}


// onDatesChanged(startDate, endDate, userTimeZone) {
//   // Get the user's timezone offset at the specific date (considering daylight saving time if applicable)
//   const timeZoneOffset = new Date(startDate).toLocaleString('en-US', { timeZone: userTimeZone, timeZoneName: 'short' }).split(' ')[2];

//   // Create Date objects in user's local timezone
//   const startOfDay = new Date(new Date(startDate).toLocaleString('en-US', { timeZone: userTimeZone }));
//   startOfDay.setHours(0, 0, 0, 0);

//   const endOfDay = new Date(new Date(endDate).toLocaleString('en-US', { timeZone: userTimeZone }));
//   endOfDay.setHours(23, 59, 59, 999);

//   // Convert to ISO string without further timezone adjustment
//   const startISO = startOfDay.toISOString();
//   const endISO = endOfDay.toISOString();

//   // Emit the adjusted ISO date strings
//   console.log(82, { "start_date": startISO, "end_date": endISO, "userTimeZone": timeZoneOffset });

//   this.datesChanged.emit({ "start_date": startISO, "end_date": endISO });
// }

onDatesChanged(startDate: string, endDate: string) {
  // Optionally, retrieve user's timezone if it's not globally set
  const userTimeZone = dayjs.tz.guess();

  // Convert start and end dates to the beginning and end of the day in user's local timezone
  const localStart = dayjs.tz(startDate, userTimeZone).startOf('day');

  const localEnd = dayjs.tz(endDate, userTimeZone).endOf('day');

  // Convert the local timezone dates to UTC
  const utcStart = localStart.utc().toISOString();
  const utcEnd = localEnd.utc().toISOString();

  // Emit the adjusted ISO date strings in UTC

  // Assuming you have an EventEmitter setup to emit these dates
  this.datesChanged.emit({ start_date: utcStart, end_date: utcEnd });
}

ngOnChanges(changes: SimpleChanges) {
    if (changes['reset'] && changes['reset'].currentValue === true) {
      this.performReset();
      // Optionally reset the input to false after action
      this.reset = false;
    }
  }

performReset() {
    // Reset logic here
    if(this.resetOnGo == true){
      this.datepickerInput.nativeElement.value = "";  
      this.airDatePicker.clear();
    }
    
    this.airDatePicker.hide();
    
}

}
