<div class="container mt-3">

    <div>

        <div class="row">
            <div class="col">
                <label for="domains">{{ "Domains" | translate }}</label>
                <textarea class="form-select" [(ngModel)]="domains" multiple id="domains" name="domains" style="height: 800px;"></textarea>
            </div>

            <div class="col">
                <label for="phoneNumbers">{{"Phone Numbers" | translate }}</label>
                <textarea class="form-select" [(ngModel)]="phoneNumbers" multiple id="phoneNumbers" name="phoneNumbers" style="height: 800px;"></textarea>
            </div>
        </div>
        
        <!-- Save button at the bottom center -->
        <div class="row">
            <div class="col text-center mt-3">
                <button type="button" (click)="save()" class="btn btn-primary">{{"Save" | translate }}</button>
            </div>
        </div>
    </div>

</div>
