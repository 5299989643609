import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-viewer',
  templateUrl: './activity-viewer.component.html',
  styleUrl: './activity-viewer.component.css'
})
export class ActivityViewerComponent {

}
