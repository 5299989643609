import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apikeys',
  templateUrl: './apikeys.component.html',
  styleUrls: ['./apikeys.component.css']
})
export class ApikeysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  headerButtonClicked(event: any){

  }

  tableButtonClicked(requestResult: any){

  }
  
}
