import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '../../../../../reusable/ui/network-request-button/network-request-button.component'
import { FlextableComponent } from "../../../../../reusable/ui/flextable/flextable.component"


@Component({
  selector: 'app-country-management',
  templateUrl: './country-management.component.html',
  styleUrls: ['./country-management.component.css']
})
export class CountryManagementComponent implements OnInit {

  constructor(private renderer: Renderer2, public http: HttpClient) {}

  endpoint = "/api/datasource/countries";
  
  payload: any = { 
    countryName: '',
    countryCode: '',
  }

  ngOnInit(): void {

  }


  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('submitForm') submitForm: NetworkRequestButtonComponent;
  @ViewChild('flextable') flextable: FlextableComponent;

  async waitForResponse(response: any){

    if(response == false){
      console.error("Request Failure");
      return;
    }

    this.dismissModal();

    this.flextable.refreshTable();
  }


  verb = "POST";

  buttonAction = "Add Company"
  rowLinkClicked($event){
    
    this.buttonAction = "Save"
    this.payload = { ... $event.rowData }    
    console.log(45, this.payload);
    this.verb = "PUT"

    this.endpoint = "/api/datasource/countries/id/" + this.payload["_id"];
    this.openModal.nativeElement.click();
  }

  headerButtonClicked(event: any){
    this.openModal.nativeElement.click();
  }

  tableButtonClicked(requestResult: any){
    alert( JSON.stringify(requestResult ) );
  }

  dismissModal() {
    const modalElement: HTMLElement = this.myModal.nativeElement;
    this.renderer.selectRootElement(modalElement, true).dispatchEvent(new Event('click'));
  }

}
