<table-loading-button [disabled]=true #stripeloading [ngStyle]="{ 'visibility': isVisible ? 'hidden' : 'visible' }" >&nbsp;</table-loading-button>
<div class="payment_method_area_add" [ngStyle]="{ 'visibility': isVisible ? 'visible' : 'hidden' }">
   <p>Add Payment Method</p>
   <div class="add-payment-area">
      <div class="display_card_button">
         <button class="active_card_button"><i class="material-icons" style="font-size: 56px;">credit_card</i><br>Credit Card</button>
      </div>
      <div class="card_element">
         <p>Add Credit Card Details</p>
         <div class="card_element_input" >
            <div #stripeCardElement id="card-element"></div>
            <div id="card-errors" class="danger-notify" role="alert"></div>
         </div>
         <generic-loading-button [disabled]=true #addcard (click)="submit()">Add Card</generic-loading-button>
      </div>
   </div>
</div>
<div class="payment_method_area">
   <p>Payment Methods</p>
   <div class="payment-methods-area">
      <table class="table table-striped">
         <thead>
            <tr>
               <th scope="col"><table-loading-button [disabled]=true #tableloading>&nbsp;</table-loading-button></th>
               <th scope="col">Type</th>
               <th scope="col">Card</th>
               <th scope="col">Exp</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let card of paymentMethods; let rowIndex = index" [class.light-red-row]="rowIndex === 0" >
            <th scope="row">&nbsp;</th>
            <td>{{card.card.brand | titlecase}}</td>
            <td>****-****-****-{{card.card.last4}}</td>
            <td>{{card.card.exp_month}}/{{card.card.exp_year}}</td>
            <td><a href="" (click)="deleteCard($event, card, rowIndex)"><i class="material-icons" style="font-size: 20px;" >delete</i></a></td>
            </tr>
            <tr></tr>
         </tbody>
      </table>
   </div>
</div>
<div class="payment_method_area">
   <p>Active Subscriptions</p>
   <div class="payment-methods-area">
      <table class="table table-striped custom-table">
         <thead>
            <tr style="height: 45px;">
               <th scope="col"></th>
               <th scope="col">Subscription Plan</th>
               <th scope="col">Cost</th>
               <th scope="col">Action</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let subscription of activeSubscriptions; let rowIndex = index"  >
               <th scope="row">&nbsp;</th>
               <td>{{subscription.plan.metadata.displayName}}</td>
               <td>{{(subscription.plan.amount_decimal / 100) | currency:'USD' }}</td>
               <td><button type="button" class="btn btn-primary btn-sm" data-bs-toggle="billingModal" data-bs-target="#ExtralargeModal">Change</button>&nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-primary btn-sm">Pause</button>&nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-primary btn-sm">Cancel</button></td>
               <td>{{ userAccount['subscriptionStatus']}}</td>
               <td>{{subscription.plan.trial_period_days}}</td>
            </tr>
            <tr *ngIf="activeSubscriptions.length == 0">
               <td><button type="button" class="btn btn-primary btn-sm" data-bs-toggle="billigM odal" data-bs-target="#ExtralargeModal">Add Subscription Plan</button></td>
            </tr>
         </tbody>
      </table>
   </div>
</div>
<div class="modal fade" appMoveModalToBody  id="ExtralargeModal" tabindex="-1">
<div class="modal-dialog modal-xl">
   <div class="modal-content">
      <div class="modal-header">
         <h5 class="modal-title">Change Plans</h5>
         <button type="button" class="btn-close" data-bs-dismiss="billingModal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <section class="section dashboard">
            <div class="row">
               <div class="row">
                  <!-- Sales Card -->
                  <div *ngFor="let plan of subscriptionPlans" class="col-xxl-4 col-md-6">
                     <div class="card info-card sales-card">
                        <div class="filter">
                           <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                           <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                              <li class="dropdown-header text-start">
                                 <h6>Filter</h6>
                              </li>
                              <li><a class="dropdown-item" href="#">Today</a></li>
                              <li><a class="dropdown-item" href="#">This Month</a></li>
                              <li><a class="dropdown-item" href="#">This Year</a></li>
                           </ul>
                        </div>
                        <div class="card-body">
                           <h5 class="card-title">{{plan.displayName}} <span></span></h5>
                           <div class="d-flex align-items-center">
                              <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                 <i class="bi {{plan.icon}}"></i>
                              </div>
                              <div class="ps-3">
                                 <h6>{{plan.displayCost | currency: 'USD'}}</h6>
                                 <span class="text-success small pt-1 fw-bold">/month</span> <span class="text-muted small pt-2 ps-1">{{plan.shortDescription}}</span>
                              </div>
                           </div>
                        </div>
                        <div class="ps-3">
                           <div class="activity">
                              <div class="activity-item d-flex" *ngFor="let feature of plan.features">
                                 <i class="bi bi-circle-fill activity-badge text-success align-self-start"></i>
                                 <div class="activity-content">
                                    {{feature}}
                                 </div>
                              </div>
                              <network-request-button *ngIf="currentPlan != plan.displayName" (result)="planChanged($event)" [request]='changePlanEndpoint' [payload]="plan['planInfo']" verb="POST">Switch To {{plan.displayName}}</network-request-button>
                              <network-request-button *ngIf="currentPlan == plan.displayName" [disabled]="true">Current Plan</network-request-button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- End Sales Card -->
               </div>
            </div>
         </section>
         <!--          <div class="container-fluid" style="background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);">
            <div class="container p-5">
               <div class="row">
                  <div *ngFor="let plan of subscriptionPlans" class="col-lg-4 col-md-12 mb-4">
                     <div class="card h-100 shadow-lg">
                        <div class="card-body">
                           <div class="text-center p-3">
                              <h5 class="card-title">{{plan.displayName}}</h5>
                              <small>{{plan.useCase}}</small>
                              <br><br>
                              <span class="h2">{{plan.displayCost}}</span>/month
                              <br><br>
                           </div>
                           <p class="card-text">{{plan.shortDescription}}</p>
                        </div>
                        <ul class="list-group list-group-flush">
                           <li *ngFor="let feature of plan.featuresAr" class="list-group-item">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                 <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                              </svg>
                              &nbsp;{{feature}}
                           </li>
                        </ul>
                        <div class="card-body text-center">
                           <button (click)="changeSubscription($event, plan.displayName)" class="btn btn-outline-primary btn-lg" style="border-radius:30px">Select</button>
                        </div>
                        <a href="#" routerLink="{{plan.readMoreLink}}" class="card-link">All Features</a>
                     </div>
                  </div>
               </div>
            </div>
            </div> -->
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="billingModal">Close</button>
         </div>
      </div>
   </div>
</div>