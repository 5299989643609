import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '../../../../../reusable/ui/network-request-button/network-request-button.component'
import { FlextableComponent } from "../../../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../../../shared.service';
import { AdminManagement } from '../../admin.component'
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from "@angular/forms";
import { AdminUsersComponent } from '../users.component'

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.css'
})

export class AddUserComponent extends AdminUsersComponent {

}
