<div class="modal fade" appMoveModalToBody id="users" tabindex="-1">
   <form name="addUser" (ngSubmit)="inviteUser()">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title">{{"Invite New User" | translate}}</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <div class="row mb-4">
                  <label for="inputText" class="col-sm-2 col-form-label">{{"Email" | translate}}</label>
                  <div class="col-sm-10">
                     <input id="email" name="email" [(ngModel)]="payload.to" type="text" class="form-control">
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="roleDropdown" class="col-sm-2 col-form-label">{{"Role" | translate}}</label>
                  <div class="col-sm-10 mb-2">
                     <select id="roleDropdown" name="role" class="form-control" [(ngModel)]="payload.role">
                        <option *ngFor="let role of availableRoles" [ngValue]="role">{{ role | translate }}</option>
                     </select>
                  </div>
                  <label for="roleSuperior" class="col-sm-2 col-form-label mt-2">{{"Superior" | translate}}</label>
                  <div class="col-sm-10 mt-2">
                     <select id="roleSuperior" name="roleSuperior" class="form-control" [(ngModel)]="payload.role_superior" (change)="onSupervisorChange($event)">
                        <option *ngFor="let role of availableRoles" [ngValue]="role">{{ role | translate }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-3">
                  <label class="col-sm-2 col-form-label">&nbsp;</label>
               </div>
               <div class="row mb-4">
                  <label for="teamDropdown" class="col-sm-2 col-form-label">{{"Team" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="teamDropdown" name="team" class="form-control" [(ngModel)]="payload.team_id" (change)="onTeamChange($event)">
                        <option *ngFor="let team of teams" [ngValue]="team.team_id">{{ team.team_name }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="officeDropdown" class="col-sm-2 col-form-label">{{"Office" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="officeDropdown" name="office" class="form-control" [(ngModel)]="payload.office_id">
                        <option *ngFor="let office of offices" [ngValue]="office.office_id">{{ office.office_name }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="supervisorDropdown" class="col-sm-2 col-form-label">{{"Supervisor" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="supervisorDropdown" name="supervisor" class="form-control" [(ngModel)]="payload.superior_id">
                        <option *ngFor="let availableSuperior of availableSuperiors" [ngValue]="availableSuperior._id">{{ availableSuperior.first_name }} {{ availableSuperior.last_name }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="countryDropdown" class="col-sm-2 col-form-label">{{"Country" | translate }}</label>
                  <div class="col-sm-10">
                     <select id="countryDropdown" name="country" class="form-control" [(ngModel)]="selectedCountry">
                        <option value="USA">{{"USA" | translate }}</option>
                        <option value="Canada">{{"Canada" | translate }}</option>
                        <option value="Germany">{{"Germany" | translate }}</option>
                        <option value="France">{{"France" | translate }}</option>
                     </select>
                  </div>
               </div>
               <div class="row mb-4">
                  <label for="phonePrefix" class="col-sm-2 col-form-label">{{"Phone Prefix" | translate }}</label>
                  <div class="col-sm-10">
                     <input id="phonePrefix" name="phonePrefix" [(ngModel)]="phonePrefix" type="text" class="form-control" maxlength="3"><br>
                     <div class="input-group phone-number-input m-2">
                        <select name="selectCountryCode" class="form-select" style="max-width: 70px;" [(ngModel)]="selectedCountryCode">
                           <option appTwemoji value="US" selected>🇺🇸</option>
                           <option appTwemoji value="DE">🇩🇪</option>
                           <option appTwemoji value="TR">🇹🇷</option>
                           <option appTwemoji value="FR">🇫🇷</option>
                        </select>
                        <button type="button" class="btn btn-primary" (click)="searchForNumbers()">{{"Search For Numbers" | translate }}</button>
                        <!-- <input type="text" class="form-control" placeholder="Enter phone number" name="manualPhoneNumber" [(ngModel)]="manualPhoneNumber"> -->
                     </div>
                  </div>

<div class="list-group mt-2 border p-2" *ngIf="ownedPhoneNumbers.length > 0">
   <h5>{{"Phone Numbers You Already Own" | translate }}</h5>
   <div class="row">
      <div class="col-3" *ngFor="let phoneNumber of ownedPhoneNumbers">
         <div class="form-check">
            <input type="radio" class="form-check-input" name="selectedPhoneNumber" [(ngModel)]="selectedPhoneNumber" [value]="phoneNumber.phone_number" id="{{phoneNumber.phone_number}}" (change)="onPhoneNumberChange(phoneNumber.phone_number)">
            <label class="form-check-label" for="{{phoneNumber.phone_number}}">
               {{ phoneNumber.phone_number | formatPhone }}
            </label>
         </div>
      </div>
   </div>
</div>

                  <div class="list-group mt-2">
                     <div class="row">
                        <div class="col-3" *ngFor="let phoneNumber of availablePhoneNumbers | slice:0:5">
                           <div class="form-check">
                              <input type="radio" class="form-check-input" name="selectedPhoneNumber" [(ngModel)]="selectedPhoneNumber" [value]="phoneNumber.phone_number" id="{{phoneNumber.phone_number}}">
                              <label class="form-check-label" for="{{phoneNumber.phone_number}}">
                                 {{ phoneNumber.phone_number | formatPhone }}
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <language-picker [(selectedLanguage)]="parentSelectedLanguage"></language-picker>  
               </div>
            </div>
            <div class="modal-footer">
               <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{"Close" | translate }}</button>
               <button type="submit" class="btn btn-primary">{{"Invite User" | translate }}</button>
            </div>
         </div>
      </div>
   </form>
</div>