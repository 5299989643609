import { Component } from '@angular/core';

@Component({
  selector: 'app-yourfullschool',
  templateUrl: './yourfullschool.component.html',
  styleUrls: ['./yourfullschool.component.css']
})
export class YourfullschoolComponent {

}
