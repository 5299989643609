<div class="container mt-5" *ngIf="!redeemed">
  <div class="row text-center mb-4">
    <div class="col">
      <h1 class="display-4">Redeem Your Gift Card</h1>
      <p class="lead">Choose from the available gift cards below and start redeeming your rewards!</p>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" *ngFor="let brand of brands">
      <div class="card shadow-sm rounded">
        <img [src]="brand.image_url" class="card-img-top" alt="">
        <div class="card-body text-center">
          <h5 class="card-title">{{brand.name}}</h5>
          <p class="card-text">$25 value</p>
          <button class="btn btn-primary btn-block" (click)="redeem(25, brand.brand_code)">Redeem</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="redeemed" class="redeemed-container">
  <h2>Check your email -- your gift card has been emailed</h2>
</div>