<audio id="remoteMedia" autoplay="true"></audio>

<div #dialPad class="position-fixed bottom-0" [ngClass]="{'end-0': bPositionRight, 'start-0': !bPositionRight}" [cdkDragDisabled]="draggingDisabled" cdkDrag cdkDragBoundary=".drag-boundary" style="background-color: #15163c; height: 644px; width: 315px; border-radius: 10px; z-index: 99999; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); border: 1px solid rgba(255, 255, 255, 0.75);;">

    <div  style="" >
        <div class="header-buttons d-flex align-items-center">

            <div style="color: white;">
                

                                    <div class="">
                                             {{ currentCallTalkTime || '00:00:00' }}&nbsp;&nbsp;&nbsp;&nbsp;{{talkTime?.totalTalkTimeMinutes || 0 | number:'1.0-0'}}m

                                             <!-- | 📞{{user?.talkTime?.callCount || 0}} -->


                                        </div>

            </div>
            <div class="dropdown">
                <button class="btn btn-small header-button dropdown-toggle" type="button" id="settingsDropdown" data-bs-toggle="dropdown" aria-expanded="false">⚙️</button>
                <ul class="dropdown-menu" aria-labelledby="settingsDropdown">

                    <li class="dropdown-item">
                        <input class="form-check-input" type="checkbox" id="clearNumberCheck1" [(ngModel)]="settings.clearNumberOnHangup" (ngModelChange)="saveSettings()" />
                        <label  (click)="$event.stopPropagation()" class="form-check-label" for="clearNumberCheck">&nbsp;{{ "Clear Number On Hangup" | translate }}</label>
                    </li>
                    <li class="dropdown-item">
                        <input class="form-check-input" type="checkbox" id="clearNumberCheck2" [(ngModel)]="settings.displayIncomingCalls" (ngModelChange)="saveSettings()" />
                        <label  (click)="$event.stopPropagation()" class="form-check-label" for="clearNumberCheck">&nbsp;{{ "Display Incoming Calls" | translate }}</label>
                    </li>
                    <li class="dropdown-item">
                        <input class="form-check-input" type="checkbox" id="clearNumberCheck3" [(ngModel)]="settings.displayErrorMessages" (ngModelChange)="saveSettings()" />
                        <label  (click)="$event.stopPropagation()" class="form-check-label" for="clearNumberCheck">&nbsp;{{ "Display Error Messages" | translate }}</label>
                    </li>
                    <li class="dropdown-item">
                        <input class="form-check-input" type="checkbox" id="clearNumberCheck4" [(ngModel)]="settings.playSoundsOnCall" (ngModelChange)="saveSettings()" />
                        <label  (click)="$event.stopPropagation()" class="form-check-label" for="clearNumberCheck">&nbsp;{{ "Play Sounds On Call" | translate }}</label>
                    </li>
                    <li class="dropdown-item">
                        <input class="form-check-input" type="checkbox" id="callDirectlyCheck" [(ngModel)]="settings.callDirectly" (ngModelChange)="saveSettings()" />
                        <label (click)="$event.stopPropagation()" class="form-check-label" for="callDirectlyCheck">&nbsp;{{ "Call Directly" | translate }}</label>
                    </li>

        <li class="dropdown-item d-flex flex-column align-items-start">
            <div>
                <div class="form-check">
                    <input (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" class="form-check-input" type="radio" id="normalCalls" name="callOption" [(ngModel)]="settings.callOption" value="normalCalls" (ngModelChange)="saveSettings()" />
                    <label  (click)="$event.stopPropagation()"  (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" class="form-check-label" for="normalCalls">{{ "Normal" | translate }}</label>
                </div>

                <div class="form-check">
                    <input (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" class="form-check-input" type="radio" id="sendToVoicemail" name="callOption" [(ngModel)]="settings.callOption" value="voicemail" (ngModelChange)="saveSettings()" />
                    <label  (click)="$event.stopPropagation()"  (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" class="form-check-label" for="sendToVoicemail">{{ "Send All Calls To Voicemail" | translate }}</label>
                </div>
                <div class="form-check">
                    <input (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" class="form-check-input" type="radio" id="forwardAll" name="callOption" [(ngModel)]="settings.callOption" value="forwardAll" (ngModelChange)="saveSettings()" />
                    <label  (click)="$event.stopPropagation()"  (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" class="form-check-label" for="forwardAll">{{ "Forward All Calls" | translate }}</label>
                </div>
                <div class="form-check">
                    <input (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false"class="form-check-input" type="radio" id="forwardBusy" name="callOption" [(ngModel)]="settings.callOption" value="forwardBusy" (ngModelChange)="saveSettings()" />
                    <label (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" (click)="$event.stopPropagation()" class="form-check-label" for="forwardBusy">{{ "Forward While Busy" | translate }}</label>
                </div>
                <div class="form-check">
                    <input (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false"class="form-check-input" type="radio" id="voicemailBusy" name="callOption" [(ngModel)]="settings.callOption" value="voicemailBusy" (ngModelChange)="saveSettings()" />
                    <label (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" (click)="$event.stopPropagation()" class="form-check-label" for="voicemailBusy">{{ "Send To Voicemail While Busy" | translate }}</label>
                </div>

<!-- <button 
  (click)="toggleCallerId($event)" 
  [class.active]="settings.callOption === 'callerIdA'" 
  (mousedown)="draggingDisabled=true" 
  (mouseup)="draggingDisabled=false" 
  (mouseleave)="draggingDisabled=false" 
  class="btn btn-toggle">
  {{ callerIdText | translate }}
</button> -->
            </div>
        </li>

<div *ngIf="secondaryNumbers && secondaryNumbers.length > 0" class="dropdown-section" (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" style="padding: 10px;">
    <label class="form-check-label mb-2 d-block" for="secondaryNumber">{{ "Change Number" | translate }}</label>
    <select class="form-select" (change)="onNumberChange($event)">
        <option *ngFor="let number of secondaryNumbers" [value]="number.number">{{number.number}}</option>
    </select>
</div>

<div class="dropdown-section mt-3" (mousedown)="draggingDisabled=true" (mouseup)="draggingDisabled=false" (mouseleave)="draggingDisabled=false" style="padding: 10px;">
    <label class="form-check-label mb-2 d-block" for="secondaryNumber">{{ "Forward Busy Calls Here" | translate }}</label>
    <select class="form-select" [(ngModel)]="selectedNumber" (ngModelChange)="onForwardToUserChange($event)">
        <option *ngFor="let officeUser of users; trackBy: trackByUserNumber" [value]="officeUser.number">
            {{officeUser.name}}
        </option>
    </select>
</div>


                </ul>
            </div>

            <button class="btn btn-small header-button" (click)="resetDialPad()">↩️</button>
            <button class="btn btn-small header-button" (click)="minimizeDialPad()">-</button>
            <button class="btn btn-small header-button" (click)="maximizeDialPad()">+</button>
        </div>

        <div style="height: 159px; background-color: #15163c;">
            <div class="col" style="background-color: #15163c;">
                <div class="phone-number-input"></div>
                <div class="row p-2">
                    <div class="col"></div>
                </div>
                <div class="col text-center">

                <button *ngIf="!bIsRecording" [disabled]="!bIsCallActive" class="btn btn-danger m-2 fixed-width-btn" (click)="toggleRecording()">
<div class="d-flex align-items-center">
  <i *ngIf="!circle" class="bi bi-record-circle"></i>
  <div class="circle d-flex align-items-center justify-content-center ms-2" *ngIf="circle" style="width: 24px; height: 24px;">
    <i [ngClass]="{'material-icons': idle, 'progress-indicator': loading, 'animate-checkbox': success, 'animate-error': failure}" style="font-size: 16px;">{{material_icon}}</i>
  </div>
  <span class="ms-2">{{ 'record' | translate }}</span>
</div>

                </button>

                    <button *ngIf="bIsRecording" class="btn btn-warning m-2 fixed-width-btn" (click)="toggleRecording()"><i class="bi bi-pause-circle"></i> {{ 'pause' | translate }}</button>
                    <button *ngIf="!bIsMuted" [disabled]="!bIsCallActive" class="btn btn-danger m-2 fixed-width-btn" (click)="toggleMuted()"><i class="bi bi-mic-mute"></i> {{ 'mute' | translate }}</button>
                    <button *ngIf="bIsMuted" class="btn btn-warning m-2 fixed-width-btn" (click)="toggleMuted()"><i class="bi bi-play-circle"></i> {{ 'unmute' | translate }}</button>

                    <div class="alert alert-info m-2 p-1" role="alert" style="font-size: smaller; max-height: 30px; overflow: hidden;">
                        {{ status }}
                    </div>

                    <div class="alert alert-light m-2 p-1" role="alert" style="font-size: smaller; max-height: 30px; overflow: hidden;">
                        <div class="form-check form-switch" style="margin-bottom: 0;">
                            <input (change)="updateLocalStorage()" class="form-check-input" type="checkbox" id="openDispositionOnHangup" [(ngModel)]="openDispositionOnHangup" />
                            <label class="form-check-label" for="openDispositionOnHangup" style="font-size: smaller;">Open Disposition On Hangup</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 436px; background-color: #15163c;">
            <div class="col" style="background-color: #15163c;">
                <div class="input-group phone-number-input m-2">
                    <select
                        class="form-select"
                        (change)="saveCountryCode()"
                        style="max-width: 70px;"
                        [(ngModel)]="selectedCountry"
                        (mousedown)="draggingDisabled=true"
                        (mouseup)="draggingDisabled=false"
                        (mouseleave)="draggingDisabled=false"
                    >
                        <option value="+1" selected>🇺🇸</option>
                        <option value="+49">🇩🇪</option>
                        <option value="+41">🇨🇭</option>
                        <option value="+43">🇦🇹</option>
                        <option value="+90">🇹🇷</option>
                        <option value="+33">🇫🇷</option>
                    </select>
                    <input (keypress)="onEnter($event)" type="tel" style="max-width: 228px;" class="form-control" [placeholder]="enter_phone_number" [(ngModel)]="inputPhoneNumber" />
                </div>

                <div class="dial-pad">
                    <div class="row">
                        <button class="btn btn-circle" (click)="dialnum('1')">1</button>
                        <button class="btn btn-circle" (click)="dialnum('2')">2</button>
                        <button class="btn btn-circle" (click)="dialnum('3')">3</button>
                    </div>
                    <div class="row">
                        <button class="btn btn-circle" (click)="dialnum('4')">4</button>
                        <button class="btn btn-circle" (click)="dialnum('5')">5</button>
                        <button class="btn btn-circle" (click)="dialnum('6')">6</button>
                    </div>
                    <div class="row">
                        <button class="btn btn-circle" (click)="dialnum('7')">7</button>
                        <button class="btn btn-circle" (click)="dialnum('8')">8</button>
                        <button class="btn btn-circle" (click)="dialnum('9')">9</button>
                    </div>
                    <div class="row">
                        <button class="btn btn-circle" (click)="dialnum('*')">*</button>
                        <button class="btn btn-circle" (click)="dialnum('0')">0</button>
                        <button class="btn btn-circle" (click)="dialnum('#')">#</button>
                    </div>
                </div>
                <div class="call-actions">
                    <button [disabled]="callCircle" *ngIf="buttonState == 'call'" class="btn btn-success" (click)="manualCall()">

<div class="d-flex align-items-center">
 <i *ngIf="!callCircle" class="bi bi-telephone"></i>
  <div class="call-btn-circle d-flex align-items-center justify-content-center ms-2" *ngIf="callCircle" style="width: 24px; height: 24px;">
    <i [ngClass]="{'material-icons': idle, 'progress-indicator': callLoading, 'animate-checkbox': success, 'animate-error': failure}" style="font-size: 16px;">{{material_icon}}</i>
  </div>
  <span class="ms-2">{{ 'call' | translate }}</span>
</div>

</button>

                    <button *ngIf="buttonState == 'disconnect'" class="btn btn-danger" (click)="disconnect()">{{ 'disconnect' | translate }}</button>
                    <button *ngIf="buttonState == 'answer'" class="btn btn-danger" (click)="answer()">{{ 'answer' | translate }}</button>
                    <button *ngIf="buttonState == 'answer'" class="btn btn-danger" (click)="reject()">{{ 'reject' | translate }}</button>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="inboundCallerBestMatches.length > 0"
        class="position-fixed bottom-0 start-315"
        style="height: 644px; width: 315px; left: 315px; z-index: 1000; border-radius: 10px; background-color: lemonchiffon;"
        cdkDrag
        cdkDragBoundary=".drag-boundary"
    >
        <h5 class="card-title" style="cursor: pointer;">Incoming Call</h5>
        <h5 style="cursor: pointer;">{{incomingCaller}}</h5>
        <h3>Best Matches</h3>
        <div *ngFor="let bestMatch of inboundCallerBestMatches; let rowIndex = index" class="card mb-2">
            <div class="card-body" style="background-color: limegreen;">
                <h5 class="card-title" style="cursor: pointer;" (click)="selectCorrectLead(inboundCallerBestMatches[rowIndex])">{{bestMatch.scrape_result.title}}</h5>
                <p>{{bestMatch.scrape_result.phone}}</p>
                <p>{{bestMatch?.contactName}}</p>
            </div>
        </div>
    </div>

    <div class="card-body">
        <button type="button" #calldisposition class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#calls" style="display: none;"></button>
        <div class="modal fade" appMoveModalToBody appMoveModalToBody id="calls" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Call Dispositions on hangup ... coming soon.</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-1 p-2">
                            <div class="col mb-1 p-2">
                                <label for="contactName" class="form-label">First Name</label>
                                <input type="text" class="form-control" id="firstName" [(ngModel)]="contact.first_name" (ngModelChange)="onfNameChange()" />
                            </div>
                            <div class="col mb-1 p-2">
                                <label for="contactName" class="form-label">Last Name</label>
                                <input type="text" class="form-control" id="lastName" [(ngModel)]="contact.last_name" (ngModelChange)="onlNameChange()" />
                                <!--             <label for="contactExtension" class="form-label">Extension</label>
            <input type="text" class="form-control" id="contactExtension" [(ngModel)]="contact.extension"(ngModelChange)="onExtensionChange()"> -->
                            </div>
                        </div>
                        <div class="mb-1 p-2">
                            <label for="contactEmail" class="form-label">Contact Email</label>
                            <input type="email" class="form-control" id="contactEmail" [(ngModel)]="contact.email" (ngModelChange)="onEmailChange()" />
                        </div>

                        <div class="row mb-1 p-2">
                            <div class="col mb-3 p-2">
                                <label for="coolingPeriod" class="form-label">Let Prospect Cool For ...</label>
                                <select class="form-select" id="coolingPeriod" [(ngModel)]="selectedCoolingPeriod" (change)="onDropdownChange()">
                                    <option value="1 week">1 Week</option>
                                    <option value="1 month">1 Month</option>
                                    <option value="6 weeks">6 Weeks</option>
                                    <option value="3 months">3 Months</option>
                                    <option value="6 months">6 Months</option>
                                    <option value="12 months">12 Months</option>
                                    <option value="Forever">Forever</option>
                                </select>
                            </div>
                            <div class="col mb-3 p-2">
                                <label for="coolingPeriod" class="form-label">Best Time To Call Back</label>
                                <select class="form-select" id="bestCallbackTime" [(ngModel)]="appointmentTime" (change)="setAppointmentTime()">
                                    <option value="00:00">00:00 (12 am)</option>
                                    <option value="01:00">01:00 (1 am)</option>
                                    <option value="02:00">02:00 (2 am)</option>
                                    <option value="03:00">03:00 (3 am)</option>
                                    <option value="04:00">04:00 (4 am)</option>
                                    <option value="05:00">05:00 (5 am)</option>
                                    <option value="06:00">06:00 (6 am)</option>
                                    <option value="07:00">07:00 (7 am)</option>
                                    <option value="08:00">08:00 (8 am)</option>
                                    <option value="09:00">09:00 (9 am)</option>
                                    <option value="10:00">10:00 (10 am)</option>
                                    <option value="11:00">11:00 (11 am)</option>
                                    <option value="12:00">12:00 (12 pm)</option>
                                    <option value="13:00">13:00 (1 pm)</option>
                                    <option value="14:00">14:00 (2 pm)</option>
                                    <option value="15:00">15:00 (3 pm)</option>
                                    <option value="16:00">16:00 (4 pm)</option>
                                    <option value="17:00">17:00 (5 pm)</option>
                                    <option value="18:00">18:00 (6 pm)</option>
                                    <option value="19:00">19:00 (7 pm)</option>
                                    <option value="20:00">20:00 (8 pm)</option>
                                    <option value="21:00">21:00 (9 pm)</option>
                                    <option value="22:00">22:00 (10 pm)</option>
                                    <option value="23:00">23:00 (11 pm)</option>
                                </select>
                            </div>

                            <div class="col mb-3 p-2">
                                <label for="coolingPeriod" class="form-label">Best Day To Call Back</label>
                                <select class="form-select" id="bestCallbackTime" [(ngModel)]="appointmentDay" (change)="setAppointmentDay()">
                                    <option value="any">Any</option>
                                    <option value="monday">Monday</option>
                                    <option value="tuesday">Tuesday</option>
                                    <option value="wednesday">Wednesday</option>
                                    <option value="thursday">Thursday</option>
                                    <option value="friday">Friday</option>
                                    <option value="saturday">Saturday</option>
                                    <option value="sunday">Sunday</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 p-2">
                            <label for="noteInput" class="form-label">Note</label>
                            <textarea class="form-control" id="noteInput" rows="3" [(ngModel)]="note"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" (click)="saveDisposition()">Save</button>
                        <!-- <button type="button" class="btn btn-warning" (click)="">Do Not Call Again</button> -->
                        <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal" #closePopup>Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
