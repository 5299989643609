import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { SharedService } from '../shared.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { BaseService} from "../legacy/base/base.service"
import { NetworkRequestButtonComponent } from '../reusable/ui/network-request-button/network-request-button.component'
import { GlobalService } from '../services/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

    currentLanguageLabel: string = '🇺🇸 English'; // Default language

  constructor(
    private cookieService: CookieService,
    private translate: TranslateService,
    private http: HttpClient,
    private router: Router,
    public dataService: DataService,
    private globalService: GlobalService,
    private sharedService: SharedService,
    public service: BaseService) { 
  }

  @ViewChild('login') loginButton: NetworkRequestButtonComponent;
  @ViewChild('firstDigit') firstDigit: ElementRef;

  corporationObj = "10";

  username = "";
  password = "";

  showPassword: boolean = false;

  rememberMe: boolean = false;

  authorizeEndpoint = "/api/authorize";

  loginData: any = { }


  ngOnInit(): void {

    localStorage.removeItem('masterTab');  
    
    this.sharedService.sendCmd({ "cmd": "onInactive" });
    console.log(56, "sending inactive command");

    this.loadLanguagePreference();

    this.loginData = {
      "userId": this.username,
      "pwd": this.password 
    }
    const cookieValue = this.cookieService.get('rememberMe');
    if(cookieValue != ""){
      try {
        var loginData = JSON.parse(cookieValue)
      } catch(err){
        // Unable to parse cookie...  
        return;
      }
      this.loginData = loginData;
      this.rememberMe = true;
    }

  }

  async waitForLoginResponse(response: any){

    if(response == false){
      console.error("Login Failure");
      return;
    }

    this.dataService.setSharedData(response);
    this.dataService.setNames(response.fName, response.lName, "User");
    this.dataService.setPlan(response.plan);
    this.dataService.setAttachedPaymentMethod(response['paymentMethodAttached'])

    this.dataService.setUserInfo(response)

    let home = response.home;
    if(typeof response.home === 'undefined'){
      home = "/main/user/home"
    }    

    this.router.navigate([home]);

    if(this.rememberMe == true){
      this.cookieService.set('rememberMe', JSON.stringify(this.loginData));
    }
  }

  async onLogin() {

  }

  bRecoverAccount = false;

  async recoverAccount($event){
    $event.preventDefault();
    if(this.loginData.userId.length < 5){
      alert("Please enter a valid email address to get a recovery code");
      return;
    }

    this.bRecoverAccount = !this.bRecoverAccount;
    //this.firstDigit.nativeElement.select();

  }

  async tryLogin($event){
    this.loginButton.startNetworkRequest($event);
  }

  changeLanguage(event: Event, language: string): void {
    event.preventDefault();
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    this.currentLanguageLabel = languageLabel[language];
    localStorage.setItem('selectedLanguage', language);

    this.translate.use(language);

    // Add any additional logic here for applying the language changes in your application
  }

  private loadLanguagePreference(): void {
    let primaryLanguage = navigator.language.substring(0, 2);
    var selectedLanguage = localStorage.getItem('selectedLanguage');
    if(selectedLanguage === null){
      localStorage.setItem('selectedLanguage', primaryLanguage);
      this.translate.use(primaryLanguage);
      selectedLanguage = primaryLanguage;
    }

    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    if (selectedLanguage && languageLabel[selectedLanguage]) {
      this.currentLanguageLabel = languageLabel[selectedLanguage];
    }
    
    this.translate.setDefaultLang(selectedLanguage);


    // Add any additional initialization logic here for applying the stored language preference
  }

}
