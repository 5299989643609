<div class="row">
<div class="network-button">
<network-request-button #login icon="mail" (result)="gmailAuthorizationUrl($event)" [customClasses]="linkbuttonclass" [request]="linkGmailEndpoint" verb="GET">{{"Link Gmail" | translate }}</network-request-button>
</div>
&nbsp;

<div class="network-button">
<network-request-button #login icon="mail" (result)="quickbooksAuthorizationUrl($event)" [customClasses]="linkbuttonclass" [request]="linkQuickbooksEndpoint" verb="GET">{{"Link Quickbooks" | translate }}</network-request-button>
</div>
&nbsp;

<div class="network-button">
<network-request-button #login icon="chat_bubble" (result)="slackAuthorizationUrl($event)" customClasses="slack-button" [request]="linkSlackEndpoint" verb="GET">{{"Link Slack" | translate }}</network-request-button>
</div>

<div class="network-button">
<network-request-button #login icon="description" (result)="docsAuthorizationUrl($event)" customClasses="slack-button" [request]="linkDocsEndpoint" verb="GET">{{"Google Docs" | translate}}</network-request-button>
</div>

<div class="network-button">
<network-request-button #login icon="bar_chart" (result)="analyticsAuthorizationUrl($event)" customClasses="slack-button" [request]="linkAnalyticsEndpoint" verb="GET">{{"Google Analytics" | translate }}</network-request-button>
</div>

<div class="network-button">
<network-request-button #login icon="bar_chart" (result)="analyticsAuthorizationUrl($event)" customClasses="slack-button" [request]="searchConsoleEndpoint" verb="GET">{{"Search Console" | translate }}</network-request-button>
</div>
</div>
<div class="row m-1">
     <section class="section profile">
      <div class="row">


        <div >

          <div class="card">
            <div class="card-body pt-3">
              <!-- Bordered Tabs -->
              <ul class="nav nav-tabs nav-tabs-bordered">


                <li class="nav-item">
                  <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">{{"Gmail" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">{{"Quickbooks" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#google-docs">{{"Languages" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#google-analytics">{{"Countries" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#google-searchconsole">{{"Currencies" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#open-ai">{{"Warehouses" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#open-ai">{{"Offices" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#open-ai">{{"Products" | translate }}</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#open-ai">{{"Teams" | translate }}</button>
                </li>
              </ul>
              <div class="tab-content pt-2">

                <div class="tab-pane fade show active profile-overview pt-1" id="profile-overview">
                  <h5 class="card-title">{{"Gmail" | translate }}</h5>
                  <p class="small fst-italic">{{"Add Gmail Accounts" | translate }}</p>

                  <app-flextable
                    [options]="
                      {
                        datasource: 'integrationsgmails',

                        scope: 'user',

                        bNoHeader: true,

                        bAllowDeletion: true,

                        filter: {
                          visible: {
                            $ne: false 
                          }, 
                          archived: { $ne: true }
                        }, 
                        
                        columns: [
                            'email'
                        ]
                      }"
                    ></app-flextable>
                </div>

                <div class="tab-pane fade profile-edit pt-2" id="profile-edit">
                  <app-flextable
                    [options]="
                      {
                        datasource: 'integrationsslacks',

                        scope: 'user',

                        bNoHeader: true,

                        bAllowDeletion: true,

                        bAutoLinks: true,

                        filter: {
                          visible: {
                            $ne: false 
                          }, 
                          archived: { $ne: true }
                        }, 
                        
                        columns: [
                            'name', 'channel', 'configuration_url', 
                        ]
                      }"
                    ></app-flextable>
                </div>

                <div class="tab-pane fade pt-3" id="google-docs">

                  <h5 class="card-title">{{"Google Docs" | translate }}</h5>

                  <app-flextable
                    [options]="
                      {
                        datasource: 'integrationsdocs',

                        scope: 'user',

                        bNoHeader: true,

                        bAllowDeletion: true,

                        filter: {
                          visible: {
                            $ne: false 
                          }, 
                          archived: { $ne: true }
                        }, 
                        
                        columns: [
                            'email', 'hd'
                        ]
                      }"
                    ></app-flextable>

                </div>

                <div class="tab-pane fade pt-4" id="open-ai">
                  <form (ngSubmit)="saveOpenAIAPIKey()">
                    <div class="row mb-3">
                      <label for="Email" class="col-md-4 col-lg-3 col-form-label">{{"OpenAI API Key" | translate }}</label>
                      <div class="col-md-8 col-lg-9">
                        <input [(ngModel)]="openAIAPIKey" name="openai_apikey" type="text" class="form-control" id="openai_apikey" value="">
                      </div>
                    </div>

                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">{{"Save Changes" | translate }}</button>
                    </div>
                  </form>
                </div>

                <div class="tab-pane google-analytics pt-5" id="google-analytics">
                  <h5 class="card-title">{{"Google Analytics" | translate }}</h5>

                  <app-flextable
                    [options]="
                      {
                        datasource: 'integrationsanalytics',

                        scope: 'user',

                        bNoHeader: true,

                        bAllowDeletion: true,

                        filter: {
                          visible: {
                            $ne: false 
                          }, 
                          archived: { $ne: true }
                        }, 
                        
                        columns: [
                            'email', 'hd'
                        ]
                      }"
                    ></app-flextable>
                </div>
                <!--
                   -->

                

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>