import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-animated-notification',
  templateUrl: './animated-notification.component.html',
  styleUrls: ['./animated-notification.component.css']
})
export class AnimatedNotificationComponent implements OnInit {

  showNotification = false;
  notificationMessage = '';
  notifications: any = [];
  constructor(private notificationService: SharedService) { }

  ngOnInit(): void {
    this.notificationService.notification$.subscribe(message => {
      this.notificationMessage = message;
      this.showNotification = true;

      setTimeout(() => {
        this.showNotification = false;
      }, 6000); // Display duration for 6 seconds
    });
  }
}