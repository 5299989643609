import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '../../../../../reusable/ui/network-request-button/network-request-button.component'
import { FlextableComponent } from "../../../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../../../shared.service';
import { AdminManagement } from '../../admin.component'
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from "@angular/forms";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.css'
})

export class EditUserComponent extends AdminManagement implements OnInit {

  offices = [];
  teams = [];
  accounts = [];
  availableSuperiors = [];

  availableRoles: any = [];

  selectedCountry: string;
  phonePrefix: string = '';
  selectedTeam: string;
  selectedOffice: string;
  selectedSupervisor: string;
  selectedLanguage: string = "en";
  createCompanyEmail: boolean = false;
  companyEmail: string;
  selectedPhoneNumber: string = "";
  team_id = "";
  user_id = "";
  availablePhoneNumbers = [ ]

  ownedPhoneNumbers = [];

  @ViewChild('flextable') table: FlextableComponent;

  override async ngOnInit() {
    super.ngOnInit()
    await this.getAvailableRoles();
    this.sharedService.getTeams().subscribe(data => this.teams = data);
    this.sharedService.getOffices().subscribe(data => this.offices = data);
    
    this.clearPayload();

    this.dataService.getEditUserInfo().subscribe( async data =>{
      console.log(48, data);
      this.team_id = data['team_id'];
      this.user_id = data['user_id'];
      this.selectedUserInfo = data;

      //this.onSupervisorChange($event, userInfo['role_superior'])
      //console.log(62, userInfo);
      //this.selectedUserInfo = userInfo;
      
      this.availableSuperiors = await this.sharedService.getAllAccountsWithRole(data['role_superior']);
      this.availableSuperiors.unshift({ first_name: 'None', last_name: '', _id: ''});
      this.selectedUserInfo.superior_id = data["superior_id"];


    });
  }

  async getOwnedNumbers(){
    console.log(70, this.team_id);
    let availableNumbers = await this.http.post(`/api/administration/getAvailableNumbers`, { "team_id":this.team_id } ).toPromise();
    this.ownedPhoneNumbers = availableNumbers["availableNumbers"];   
  }

  onPhoneNumberChange(phoneNumber: any){

  }

    async assignPhoneNumber(){
      console.log(76, "Assign", this.selectedPhoneNumber);
      let availableNumbers = await this.http.post(`/api/administration/assignPrimaryNumber`, { "user_id":this.user_id, "phoneNumber": this.selectedPhoneNumber } ).toPromise();
      await this.getOwnedNumbers();
    }

  async addSecondaryPhoneNumber(){
    console.log(76, "Assign", this.selectedPhoneNumber);
    let availableNumbers = await this.http.post(`/api/administration/assignAdditionalNumber`, { "user_id":this.user_id, "phoneNumber": this.selectedPhoneNumber } ).toPromise();
    await this.getOwnedNumbers();
  }

  async clearPayload(){
    this.payload = { }
    this.payload.role = this.availableRoles[0];
    this.payload.role_superior = this.availableRoles[1];    

  }

  email = '';

  @ViewChild('editUser') editUser: ElementRef
  @ViewChild('closeEditUser') closeEditButton: ElementRef

  selectedUserInfo: any = {};

  async updateUser(){

    delete this.selectedUserInfo.pwd;
    await this.http.post(`/api/administration/updateUser`, { "userInfo": this.selectedUserInfo } ).toPromise();
    this.selectedUserInfo = {}
    this.table.refreshTableStayOnPage();
    this.closeEditButton.nativeElement.click();
  }

  async changePassword(){
    let newPwd = this.selectedUserInfo.pwd;

    try {
          var changePasswordResult = await this.http.post(`/api/administration/changeUserPassword`, { pwd: newPwd, user_id: this.selectedUserInfo.user_id }).toPromise();
         } catch(err){
           
    }

  }

  parentSelectedLanguage: string = 'en';

  async inviteUser(){

    const baseHref = this.location.prepareExternalUrl('/'); // Get base URL
    const url = `https://google-maps-crm.saas-product.com/`;//window.location.protocol + '//' + window.location.host + baseHref;

    this.payload.selectedPhoneNumber = this.selectedPhoneNumber
    this.payload.requiredAreaCode = this.phonePrefix
    console.log(103, this.parentSelectedLanguage);
    await this.http.post(`/api/administration/sendInvitationEmail`, { "baseHref": url, ... this.payload, language: this.parentSelectedLanguage } ).toPromise();

    this.payload.to = '';
    this.availablePhoneNumbers = [];
    alert("User Invited");

  }

  selectedCountryCode = "US"
  async searchForNumbers(){

    console.log(126, "Search For Numbers Clicked");
    
    let availableNumbers = await this.http.post(`/api/administration/searchForNumbers`, { "numberPrefix": this.phonePrefix, countryCode: this.selectedCountryCode } ).toPromise();
    this.availablePhoneNumbers = availableNumbers["availableNumbers"];
  }

  selectedRole = 'user';
  async getAvailableRoles(){
    var result = await this.http.get(`/api/plans/getAvailableRoles`).toPromise();
    this.availableRoles = result['result'];
    this.availableRoles.unshift(this.translateService.instant("None"));
    this.availableRoles.unshift(this.translateService.instant("Select Role"));
  }

  async onSupervisorChange($event){
    this.availableSuperiors = await this.sharedService.getAllAccountsWithRole( this.payload.role_superior);
    this.availableSuperiors.unshift({ first_name: 'None', last_name: '', _id: ''});
    this.payload.superior_id = this.availableSuperiors[0]["_id"];
  }


}
