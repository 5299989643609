import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BehaviorSubject } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  private dataSubject = new Subject<any>();
  private callStatus = new Subject<any>();
  private cmd = new Subject<any>();
  httpOptions = {}

  private mapCoordinates = new BehaviorSubject<Object>(null);

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'x-body': ''
      }),
        "withCredentials": true
    };
  }

  setMapCenter(gps_coords: any){
    this.mapCoordinates.next(gps_coords);
  }

  getMapCenter() {
    return this.mapCoordinates.asObservable()
  }

  sendData(data: any) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }

  getCallStatus() {
    return this.callStatus.asObservable();
  }

  private loadingIndicator = new Subject<boolean>();
  
  setLoadingFalse(){
    this.loadingIndicator.next(false);
  }

  stopLoadingIndicator() {
    return this.loadingIndicator.asObservable()
  }

  setCallStatus(callStatus: any){
    this.callStatus.next(callStatus);
  }

  disconnectCall(){

  }

  recordCall(){

  }

  getCmd() {
    return this.cmd.asObservable();
  }

  sendCmd(cmd: any){
    this.cmd.next(cmd);
  }

private countriesSubject = new BehaviorSubject<any[]>([]);
private bCountriesLoaded = false;

getCountries(): Observable<any[]> {
  if (!this.bCountriesLoaded) {
    let url = "/api/tmpdatasource/find/datasource/countries/scope/account/max_records/all";
    this.http.get<any>(url).pipe(
      map(results => results["countries"].map(result => ({
        countryName: result["countryName"],
        countryCode: result["countryCode"]
      }))),
      tap(countriesAr => {
        this.countriesSubject.next(countriesAr);
        this.bCountriesLoaded = true;
      })
    ).subscribe();
  }
  return this.countriesSubject.asObservable();
}

private languagesSubject = new BehaviorSubject<any[]>([]);
private bLanguagesLoaded = false;

getLanguages(): Observable<any[]> {
  if (!this.bLanguagesLoaded) {
    let url = "/api/tmpdatasource/find/datasource/languages/scope/account/max_records/all";
    this.http.get<any>(url).pipe(
      map(results => results["languages"].map(result => ({
        languageName: result["languageName"],
        languageShortCode: result["languageShortCode"]
      }))),
      tap(languagesAr => {
        this.languagesSubject.next(languagesAr);
        this.bLanguagesLoaded = true;
      })
    ).subscribe();
  }
  return this.languagesSubject.asObservable();
}

  private warehousesSubject = new BehaviorSubject<any[]>([]);
  private bWarehousesLoaded = false;
  getWarehouses(): Observable<any[]> {
      if (!this.bWarehousesLoaded) {
        let url = "/api/tmpdatasource/find/datasource/warehouses/scope/account/max_records/all";
        this.http.get<any>(url).pipe(
          map(results => results["warehouses"].map(result => ({
            warehouseName: result["warehouseName"],
            warehouse_id: result["_id"]
          }))),
          tap(warehousesAr => {
            this.warehousesSubject.next(warehousesAr);
            this.bWarehousesLoaded = true;
          })
        ).subscribe();
      }
      return this.warehousesSubject.asObservable();
  }

  private officeUsersSubject = new BehaviorSubject<any[]>([]);
  private bOfficeUsersLoaded = false;

  getOfficeUsers(office_id): Observable<any[]>{

      if (!this.bOfficeUsersLoaded) {
        var url = `/api/administration/live-listening/getOfficeUsers/office_id/${office_id}`;
        this.http.get<any>(url).pipe(
          map(results => results["office_users"].map(result => ({
            name: `${result['first_name']} ${result['last_name']}`,
            number: result["telephony"]["number"]
          }))),
          tap(officeUsersAr => {
            this.officeUsersSubject.next(officeUsersAr);
            this.bOfficeUsersLoaded = true;
          })
        ).subscribe();
      }
    
    return this.officeUsersSubject.asObservable();
  }

private companiesSubject = new BehaviorSubject<any[]>([]);
private bCompaniesLoaded = false;

getCompanies(): Observable<any[]> {
  if (!this.bCompaniesLoaded) {
    let url = "/api/tmpdatasource/find/datasource/companies/scope/account/max_records/all";
    this.http.get<any>(url).pipe(
      map(results => results["companies"].map(result => ({
        companyName: result["companyName"],
        company_id: result["_id"]
      }))),
      tap(companiesAr => {
        this.companiesSubject.next(companiesAr);
        this.bCompaniesLoaded = true;
      })
    ).subscribe();
  }
  return this.companiesSubject.asObservable();
}

private teamsSubject = new BehaviorSubject<any[]>([]);
private bTeamsLoaded = false;

getTeams(): Observable<any[]> {
  if (!this.bTeamsLoaded) {
    let url = "/api/tmpdatasource/find/datasource/teams/scope/account/max_records/all";
    this.http.get<any>(url).pipe(
      map(results => results["teams"].map(result => ({
        team_name: result["teamName"],
        team_id: result["_id"]
      }))),
      tap(teamsAr => {
        this.teamsSubject.next(teamsAr);
        this.bTeamsLoaded = true;
      })
    ).subscribe();
  }
  return this.teamsSubject.asObservable();
}

private officesSubject = new BehaviorSubject<any[]>([]);
private bOfficesLoaded = false;

getOffices(): Observable<any[]> {
  if (!this.bOfficesLoaded) {
    let url = "/api/tmpdatasource/find/datasource/offices/scope/account/max_records/all";
    this.http.get<any>(url).pipe(
      map(results => results["offices"].map(result => ({
        office_name: result["officeName"],
        office_id: result["_id"]
      }))),
      tap(officesAr => {
        this.officesSubject.next(officesAr);
        this.bOfficesLoaded = true;
      })
    ).subscribe();
  }
  return this.officesSubject.asObservable();
}

private productsSubject = new BehaviorSubject<any[]>([]);
private bProductsLoaded = false;

getProducts(): Observable<any[]> {

  if (!this.bProductsLoaded) {
    this.bProductsLoaded = true;
    let url = "/api/tmpdatasource/find/datasource/products/scope/account/max_records/all";

    this.http.get<any>(url).pipe(
      map(results => results["products"]),
      tap(productsAr => {
        this.productsSubject.next(productsAr);

      })
    ).subscribe();
  }
  return this.productsSubject.asObservable();

}


private usersSubject = new BehaviorSubject<any[]>([]);
private bUsersLoaded = false;

getUsers(): Observable<any[]> {
  if (!this.bUsersLoaded) {
    let url = "/api/tmpdatasource/find/datasource/users/scope/account/max_records/all";
    this.http.get<any>(url).pipe(
      map(results => results["users"]),
      tap(usersAr => {
        this.usersSubject.next(usersAr);
        this.bUsersLoaded = true;
      })
    ).subscribe();
  }
  return this.usersSubject.asObservable();
}

  async aggregate(datasource, aggregate, max_records =10, scope ="account"){
    let postBody = {
      "aggregate": btoa(JSON.stringify(aggregate))
    }

    console.log(266, 'shared.service.ts', 'aggregate');

    let url = `api/tmpdatasource/aggregate/aggregate/datasource/${datasource}/max_records/${max_records}/scope/${scope}/page_size/${max_records}`
    let results = await this.http.post(url, postBody).toPromise();
    if(Array.isArray(results[datasource])){
      if(results[datasource].length == 1){
        if(max_records == 1){
          return results[datasource][0]; 
        }
      }
    }
    return results[datasource];    
  }

  supervisorsLoaded = false;
  supervisorsAr: any = [];
  async getSupervisors(){

    if(this.supervisorsLoaded == false){
      let url = "/api/administration/getAllSupervisors"
      let results = await this.http.get(url).toPromise();    

      this.supervisorsAr = results;  
      return this.supervisorsAr;
    } else {
      return this.supervisorsAr
    }

    //return results["supervisors"]
    // var officesAr = [];
    // for(let result of results["offices"]){
    //   officesAr.push({ "office_name": result["officeName"], "office_id": result["_id"] });
    // }
    // return officesAr;
  }

  async getAllAccountsWithRole(role =''){
    return await this.getArray("users", { "account_type": role }, 'owner', 1000, ["first_name", "last_name", "_id"]);
  }  

  async getArray(datasource, filter = null, all:any =true, max = null, columns =[]) {
    var key = datasource;

    var getAll = "/all";
    if (all == false)
      getAll = '';
    if((all !== false) && (all !== true)){
      getAll = "/" + all;

      
    }

    if(columns.length > 0){
      this.httpOptions['headers'] = this.httpOptions['headers'].append("x-body", columns.toString());
    } else {
      this.httpOptions['headers'] = this.httpOptions['headers'].delete('x-body');
    }

    var api_url = 'api/datasource/' + key + getAll;
    if (max != null) {
      api_url += "/max_records/" + String(max)
    }

    if (filter != "") {
      api_url = api_url + "/filter/" + btoa(JSON.stringify(filter))
    }

    let results = await this.http.get(api_url, this.httpOptions).toPromise();
    this.httpOptions['headers'] = this.httpOptions['headers'].delete('x-body');
    console.log(103, api_url);
    return results[datasource];
  }

  private SearchResults = new BehaviorSubject<Object>(null);
  searchResults = this.SearchResults.asObservable();

  private SearchTerm = new BehaviorSubject<Object>(null);
  searchTerm = this.SearchTerm.asObservable();

  updateSearchResults(data: Object, searchTerm: Object) {
      this.SearchResults.next(data);
      this.SearchTerm.next(searchTerm);
  }

  private clearSearch = new BehaviorSubject<boolean>(false);
  bClearSearch = this.clearSearch.asObservable();
  clearAndStartSearchProgress(){
    this.clearSearch.next(true);
  }

  private CallButtonsEnabled = new Subject<any>();
  callButtonsEnabled = this.CallButtonsEnabled.asObservable();

  enableCallButtons(bEnabled: boolean){

    this.CallButtonsEnabled.next(bEnabled);
  }

  private RecordButtonStatus = new Subject<any>();
  recordButtonStatus = this.RecordButtonStatus.asObservable();
  
  enableRecordButton(){
    this.RecordButtonStatus.next(true)
  }

  disableRecordButton(){
    this.RecordButtonStatus.next(false);
  }

  private MuteButtonStatus = new Subject<any>();
  muteButtonStatus = this.MuteButtonStatus.asObservable()
  enableMuteButton(){
    this.MuteButtonStatus.next(true)
  }

  disableMuteButton(){
    //console.log(259, "disableMuteButton")
    this.MuteButtonStatus.next(false)
  }

  private notificationSubject = new Subject<string>();

  notification$ = this.notificationSubject.asObservable();

  triggerNotification(message: string): void {
    this.notificationSubject.next(message);
  }

}

