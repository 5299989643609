
<div class="map-container" [ngClass]="{'hidden': !bStreetViewResults}" >
  <div id="map" #map></div>
</div>

<div *ngIf="!bStreetViewResults" class="map-container no-street-view-available">
  Street View Not Available
</div>


  <div class="map-container2">
    <div id="map2" #map2></div>
  </div> 

<div cdkDrag class="position-fixed top-0 start-0 shadow-lg rounded-3 mt-3 ms-3" style="width: 500px; height: 97vh; background-color: #fff; z-index: 500; border: 1px solid #ccc;">
  <div class="card bg-transparent border-0" style="height: 100%; width: 100%;">
    <div class="card-header bg-orange" cdkDragHandle style="cursor: move; height: 50px; width: 100%; z-index: 500; font-weight: bold;">

      Drag here to move
    </div>
    <div class="card-body bg-white p-3 shadow-lg rounded-3" style="height: calc(100% - 60px); overflow-y: auto;">

      <app-info-accordion [result]="leadInfo" [ngStyle]="{'height': '95vh'}"></app-info-accordion>


    </div>
  </div>
</div>


<div cdkDrag class="position-fixed top-0 end-0 shadow-lg rounded-3 mt-3 me-3" style="width: 500px; height: 500px; z-index: 9997;">
  <div class="card bg-transparent border-0" style="height: 100%; width: 100%;">
    <div class="card-header bg-orange" cdkDragHandle style="cursor: move; height: 50px; width: 100%; z-index: 500">

      Drag here to move
    </div>
    <div style="height: calc(100% - 60px); margin: 5px; overflow-y: auto; position: relative;">

      <div class="carousel slide carousel-fade" id="carouselExampleFade" data-bs-ride="carousel" data-bs-interval="5000">
<div *ngIf="leadInfo?.scrape_result?.images" class="carousel-inner">
  <div *ngFor="let image of leadInfo.scrape_result.images; let isFirst=first" class="carousel-item" [class.active]="isFirst" data-bs-interval="5000">
    <img [src]="image.image" class="d-block w-100" [alt]="image.title">
  </div>
</div>

      </div>

      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev" style="position: absolute; top: 50%; transform: translateY(-50%); left: 10px;">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next" style="position: absolute; top: 50%; transform: translateY(-50%); right: 10px;">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>






<!-- <div cdkDrag class="position-fixed bottom-0 shadow-lg rounded-3 mb-3" style="left: 50%; transform: translateX(-50%); width: 80%; height: 100px; background-color: red; z-index: 9997;">
  <div class="card bg-transparent border-0" style="height: 100%; width: 100%;">
    <div class="card-header bg-orange" cdkDragHandle style="cursor: move; height: 50px; width: 100%; z-index: 9999">

      Drag here to move
    </div>
    <div class="card-body bg-white p-3 shadow-lg rounded-3" style="height: calc(100% - 60px); overflow-y: auto;">
      
      Content of your floating footer.
    </div>
  </div>
</div> -->

<app-order-form></app-order-form>
<app-calls [bPositionRight]="true"></app-calls>
<app-incoming-calls></app-incoming-calls>
<app-unmatched-incoming-call></app-unmatched-incoming-call> 

<!-- <div class="position-fixed top-0 start-0" style="height: 644px; width: 315px; z-index: 1000;" cdkDrag cdkDragBoundary=".drag-boundary" style="background-color:blue">
  This is a test
</div> -->
<!-- 

<div class="position-fixed bottom-0 end-0 mb-3 me-3 shadow-lg rounded-3 mt-3 ms-3" style="width: 300px; height: 800px; background-color: red; z-index: 9999">
  <div class="card shadow-lg rounded-3">
    <div class="card-body">
      
      Content of your floating panel.
    </div>
  </div>
</div>
 -->