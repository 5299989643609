 <button #openModal type="button" class="btn btn-secondary" style="display: none" data-bs-toggle="modal" data-bs-target="#language">{{"Open" | translate }}</button>

<app-flextable #flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
    (rowLinkClicked)='rowLinkClicked($event)'
	[options]="
		{
			datasource: 'languages',

			bAllowDeletion: true,

			headerButtonsAr: [
				'Add Language'
			],

            linkColumnPreventDefault: true,
            
            linkColumns: [ {
                     column: 'LanguageName',
                     path: '/main/sysadmin/planmanagement/plan/'
                }
            ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

            columnNameRewrites: [
                'Selected', 'Language Name', 'Short Code'
            ],

			scope: 'account',
			
			columns: [
				'selected',
				'languageName',
				'languageShortCode'
			]
		}"
></app-flextable>

<div class="modal fade" appMoveModalToBody  id="language" tabindex="-1">
    <form name="addLanguage">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{"Add Language" | translate }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">
                        <label for="language_name" class="form-label">{{"Language" | translate }}</label>
                        <input id="language_name" name="language_name" [(ngModel)]="payload.languageName" type="text" class="form-control">
                    </div>
                    <div class="mb-4">
                        <label for="language_short_code" class="form-label">{{"Language Short Code" | translate }}</label>
                        <input id="language_short_code" name="language_short_code" [(ngModel)]="payload.languageShortCode" type="text" class="form-control">
                    </div>
                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="submit" class="btn btn-primary">Add Language</button> -->
                    <network-request-button #submitForm (result)="waitForResponse($event)" [request]="endpoint" [payload]="payload" [verb]="verb">{{buttonAction | translate }}</network-request-button>
                </div>
            </div>
        </div>
    </form>
</div>

