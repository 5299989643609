import { Component, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { DataService } from '@services/data.service'
import { Router, CanActivate } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

	profile: any = {
		first_name: "",
		last_name: "",
		alias: "",
		about: "",
		company:  "",
		job:  "",
		country:  "",
		address: "",
		phone: "",
		email: ""
	}

	settings: any = {
		accountChanges:true,
		newProducts:true,
		marketingAndPromos:true,
		securityAlerts:true,
	}

	password: any = {
		oldPassword: "",
		newPassword: "",
		confirmNewPassword: ""
	}

	email: any = {
		origEmail: "",
		newEmail: "",
	}

	sipAccount: any = {
		
	}

  constructor(private http: HttpClient, private router: Router, private dataService: DataService) { }

  async ngOnInit() {
//  	let profile = await this.http.get(`/api/main/user/profile/getProfile`).toPromise();
//  	this.profile = profile['profile'];

  	

  	let settings = await this.http.get(`/api/main/user/profile/getSettings`).toPromise();
  	this.settings = settings['settings'];

    this.dataService.getUserInfo().subscribe(data => {
    	this.profile.first_name = data["first_name"];
    	this.profile.last_name = data["last_name"];
    	this.profile.email = data["email"];
    	this.profile.phone = `+${data["telephony"]["number"]}`;
    	this.profile.alias = data["alias"];
    	this.email.origEmail = data["email"];
    	this.sipAccount = data["telephony"];
		})

  }

  async onSubmit() {
  	let saveResult = await this.http.post(`/api/main/user/profile/save`, this.profile).toPromise();
  	this.dataService.setNames(this.profile.first_name, this.profile.last_name, "agent");
  	this.email.newEmail = this.profile.email;
  	console.log(77, this.email.origEmail, this.email.newEmail);

  	if(this.email.origEmail !== this.email.newEmail){
  		alert("Changing email addresses requires that you log off and log back in again.")
  		this.router.navigate(['/login']);
  	} else {
  		alert("Changes Saved");	
  	}
  	
  }

  async saveSettings(){
	let saveResult = await this.http.post(`/api/main/user/profile/saveSettings`, this.settings).toPromise();
  	alert("Changes Saved");  	
  }

  async changePassword(){
  	if(this.password.newPassword !== this.password.confirmNewPassword){
  		alert("The new password and the confirmation do not match");
  		return;
  	}

  	if(this.password.newPassword.length < 4){
  		alert("Passwords must be at least four characters long");
  		return;
  	}

    let alertMsg = "Password Changed";

     try {
      var changePasswordResult = await this.http.post(`/api/changePassword`, this.password).toPromise();
     } catch(err){
       alertMsg = err.error.Context;
     }

     this.password = { };
  	alert(alertMsg);

  }

}
