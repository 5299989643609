<div class="row mb-4">
  <label for="reportDropdown" class="col-sm-2 col-form-label">{{ "Reports" | translate }}</label>
  <div class="col-sm-10">
    <select id="reportDropdown" name="reports" class="form-control w-50" (change)="onReportChange($event)">
      <option [ngValue]="null" disabled selected>{{ "Select Report" | translate }}</option>
      <option *ngFor="let report of reports" [ngValue]="report">{{ report.reportName | translate }}</option>
    </select>
  </div>
</div>

