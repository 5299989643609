 <button #openModal type="button" class="btn btn-secondary" style="display: none" data-bs-toggle="modal" data-bs-target="#offices">{{"Open" | translate }}</button>

<app-flextable #flextable
	(headerButtonClicked)='headerButtonClicked($event)'
	(tableBtnClicked)='tableButtonClicked($event)'
	(rowLinkClicked)='rowLinkClicked($event)'
	[options]="
		{
			datasource: 'offices',

			bAllowDeletion: true,

			headerButtonsAr: [
				'Add Office'
			],


            linkColumnPreventDefault: true,
            
            linkColumns: [ {
                     column: 'OfficeName',
                     path: '/main/sysadmin/planmanagement/plan/'
                }
            ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

			hiddenColumns: {
				'Email': {
					header: false
				},
				'Primary Email': {
					header: false
				},
				'Cntry': {
					header: false
				},
				'OfficeCountry': {
					header: false
				},
				'Lang':{
					header: false
				},
				'OfficeLanguage': {
					header: false
				},
				'Cc Emails': {
					header: false
				},
				'CC': {
					header: false
				}
			},

            columnNameRewrites: [
                'Selected', 'Office', 'City', 'Cntry', 'Lang', 'Email', 'CC'
            ],

			scope: 'account',
			
			columns: [
				'selected',
				'officeName',
				'officeCity',
				'officeCountry',
				'officeLanguage',
				'primary_email',
				'cc_emails'
			]
		}"
></app-flextable>

<div class="modal fade" appMoveModalToBody  id="offices" tabindex="-1">
    <form name="addCountry">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{"Add Office" | translate }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    

                    <div class="mb-4">
                        <label for="office_name" class="form-label">{{"Office Name" | translate }}</label>
                        <input id="office_name" name="office_name" [(ngModel)]="payload.officeName" type="text" class="form-control">
                    </div>
                    <div class="mb-4">
                        <label for="office_city" class="form-label">{{"Office City" | translate }}</label>
                        <input id="office_city" name="office_name" [(ngModel)]="payload.officeCity" type="text" class="form-control">
                    </div>
					<div class="mb-4">
					    <label for="countryDropdown" class="form-label">{{"Country" | translate }}</label>
					    <select id="countryDropdown" name="country" class="form-control" [(ngModel)]="payload.officeCountry">
					        <option *ngFor="let country of countries" [ngValue]="country.countryCode">{{ country.countryName }}</option>
					    </select>
					</div>
                    <div class="mb-4">
	                     <label for="primary_language" class="form-label">{{"Primary Language" | translate }}</label>
	                    <select id="languageDropdown" name="language" class="form-control" [(ngModel)]="payload.officeLanguage">
    						<option *ngFor="let language of languages" [ngValue]="language.languageShortCode">{{ language.languageName }}</option>
						</select>

                    </div>
	                <div class="mb-4">
	                        <label for="email_orders" class="form-label">Email Orders</label>
	                        <input id="email_orders" name="email_orders" [(ngModel)]="payload.primary_email" type="text" class="form-control">
	                </div>
	            	<div class="mb-4">
	                        <label for="email_orders_cc" class="form-label">CC Orders</label>
	                        <input id="email_orders_cc" name="email_orders_cc" [(ngModel)]="payload.cc_emails" type="text" class="form-control">
	                </div>
                </div>
                <div class="modal-footer">
                    <button #myModal type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{"Close" | translate }}</button>
                    <!-- <button type="submit" class="btn btn-primary">Add Language</button> -->
                    <network-request-button #submitForm (result)="waitForResponse($event)" [request]="endpoint" [payload]="payload" [verb]="verb">{{buttonAction | translate}}</network-request-button>
                </div>
            </div>
        </div>
    </form>
</div>