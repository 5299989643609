  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">


<li *ngFor="let menuItem of menus; let i = index" class="nav-item">
  <a class="nav-link collapsed" routerLink="/main/{{menuItem.route}}">
    <i class="material-icons">{{menuItem.icon}}</i>
    <span *ngIf="!menuItem.submenus">{{menuItem.title}}</span>
  </a>
  <div *ngIf="menuItem.submenus">
    <div [id]="'accordionExample' + i">
      <div >
        <h2 [id]="'heading' + i">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
            {{menuItem.title}}
          </button>
        </h2>
        <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" [attr.data-bs-parent]="'#accordionExample' + i">
          <div *ngFor="let submenu of menuItem.submenus" class="accordion-body">
            <a class="nav-link collapsed" routerLink="/main/{{submenu.route}}">
              <i class="material-icons">{{submenu.icon}}</i>
              <span>{{submenu.title}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</li>





    </ul>
  </aside>


