import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SharedService } from '../../shared.service';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { WebsocketService } from '@websocket'
import { NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@environments';
import * as wavEncoder from 'wav-encoder';
import { v4 as uuidv4 } from 'uuid';

import { io } from "socket.io-client";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

	@ViewChild("audioPlayer") audioPlayer: ElementRef;

	/* Search Options */
	selectedTeam = "";
	selectedLanguage = "";

	bDisplayValidateButton = true;
	title = "";
	homeRoute = "";
	invoiceSearch = ""

	dispositions = [
	  { id: 'spokeWithDM', key: 'Spoke With DM' },
	  { id: 'dmNotAvailable', key: 'DM Not Available' },
	  { id: 'leftVM', key: 'Left VM' },
	  { id: 'dmAskedForInfo', key: 'DM Asked for Info' },
	  { id: 'notInterested', key: 'Not interested' },
	  { id: 'dnc', key: 'DNC' },
	  { id: 'notQualified', key: 'Not Qualified' },
	  { id: 'calledNoAnswerTryAgain', key: 'Called, no answer.  Will try again in a week' }
	];

	preventDefault($event){
		$event.stopPropagation();
		console.log(48);
	}

  async loadCustomNotes(){
    let results = await this.http.get(`api/datasource/customnotes`).toPromise();
    if(typeof results['customnotes'] !== 'undefined'){
    for(let customnote of results['customnotes']){	
    		this.dispositions.push({ id: customnote['_id'], key: customnote['custom_note'] } );	
    	}
     }
    }

	async searchByInvoice($event){
		$event.preventDefault();
		console.log(37, this.invoiceSearch);

    let contactExists: any = await this.http.post(`api/administration/searchByInvoice`, { 
    	  "invoiceDocNumber":`${this.invoiceSearch}`
    } ).toPromise();

    if(contactExists.contact !== "Not Found"){
    	this.searchTerm = contactExists.contact;
    	this.searchFunction();
    } else {
    	this.invoiceSearch = "Customer Invoice Not Found"
    }


	}

	async validatePhoneInput($event){
		$event.stopPropagation();

		let formattedPhoneNumber = this.dataService.formatPhoneNumber(this.phone);
		this.phone = formattedPhoneNumber;

		// Check and see if this contact already exists
    let contactExists = await this.http.post(`api/administration/contactExists`, { 
    	  "phone":this.phone
    } ).toPromise();

    if(contactExists["contactExists"] == true){
    	alert("This contact already exists in the database and cannot be added again");
    	return;
    }

		this.bDisplayValidateButton = !this.bDisplayValidateButton;

	}

	checkValidPhone(){

	}

	async addContact($event){

    let call_recording_info = await this.http.post(`api/datasource/coldleads`, { 
    	  "scrape_result": { "title": this.title, "phone": this.phone, "web_site": this.website, 'category': 'Manual Entry', 'address': '', 'complete_address': { } },
    	  contactHistory: [],
		    lastVisited: false,
		    receivedResponse: false,
		    doNotCall: false,
		    emailScraped: false,
		    voiceContactEstablished: false,
		    agent_id: "$this.res.user._id",
		    legacyCalls: [],
		    callLogs: [],
		    events: [],
    } ).toPromise();

    alert("Contact Added")
	  var api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.title" + '/' + this.title;

	  // this.router.navigate([`main/search`]);
	  // let result = await this.http.get(api_url, this.httpOptions).toPromise();
	  // this.sharedService.updateSearchResults(result, this.title);

	  this.searchTerm = this.phone;
	  this.searchFunction();
		this.bDisplayValidateButton = true;
		this.phone = "";
		this.title = "";
		this.website = "";

	}

  changeLanguage(event: Event, language: string): void {
    event.preventDefault();
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    this.currentLanguageLabel = languageLabel[language];
    localStorage.setItem('selectedLanguage', language);

	  this.translate.use(language);

	  this.dataService.triggerRequest();
    // Add any additional logic here for applying the language changes in your application
  }

  private loadLanguagePreference(): void {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    
    const languageLabel = {
      'en': '🇺🇸 English',
      'de': '🇩🇪 Deutsch',
      'tr': '🇹🇷 Türkçe',
      'fr': '🇫🇷 Français'
    };

    if (selectedLanguage && languageLabel[selectedLanguage]) {
      this.currentLanguageLabel = languageLabel[selectedLanguage];
    }

    this.translate.setDefaultLang(selectedLanguage);


    // Add any additional initialization logic here for applying the stored language preference
  }

	// formatPhoneNumber(phoneNumber: string): string {

	// 	const regexPattern = /^\+1 \d{3}-\d{3}-\d{4}$/;
  // 	if (regexPattern.test(phoneNumber)) {
  //   	return phoneNumber;
  // 	}

	//   // Remove non-digit characters from the input
	//   const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

	//   // Assuming cleanedPhoneNumber is now a 10-digit string
	//   const areaCode = cleanedPhoneNumber.substring(0, 3);
	//   const firstPart = cleanedPhoneNumber.substring(3, 6);
	//   const secondPart = cleanedPhoneNumber.substring(6);

	//   // Format: +1 XXX-XXX-XXXX
	//   return `+1 ${areaCode}-${firstPart}-${secondPart}`;
	// }


	notifications: number = 0;
	messagesCount: number = 0;

	testCount: number = 0;
	messagesAr: any = [ ];

  data: any;
  private dataSubscription: Subscription = Subscription.EMPTY;

	notificationsAr: any = [ ]

	pointsBalance: number = 0;
	cashBalance: number = 0;

	bOnActiveCall = false;

	bIsMuted = true;

	toggleMuted(){
    this.bIsMuted = !this.bIsMuted


  }

  searchTerm = "";

	website: string;
  phone: string;

  submitDetails(){

  }
  
	onAddContactButtonClick($event){

	}

  async searchFunction(){

  	this.sharedService.clearAndStartSearchProgress();
		const urlRegex = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
    if(urlRegex.test(this.searchTerm)) {
    		// If we have a url, we check to see if its a google place.  If so, we try to add it as a contact.
    		var api_url = 'api/agents/scrapeGoogleMaps';

    		var countryCode = "";
    		let intlPrefix = this.dataService.getCountryCode();

    		switch(intlPrefix){
			    case '+1':
			      countryCode =  'US';
			      break;
			    case '+41':
			      countryCode = 'CH';
			      break;
			    case '+49':
			      countryCode = 'DE';
			      break;
			    case '+43':
			      countryCode = 'AT';
			      break;
			    case '+33':
			      countryCode = 'FR';
			      break;
			  }

			  console.log(258, { fullUrl: this.searchTerm, countryCode: countryCode });

    		let result: any = await this.http.post(api_url, { fullUrl: this.searchTerm, countryCode: countryCode }).toPromise();

        this.searchTerm = result.scrape_result.phone;
        
        return;
    }


  	var redirectPath = `main/search`;
  	var api_url = 'api/tmpdatasource//search'
  	
  	let searchBody = {
  		key: 'scrape_result.title',
  		value: this.searchTerm,
  		datasource: "coldleads"
  		// language: "",
  		// team: "",
  		// invoice: "",
  		// disposition: ""
  	}

  	let searchType = 'title';

		// Test if searchTerm is a phone number
		var isPhoneNumber = this.dataService.isPhoneNumber(this.searchTerm)
		this.searchTerm = this.searchTerm.trim();

		if(isPhoneNumber){
			var phoneNumber = this.dataService.formatPhoneNumber(this.searchTerm);
			api_url = 'api/datasource/' + "coldleads" + '/search/' + "scrape_result.phone" + '/' + phoneNumber;
			this.searchTerm = phoneNumber;
			this.dataService.setDialerNumber(phoneNumber);
			searchType = 'phone';
			return this.doSearch(redirectPath, api_url, this.searchTerm)
		}

    // Check if the input is a URL
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    localStorage.setItem("lastSearch", this.searchTerm);

    let searchTermWithProtocol = this.searchTerm;
    if (!this.searchTerm.startsWith('http://') && !this.searchTerm.startsWith('https://')) {
        searchTermWithProtocol = 'http://' + this.searchTerm;
    }

    if (urlPattern.test(searchTermWithProtocol)) {
        let domain;
        try {
            domain = (new URL(searchTermWithProtocol)).hostname;
        } catch (error) {
            console.error('Invalid URL');
            return false;
        }
        let rootDomain = domain.split('.').slice(-2).join('.'); // Extracts ROOT.TLD

        // Additional logic for handling URLs...
        this.searchTerm = rootDomain;

    }

 		var header = header
    
    
 		// First, we check if its a 4 digit number.  If so, we do a search based on that...
		if(this.searchTerm.length == 4 && /^\d{4}$/.test(this.searchTerm)){

			redirectPath = `main/admin/call-search/search/` + this.searchTerm;
			searchType = 'call'
			this.router.navigate([redirectPath]);
			this.searchTerm = "";
			return true;
		}

		this.doSearch(redirectPath, api_url, this.searchTerm, 'POST', searchBody)
		return;

  }

  async doSearch(redirectPath, api_url, searchTerm, verb ='GET', body ={}){
    this.router.navigate([redirectPath]);
    let result;
    if(verb === 'GET'){
    	result = await this.http.get(api_url, this.httpOptions).toPromise();
    } else {
    	result = await this.http.post(api_url, body).toPromise();
    }

    this.sharedService.updateSearchResults(result, searchTerm);  	
  }

  httpOptions = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    "withCredentials": true
  };

  currentLanguageLabel: string = '🇺🇸 English'; // Default language
  
  bRequestedNotifications = false;

  userInfo = null;

  bShowNotification = false;

	constructor(public dataService: DataService, 
		private location: Location,
		public websocketService: WebsocketService,
		private translate: TranslateService,
		public http: HttpClient, private sharedService: SharedService, private cookieService: CookieService, private router: Router) { }


	  ngOnInit(): void {

	  const currentPath = this.location.path();

	  this.loadCustomNotes();

    if(currentPath == "/main/search"){
      this.searchTerm = localStorage.getItem('lastSearch');
      this.searchFunction();    	
    }

    this.initSocket();
    
    this.router.events.subscribe(event => {
    	
    	

      //if (event instanceof Scroll) {
      	
      	if(event['routerEvent']){
      		if(event['routerEvent']['url'] == "/main/search"){

      			if(event['routerEvent']['type'] == 15){

      		}
      	}
      	}
        // if (event.navigationTrigger === 'imperative') {
        //   // Not a refresh, navigation triggered imperatively by user action in the app
        // } else if (event.navigationTrigger === 'popstate') {
        //   // Navigation triggered by a popstate event (e.g., back/forward button, refresh)

        // }
      //}
    });

	  	this.websocketService.getConnectionStatus().subscribe(status => {

	  		if(status === false){
	  			this.bRequestedNotifications = false;
	  			return;
	  		}

	  		if(!this.bRequestedNotifications){
	  			
	  			if(this.websocketService.user_id != ""){
	  				this.bRequestedNotifications = true; 

	  			this.dataService.getUserInfo().subscribe(data => {
	  				if(data === null){
	  					return;
	  				}

	  				this.userInfo = data;


	  				this.homeRoute = data['homeRoute']
	  				this.websocketService.sendCmd({ "cmd": "request_user_voicemails", filter: { user_phone: `+${data["telephony"]["number"]}` } } ); 

	  				this.websocketService.sendCmd({ "cmd": "request_user_popups", filter: { user_id: data["_id"] } } ); 

	  				this.websocketService.sendCmd({ "cmd": "request_user_notification", filter: { user_id: data["_id"] } } ); 

	  				this.websocketService.sendCmd({ "cmd": "set_user_socket", filter: { user_id: data["_id"] } } );

	  				if(typeof this.userInfo['voicemailUrl'] !== 'undefined'){
	  					this.audioUrl = this.userInfo.voicemailUrl;	
	  				}
	  				

	  				this.audioPlayer.nativeElement.load();
	  			});

	  			this.websocketService.getData().subscribe(cmd => {

            if(cmd['event'] == 'request_user_notification'){

              this.notificationsAr.push(cmd['payload'])
              this.notifications = this.notificationsAr.length
              
            }

            if(cmd['event'] == 'request_user_notifications'){
              this.notificationsAr = cmd['payload']
              this.notifications = this.notificationsAr.length
            }

						if(cmd['event'] == 'request_user_popups'){
              //this.notificationsAr = cmd['payload']
              //this.notifications = this.notificationsAr.length
              console.log(472, "received popup", cmd);
              this.dataService.setAppointmentMessage( cmd["payload"] );
            }

						if(cmd['event'] == 'request_user_voicemails'){
              this.messagesAr = cmd['payload'];
              this.messagesCount = this.messagesAr.length;
              if(this.messagesCount > 0){
              	this.sharedService.triggerNotification("You have a new voicemail");	
              }
							

            }

            if(cmd['event'] == 'join_conference_call'){
            	this.sharedService.sendCmd({ "cmd":"join_conference_call", "conference_id": cmd["payload"]["conference_id"]})
            }

            if(cmd['event'] == 'end_call_barging'){
            	this.sharedService.sendCmd({ "cmd":"end_call_barging"})
            }

          })

	  		}
	  		}
	  		
	  	})

	  	this.loadLanguagePreference();

		this.dataSubscription = this.dataService.publicNotificationsAr$.subscribe(data => {
	        //this.notificationsAr = data?.notifications;
	        //this.notifications = data?.count
	   });

		this.dataSubscription = this.dataService.publicMessagesAr$.subscribe(data => {
	        this.messagesAr = data?.messages;
	        this.messagesCount = data?.count
	   });

		this.dataSubscription = this.dataService.public_fName$.subscribe(data => {

	   });

		this.dataSubscription = this.dataService.public_lName$.subscribe(data => {

	   });	

	   this.dataService.public_cashBalance$.subscribe(data => {
	   		this.cashBalance = data;
	   })

	   this.dataService.public_pointsBalance$.subscribe(data => {
	   		this.pointsBalance = data;
	   })

  	this.sharedService.muteButtonStatus.subscribe( muteButtonStatus => {

  		this.muteButtonEnabled = !muteButtonStatus;
  		this.bIsMuted = muteButtonStatus;
  	})




    this.sharedService.recordButtonStatus.subscribe( recordButtonStatus => {
    	//console.log(200, recordButtonStatus);

  		this.recordButtonEnabled = recordButtonStatus;

      
    });









    this.sharedService.getCallStatus().subscribe(callStatus => {


      if(callStatus == "destroy"){
        this.bOnActiveCall = false;
        this.disconnectEnabled = true;
        this.bIsMuted = true;
        this.recordButtonEnabled = false;
      }

      if(callStatus == "new"){
        this.bOnActiveCall = true;
        this.disconnectEnabled = false;
        this.bIsMuted = false;
      }
      
    });

    this.sharedService.callButtonsEnabled.subscribe(callButtonsEnabled => {
    	
    });
  
	   // setInterval( ()=> {
	   // 		this.updateCountdown()
	   // }, 1000);

	  }

	disconnectEnabled = true;
	onDisconnectButtonClick($event){
    this.bOnActiveCall = false;
    this.disconnectEnabled = true;
    this.bIsMuted = true;
    this.recordButtonEnabled = false;
		this.sharedService.sendCmd({ "cmd":"disconnect"})
	}

	onRecordButtonClick($event){

		this.sharedService.sendCmd({ "cmd":"record"})
	}


	onPauseButtonClick($event){

		this.sharedService.sendCmd({ "cmd":"pause"})
	}

	muteButtonEnabled = false;
	recordButtonEnabled = false;

	onMuteButtonClick(state){

		this.muteButtonEnabled = state;

		if(state === false){
			this.sharedService.sendCmd({ "cmd":"mute"})
			this.bIsMuted = true;	
			//this.muteButtonEnabled = true;

		} else {
			this.bIsMuted = false;	
			//this.muteButtonEnabled = false;
			this.sharedService.sendCmd({ "cmd":"unmute"})
		}
		
	}

	onChatButtonClick($event: Event): void {
		$event.preventDefault();
    	this.dataService.notifyChatButtonClick();
  	}

	handleSidebarClick(){
	    const sidebar = document.querySelector('body');
	    sidebar?.classList.toggle('toggle-sidebar');
	}

	signOut(event: Event){
		event.preventDefault();
		this.cookieService.delete('Authorization', '/');
		this.router.navigate(['/login']);
	}

    // Your target date as an ISO string
    isoString = "2023-09-15T00:00:00.000Z";
    
    Countdown: any = '';

	updateCountdown() {


            var now: Date = new Date();
			var targetDate: Date = new Date("2023-09-15T00:00:00.000Z");
            var difference = targetDate.getTime() - now.getTime()


            // // Calculate days, hours, minutes, and seconds
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            // // Display the countdown
            this.Countdown = `Free For the next ${days}d ${hours}h ${minutes}m ${seconds}s`;

        }

	navigateHome($event) {
	  try {
	    $event.preventDefault();
	    // Check if Ctrl key is pressed
	    if ($event.ctrlKey || $event.metaKey) { // metaKey for macOS
	      // Open the desired route in a new tab
	      const url = this.router.serializeUrl(
	        this.router.createUrlTree([this.homeRoute])
	      );
	      window.open(url, '_blank');
	    } else {
	      // Navigate normally within the app
	      this.router.navigate([this.homeRoute]);
	    }
	  } catch (err) {
	    // Handle error if necessary
	  }
	}

  async listDispositions($event, disposition){
  	$event.preventDefault();

  	var redirectPath = `main/search`;  
  	this.router.navigate([redirectPath]);
    	
     let results: any = await this.http.post(`api/tmpdatasource/search/searchByDisposition`, { 
    	  "disposition":disposition
    } ).toPromise();

     console.log(608, results);

     
		this.sharedService.updateSearchResults(results, disposition);  	

    // if(contactExists.contact !== "Not Found"){
    // 	this.searchTerm = contactExists.contact;
    // 	this.searchFunction();
    // } else {
    // 	this.invoiceSearch = "Customer Invoice Not Found"
    // }

  }

  isRecording = false;
  audioUrl: string | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];

  toggleRecording($event) {
  	$event.preventDefault();
		$event.stopPropagation();
    this.isRecording ? this.stopRecording() : this.startRecording();
  }

  async startRecording() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };
      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks);
        this.audioChunks = [];
        const wavBlob = await this.convertToWav(audioBlob);
        const uuid = uuidv4();
        const audioFile = new File([wavBlob], `${uuid}.wav`, { type: 'audio/wav' });

        this.audioUrl = environment.staticStorageBase + "/greetings/" + uuid + ".wav"

		    var api_url = `api/tmpdatasource/patch/datasource/users/_id/${this.userInfo._id}`
		    let postBody = {
		      "key": "voicemailUrl",
		      "value": this.audioUrl
		    }

		    var result = await this.http.patch(api_url, postBody).toPromise();

        try {
          await this.uploadFile(audioFile);
          setTimeout(() => {
            this.audioPlayer.nativeElement.load();
            this.audioPlayer.nativeElement.play();
          }, 1500);
        } catch (error) {
          console.error('Error uploading file:', error);
        }

		    api_url = `api/tmpdatasource/patch/datasource/users/_id/${this.userInfo._id}`
		    postBody = {
		      "key": "settings.callOption",
		      "value": "voicemailBusy"
		    }

		    let settings = JSON.parse(localStorage.getItem('settings'));
		    console.log(757, settings);
		    settings.callOption = "voicemailBusy";
		    localStorage.setItem('settings', JSON.stringify(settings));

		    api_url = `api/tmpdatasource/patch/datasource/users/_id/${this.userInfo._id}`
		    postBody = {
		      "key": "settings",
		      "value": settings
		    }

	    	result = await this.http.patch(api_url, postBody).toPromise();

	    	// Change the inbound profile to the call control app that will handle this
	    	this.http.get('api/calls/setInboundConnectionProfile').toPromise();

	    	// Notify the dialer to update the settings
    
  

      };
      this.mediaRecorder.start();
      this.isRecording = true;
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  }

	private async convertToWav(audioBlob: Blob): Promise<Blob> {
    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const wavData = await wavEncoder.encode({
      sampleRate: audioBuffer.sampleRate,
      channelData: [
        audioBuffer.getChannelData(0), // Left channel
        audioBuffer.numberOfChannels > 1 ? audioBuffer.getChannelData(1) : new Float32Array(audioBuffer.length) // Right channel, if available
      ]
    });

    return new Blob([wavData], { type: 'audio/wav' });
  }

  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.isRecording = false;
    }
  }

  async uploadFile(file: File) {


    // try {
    //   return await this.http.get('/api/agents/deleteAudioFile').toPromise();
    // } catch (error) {
    //   //console.error('Error uploading file:', error);
    //   //throw error;
    // }


    const formData = new FormData();
    formData.append('files', file);

    try {
      return await this.http.post('/api/agents/uploadAudioFile', formData).toPromise();
    } catch (error) {
      console.error(621, 'Error uploading file:', error);
      throw error;
    }


  }

  stopClickPropagation($event) {
  	$event.stopPropagation()
  }

		private socket;
		private reinitSocket = false;

		public disconnectSocket(): void {
		    if (this.socket) {
		        this.socket.disconnect();
		    }
		}

		public reconnectSocket(): void {
		    if (this.socket && !this.socket.connected) {
		        this.socket.connect();
		    }
		}

		callStatusRequested = false;
		user_idsAr = [];

    public initSocket(): void {


    		this.sharedService.getCmd().subscribe(cmd => {
    			

	      switch(cmd["cmd"]){

	      		case "onIdle":
	      			console.log(854, "Set user as idle");    
	      			{      
	      			let encodedStr = this.getCookie('Authorization');
		          let base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
		          let decodedStr = atob(base64Str);
		          let authStr = decodedStr.split(':')[0];
		          let user_id = authStr.split('@%40@')[1];
	      			this.socket.emit("set_idle", { user_id: user_id } );
	      		}
	      		break;

	      		case "noLongerIdle":
	      			console.log(854, "Set useris active");     
	      			{     
	      			let encodedStr = this.getCookie('Authorization');
		          let base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
		          let decodedStr = atob(base64Str);
		          let authStr = decodedStr.split(':')[0];
		          let user_id = authStr.split('@%40@')[1];

	      			this.socket.emit("set_active", { user_id: user_id } );
	      			}
	      			break;

	      		case "onInactive":
	      			{     
	      			let encodedStr = this.getCookie('Authorization');
		          let base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
		          let decodedStr = atob(base64Str);
		          let authStr = decodedStr.split(':')[0];
		          let user_id = authStr.split('@%40@')[1];
	      			this.socket.emit("set_inactive", { user_id: user_id } );
	      			}
	      			break;


					}
				});


        this.socket = io(window.location.origin, { path: '/socket.io' });

        this.socket.on('connect', ($socket) => {

        	  console.log(905, "Connected");
            this.websocketService.notifyConnected();

            if(this.reinitSocket === true){
            	this.initSocket();
            	this.reinitSocket = false;
            }
            //
        });

        this.socket.on('disconnect', () => {
        	  this.callStatusRequested = false;
            this.websocketService.notifyDisconnected();
            console.log(912, "Disconnected");
            this.reinitSocket = true;
        });

        this.socket.on('error', (error: any) => {
            console.error('Websocket Error:', error);
        });

        this.socket.on('data', (data: any) => {

            this.websocketService.sendData(data);
        });

        this.socket.on('request_call_status', (data: any) => {
            this.websocketService.updateUserOnCallStatus(data);
        });

        this.socket.on('batch_call_status', (data: any) => {

        	for(var update of data){

            this.websocketService.updateUserOnCallStatus(update);
        	}
        });

        this.websocketService.getCallStatus().subscribe(bOnCallStatus => {

          const encodedStr = this.getCookie('Authorization');
          const base64Str = decodeURIComponent(encodedStr.split('%20')[1]);
          const decodedStr = atob(base64Str);
          const authStr = decodedStr.split(':')[0];
          let user_id = authStr.split('@%40@')[1];
          this.socket.emit("set_call_status", { user_id: user_id, bOnCallStatus: bOnCallStatus } );
         
        });

				this.websocketService.getCallStatusUpdates().subscribe(user_idsAr => {

					if(user_idsAr.length == 0){
						return;
					}

				  if (user_idsAr !== this.user_idsAr) {
				  	this.user_idsAr = user_idsAr
				    this.socket.emit("request_call_status", { "user_ids": user_idsAr });
				    this.callStatusRequested = true;
				  }
				});

        this.websocketService.getCmds().subscribe(data => {

            // This is a passthrough function to send websocket data to the server
            // the data object is expected to be in a format that the server can parse 
            // and understand
            this.socket.emit(data["cmd"], data["filter"]);
        });

    }

		getCookie(name: string): string | null {
		    const nameEQ = name + "=";
		    const ca = document.cookie.split(';');
		    for(let i=0;i < ca.length;i++) {
		        let c = ca[i];
		        while (c.charAt(0)==' ') c = c.substring(1,c.length);
		        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		    }
		    return null;
		  }
notificationDeleted($event){
  // I'm using a delay here so the user can see that something happened.
	setTimeout( () => {
    this.notificationsAr = this.notificationsAr.filter(element => element._id !== $event.notification_id);
    console.log(727, this.notificationsAr.length)
	}, 500)

}

}
