<app-flextable #flextable
   (rowLinkClicked)='appointmentRowLinkClicked($event)'
   (rowTableBtnClicked)='tableButtonClicked($event)'
	[options]="
		{
			datasource: 'appointments',

			bAllowDeletion: true,

         rowButtons: [
            {
            'column': 'contact_title',
            'buttonName': 'Call',
            'api': '',
            'action': 'call'
            }
         ],

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

			scope: 'user',

			pageSize: '25',
			displayPagination: false,

		   aggregate: [

			  {
			    $match: {
			      appointmentTime: { $lte: '$$$NOW' }
			    }
			  },
			  {
			    $project: {
			      _id: 1,
			      contact_title: 1,
			      contact_phone: 1,
			      appointmentTime: 1,
			      contacts_timezone: 1,
			      contact_id: 1
			    }
			  },
			  {
			    $sort: {
			      appointmentTime: -1
			    }
			  }

		   ],

         linkColumnPreventDefault: true,
         linkColumns: [ {
            column: 'Contact Title',
            path: '/main/sysadmin/planmanagement/plan/'
           }
         ],

         hiddenColumns: {
            'Contact Id': { header: true }
         },

			columns: [
				'contact_title',
            'contact_phone',
				'appointmentTime',
				'contacts_timezone',
            'contact_id'
			]
		}"

></app-flextable>

