import { Directive, Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '../../../reusable/ui/network-request-button/network-request-button.component'
import { FlextableComponent } from "../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../shared.service';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@services/data.service'

@Directive()
export class AdminManagement implements OnInit {

  constructor(public dataService: DataService, public renderer: Renderer2, public location: Location, public http: HttpClient, public sharedService: SharedService, public router: Router, public translateService: TranslateService) {}

  endpoint = "";

  payload: any = {

  }

  countries = [];
  languages = [];

  async ngOnInit() {
    this.sharedService.getCountries().subscribe(data => this.countries = data);
    this.sharedService.getLanguages().subscribe(data => this.languages = data);
  }

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('submitForm') submitForm: NetworkRequestButtonComponent;
  @ViewChild('flextable') flextable: FlextableComponent;

  async waitForResponse(response: any){

    if(response == false){
      console.error("Request Failure");
      return;
    }

    this.dismissModal();

    this.flextable.refreshTable();
  }

  headerButtonClicked(event: any){
    this.openModal.nativeElement.click();
  }

  tableButtonClicked(requestResult: any){
    alert( JSON.stringify(requestResult ) );
  }

  dismissModal() {
    const modalElement: HTMLElement = this.myModal.nativeElement;
    this.renderer.selectRootElement(modalElement, true).dispatchEvent(new Event('click'));
    this.payload = { }
  }

}
