<button #placeOrderBtn type="button" data-bs-toggle="modal" style="display:none" data-bs-target="#order"></button>

<app-flextable #flextable
   (rowLinkClicked)='rowLinkClicked($event)'
   (rowTableBtnClicked)='tableButtonClicked($event)'
	[options]="
		{
			datasource: 'orders',

            'bEnableDateRange': true,
         
            dateFilter: { createdAt : { $lte : '', $gte: '' } },

			filter: {
				visible: {
					$ne: false 
				}, 
				archived: { $ne: true }
			}, 

      rowButtons: [
            {
            'column': 'title',
            'buttonName': 'Edit',
            'api': '',
            'action': 'call'
            }
      ],

      linkColumnPreventDefault: true,
            linkColumns: [ {
            column: 'Title',
            path: '/main/sysadmin/planmanagement/plan/'
          }
       ],


       hiddenColumns: {
            'customer_id': { header: true }
        },

			scope: 'user',

            columnBadges: {
               'approved': {
                column: 'approved',
                color: 'red',
                states: {
                  'complete': { color: 'white', icon_color: '#93C572', material_icon: 'check_circle', display_text: false, tooltip: 'This transaction is complete' },
                  'invoice_sent': { color: 'green', material_icon: 'send', tooltip: 'We are waiting on the seller to complete an action'  },
                  'approved': { color: 'blue', material_icon: 'thumb_up', tooltip: 'We are waiting on the buyer to complete an action'  },
                  'pending': { icon_color: '#FF7F50', color: 'teal', material_icon: 'hourglass_full', tooltip: 'This exchange is in escrpw'   },
                  'rejected': { color: 'red', material_icon: 'hourglass_empty', tooltip: 'This link opportunity was rejected for not meeting our standards'  },
                  'incomplete': { color: '#FF7F50', material_icon: 'hourglass_empty', tooltip: 'This order is missing information'  },
                  'return': { color: '#FF7F50', material_icon: 'hourglass_empty', tooltip: 'This order is awaiting a return'  },
                  'paid': { color: '#007F00', material_icon: 'hourglass_empty', tooltip: 'This order is missing information'  },
                  'shipped': { color: '#1E90FF', icon_color: '#FFFFAF', material_icon: 'local_shipping', tooltip: 'This order has shipped'  },
                  'void': { color: '#1E90FF', icon_color: '#FFFFAF', material_icon: 'local_shipping', tooltip: 'This order is voided'  },
                  'preapproved': { color: '#1E90FF', icon_color: '#FFFFAF', material_icon: 'local_shipping', tooltip: 'This order is pre-approved'  },
               },
               index: 0
               }
            },		
            	
			columns: [
				'title',
				'totalValue',
                'orderType',
				'contactEmail',
				'address',
                'customer_id',
			],

			columnNameRewrites: [ 'Title', 'Total', 'Order Type', 'Email', 'Address', 'customer_id' ],
		}"

></app-flextable>