import { Component } from '@angular/core';

@Component({
  selector: 'app-supervisor-picker',
  templateUrl: './supervisor-picker.component.html',
  styleUrl: './supervisor-picker.component.css'
})
export class SupervisorPickerComponent {

}
