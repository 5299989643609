import { Component } from '@angular/core';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrl: './country-select.component.css'
})
export class CountrySelectComponent {

}
