import { Component, ViewChild, ElementRef, Renderer2, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NetworkRequestButtonComponent } from '../../../../reusable/ui/network-request-button/network-request-button.component'
import { FlextableComponent } from "../../../../reusable/ui/flextable/flextable.component"
import { SharedService } from '../../../../shared.service';
import { AdminManagement } from '../admin.component'
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from "@angular/forms";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class AdminUsersComponent extends AdminManagement implements OnInit, FlexTableInterface {

  offices = [];
  teams = [];
  accounts = [];
  availableSuperiors = [];

  availableRoles: any = [];

  selectedCountry: string;
  phonePrefix: string = '';
  selectedTeam: string;
  selectedOffice: string;
  selectedSupervisor: string;
  selectedLanguage: string = "en";
  createCompanyEmail: boolean = false;
  companyEmail: string;
  selectedPhoneNumber: string = "";
  manualPhoneNumber: string = "";

  availablePhoneNumbers = [ ]

  @ViewChild('flextable') table: FlextableComponent;

  override async ngOnInit() {
    super.ngOnInit()
    await this.getAvailableRoles();
    this.sharedService.getTeams().subscribe(data => this.teams = data);
    this.sharedService.getOffices().subscribe(data => this.offices = data);
    
    this.clearPayload();
  }

  async clearPayload(){
    this.payload = { }
    this.payload.role = this.availableRoles[0];
    this.payload.role_superior = this.availableRoles[1];    
    this.availablePhoneNumbers = [];
  }

  email = '';

  @ViewChild('editUser') editUser: ElementRef
  @ViewChild('closeEditUser') closeEditButton: ElementRef

  selectedUserInfo: any = {};

  async rowLinkClicked($event){

    // this.buttonAction = "Save"
    // this.payload = { ... $event.rowData }    
    // console.log(45, this.payload);
    // this.verb = "PUT"

    //console.log(58, $event['rowData']['_id']);

    var endpoint = "/api/administration/getUser/user_id/" + $event['rowData']['_id'];
    let { userInfo }: any = await this.http.get(endpoint).toPromise();

    //this.onSupervisorChange($event, userInfo['role_superior'])
    //console.log(62, userInfo);
    //this.selectedUserInfo = userInfo;
    

    // this.availableSuperiors = await this.sharedService.getAllAccountsWithRole(userInfo['role_superior']);
    // this.availableSuperiors.unshift({ first_name: 'None', last_name: '', _id: ''});
    // this.selectedUserInfo.superior_id = userInfo["superior_id"];

    this.dataService.setEditUserInfo(userInfo);
    this.editUser.nativeElement.click();

  }

  async updateUser(){

    delete this.selectedUserInfo.pwd;
    await this.http.post(`/api/administration/updateUser`, { "userInfo": this.selectedUserInfo } ).toPromise();
    this.selectedUserInfo = {}
    this.table.refreshTableStayOnPage();
    this.closeEditButton.nativeElement.click();
  }

  async changePassword(){
    let newPwd = this.selectedUserInfo.pwd;

    try {
          var changePasswordResult = await this.http.post(`/api/administration/changeUserPassword`, { pwd: newPwd, user_id: this.selectedUserInfo.user_id }).toPromise();
         } catch(err){
           
    }

  }

  parentSelectedLanguage: string = 'en';

  async inviteUser(){

    const baseHref = this.location.prepareExternalUrl('/'); // Get base URL
    const url = `https://google-maps-crm.saas-product.com/`;//window.location.protocol + '//' + window.location.host + baseHref;

    this.payload.selectedPhoneNumber = this.selectedPhoneNumber
    this.payload.alreadyPurchased = false;
    if(this.manualPhoneNumber !== ""){
      this.payload.selectedPhoneNumber = this.manualPhoneNumber.trim();
      this.payload.alreadyPurchased = true;
    }

    this.payload.requiredAreaCode = this.phonePrefix
    console.log(103, { "baseHref": url, ... this.payload, language: this.parentSelectedLanguage });

    await this.http.post(`/api/administration/sendInvitationEmail`, { "baseHref": url, ... this.payload, language: this.parentSelectedLanguage } ).toPromise();

    this.payload.to = '';
    this.availablePhoneNumbers = [];
    this.manualPhoneNumber = "";
    this.ownedPhoneNumbers = [];
    //alert("User Invited");
    //this.dataService.setPopupMessage({ title: "User Invited, message: errorMessage });
  }

  selectedCountryCode = "US"
  async searchForNumbers(){

    console.log(126, "Search For Numbers Clicked");

    let availableNumbers = await this.http.post(`/api/administration/searchForNumbers`, { "numberPrefix": this.phonePrefix, countryCode: this.selectedCountryCode } ).toPromise();
    this.availablePhoneNumbers = availableNumbers["availableNumbers"];
  }

  selectedRole = 'user';
  async getAvailableRoles(){
    var result = await this.http.get(`/api/plans/getAvailableRoles`).toPromise();
    this.availableRoles = result['result'];
    this.availableRoles.unshift(this.translateService.instant("None"));
    this.availableRoles.unshift(this.translateService.instant("Select Role"));
  }

  async onSupervisorChange($event){
    this.availableSuperiors = await this.sharedService.getAllAccountsWithRole( this.payload.role_superior);
    this.availableSuperiors.unshift({ first_name: 'None', last_name: '', _id: ''});
    this.payload.superior_id = this.availableSuperiors[0]["_id"];
  }

  ownedPhoneNumbers = [];
  async onTeamChange($event){
    let availableNumbers = await this.http.post(`/api/administration/getAvailableNumbers`, { "team_id":this.payload.team_id } ).toPromise();
    this.ownedPhoneNumbers = availableNumbers["availableNumbers"];    
  }

  onPhoneNumberChange(selectedNumber){
    this.manualPhoneNumber = selectedNumber;

  }

  onLanguageChange($event){
    console.log(135, $event);
  }

  async rowDeletedEvent($event){
    console.log(177, $event);
    let availableNumbers = await this.http.post(`/api/administration/makeNumberAvailable`, { "number":$event['number'] } ).toPromise();
  }
}