import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '@app/shared.service';

@Component({
  selector: 'office-picker',
  templateUrl: './office-picker.component.html',
  styleUrl: './office-picker.component.css'
})

export class OfficePickerComponent implements OnInit{

  offices = [];
  office_id: any | null = null;

  @Output() officeChanged = new EventEmitter<string>();

  constructor(private http: HttpClient, private sharedService: SharedService ) { 
  }

  ngOnInit(){
    this.loadOffices();
  }

  async loadOffices(){
    this.sharedService.getOffices().subscribe(data => this.offices = data);
  }

  async onOfficeChange($event){
    this.officeChanged.emit(this.office_id);
  }
}
