import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ViewChildren, OnInit, OnChanges, QueryList, ElementRef, SimpleChanges, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators,FormControl } from "@angular/forms";
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "@app/shared.service";
import { DataService } from "@services/data.service";
import { BaseAccordionComponent } from './info-accordion-base';
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import { TranslateService } from '@ngx-translate/core';
import en from 'air-datepicker/locale/en';

import AirDatepicker from 'air-datepicker';

dayjs.extend(utc);

import * as timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

@Component({
  selector: 'app-info-accordion',
  templateUrl: './info-accordion.component.html',
  styleUrl: './info-accordion.component.css'
})

export class InfoAccordionComponent extends BaseAccordionComponent implements OnInit, AfterViewInit {

  // Inputs and Outputs
  @Input() override result: any = {};
  @Input() parentEvent;
  @Input() childVar: string;
  @Output() closePanel = new EventEmitter<any>();
  @Output() childEvent = new EventEmitter<any>();
  @Input() openMap: boolean;
  @Input() fullReset: boolean;

  // ViewChildren declarations
  @ViewChildren("audioPlayers") audioPlayers: QueryList<ElementRef>;
  @ViewChildren("partialAudioPlayers") partialAudioPlayers: QueryList<ElementRef>;

  // ViewChild declarations
  @ViewChild("accordionPhotos") accordionPhotos!: ElementRef;
  @ViewChild("infoAccordion") accordionInfo!: ElementRef;
  @ViewChild("accordionInfoBtn") accordionInfoBtn!: ElementRef;
  @ViewChild("floatButtons") floatButtons!: ElementRef;
  @ViewChild("photosBtn") photosBtn!: ElementRef;
  @ViewChild("callLogsBtn") callLogsBtn!: ElementRef;
  @ViewChild("callLogsAccordian") callLogsAccordian!: ElementRef;
  @ViewChild("contactInfoBtn") contactInfoBtn!: ElementRef;
  @ViewChild("contactInfoAccordian") contactInfoAccordian!: ElementRef;  
  @ViewChild("orderInfoAccordian") orderInfoAccordian!: ElementRef;
  @ViewChild("orderInfoBtn") orderInfoBtn!: ElementRef;
  @ViewChild('datepickerInput') datepickerInput: ElementRef;

  dateRange: any = null;
  isContactOpen: boolean = false;
  bAreOrdersOpen: boolean = false;
  isPhotosOpen: boolean = false;
  isInfoOpen: boolean = false;
  isCallLogsOpen: boolean = false;
  bAddNote: boolean = false;
  bSaveToDefaultAction: boolean = false;  // Default value
  material_icon = ""
  userLocale = "en-US"

  // Initial state
  idle: boolean = true;

  // Request Initiated
  loading: boolean = true;

  // Success
  success: boolean = false;

  // Failure
  failure: boolean = false;

  // Show the circle
  circle: boolean = true;

  userInfo: any = {}

notes = [
  { id: 'spokeWithDM', key: 'Spoke With DM' },
  { id: 'dmNotAvailable', key: 'DM Not Available' },
  { id: 'leftVM', key: 'Left VM' },
  { id: 'dmAskedForInfo', key: 'DM Asked for Info' },
  { id: 'notInterested', key: 'Not interested' },
  { id: 'dnc', key: 'DNC' },
  { id: 'notQualified', key: 'Not Qualified' },
  { id: 'calledNoAnswerTryAgain', key: 'Called, no answer.  Will try again in a week' }
];

actions = [
  // { id: 5, key: 'Create Fedex Return' }
  // { id: 'snailMail', key: 'Add To Bulk Snail Mail Campaign'},
  // { id: 'testEmail', key: 'Send Test Email'},
  // { id: '25Amazon', key: 'Send $25 Amazon Giftcard'},
  // { id: '25BestBuy', key: 'Send $25 BestBuy Giftcard'},
  // { id: 'sendInvoice', key: 'Send Customer Invoice'},
  // { id: 'sendGiftcard', key: 'Send Giftcard (coming soon)'},
  // { id: 'sendHandwrittenPostcard', key: 'Send Handwritten Postcard (coming soon)'},
  // { id: 'sendSms', key: 'Send Text Message (coming soon)'},
  // { id: 'sendMms', key: 'Text Video or Rich Media (coming soon)'},
  // { id: 'sendEmail', key: 'Send Email (coming soon)'},
  // { id: 'addToDripCampaign', key: 'Add To Drip Campaign (coming soon)'},
  // { id: 'archiveForever', key: 'Permanently Archive as Not Qualified'}
]

automations = [
  { _id: 'must_book', key: 'Must Book To Get Giftcard'},
  { _id: 'must_watch', key: 'Must Watch Video To Giftcard'},
  { _id: 'no_requirements', key: 'No Actions Required To Get Giftcard'},
  { _id: 'appointment_confirmation', key: 'Appointment Confirmation'},
  { _id: 'voicemail_email_giftcard', key: 'Voicemail, Email, Giftcard'}
  // { _id: 'more_info', key: 'More Info'},
  // { _id: 'not_interested', key: 'Not Interested'},
  // { _id: 'today_appointment', key: 'Appointment - Today'},
  // { _id: 'tomorrow_appointment', key: 'Appointment - Tomorrow'},
  // { _id: '2_day_appointment', key: 'Appointment - 2 Days From Now'},
  // { _id: '3_day_appointment', key: 'Appointment - 3 Days From Now'},
  // { _id: 'next_week_appointment', key: 'Appointment - Next Week'},
  // { _id: 'good_convo_no_appointment_verfified_info', key: 'Good Convo - Verified Info'},
  // { _id: 'good_convo_no_appointment_info_not_verified', key: 'Good Confo - Info Not Verified'},
  // { id: 'snailMail', key: 'Add To Bulk Snail Mail Campaign'},
  // { id: 'testEmail', key: 'Send Test Email'},
  // { id: '25Amazon', key: 'Send $25 Amazon Giftcard'},
  // { id: '25BestBuy', key: 'Send $25 BestBuy Giftcard'},
  // { id: 'sendInvoice', key: 'Send Customer Invoice'},
  // { id: 'sendGiftcard', key: 'Send Giftcard (coming soon)'},
  // { id: 'sendHandwrittenPostcard', key: 'Send Handwritten Postcard (coming soon)'},
  // { id: 'sendSms', key: 'Send Text Message (coming soon)'},
  // { id: 'sendMms', key: 'Text Video or Rich Media (coming soon)'},
  // { id: 'sendEmail', key: 'Send Email (coming soon)'},
  // { id: 'addToDripCampaign', key: 'Add To Drip Campaign (coming soon)'},
  // { id: 'archiveForever', key: 'Permanently Archive as Not Qualified'}
]

  async triggerAutomation($event, action){
    $event.preventDefault();
    let submission = await this.http.post(`api/automation/triggerAutomation/lead_id/${this.current_id}`, { action: action }).toPromise();    

  }

  previousActions = []

  handleEvent(action: any) {
    console.log('Action received:', action);
    // Add your event handling logic here
  }
  
  async saveAction($event, action){
    $event.preventDefault();
    // var action = this.getNoteMessage(actionId);

    // let now = dayjs();

    // let submission = await this.http
    //   .post(`api/datasource/coldleads/array/id/${this.current_id}`, {
    //     key: "actions",
    //     value: {
    //       content: this.action,
    //       date: `${this.fullName} - ${now.toISOString()}`,
    //     },
    //   })
    //   .toPromise();

    // this.previousActions.push({
    //   content: this.note,
    //   date: `${this.fullName} - ${now.toISOString()}`,
    // });

    //await this.http.post(`api/agents/scheduleAction`, { ... action, "contact_id": this.current_id }).toPromise();

    if(action.id === 5){
      const confirmation = confirm("Are you sure you want to create a Fedex Return Label?");

      if (confirmation) {
        try{
          console.log(143, );
          let orderId = action['orderId']
          let result = await this.http.get(`api/administration/createFedexReturnShippingLabel/order_id/${orderId}`).toPromise();
        } catch(err){
           
        }
      }

        alert("Return Label Has Been Generated And Emailed");
      }
    
    console.log(109, action, this.current_id);

  }

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private dataService: DataService,
    private translateService: TranslateService
  ) {
    super();
  }

  accountType = "";

  override async ngOnInit(){

    super.ngOnInit();

    this.getNavigationMenus();

    this.dataService.newPanelId().subscribe( contactId => {
      this.loadContact(contactId);
    });

    let profile = await this.http
      .get(`/api/main/user/profile/getProfile`)
      .toPromise();

    this.dataService.getUserInfo().subscribe((data) => {
      this.userInfo = data;
      this.accountType = 'office-manager'
    });

    if (profile["profile"]) {
      this.fullName = profile["profile"]["fullName"];
    }

    this.loadCustomNotes();
  }

  async loadCustomNotes(){
    let results = await this.http.get(`api/datasource/customnotes`).toPromise();
    
    if(typeof results['customnotes'] === 'undefined'){
      return;
    }

    for(let customnote of results['customnotes']){
      this.notes.push({ id: customnote['_id'], key: customnote['custom_note'] } );
    }
  }

  async loadContact(contactId, result: any =null){

        this.resetPanel();

        this.findOrders(contactId);

        this.current_id = contactId

        if(result === null){
          result = await this.http
            .get(`api/datasource/coldleads/id/${this.current_id}`)
            .toPromise();          
        }

          let coldleads = result["coldleads"];

          if (coldleads["notes"]) {
            this.previousNotes = coldleads["notes"].reverse();
          } else {
            this.previousNotes = [];
          }

          if (coldleads["events"]) {
            this.events = coldleads["events"].reverse();
          } else {
            this.events = [];
          }

          if (this.openMap) {
            console.log(207, "setMapCenter", coldleads["scrape_result"]["latitude"]);
            
            this.sharedService.setMapCenter({
              latitude: coldleads["scrape_result"]["latitude"],
              longtitude: coldleads["scrape_result"]["longtitude"],
            });
          }

          this.contact.first_name = coldleads["contactFirstName"];
          this.contact.last_name = coldleads["contactLastName"];
          this.contact.extension = coldleads["contactExtension"];
          this.contact.email = coldleads["contactEmail"];
          this.contact.apemail = coldleads["contactAPEmail"];
          this.contact.phone = coldleads["scrape_result"]["phone"];
          this.contact.title = coldleads["scrape_result"]["title"];
          this.contact.group = coldleads["scrape_result"]["group"] || "";
          this.contact.isCellPhone = coldleads["scrape_result"]["isCellPhone"] || false;
          this.contact.additionalPhones = coldleads["additionalPhones"] || [];
          this.imagesAr = coldleads["scrape_result"]["images"];

          this.result = result["coldleads"];

          if (
            typeof this.result["scrape_result"]["complete_address"] ==
            "undefined"
          ) {
            this.result["scrape_result"]["complete_address"] = {
              street: "",
              city: "",
              state: "",
              postal_code: "",
              country: "",
            };
          }

          this.contactPhoneNumbers.push(this.contact.phone);

          if (coldleads["additionalPhones"]) {
            if (Array.isArray(coldleads["additionalPhones"])) {
              for(var num of coldleads["additionalPhones"])
              this.contactPhoneNumbers = this.contactPhoneNumbers.concat(
                num['number']
              );
            }
          }

    let cleanedPhoneNumbers;

    if (this.contactPhoneNumbers.length > 0) {
      if (typeof this.contactPhoneNumbers[0] === 'string') {
        // If the array contains strings, remove null or undefined elements
        cleanedPhoneNumbers = this.contactPhoneNumbers.filter(number => number != null);
      } else if (typeof this.contactPhoneNumbers[0] === 'object' && this.contactPhoneNumbers[0] !== null) {
        // If the array contains objects, convert it to an array of strings
        cleanedPhoneNumbers = this.contactPhoneNumbers.map(obj => String(obj));
      }
    } else {
      cleanedPhoneNumbers = []; // Handle empty array case
    }


          let callLogs: any = await this.http
            .post(`api/administration/getCallLogs`, {
              phoneNumbers: cleanedPhoneNumbers,
            })
            .toPromise();

          this.callLogs = callLogs["callLogs"];

          if(this.callLogs.length > 0){
            this.bShowCallLogs = true;
          }

          // for(var call of this.callLogs){

          //   //call['from'] = this.dataService.formatPhoneNumber(call['from']);
          //   //call['to'] = this.dataService.formatPhoneNumber(call['to']);
            
          //   call['duration'] = this.calculateDuration(call['start_time'], call['end_time'])

          // }

          this.callLogs = this.callLogs.filter(call => {
            // Format phone numbers if needed
            // call['from'] = this.dataService.formatPhoneNumber(call['from']);
            // call['to'] = this.dataService.formatPhoneNumber(call['to']);

            // Calculate the duration
            call['duration'] = this.calculateDuration(call['start_time'], call['end_time']);

            // Only keep calls that don't have a duration of '00:00:00'
            return call['duration'] !== '00:00:00';
          });



          this.callRecords = coldleads["callRecords"];
          try {
            this.callRecords = this.callRecords.reverse();
          } catch (err) {}

          this.recordingsLoaded = true;

          if (coldleads["scrape_result"]["user_reviews"] != null) {
            for (let userReview of coldleads["scrape_result"]["user_reviews"]) {
              if (userReview["Images"] != null) {
                for (let image of userReview["Images"]) {
                  this.imagesAr.push({
                    title: "User Review Image",
                    image: image,
                  });
                }

              }
            }
          }

          let website_url = coldleads["scrape_result"]["web_site"];

          if(this.userInfo.team_id == "65e334ea8d05c2e2171b24d3"){
            //this.lookupSales(coldleads["scrape_result"]['title']);  
          }
          
          //if(this.userInfo.account_type == 'accounting'){
            
          //}
  }

  ngAfterViewInit(){

    let startDate = new Date();
    startDate.setHours(8, 0, 0, 0);

    // new AirDatepicker(this.datepickerInput.nativeElement, {
    //   startDate: startDate,
    //   timepicker: true,
    //   locale: en,
    //   minutesStep: 15,
    //   onSelect: ({formattedDate, date, datepicker}) => {
    //     console.log('Selected date:', formattedDate);  // formattedDate is the formatted string of the date
    //     console.log('Date object:', date);  // date is the JavaScript Date object or an array of Dates
    //     console.log('Datepicker instance:', datepicker);  // datepicker is the instance of AirDatepicker
    //     this.setAppointmentTime(date)
    //   },
    // });
  
  }

  handleDateChange($event){
    this.setAppointmentTime($event['start_date']);
  }

  async setAppointmentTime(date) {
    date = dayjs(date); // Use dayjs for handling dates
    const timeZone = this.result["scrape_result"]["timezone"];

    // Assuming you need to set the time to a specific time of the day:
    const appointmentTimeInTimeZone = date.format("YYYY-MM-DDTHH:mm:ss.sssZ");

    let appointment = {
      appointmentTime: appointmentTimeInTimeZone,
      contact_id: this.result["_id"],
      contact_title: this.contact.title,
      contact_phone: this.contact.phone,
      contact_name: this.contact.first_name,
      contacts_timezone: timeZone,
    };

    if (this.appointmentId == null) {
      let record = await this.http.post(`api/datasource/appointments`, appointment).toPromise();
      this.appointmentId = record["appointments"]["inserted_id"];
    } else {
      let record = await this.http.patch(`api/datasource/appointments/id/${this.appointmentId}`, {
        key: "appointmentTime",
        value: appointment.appointmentTime
      }).toPromise();
    }

    try {
      let patchData = { cooldown: appointmentTimeInTimeZone };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.result["_id"]}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    this.dataService.addToArray("coldleads", this.result["_id"], "appointments", appointment)

    this.dataService.refreshTable();
    this.resetPanel();
    this.dataService.closePanelAction();
  }


  async flagAccountForReview() {

    this.result["bFlagAccountForReview"] = !this.result["bFlagAccountForReview"];
    
    try {
      let patchData = { bFlagAccountForReview: this.result["bFlagAccountForReview"] };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.result["_id"]}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

  }

async toggleFavoriteAccount(){
    this.result["bSetAsFavorite"] = !this.result["bSetAsFavorite"];
    
    try {
      let patchData = { bSetAsFavorite: this.result["bSetAsFavorite"] };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.result["_id"]}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

}

  async onSetCooldownPeriod() {
    let cooldownUntil = "";
    let now = dayjs();

    switch (this.selectedCoolingPeriod) {
      case "1 hour":
        cooldownUntil = now.add(1, "hour").format();
        break;
      case "10 hour":
        cooldownUntil = now.add(10, "hour").format();
        break;
      case "1 day":
        cooldownUntil = now.add(1, "day").format();
        break;
      case "1 week":
        cooldownUntil = now.add(1, "week").format();
        break;
      case "1 month":
        cooldownUntil = now.add(1, "month").format();
        break;
      case "6 weeks":
        cooldownUntil = now.add(6, "week").format();
        break;
      case "3 months":
        cooldownUntil = now.add(3, "month").format();
        break;
      case "6 months":
        cooldownUntil = now.add(6, "month").format();
        break;
      case "12 months":
        cooldownUntil = now.add(12, "month").format();
        break;
      case "Forever":
        //cooldownUntil = now.add(100, 'year').format();
        break;
    }

    // For testing

    let patchData = { cooldown: cooldownUntil, appointment: 'appointment' };
    if (this.selectedCoolingPeriod == "Forever") {
      patchData["archived"] = now.add(100, "year");
      delete patchData["cooldown"];
    }

    try {
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.result["_id"]}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }

    console.log(312, this.result["_id"]);

    this.dataService.addToArray("coldleads", this.result["_id"], "cooldownHistory", { appointmentTime: cooldownUntil } )

    this.dataService.refreshTable();
    this.resetPanel();
    this.dataService.closePanelAction();
  }

  closePanelAction($event) {
    this.resetPanel();
    this.closePanel.emit(true);
  }

  trackByFn(index: any, item: any) {
    return index; // or item.id if your items have unique ids
  }

  scrollToView(element: HTMLElement): void {
    setTimeout(() => {
      const options: ScrollIntoViewOptions = {
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      };
      element.scrollIntoView(options);

    }, 750);
  }

  async editContact() {
    this.edit = !this.edit;
  }

  async saveContact() {
    let complete_address: any = {
      borough: "",
      city: this.result["scrape_result"]["complete_address"]["city"],
      street: this.result["scrape_result"]["complete_address"]["street"],
      postal_code:
        this.result["scrape_result"]["complete_address"]["postal_code"],
      state: this.result["scrape_result"]["complete_address"]["state"],
      country: this.result["scrape_result"]["complete_address"]["country"],
    };

    let address = `${complete_address.street}, ${complete_address.city}, ${complete_address.state} ${complete_address.postal_code}`;
    this.result["scrape_result"]["address"] = address;
    try {
      let patchData = {
        "scrape_result.complete_address": complete_address,
        "scrape_result.title": this.result["scrape_result"]["title"],
        "scrape_result.address": address,
      };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
    console.log(918, complete_address);

    this.edit = !this.edit;
  }

  async addToHistory(eventType: string) {
    let now = dayjs();
    let submission = await this.http
      .post(`api/datasource/coldleads/array/id/${this.current_id}`, {
        key: "events",
        value: {
          type: eventType,
          date: `${this.fullName} - ${now.toISOString()}`,
        },
      })
      .toPromise();

    this.events.push({
      type: eventType,
      date: `${this.fullName} - ${now.toISOString()}`,
    });
  }

  async archive() {
    this.loadNextContact();
    // let cooldownUntil = "";
    // let now = dayjs();

    // let patchData = { archived: now.add(100, "year") };

    // try {
    //   let result = await this.http
    //     .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
    //     .toPromise();
    //   console.log("Patch result:", result);
    // } catch (error) {
    //   console.error("Error during PATCH request:", error);
    // }

    // this.dataService.refreshTable();
    // this.resetPanel();
    // this.closePanelAction(null);


  }

  async loadNextContact(){
    console.log(417, this.current_id)
    let next_contact = await this.http.get(`api/agents/getNextContact/contact_id/${this.current_id}`).toPromise();    
    console.log(419, next_contact);

    this.loadContact(next_contact["coldleads"]["_id"], next_contact);
    this.dataService.setNextContact(next_contact);

  }

  menus = []

  async getNavigationMenus(){
              const response: any = await this.http.get('/api/getNavigationMenus').toPromise();
              this.menus = response["menus"];

              let userInfo = response["userInfo"];

              const first_name = userInfo?.first_name ?? false;
              const last_name = userInfo?.last_name ?? false;
              const account_type = userInfo?.account_type ?? false;

              this.dataService.setNames(first_name, last_name, account_type);

              const balance = userInfo?.balance ?? 0;
              const points = userInfo?.points ?? 0;
              this.dataService.setBalances(balance, points)

              this.dataService.setSharedData(userInfo);

              this.dataService.setUserInfo(userInfo);
  }

  placeOrder() {
    console.log(962, this.result);
    this.dataService.updateOrderCustomer(this.result);
  }

  displayNote(){
    this.bAddNote = true;
  }

  async call(){
    let data = {
      _id: this.result._id,
      buttonName: "Call",
      row: this.result["scrape_result"]
    }
    this.sharedService.sendData( data );
    console.log(443, data);
  }

  async saveNote($event, note){
    $event.preventDefault();
    this.note = this.getNoteMessage(note);
    this.addNote();
  }

private getNoteMessage(noteType: string): string {
  const note = this.notes.find(n => n.id === noteType);
  return note ? this.translateService.instant(note.key) : 'Unknown note type';
}

  async addNote() {
    let now = dayjs();

    let submission = await this.http
      .post(`api/datasource/coldleads/array/id/${this.current_id}`, {
        key: "notes",
        value: {
          content: this.note,
          date: `${this.fullName} - ${now.toISOString()}`,
        },
      })
      .toPromise();

    this.previousNotes.push({
      content: this.note,
      date: `${this.fullName} - ${now.toISOString()}`,
    });

    await this.http.put(`api/datasource/coldleads/id/${this.current_id}`, { "lastDisposition": this.note } ).toPromise();

    // Save this as a default note
    if(this.bSaveToDefaultAction === true){
      let result = await this.http.post(`api/datasource/customnotes`, { "custom_note": this.note }).toPromise();
      this.notes.push({ id: result['customnotes']['inserted_id'], key: this.note } );
    }
    
    this.note = "";
    this.bAddNote = false;

  }

  async playAudio(call_session_id, playerIndex: number, partial = false) {
    let index = playerIndex;

    let endpoint = `api/calls/getCallRecordingInfo`;
    if (partial === true) {
      endpoint = `api/calls/getCallRecordingInfoPartial`;
    }
    let call_recording_info = await this.http
      .post(endpoint, {
        call_record_id: call_session_id,
      })
      .toPromise();

    try {
      let download_urls =
        call_recording_info["call_recording"]["download_urls"];
      let keys = Object.keys(download_urls);

      const audioPlayerRef = this.audioPlayers.toArray()[index];

      var url = download_urls[keys[0]];
    } catch (err) {}

    this.tracks[playerIndex].url = url;

    const audioPlayerRef = this.audioPlayers.toArray()[playerIndex];

    if (audioPlayerRef && audioPlayerRef.nativeElement) {
      // Check if source is already set, if not, set it here and load

      if (
        !audioPlayerRef.nativeElement.src ||
        audioPlayerRef.nativeElement.src !== this.tracks[playerIndex].url
      ) {
        audioPlayerRef.nativeElement.src = this.tracks[playerIndex].url;
        audioPlayerRef.nativeElement.load();
      }
      // Attempt to play
      audioPlayerRef.nativeElement
        .play()
        .then(() => {
          console.log("Playback started successfully");
        })
        .catch((e) => {
          console.error("Playback failed:", e);
          // Handle or log the error, possibly retry or provide user feedback
        });
    }
  }

  async onPlay(call_session_id, index) {
    let call_recording_info = await this.http
      .post(`api/calls/getCallRecordingInfo`, {
        call_record_id: call_session_id,
      })
      .toPromise();

    try {
      let download_urls =
        call_recording_info["call_recording"]["download_urls"];
      let keys = Object.keys(download_urls);

      const audioPlayerRef = this.audioPlayers.toArray()[index];

      let url = download_urls[keys[0]];
      this.tracks[index] = url;
      audioPlayerRef.nativeElement.load();
      audioPlayerRef.nativeElement
        .play()
        .catch((e) => console.error("Playback failed:", e));
      //his.currentAudioFile = url;
      //this.loadAudioFile = true;
    } catch (err) {}
    //console.log(39, call_recording_info);
  }

  onPause() {
    console.log("Audio paused");
    this.currentAudioFile = "";
    this.loadAudioFile = false;
  }

  onEnded() {
    this.currentAudioFile = "";
    this.loadAudioFile = false;
  }

  onTimeUpdate(event: any) {
    console.log("Current time: ", event.target.currentTime);
  }

  async playPartialAudio(call_session_id, playerIndex: number, partial = false ) {
    var endpoint = `api/calls/getCallRecording/call_record_id/${call_session_id}`;

    let call_recording_info = await this.http.get(endpoint).toPromise();

    try {
      let download_urls = call_recording_info["download_urls"];
      let keys = Object.keys(download_urls);
      console.log(keys);
      var url = download_urls[keys[0]];
    } catch (err) {}

    console.log(93, url);

    let pindex = 0;
    let answer = 0;
    for (let i = 0; i < this.callLogs.length; i++) {
      //console.log(97,  this.callLogs[i]);
      if (!Array.isArray(this.callLogs[i].callRecordings)) {
        continue;
      }

      for (let y = 0; y < this.callLogs[i].callRecordings.length; y++) {
        let callRecording = this.callLogs[i]["callRecordings"][y];
        console.log(99, callRecording);
        if (callRecording["recording_id"] == call_session_id) {
          answer = pindex;
        } else {
          pindex++;
        }
      }
    }

    this.partialTracks[answer].url = url;

    const audioPlayerRef = this.partialAudioPlayers.toArray()[answer];

    if (audioPlayerRef && audioPlayerRef.nativeElement) {
      // Check if source is already set, if not, set it here and load

      if (
        !audioPlayerRef.nativeElement.src ||
        audioPlayerRef.nativeElement.src !== this.partialTracks[answer].url
      ) {
        audioPlayerRef.nativeElement.src = this.partialTracks[answer].url;
        audioPlayerRef.nativeElement.load();
      }
      // Attempt to play
      audioPlayerRef.nativeElement
        .play()
        .then(() => {
          console.log("Playback started successfully");
        })
        .catch((e) => {
          console.error("Playback failed:", e);
          // Handle or log the error, possibly retry or provide user feedback
        });
    }
  }

  async onfNameChange() {
    try {
      let patchData = { contactFirstName: this.contact.first_name };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactFirstName"] = this.contact.first_name;
      this.result = { patchData, ...this.result };
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onlNameChange() {
    try {
      let patchData = { contactLastName: this.contact.last_name };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactLastName"] = this.contact.last_name;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onExtensionChange() {
    try {
      let patchData = { contactExtension: this.contact.extension };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactExtension"] = this.contact.extension;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onEmailChange() {
    try {
      let patchData = { contactEmail: this.contact.email };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactEmail"] = this.contact.email;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onAPEmailChange() {
    try {
      let patchData = { contactAPEmail: this.contact.apemail };
      console.log(439, patchData);
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      this.result["contactAPEmail"] = this.contact.apemail;
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  onAdditionalPhoneChange(one){
  }

  addSecondaryNumber(): void {
    this.contact.additionalPhones.push({number: "", isCellPhone: false});
  }

  async onPhoneChange() {
    try {
      let patchData = { "scrape_result.phone": this.contact.phone };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onTitleChange() {
    try {
      let patchData = { "scrape_result.title": this.contact.title };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async onGroupChange() {
    try {
      let patchData = { "scrape_result.group": this.contact.group };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
    }
  }

  async saveAPEmail() {
    console.log(882, this.contact.apemail, this.contact.title);
    let callLogs: any = await this.http
      .post(`api/administration/addAPEMailToQuickbooks`, {
        apEmail: this.contact.apemail,
        primaryEmail: this.contact.email,
        displayName: this.contact.title,
      })
      .toPromise();
    alert("Saved");
  }

async savePhone(index) {
    // Format the phone number

    const formattedPhoneNumber = this.dataService.formatPhoneNumber(
        this.contact.additionalPhones[index]['number']
    );

    // Check for duplicates
    if (this.contact.additionalPhones[index]['number'].includes(formattedPhoneNumber)) {
        console.log(92, this.contact.additionalPhones, formattedPhoneNumber)
        alert("Duplicate phone number. The number is already added.");
        return false;
    }

    // Update the phone number in the contact's additionalPhones array
    this.contact.additionalPhones[index]['number'] = formattedPhoneNumber;

    // Update contactPhoneNumbers array
    //this.contactPhoneNumbers = [this.contact.phone].concat({ phone: this.contact.additionalPhones, isCellPhone: false } );

    // Save the phone number to the server
    let result = await this.http
        .put(`api/datasource/coldleads/array/id/${this.current_id}`, {
            key: "additionalPhones",
            value: this.contact.additionalPhones[index],
        })
        .toPromise();


    let cleanedPhoneNumbers;

    if (this.contactPhoneNumbers.length > 0) {
      if (typeof this.contactPhoneNumbers[0] === 'string') {
        // If the array contains strings, remove null or undefined elements
        cleanedPhoneNumbers = this.contactPhoneNumbers.filter(number => number != null);
      } else if (typeof this.contactPhoneNumbers[0] === 'object' && this.contactPhoneNumbers[0] !== null) {
        // If the array contains objects, convert it to an array of strings
        cleanedPhoneNumbers = this.contactPhoneNumbers.map(obj => String(obj));
      }
    } else {
      cleanedPhoneNumbers = []; // Handle empty array case
    }

    // Fetch call logs
    let callLogs: any = await this.http
        .post(`api/administration/getCallLogs`, {
            phoneNumbers: cleanedPhoneNumbers,
        })
        .toPromise();

    this.callLogs = callLogs["callLogs"];

    alert("Phone Number Saved");
    return true;
}

async deletePhone(index){
    let result = await this.http
        .put(`api/datasource/coldleads/arrayrm/id/${this.current_id}`, {
            key: "additionalPhones",
            value: this.contact.additionalPhones[index],
        })
    .toPromise();
    this.contact.additionalPhones.splice(index, 1);
}

  onDropdownChange(){
  }

  bShowCallLogs = false;

  resetPanel() {

    this.germanTeamSuggest = null;
    this.resetTrigger = true;
    this.bAddNote = false;
    this.ordersAr = [];
    this.bSaveToDefaultAction = false;
    // Reset the trigger after a brief delay to avoid multiple resets
    setTimeout(() => this.resetTrigger = false, 500);
    this.bShowCallLogs = false;
    this.current_id = "";
    this.imagesAr = [];
    this.possibleExtensionsAr = [];
    this.contact = {
      first_name: "",
      last_name: "",
      extension: "",
      apemail: "",
      email: "",
      phone: "",
      title: "",
      additionalPhones: [],
      group: "",
      isCellPhone: false
    };
    this.note = "";
    this.selectedCoolingPeriod = "";
    this.result = {};
    this.events = [];
    this.call_recordings = [];
    this.appointmentId = null;
    this.callLogs = [];
    this.tracks = Array.from({ length: 200 }, () => ({ url: "" }));
    this.partialTracks = Array.from({ length: 200 }, () => ({ url: "" }));

    this.appointmentTime = "";
    this.appointmentDay = "";
    this.selectedCoolingPeriod = "";

    this.contactPhoneNumbers = [];

    if(this.fullReset === true){

      const accordionInfo: HTMLElement = this.accordionInfo.nativeElement;
      const accordionInfoBtn: HTMLElement = this.accordionInfoBtn.nativeElement;

      accordionInfo.classList.remove("show");
      //accordionInfoBtn.add("collapsed");

      console.log(776, this.fullReset);
      const accordion: HTMLElement = this.accordionPhotos.nativeElement;
      const accordionBtn: HTMLElement = this.photosBtn.nativeElement;
      //if (accordion.classList.contains("show")) {
        accordion.classList.remove("show");
        accordionBtn.classList.add("collapsed");
      //}

      //if (this.callLogsAccordian.nativeElement.classList.contains("show")) {
        this.callLogsAccordian.nativeElement.classList.remove("show");
        this.callLogsBtn.nativeElement.classList.add("collapsed");
      //}

      //if (this.contactInfoAccordian.nativeElement.classList.contains("show")) {
        this.contactInfoAccordian.nativeElement.classList.remove("show");
        this.contactInfoBtn.nativeElement.classList.add("collapsed");

if (this.orderInfoAccordian && this.orderInfoAccordian.nativeElement) {
  this.orderInfoAccordian.nativeElement.classList.remove("show");
}

if (this.orderInfoBtn && this.orderInfoBtn.nativeElement) {
  this.orderInfoBtn.nativeElement.classList.add("collapsed");
}

      //}      
    }

  }

async removeAppointment(note) {

}

async removeNote(note) {
  console.log("Note object received:", note);  // Log the entire event object

    var api_url = 'api/datasource/coldleads/arrayrm/id/' + this.current_id;
    let result = await this.http.post(api_url, { "key": "notes", "value": note }).toPromise();
    this.previousNotes = this.previousNotes.filter(e => e.content !== note.content || e.date !== note.date);
}

async removeEvent(event) {
    console.log("Event object received:", event);  // Log the entire event object

    var api_url = 'api/datasource/coldleads/arrayrm/id/' + this.current_id;
    let result = await this.http.post(api_url, { "key": "events", "value": event }).toPromise();

    //if (result.success) {
        console.log("Removing event with ID:", event.id);  // Now log the ID
        console.log("Original events count:", this.events.length);
        
        this.events = this.events.filter(e => e.type !== event.type || e.date !== event.date);

        console.log("Updated events count:", this.events.length);
    //} else {
    //    console.error('Failed to remove event from the server');
    //}
}

getNameComponent(dateString: string): string {
  const parts = dateString.split(' - ', 2);
  return parts[0]; 
}

getIsoDateString(dateString: string): string {
  const parts = dateString.split(' - ', 2); // Split based on the first occurrence of "-"
  return parts[1]; // Return the ISO string part
}

goFullScreen() {
  const doc = document.documentElement as any;  // Using 'any' to bypass strict type checks

  if (doc.requestFullscreen) {
    doc.requestFullscreen();
  } else if (doc.mozRequestFullScreen) { // Firefox
    doc.mozRequestFullScreen();
  } else if (doc.webkitRequestFullscreen) { // Chrome, Safari & Opera
    doc.webkitRequestFullscreen();
  } else if (doc.msRequestFullscreen) { // IE/Edge
    doc.msRequestFullscreen();
  }
}

exitFullScreen() {
  const doc = document as any;  // Using 'any' to bypass strict type checks

  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.mozCancelFullScreen) { // Firefox
    doc.mozCancelFullScreen();
  } else if (doc.webkitExitFullscreen) { // Chrome, Safari, Opera
    doc.webkitExitFullscreen();
  } else if (doc.msExitFullscreen) { // IE/Edge
    doc.msExitFullscreen();
  }
}

async deleteAppointment(appointment){

    var api_url = 'api/datasource/coldleads/arrayrm/id/' + this.result["_id"];

    let result = await this.http.post(api_url, { "key": "appointments", "value": appointment}).toPromise();
    this.result.appointments = this.result.appointments.filter(e => e.appointmentTime !== appointment.appointmentTime);

}

async deleteCooldown(cooldown){
    var api_url = 'api/datasource/coldleads/arrayrm/id/' + this.result["_id"];
    console.log(953, api_url);
    let result = await this.http.post(api_url, { "key": "cooldownHistory", "value": cooldown}).toPromise();
    this.result.cooldownHistory = this.result.cooldownHistory.filter(e => e.appointmentTime !== cooldown.appointmentTime);
    console.log(956, result);
}

calculateDuration(start: string, end: string): string {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Check if either date is invalid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return '00:00:00'; // Return empty string if dates are invalid
  }

  const duration = endDate.getTime() - startDate.getTime(); // Duration in milliseconds

  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor(duration / (1000 * 60 * 60));

  const hoursPad = (hours < 10) ? "0" + hours : hours;
  const minutesPad = (minutes < 10) ? "0" + minutes : minutes;
  const secondsPad = (seconds < 10) ? "0" + seconds : seconds;

  return `${hoursPad}:${minutesPad}:${secondsPad}`;
}

openGoogleMaps($event, cid){
  $event.preventDefault();
  console.log(1109, cid);
  window.open(`https://www.google.com/maps?cid=${cid}`, "_blank");
}

openGoogleReviews($event, link){
  $event.preventDefault();
  console.log(1109, link);
  window.open(link, "_blank");
}

controlslist: string = 'nodownload';  

disableContextMenu(event: MouseEvent) {   
 event.preventDefault();  
}

germanTeamSuggest = null;
// Custom for German Team
async lookupSales(searchTerm: string) {
  const apiUrl = 'https://www.northdata.de/suggest.json';

  while (searchTerm) {
    const url = `${apiUrl}?query=${encodeURIComponent(searchTerm)}&countries=`;
    let result = await this.http.get<any>(url).toPromise();
    console.log(1133, result);

    if (result['results'] && result['results'].length > 0) {
      this.germanTeamSuggest = result;
      return;
    }

    // Remove the last word from the search term
    const words = searchTerm.split(' ');
    words.pop();
    searchTerm = words.join(' ');
  }

  // If no results found after all attempts
  this.germanTeamSuggest = { results: [] };
}

ordersAr = [];

async findOrders(customer_id =""){
  let orders = await this.http.post(`api/administration/getContactOrders`, { customer_id: customer_id }).toPromise();
  if(orders['customer_orders'])
    this.ordersAr = orders['customer_orders'];
}

getFedExTrackingLink(trackingNumber: string): string {
  return `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`;
}

possibleExtensionsAr = [];
async findPossibleExtensions(){
  //console.log(1180, this.result.scrape_result.phone, this.userInfo.telephony.number);
  let possibleExtensions = await this.http.post(`api/calls/findPossibleExtensions`, { number: this.result.scrape_result.phone, myNumber: `+this.userInfo.telephony.number`}).toPromise();
  this.possibleExtensionsAr = possibleExtensions['result'];
  console.log(1182, possibleExtensions);
}

  async addSecondaryExtension(number) {
    
    // console.log(1190, this.contact.additionalPhones[this.contact.additionalPhones.length -1])
    let result = await this.saveExtension(number);
    // if(result !== false){
    //   //this.contact.additionalPhones.pop()
    //   this.contact.additionalPhones.push(number);
    // }
  }

async saveExtension(number) {
    // Format the phone number
    const formattedPhoneNumber = this.dataService.formatPhoneNumber(number);

    // Check for duplicates
    if (this.contact.additionalPhones['number'].includes(formattedPhoneNumber)) {
        console.log(92, this.contact.additionalPhones, formattedPhoneNumber)
        alert("Duplicate phone number. The number is already added.");
        return false;
    } else {
        this.contact.additionalPhones['number'].push(formattedPhoneNumber)
    }

    // Update contactPhoneNumbers array
    //this.contactPhoneNumbers = [this.contact.phone].concat(this.contact.additionalPhones);

    // Save the phone number to the server
    let result = await this.http
        .put(`api/datasource/coldleads/array/id/${this.current_id}`, {
            key: "additionalPhones",
            value: formattedPhoneNumber,
        })
        .toPromise();

    // Fetch call logs
    let cleanedPhoneNumbers;

    if (this.contactPhoneNumbers.length > 0) {
      if (typeof this.contactPhoneNumbers[0] === 'string') {
        // If the array contains strings, remove null or undefined elements
        cleanedPhoneNumbers = this.contactPhoneNumbers.filter(number => number != null);
      } else if (typeof this.contactPhoneNumbers[0] === 'object' && this.contactPhoneNumbers[0] !== null) {
        // If the array contains objects, convert it to an array of strings
        cleanedPhoneNumbers = this.contactPhoneNumbers.map(obj => String(obj));
      }
    } else {
      cleanedPhoneNumbers = []; // Handle empty array case
    }

    let callLogs: any = await this.http
        .post(`api/administration/getCallLogs`, {
            phoneNumbers: cleanedPhoneNumbers,
        })
        .toPromise();

    this.callLogs = callLogs["callLogs"];

    alert("Phone Number Saved");
    return true;
}

async onCellPhoneChange() {

    try {
      let patchData = { "scrape_result.isCellPhone": this.contact.isCellPhone };
      let result = await this.http
        .put(`api/datasource/coldleads/id/${this.current_id}`, patchData)
        .toPromise();
      console.log("Patch result:", result);
      // Additional logic here...
    } catch (error) {
      console.error("Error during PATCH request:", error);
  }
}


  giftcardAmount: number = 0;

  async sendGiftcard() {
    const isEmailValid = this.validateEmail(this.contact.email);
    
    if (!this.contact.first_name || !this.contact.last_name) {
       this.dataService.setPopupMessage({"title": "Missing Information", "message":'First name and last name cannot be empty.'})
      return;
    }

    if (!isEmailValid) {
      this.dataService.setPopupMessage({"title": "Missing Information", "message":'Please enter a valid email address.'})
      return;
    }

    if (this.giftcardAmount <= 0) {
      this.dataService.setPopupMessage({"title": "Missing Information", "message":'Please enter a valid amount.'})
      return;
    }

    // Logic to send the gift card
    console.log(`Sending a gift card of $${this.giftcardAmount} to ${this.contact.email}`);
    let giftcardResponse: any = await this.http.post(`api/administration/sendGiftcard`, 
      { first_name: this.contact.first_name, last_name: this.contact.last_name, email: this.contact.email, giftcard_amount: 25, contact_id: this.current_id }).toPromise();



  }

  validateEmail(email: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }


}
